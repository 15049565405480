import React, { useContext, useMemo, useState } from "react";

import { TeamStats } from "../../../shared/routers/TeamRouter";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import { StatRankRenderer } from "../table/renderers/StatRankRenderer";
import { Highlights, CELTICS_TEAM_ID } from "../../constants/AppConstants";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  pythFormat,
  pythDeltaFormat,
  dec100Format,
  plusMinusFormat,
} from "../../util/Format";
import { average, pythagoreanWinExpectation } from "../../util/Util";
import { TeamTableCell } from "../core/TableCell";
import { extent as extentNew } from "../../util/Util";
import { sortNullsToEnd } from "../../util/Sort";
import { TeamContext } from "../../TeamContext";
import { reduceArrayToObject } from "../../../shared/util/Collections";

// Refactored the extent method to return an object instead of array and this is
// just a shim to match old behavior so I can refactor later.
function extent<T>(data: T[], accessor: (d: T) => number) {
  const { min, max } = extentNew(data, accessor);
  return [min, max];
}

const columnHelper = createColumnHelper<TeamStats>();

export function TeamRankingsTable(props: {
  data: TeamStats[];
  selectedGroupSet: Set<string>;
}) {
  const { selectedGroupSet } = props;
  const [sorting, setSorting] = useState<SortingState>();
  const { teams } = useContext(TeamContext);

  const teamDict = reduceArrayToObject(teams, (t) => t.teamid.toString());

  const data = props.data.sort(
    (a, b) =>
      (b.xPPP || 0) - (b.xPPPOpp || 0) - ((a.xPPP || 0) - (a.xPPPOpp || 0))
  );

  // Given the team stats data, create a dict, keyed by teamId, that is a dict of
  // stat name to league rank.
  const teamRanks = useMemo(() => {
    // const teamRankObj: Record<number, Record<(keyof TeamStats) | "xPPPNet" | "PPPNet", number>> = {};
    const teamRankObj: Record<
      number,
      Record<keyof TeamStats | "xPPPNet" | "PPPNet", number>
    > = {};
    for (const team of data) {
      teamRankObj[team.teamId] = {} as Record<
        keyof TeamStats | "xPPPNet" | "PPPNet",
        number
      >;
    }

    const netStats: ("xPPPNet" | "PPPNet")[] = ["xPPPNet", "PPPNet"];
    const highIsGoodStats: (keyof TeamStats)[] = [
      "xPPP",
      "PPP",
      "xPPS",
      "xPPSLg",
      "PPS",
      "efg",
      "tovPctOpp",
      "xTovPctOpp",
      "crashersPerShot",
      "deepCrashersPerShot",
      "orPct",
      "xOrPct",
      "ftaPct",
      "pace",
      "2pPct",
      "3pPct",
      "3paPct",
      "ftPct",
      "astFgmPct",
      "blkRateOpp",
      "stlRateOpp",
      "netEoqPts",
      "netEoqXpts",
    ];
    const lowIsGoodStats: (keyof TeamStats)[] = [
      "xPPPOpp",
      "PPPOpp",
      "xPPSOpp",
      "xPPSLgOpp",
      "PPSOpp",
      "efgOpp",
      "tovPct",
      "xTovPct",
      "crashersPerShotOpp",
      "deepCrashersPerShotOpp",
      "orPctOpp",
      "xOrPctOpp",
      "ftaPctOpp",
      "2pPctOpp",
      "3pPctOpp",
      "3paPctOpp",
      "ftPctOpp",
      "astFgmPctOpp",
      "blkRate",
      "stlRate",
    ];

    // These don't exist on the team stats object, so we calculate them now for
    // rankings.
    const dataWithNetStats = data.map((d) => {
      return {
        teamId: d.teamId,
        xPPPNet: (d.xPPP || 0) - (d.xPPPOpp || 0),
        PPPNet: (d.PPP || 0) - (d.PPPOpp || 0),
      };
    });
    for (const netStat of netStats) {
      const sorted = dataWithNetStats.sort((a, b) =>
        sortNullsToEnd(b, a, netStat)
      );
      let i = 0;
      for (const s of sorted) {
        teamRankObj[s.teamId]![netStat] = i + 1;
        i++;
      }
    }

    for (const stat of highIsGoodStats) {
      const sorted = [...data].sort((a, b) => sortNullsToEnd(b, a, stat));
      let i = 0;
      for (const s of sorted) {
        teamRankObj[s.teamId]![stat] = i + 1;
        i++;
      }
    }

    for (const stat of lowIsGoodStats) {
      const sorted = [...data].sort((a, b) => sortNullsToEnd(a, b, stat));
      let i = 0;
      for (const s of sorted) {
        teamRankObj[s.teamId]![stat] = i + 1;
        i++;
      }
    }

    return teamRankObj;
  }, [data]);

  const columns = useMemo(() => {
    const xPppLeague = average("xPPP", data);
    const defXpppLeague = average("xPPPOpp", data);
    const pppLeague = average("PPP", data);
    const defPppLeague = average("PPPOpp", data);

    const netXpppDomain = extent(data, (d) => (d.xPPP || 0) - (d.xPPPOpp || 0));
    const offXpppDomain = extent(data, (d) => d.xPPP || 0);
    const defXpppDomain = extent(data, (d) => d.xPPPOpp || 0).reverse();

    const netPppDomain = extent(data, (d) => (d.PPP || 0) - (d.PPPOpp || 0));
    const offPppDomain = extent(data, (d) => d.PPP || 0);
    const defPppDomain = extent(data, (d) => d.PPPOpp || 0).reverse();

    const offEfgDomain = extent(data, (d) => d.efg || 0);
    const defEfgDomain = extent(data, (d) => d.efgOpp || 0).reverse();

    const offCrashersPerShotDomain = extent(
      data,
      (d) => d.crashersPerShot || 0
    );
    const defCrashersPerShotDomain = extent(
      data,
      (d) => d.crashersPerShotOpp || 0
    ).reverse();

    const offDeepCrashersPerShotDomain = extent(
      data,
      (d) => d.deepCrashersPerShot || 0
    );
    const defDeepCrashersPerShotDomain = extent(
      data,
      (d) => d.deepCrashersPerShotOpp || 0
    ).reverse();

    const offToPctDomain = extent(data, (d) => d.tovPct || 0).reverse();
    const defToPctDomain = extent(data, (d) => d.tovPctOpp || 0);

    const offxToPctDomain = extent(data, (d) => d.xTovPct || 0).reverse();
    const defxToPctDomain = extent(data, (d) => d.xTovPctOpp || 0);

    const offOrbPctDomain = extent(data, (d) => d.orPct || 0);
    const defOrbPctDomain = extent(data, (d) => d.orPctOpp || 0).reverse();

    const offxOrbPctDomain = extent(data, (d) => d.xOrPct || 0);
    const defxOrbPctDomain = extent(data, (d) => d.xOrPctOpp || 0).reverse();

    const offFtaRateDomain = extent(data, (d) => d.ftaPct || 0);
    const defFtaRateDomain = extent(data, (d) => d.ftaPctOpp || 0).reverse();

    const offPpsDomain = extent(data, (d) => d.PPS || 0);
    const defPpsDomain = extent(data, (d) => d.PPSOpp || 0).reverse();

    const offXppsDomain = extent(data, (d) => d.xPPS || 0);
    const defXppsDomain = extent(data, (d) => d.xPPSOpp || 0).reverse();

    const offXppsLeagueDomain = extent(data, (d) => d.xPPSLg || 0);
    const defXppsLeagueDomain = extent(data, (d) => d.xPPSLgOpp || 0).reverse();

    const paceDomain = extent(data, (d) => d.pace);

    const off2PctDomain = extent(data, (d) => d["2pPct"] || 0);
    const def2PctDomain = extent(data, (d) => d["2pPctOpp"] || 0).reverse();

    const off3PctDomain = extent(data, (d) => d["3pPct"] || 0);
    const def3PctDomain = extent(data, (d) => d["3pPctOpp"] || 0).reverse();

    const off3paPctDomain = extent(data, (d) => d["3paPct"] || 0);
    const def3paPctDomain = extent(data, (d) => d["3paPctOpp"] || 0).reverse();

    const offFtPctDomain = extent(data, (d) => d.ftPct || 0);
    const defFtPctDomain = extent(data, (d) => d.ftPctOpp || 0).reverse();

    const offAstFgmPctDomain = extent(data, (d) => d.astFgmPct || 0);
    const defAstFgmPctDomain = extent(
      data,
      (d) => d.astFgmPctOpp || 0
    ).reverse();

    const offStlDomain = extent(data, (d) => d.stlRate).reverse();
    const defStlDomain = extent(data, (d) => d.stlRateOpp);

    const offBlkDomain = extent(data, (d) => d.blkRate).reverse();
    const defBlkDomain = extent(data, (d) => d.blkRateOpp);

    const netEoqPtsDomain = extent(data, (d) => d.netEoqPts || 0);
    const netEoqXptsDomain = extent(data, (d) => d.netEoqXpts || 0);

    let g = 0;
    return [
      columnHelper.accessor("teamId", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            name={teamDict[info.getValue()]?.teamshortname || ""}
            ids={info.row.original.teamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.group({
        id: "xPPP",
        meta: { group: g },
        header: "Expected Efficiency",
        columns: [
          columnHelper.accessor(
            (d) =>
              d.xPPP === null || d.xPPPOpp === null ? null : d.xPPP - d.xPPPOpp,
            {
              id: "netXPPP",
              header: () => "xNET",
              cell: (info) => (
                <StatRankRenderer
                  data={{
                    rank: teamRanks[info.row.original.teamId]?.xPPPNet,
                    stat: info.getValue(),
                  }}
                  config={{
                    rendererOptions: {
                      fmt: makePlusMinus(decFormat2),
                      showStat: true,
                      tooltip: () =>
                        pythFormat(
                          pythagoreanWinExpectation(
                            info.row.original.xPPP,
                            info.row.original.xPPPOpp
                          )
                        ),
                    },
                  }}
                />
              ),
              meta: {
                group: g,
                highlights: Highlights.Max,
                colorDomain: netXpppDomain,
              },
            }
          ),
          columnHelper.accessor("xPPP", {
            header: () => "xOFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPP,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.xPPP,
                          info.row.original.xPPPOpp
                        ) -
                          pythagoreanWinExpectation(
                            xPppLeague,
                            info.row.original.xPPPOpp
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXpppDomain,
            },
          }),
          columnHelper.accessor("xPPPOpp", {
            header: () => "xDEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPPOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.xPPP,
                          info.row.original.xPPPOpp
                        ) -
                          pythagoreanWinExpectation(
                            info.row.original.xPPP,
                            defXpppLeague
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXpppDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "PPP",
        meta: { group: g },
        header: "Efficiency",
        columns: [
          columnHelper.accessor(
            (d) =>
              d.PPP === null || d.PPPOpp === null ? null : d.PPP - d.PPPOpp,
            {
              id: "netPPP",
              header: () => "NET",
              cell: (info) => (
                <StatRankRenderer
                  data={{
                    rank: teamRanks[info.row.original.teamId]?.PPPNet,
                    stat: info.getValue(),
                  }}
                  config={{
                    rendererOptions: {
                      fmt: makePlusMinus(decFormat2),
                      showStat: true,
                      tooltip: () =>
                        pythFormat(
                          pythagoreanWinExpectation(
                            info.row.original.PPP,
                            info.row.original.PPPOpp
                          )
                        ),
                    },
                  }}
                />
              ),
              meta: {
                group: g,
                highlights: Highlights.Max,
                colorDomain: netPppDomain,
              },
            }
          ),
          columnHelper.accessor("PPP", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.PPP,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.PPP,
                          info.row.original.PPPOpp
                        ) -
                          pythagoreanWinExpectation(
                            pppLeague,
                            info.row.original.PPPOpp
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offPppDomain,
            },
          }),
          columnHelper.accessor("PPPOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.PPPOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.PPP,
                          info.row.original.PPPOpp
                        ) -
                          pythagoreanWinExpectation(
                            info.row.original.PPP,
                            defPppLeague
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defPppDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xpps",
        meta: { group: g },
        header: "xPPS (Shot Quality)",
        columns: [
          columnHelper.accessor("xPPS", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXppsDomain,
            },
          }),
          columnHelper.accessor("xPPSOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPSOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXppsDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xppsLeague",
        meta: { group: g },
        header: "xPPS Lg (Shot Difficulty)",
        columns: [
          columnHelper.accessor("xPPSLg", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPSLg,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXppsLeagueDomain,
            },
          }),
          columnHelper.accessor("xPPSLgOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xPPSLgOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXppsLeagueDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "pps",
        meta: { group: g },
        header: "PPS",
        columns: [
          columnHelper.accessor("PPS", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.PPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offPpsDomain,
            },
          }),
          columnHelper.accessor("PPSOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.PPSOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defPpsDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "efg",
        meta: { group: g },
        header: "EFG%",
        columns: [
          columnHelper.accessor("efg", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.efg,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offEfgDomain,
            },
          }),
          columnHelper.accessor("efgOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.efgOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defEfgDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "crashersPerShot",
        meta: { group: g },
        header: "Crashers/Shot",
        columns: [
          columnHelper.accessor("crashersPerShot", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.crashersPerShot,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offCrashersPerShotDomain,
            },
          }),
          columnHelper.accessor("crashersPerShotOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.crashersPerShotOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defCrashersPerShotDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "deepCrashersPerShot",
        meta: { group: g },
        header: "Deep Crashers/Shot",
        columns: [
          columnHelper.accessor("deepCrashersPerShot", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]
                    ?.deepCrashersPerShot,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offDeepCrashersPerShotDomain,
            },
          }),
          columnHelper.accessor("deepCrashersPerShotOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]
                    ?.deepCrashersPerShotOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defDeepCrashersPerShotDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "turnrate",
        meta: { group: g },
        header: "TO%",
        columns: [
          columnHelper.accessor("tovPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.tovPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offToPctDomain,
            },
          }),
          columnHelper.accessor("tovPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.tovPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defToPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xTov",
        meta: { group: g },
        header: "xTO%",
        columns: [
          columnHelper.accessor("xTovPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xTovPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offxToPctDomain,
            },
          }),
          columnHelper.accessor("xTovPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xTovPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defxToPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "orb",
        meta: { group: g },
        header: "OR%",
        columns: [
          columnHelper.accessor("orPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.orPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offOrbPctDomain,
            },
          }),
          columnHelper.accessor("orPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.orPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defOrbPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xOrb",
        meta: { group: g },
        header: "xOR%",
        columns: [
          columnHelper.accessor("xOrPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xOrPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offxOrbPctDomain,
            },
          }),
          columnHelper.accessor("xOrPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.xOrPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defxOrbPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "fta",
        meta: { group: g },
        header: "FTA Rate",
        columns: [
          columnHelper.accessor("ftaPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.ftaPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offFtaRateDomain,
            },
          }),
          columnHelper.accessor("ftaPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.ftaPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defFtaRateDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "pace",
        meta: { group: g },
        header: "Pace",
        columns: [
          columnHelper.accessor("pace", {
            header: () => "Poss / 48",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.pace,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: paceDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "eoq",
        meta: { group: g },
        header: "EOQ Net Scoring",
        columns: [
          columnHelper.accessor("netEoqXpts", {
            header: () => "xPts",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["netEoqXpts"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: plusMinusFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: netEoqXptsDomain,
            },
          }),
          columnHelper.accessor("netEoqPts", {
            header: () => "Pts",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["netEoqPts"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: plusMinusFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: netEoqPtsDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "2pct",
        meta: { group: g },
        header: "2P%",
        columns: [
          columnHelper.accessor("2pPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["2pPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off2PctDomain,
            },
          }),
          columnHelper.accessor("2pPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["2pPctOpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def2PctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "3pct",
        meta: { group: g },
        header: "3P%",
        columns: [
          columnHelper.accessor("3pPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["3pPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off3PctDomain,
            },
          }),
          columnHelper.accessor("3pPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["3pPctOpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def3PctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "3papct",
        meta: { group: g },
        header: "3PA%",
        columns: [
          columnHelper.accessor("3paPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["3paPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off3paPctDomain,
            },
          }),
          columnHelper.accessor("3paPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["3paPctOpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def3paPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "ftpct",
        meta: { group: g },
        header: "FT%",
        columns: [
          columnHelper.accessor("ftPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["ftPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offFtPctDomain,
            },
          }),
          columnHelper.accessor("ftPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.["ftPctOpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defFtPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "astfgmpct",
        meta: { group: g },
        header: "AST/FGM%",
        columns: [
          columnHelper.accessor("astFgmPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.astFgmPct,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offAstFgmPctDomain,
            },
          }),
          columnHelper.accessor("astFgmPctOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.astFgmPctOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defAstFgmPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "stl",
        meta: { group: g },
        header: "STL%",
        columns: [
          columnHelper.accessor("stlRate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.stlRate,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: dec100Format,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offStlDomain,
            },
          }),
          columnHelper.accessor("stlRateOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.stlRateOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: dec100Format,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defStlDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "blk",
        meta: { group: g },
        header: "BLK%",
        columns: [
          columnHelper.accessor("blkRate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.blkRate,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: dec100Format,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offBlkDomain,
            },
          }),
          columnHelper.accessor("blkRateOpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: teamRanks[info.row.original.teamId]?.blkRateOpp,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: dec100Format,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defBlkDomain,
            },
          }),
        ],
      }),
    ];
  }, [data, teamDict, teamRanks]);

  const hiddenColumns = {
    netXPPP: selectedGroupSet.has("xPPP"),
    xPPP: selectedGroupSet.has("xPPP"),
    xPPPOpp: selectedGroupSet.has("xPPP"),
    netPPP: selectedGroupSet.has("PPP"),
    PPP: selectedGroupSet.has("PPP"),
    PPPOpp: selectedGroupSet.has("PPP"),
    efg: selectedGroupSet.has("efg"),
    efgOpp: selectedGroupSet.has("efg"),
    crashersPerShot: selectedGroupSet.has("crashers"),
    crashersPerShotOpp: selectedGroupSet.has("crashers"),
    deepCrashersPerShot: selectedGroupSet.has("deepCrashers"),
    deepCrashersPerShotOpp: selectedGroupSet.has("deepCrashers"),
    tovPct: selectedGroupSet.has("turnrate"),
    tovPctOpp: selectedGroupSet.has("turnrate"),
    xTovPct: selectedGroupSet.has("xTov"),
    xTovPctOpp: selectedGroupSet.has("xTov"),
    orPct: selectedGroupSet.has("orb"),
    orPctOpp: selectedGroupSet.has("orb"),
    xOrPct: selectedGroupSet.has("xOrb"),
    xOrPctOpp: selectedGroupSet.has("xOrb"),
    ftaPct: selectedGroupSet.has("fta"),
    ftaPctOpp: selectedGroupSet.has("fta"),
    PPS: selectedGroupSet.has("pps"),
    PPSOpp: selectedGroupSet.has("pps"),
    xPPS: selectedGroupSet.has("xpps"),
    xPPSOpp: selectedGroupSet.has("xpps"),
    xPPSLg: selectedGroupSet.has("xppsLg"),
    xPPSLgOpp: selectedGroupSet.has("xppsLg"),
    pace: selectedGroupSet.has("pace"),
    netEoqXpts: selectedGroupSet.has("eoq"),
    netEoqPts: selectedGroupSet.has("eoq"),
    "2pPct": selectedGroupSet.has("2pct"),
    "2pPctOpp": selectedGroupSet.has("2pct"),
    "3pPct": selectedGroupSet.has("3pct"),
    "3pPctOpp": selectedGroupSet.has("3pct"),
    "3paPct": selectedGroupSet.has("3papct"),
    "3paPctOpp": selectedGroupSet.has("3papct"),
    ftPct: selectedGroupSet.has("ftpct"),
    ftPctOpp: selectedGroupSet.has("ftpct"),
    astFgmPct: selectedGroupSet.has("astfgmpct"),
    astFgmPctOpp: selectedGroupSet.has("astfgmpct"),
    stlRate: selectedGroupSet.has("stl"),
    stlRateOpp: selectedGroupSet.has("stl"),
    blkRate: selectedGroupSet.has("blk"),
    blkRateOpp: selectedGroupSet.has("blk"),
  };

  const celticsIdx = data.findIndex((d) => d.teamId === CELTICS_TEAM_ID);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      hiddenColumns={hiddenColumns}
      rowColorMap={
        celticsIdx !== undefined
          ? {
              [celticsIdx]: {
                backgroundColor: "#F8FFF8",
                borderColor: "1px solid rgb(156, 203, 156)",
              },
            }
          : undefined
      }
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
