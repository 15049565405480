import React, { useMemo, useState } from "react";
import { extent } from "d3";

import { Highlights } from "../../constants/AppConstants";
import { TeamImpact } from "../../../shared/routers/ImpactRouter";
import { dec100Format, seFormat, makePlusMinus } from "../../util/Format";
import { sum } from "../../util/Util";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import { PlayerTableCell, ArrowUpDownTableCell } from "../core/TableCell";
import { TooltipItem } from "../core/TooltipItem";

const NET_IMPACT_DOMAIN = [-0.04239454, 0.04371481];

const columnHelper = createColumnHelper<TeamImpact>();

export function TeamRosterImpact(props: { data?: TeamImpact[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  function impactDelta(row: TeamImpact) {
    const valueOverTime = row.netImpactOverTime;
    if (!valueOverTime || valueOverTime.length === 0) return 0;
    return (
      (valueOverTime[valueOverTime.length - 1] || 0) - (valueOverTime[0] || 0)
    );
  }

  const columns = useMemo(() => {
    if (data === undefined) return [];

    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("offImpact", {
        header: () => "Off",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: [-0.04239453566, 0.04371481377],
        },
      }),
      columnHelper.accessor("defImpact", {
        header: () => "Def",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g++,
          colorDomain: [-0.04067071167, 0.03291763534],
        },
      }),
      columnHelper.accessor("netImpact", {
        header: () => "Net",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
          heatmap: true,
        },
      }),
      columnHelper.accessor("netSE", {
        header: () => "SE",
        cell: (info) => seFormat(100 * 1.96 * info.getValue()),
        meta: {
          highlights: Highlights.Min,
          group: g,
          neutralColorScheme: true,
          colorDomain: extent(data.map((d) => d.netSE)).reverse() as [
            number,
            number
          ],
        },
      }),
      columnHelper.accessor((row) => impactDelta(row), {
        id: "netOverTime",
        header: "Last 50",
        cell: (info) => {
          const valueOverTime = info.row.original.netImpactOverTime;
          if (!valueOverTime || valueOverTime.length === 0) return null;

          const change = impactDelta(info.row.original);

          return (
            <TooltipItem
              noListItem={true}
              arrow={false}
              tooltip={`${makePlusMinus(dec100Format)(change)} Net Impact`}
            >
              <div style={{ textAlign: "center" }}>
                <ArrowUpDownTableCell
                  data={valueOverTime}
                  // Each point represents 10 games so we want to make sure the
                  // player has at least 5 points here.
                  minPointsRequired={5}
                  significantChangeVal={0.01}
                />
              </div>
            </TooltipItem>
          );
        },
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("max_impact", {
        header: () => "Optimal",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
      columnHelper.accessor("net_impact_pg", {
        header: () => "PG",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
      columnHelper.accessor("net_impact_sg", {
        header: () => "SG",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
      columnHelper.accessor("net_impact_sf", {
        header: () => "SF",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
      columnHelper.accessor("net_impact_pf", {
        header: () => "PF",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
      columnHelper.accessor("net_impact_c", {
        header: () => "C",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: NET_IMPACT_DOMAIN,
        },
      }),
    ];
  }, [data]);

  const processSelected = (data: TeamImpact[]) => {
    return [
      "Sum of Selected",
      dec100Format(sum("offImpact", data)),
      dec100Format(sum("defImpact", data)),
      dec100Format(sum("netImpact", data)),
      seFormat(100 * 1.96 * sum("netSE", data)),
      dec100Format(sum("max_impact", data)),
      dec100Format(sum("net_impact_pg", data)),
      dec100Format(sum("net_impact_sg", data)),
      dec100Format(sum("net_impact_sf", data)),
      dec100Format(sum("net_impact_pf", data)),
      dec100Format(sum("net_impact_c", data)),
    ];
  };

  if (data === undefined) return null;

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
      processSelected={processSelected}
    />
  );
}
