import React, { useState, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { ParentSize } from "@visx/responsive";
import { Link } from "react-router-dom";

import { LineChart } from "../components/chart/LineChart";
import { lineChartColors } from "../constants/ColorConstants";
import { Panel } from "../components/core/Panel";
import { Page } from "../components/core/Page";
import { Spinner } from "../components/core/Spinner";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { pctFormat } from "../util/Format";
import { trpc } from "../util/tRPC";
import { groupBy } from "../../shared/util/Collections";
import { getFirstForField } from "../util/Util";
import { ProxiedVideoViewCounts } from "../../shared/routers/AdminRouter";

export function SiteUsagePage() {
  return (
    <Page header={{ text: "Site Usage" }} title="Site Usage">
      <div>
        <Row>
          <Col md={6}>
            <Panel header={"Usage Over Time"}>
              <UsageOverTime />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel header={"Trending Players"}>
              <TrendingPlayers />
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Panel header={"Usage Breakdown"}>
              <UsageBreakdown />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel header={"Usage Details"}>
              <Row>
                <Col>
                  <b>Usage By Page</b>
                  <UsageByPage />
                </Col>
                <Col>
                  <b>Mobile Usage</b>
                  <MobileUsage />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Panel header={"Visited Video URls in Last 14 days"}>
              <ProxiedVideoViews />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel header={"[WIP] - Draft Boards"}>
              <DraftBoards />
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

type PageUsageRow = { route: string; views: number };
const columnHelperPage = createColumnHelper<PageUsageRow>();

function UsageByPage() {
  const [sorting, setSorting] = useState<SortingState>();

  const { data: pageViews } = trpc.admin.getSiteUsageByPage.useQuery();

  const columns = useMemo(
    () => [
      columnHelperPage.accessor("route", {
        header: () => "Page",
        meta: { group: 1 },
      }),
      columnHelperPage.accessor("views", {
        header: () => "Views",
        meta: { group: 2 },
      }),
    ],
    []
  );

  if (!pageViews) return <Spinner />;

  return (
    <Table
      data={pageViews}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showRowIndex={false}
    />
  );
}

type MobileUsageRow = { email: string; pctMobile: number };
const columnHelperMobile = createColumnHelper<MobileUsageRow>();

function MobileUsage() {
  const [sorting, setSorting] = useState<SortingState>();

  const { data: mobileUsage } = trpc.admin.getMobileSiteUsage.useQuery();

  const columns = useMemo(
    () => [
      columnHelperMobile.accessor("email", {
        header: () => "Email",
        meta: { group: 1 },
      }),
      columnHelperMobile.accessor("pctMobile", {
        header: () => "Mobile",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: 2 },
      }),
    ],
    []
  );

  if (!mobileUsage) return <Spinner />;

  return (
    <Table
      data={mobileUsage}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showRowIndex={false}
    />
  );
}

type UsageBreakdownRow = { email: string; route: string; views: number };
const columnHelperUsage = createColumnHelper<UsageBreakdownRow>();

function UsageBreakdown() {
  const [emailSorting, setEmailSorting] = useState<SortingState>();
  const [routeSorting, setRouteSorting] = useState<SortingState>();

  const [email, setEmail] = useState("");
  const [route, setRoute] = useState("");

  const { data: personUsage } = trpc.admin.getSiteUsageByPerson.useQuery();

  const emailColumns = useMemo(
    () => [
      columnHelperUsage.accessor("route", {
        header: () => "Route",
        meta: { group: 1 },
      }),
      columnHelperUsage.accessor("views", {
        header: () => "Views",
        meta: { group: 2 },
      }),
    ],
    []
  );

  const routeColumns = useMemo(
    () => [
      columnHelperUsage.accessor("email", {
        header: () => "Email",
        meta: { group: 1 },
      }),
      columnHelperUsage.accessor("views", {
        header: () => "Views",
        meta: { group: 2 },
      }),
    ],
    []
  );

  if (!personUsage) return <Spinner />;

  const emails = [...new Set(personUsage.map((p) => p.email))];
  const email1 = emails[0];
  const routes = [...new Set(personUsage.map((p) => p.route))];
  const route1 = routes[0];

  if (!email && email1) {
    setEmail(email1);
  }

  if (!route && route1) {
    setRoute(route1);
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
      <div>
        <Form.Label>By User</Form.Label>
        <Form.Select
          style={{ width: "auto" }}
          value={email}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setEmail(evt.target.value);
          }}
        >
          {emails.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </Form.Select>
        {email && (
          <Table
            data={personUsage.filter((d) => d.email === email)}
            columns={emailColumns}
            sorting={emailSorting}
            setSorting={setEmailSorting}
            autoWidth={true}
            showRowIndex={false}
          />
        )}
      </div>
      <div>
        <Form.Label>By Route</Form.Label>
        <Form.Select
          style={{ width: "auto" }}
          value={route}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setRoute(evt.target.value);
          }}
        >
          {routes.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </Form.Select>
        {email && (
          <Table
            data={personUsage.filter((d) => d.route === route)}
            columns={routeColumns}
            sorting={routeSorting}
            setSorting={setRouteSorting}
            autoWidth={true}
            showRowIndex={false}
          />
        )}
      </div>
    </div>
  );
}

function UsageOverTime() {
  const { data: usageOverTime } = trpc.admin.getSiteUsageOverTime.useQuery();

  if (!usageOverTime) return null;

  const lines: {
    color: string;
    segments: { x: number; y: number }[][];
    label: string;
  }[] = [
    {
      color: lineChartColors.yellow,
      segments: [
        usageOverTime.map((u) => {
          return {
            x: moment(u.date).valueOf(),
            y: u.mobileCount + u.desktopCount,
          };
        }),
      ],
      label: "Total",
    },
    {
      color: lineChartColors.green,
      segments: [
        usageOverTime.map((u) => {
          return {
            x: moment(u.date).valueOf(),
            y: u.desktopCount,
          };
        }),
      ],
      label: "Desktop",
    },
    {
      color: lineChartColors.orange,
      segments: [
        usageOverTime.map((u) => {
          return {
            x: moment(u.date).valueOf(),
            y: u.mobileCount,
          };
        }),
      ],
      label: "Mobile",
    },
    {
      color: lineChartColors.red,
      segments: [
        usageOverTime.map((u) => {
          return {
            x: moment(u.date).valueOf(),
            y: u.errorCount,
          };
        }),
      ],
      label: "Error",
    },
  ];

  const mostRecent = usageOverTime[usageOverTime.length - 1];

  if (!mostRecent) return null;

  return (
    <div style={{ position: "relative" }}>
      <div>
        <b>Views/Day: </b>
        <span>
          {(mostRecent.desktopCount + mostRecent.mobileCount).toLocaleString()}
        </span>
      </div>
      <div>
        <b>Errors/Day: </b>
        <span>{mostRecent.errorCount.toLocaleString()}</span>
      </div>
      <ParentSize>
        {({ width }) => (
          <LineChart
            height={width}
            width={width}
            lines={lines}
            showLegend={true}
            xTickFormat={(v) => moment(v as number).format("DD/MM/YYYY")}
            numXTicks={5}
            tooltip={(tooltipData) => {
              const data1 = tooltipData.data[0];

              if (!data1) return null;

              const xVal = data1.x;

              return (
                <div>
                  <b>{moment(xVal).format("dddd, MMM Do YYYY")}</b>
                  {tooltipData.data
                    .sort((a, b) => b.y - a.y)
                    .map((d, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              background: d.color,
                              width: 10,
                              height: 10,
                            }}
                          ></div>
                          <div>{d.y.toLocaleString()}</div>
                        </div>
                      );
                    })}
                </div>
              );
            }}
          />
        )}
      </ParentSize>
    </div>
  );
}

function TrendingPlayers() {
  const [daysBack, setDaysBack] = useState(14);
  const { data: trendingPlayers } = trpc.admin.getTrendingPlayers.useQuery({
    daysBack,
  });

  return (
    <div>
      <b>Most viewed players in last </b>
      <select
        value={daysBack}
        onChange={(evt) => setDaysBack(parseInt(evt.currentTarget.value))}
      >
        {[1, 7, 14, 30, 90, 180].map((d) => (
          <option key={d} value={d}>
            {d}
          </option>
        ))}
      </select>
      <b> days.</b>
      {(trendingPlayers || []).map((p, i) => {
        return (
          <div key={i}>
            {i + 1}. <Link to={`/player/${p.playerIdStr}`}>{p.name}</Link> (
            {p.viewCount})
          </div>
        );
      })}
    </div>
  );
}

function DraftBoards() {
  const { data: draftBoards } = trpc.draft.getMostRecentDraftBoards.useQuery();
  const picksByUser = groupBy(draftBoards || [], (db) => db.username);
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {Object.values(picksByUser).map((picks) => {
        const userName = getFirstForField("username", picks, "Unknown");
        const timestamp = getFirstForField("timestamp", picks, Date.now());
        return (
          <div key={userName}>
            <b>
              {userName.replace("@celtics.com", "")}{" "}
              {moment(timestamp).format("MM/DD/YYYY")}
            </b>
            <div>
              {picks
                .sort((a, b) => a.draftPick - b.draftPick)
                .map((p) => (
                  <div key={p.draftPick}>
                    <b>{p.draftPick}.</b>{" "}
                    <Link to={`/player/${p.playerId}`}>{p.playerName}</Link>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const videoCountHelper = createColumnHelper<ProxiedVideoViewCounts>();

function ProxiedVideoViews() {
  const { data } = trpc.admin.getProxiedVideoViewCounts.useQuery();

  const columns = useMemo(
    () => [
      videoCountHelper.accessor("path", {
        header: () => "Shot",
        cell: (info) => {
          const path = info.getValue();
          return <Link to={`/${path}`}>{path}</Link>;
        },
        meta: { group: 1 },
      }),
      videoCountHelper.accessor("viewCount", {
        header: () => "Views",
        meta: { group: 2 },
      }),
      videoCountHelper.accessor("distinctViewers", {
        header: () => "Distinct Viewers",
        meta: { group: 3 },
      }),
    ],
    []
  );

  if (!data) return null;

  return (
    <Table
      data={data}
      columns={columns}
      autoWidth={true}
      showRowIndex={false}
    />
  );
}
