import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
// import $ from "jquery";
import CreateReactClass from "create-react-class";
import PropTypes from "prop-types";
import cx from "classnames";
import Immutable from "immutable";
import { FaRegTimesCircle } from "react-icons/fa";

import SelectableList from "./SelectableList";

import "./MultiSelect.css";

const MultiSelect = CreateReactClass({
  mixins: [PureRenderMixin],
  propTypes: {
    // list of objects { value, label } (value works as an ID)
    values: PropTypes.instanceOf(Immutable.List), // values that can show up

    // a subset of values used for autocomplete
    // this might be different from values if a filter has been applied to the available
    // dropdown values, but there are already other values selected from the greater 'values'
    // collection.
    autocompleteValues: PropTypes.instanceOf(Immutable.List),

    // list of "value" properties of the objects in values
    selected: PropTypes.instanceOf(Immutable.List),
    onChange: PropTypes.func.isRequired,
    onTextChange: PropTypes.func, // callback whenever typing changes
    defaultMaxItems: PropTypes.number,
    maxHeight: PropTypes.number,
    limit: PropTypes.number, // how many items can be selected (null=infinite)
    className: PropTypes.string,
    listItemRender: PropTypes.func, // optional function on how to render the items in the dropdown list
    disabled: PropTypes.bool, // optional, if true, component is disabled.
    shouldNotRemoveInvalidItems: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      selected: Immutable.List(),
    };
  },

  getInitialState() {
    return {
      listShown: false,
      text: "",
    };
  },

  componentWillMount() {
    this.listRef = React.createRef();
    this.textInputRef = React.createRef();
    // eslint-disable-next-line no-undef
    document.addEventListener("click", this._handleDocumentClick);
  },

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    document.removeEventListener("click", this._handleDocumentClick);
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.values &&
      nextProps.selected &&
      nextProps.values.size === nextProps.selected.size
    ) {
      this._hideList();

      // otherwise, there are some available. If text input is focused, make sure list is showing
      // e.g. all are selected then we backspace to remove some, list should show again
    } else if (this._isTextElementFocused()) {
      this._showList(nextProps);
    }
  },

  _hideList() {
    if (this.state.listShown) {
      this.setState({
        listShown: false,
      });
    }
  },

  _showList(props = this.props) {
    const { limit, selected, disabled } = props;
    if (
      !this.state.listShown &&
      (limit == null || selected.size < limit) &&
      !disabled
    ) {
      this.setState({
        listShown: true,
      });
    }
  },

  _resetText() {
    if (this.state.text !== "") {
      this.setState({ text: "" });
    }
  },

  _focusTextElement() {
    this.textInputRef.current.focus();
    this.listRef.current.resetSelected();
    this._showList();
  },

  _isTextElementFocused() {
    // eslint-disable-next-line no-undef
    const result = document.activeElement === this.textInputRef.current;
    return result;
  },

  _addFirst() {
    const availableValues = this._getAvailableValues();
    if (availableValues.size) {
      this._handleSelect(availableValues.get(0));
    }
  },

  _removeLast() {
    const { selected } = this.props;
    if (selected.size) {
      this._handleRemove(selected.get(-1));
    }
  },

  // close the list if we click outside of the element
  _handleDocumentClick() {
    if (!this._clickedInside) {
      this._hideList();
    }
    this._clickedInside = false;
  },

  _handleSelect(item) {
    const { onChange, selected, limit } = this.props;

    // ignore if we are at the limit
    if (limit != null && selected.size >= limit) {
      return;
    }

    const availableValues = this._getAvailableValues();

    // if we just selected the last element in the dropdown list, focus on the text input
    if (
      availableValues.findIndex((d) => d === item) ===
      availableValues.size - 1
    ) {
      this._focusTextElement();
    }

    if (onChange) {
      if (selected && selected.indexOf(this.getValue(item)) === -1) {
        onChange(selected.push(this.getValue(item)), this.getValue(item), item);
      } else if (!selected) {
        onChange(
          Immutable.List.of(this.getValue(item)),
          this.getValue(item),
          item
        );
      }
    }

    if (limit != null && selected.size + 1 >= limit) {
      this._hideList();
    }

    this._resetText();
  },

  _handleRemove(key) {
    const { onChange, selected } = this.props;

    const index = selected.indexOf(key);
    if (onChange && index !== -1) {
      onChange(selected.delete(index));
    }

    this._focusTextElement();
  },

  _handleTextChange(evt) {
    const { onTextChange } = this.props;
    const text = evt.target.value;

    this.setState({ text });

    if (onTextChange) {
      onTextChange(text);
    }
  },

  _handleKeyDown(evt) {
    switch (evt.keyCode) {
      case 8: // backspace
        if (this.state.text === "") {
          this._removeLast();
        }
        evt.stopPropagation();
        break;
      case 9: // tab
        this._justTabbed = true;
        evt.stopPropagation();
        break;
      case 13: // enter
        this._addFirst();
        break;
      case 40: // down arrow
        // focus in the list
        this.listRef.current._focusNext();
        evt.preventDefault(); // prevent scrolling the page
        break;
    }
  },

  _handleDropdownKeyDown(evt) {
    switch (evt.keyCode) {
      case 8: // backspace
        if (this.state.text === "") {
          this._removeLast();
        }
        evt.preventDefault();
        break;

      case 9: // tab from the dropdown list
        this._hideList();
        break;

      case 27: // escape
        this._hideList();
        break;

      default:
        // any other button press should re-open the list if hidden
        if (!this.state.listShown) {
          this._showList();
        }
        break;
    }
  },

  _handleTextFocus() {
    this._showList();
  },

  _handleTextBlur() {
    if (this._justTabbed) {
      this._hideList();
    }
    this._justTabbed = false;
  },

  _handleClickTextInputArea() {
    this._focusTextElement();
  },

  // set a flag to ignore the next click since it was inside the element
  _handleClick() {
    this._clickedInside = true;
  },

  _getAvailableValues() {
    const { values, autocompleteValues, selected } = this.props;
    let { text } = this.state;
    let textRegex;
    // if we get an invalid string (e.g. \), just treat it as empty
    try {
      let regexStr;
      if (text == null || text.trim() === "") {
        regexStr = "";
      } else {
        // remove punctuation
        text = text.toLowerCase().replace(/[.']/g, "");

        // fuzzy search
        regexStr = text.split(" ").join("(.*?)") + "(.*?)";
      }
      textRegex = RegExp(regexStr);
    } catch (e) {
      textRegex = RegExp("");
    }

    let valuesToUse = autocompleteValues ? autocompleteValues : values;

    const availableValues = valuesToUse.filter((value) => {
      return (
        (!selected ||
          (selected && selected.indexOf(this.getValue(value)) === -1)) &&
        textRegex.test(this.getLabel(value).toLowerCase().replace(/[.']/g, ""))
      );
    });

    return availableValues;
  },

  getValue(object) {
    if (object && object.get) {
      return object.get("value");
    } else if (object) {
      return object.value;
    }
    return null;
  },

  getLabel(object) {
    if (object && object.get) {
      return object.get("label");
    } else if (object) {
      return object.label;
    }
    return "Invalid Item";
  },

  _renderList() {
    const { listShown } = this.state;
    const availableValues = this._getAvailableValues();

    return (
      <SelectableList
        ref={this.listRef}
        items={availableValues}
        visible={listShown}
        onSelect={this._handleSelect}
        wrapUpAround={false}
        onUpperEdge={this._focusTextElement}
        defaultMaxItems={this.props.defaultMaxItems}
        itemRender={this.props.listItemRender}
        maxHeight={this.props.maxHeight}
      />
    );
  },

  render() {
    const {
      values,
      selected,
      className,
      limit,
      disabled,
      onChange,
      shouldNotRemoveInvalidItems,
    } = this.props;
    const { text } = this.state;

    const atLimit = limit != null && selected.size >= limit;

    // Find all the selected values that are not in the list of available values
    // and remove them.
    if (selected && !shouldNotRemoveInvalidItems) {
      let needsUpdate = false;
      const newSelectedList = [];
      selected.forEach((selectedValue) => {
        const value = values.find((d) => this.getValue(d) === selectedValue);

        if (value) {
          newSelectedList.push(this.getValue(value));
        } else {
          needsUpdate = true;
        }
      });
      if (needsUpdate && onChange) {
        onChange(Immutable.List(newSelectedList));
      }
    }

    return (
      <div
        className={cx(
          "multi-select",
          { "single-item": limit === 1 },
          { disabled: disabled },
          className
        )}
        onClick={this._handleClick}
        onKeyDown={this._handleDropdownKeyDown}
      >
        <div
          className={cx("text-input-area", {
            "has-text-focus": this._isTextElementFocused(),
          })}
          onClick={this._handleClickTextInputArea}
        >
          {selected
            ? selected.map((selectedValue, i) => {
                const value = values.find(
                  (d) => this.getValue(d) === selectedValue
                );

                return (
                  <div
                    className="label selected-item"
                    key={i}
                    onClick={this._handleRemove.bind(
                      this,
                      this.getValue(value)
                    )}
                    style={{ cursor: "pointer" }}
                  >
                    {this.getLabel(value)}
                    <div className="fa-close">
                      <FaRegTimesCircle />
                    </div>
                  </div>
                );
              })
            : null}
          <input
            type="text"
            value={text}
            ref={this.textInputRef}
            disabled={atLimit || disabled}
            onChange={this._handleTextChange}
            onFocus={this._handleTextFocus}
            onBlur={this._handleTextBlur}
            onKeyDown={this._handleKeyDown}
          />
        </div>
        {this._renderList()}
      </div>
    );
  },
});

export default MultiSelect;
