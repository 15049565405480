import React, { useState, useMemo } from "react";

import { PlayerCompare } from "../../../shared/routers/PlayerRouter";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  dec100Format,
  decFormat,
  decFormat2,
  seasonString,
  fracDecFormat,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import {
  StatsColorDomain,
  per100,
  perGame,
  totals,
} from "../../constants/ColorDomains";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";
import { trpc } from "../../util/tRPC";

type BoxScoreType = "perGame" | "per100Poss" | "totals";

function calcStatForType(
  data: PlayerCompare,
  stat: keyof PlayerCompare,
  type: BoxScoreType
): number {
  if (type === "totals") {
    return data[stat] as number;
  } else if (type === "perGame") {
    return (data[stat] as number) / data.G;
  } else {
    return (100 * (data[stat] as number)) / data.EstPossPlayed;
  }
}

function calcComplexStateForType(
  data: PlayerCompare,
  val: number,
  type: BoxScoreType
) {
  if (type === "totals") {
    return val;
  } else if (type === "perGame") {
    return val / data.G;
  } else {
    return (100 * val) / data.EstPossPlayed;
  }
}

function headerStrForType(header: string, type: BoxScoreType) {
  if (type === "totals") {
    return header;
  } else if (type === "perGame") {
    if (["PTS", "REB", "AST", "BLK", "STL"].includes(header)) {
      return `${header.slice(0, 1)}PG`;
    }
    return `${header}/G`;
  } else {
    if (["PTS", "REB", "AST", "BLK", "STL"].includes(header)) {
      return `${header.slice(0, 1)}/100`;
    }
    return `${header}/100`;
  }
}

function colorDomainForType(stat: keyof StatsColorDomain, type: BoxScoreType) {
  if (type === "per100Poss") {
    return per100[stat];
  } else if (type === "perGame") {
    return perGame[stat];
  } else {
    return totals[stat];
  }
}

const seasonBoxesHelper = createColumnHelper<PlayerCompare>();

export function PlayerCompareSeasonBoxScores(props: {
  players: { playerId?: string; league?: string; seasons?: string[] }[];
  type: BoxScoreType;
}) {
  const { players, type } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const playerIds = players
    .map((p) => p.playerId)
    .filter((p) => !!p) as string[];

  const { data } = trpc.player.getPlayerCompare.useQuery({
    playerIds,
  });

  const playerData = (data || []).filter((pc) => {
    for (const p of players) {
      if (p.playerId === pc.celticsID.toString()) {
        if (p.league === undefined) {
          return true;
        }
        if (p.league === pc.League.toLocaleLowerCase().replaceAll(" ", "_")) {
          if (p.seasons === undefined || p.seasons.length === 0) {
            return true;
          } else {
            return p.seasons.includes(pc.Season.toString());
          }
        }
      }
    }
    return false;
  });

  const columns = useMemo(() => {
    let g = 0;

    return [
      seasonBoxesHelper.accessor("celticsID", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.fullName}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: g },
      }),
      seasonBoxesHelper.accessor("League", {
        header: () => "League",
        meta: { group: g, textAlign: "left" },
      }),
      seasonBoxesHelper.accessor("teamID", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            id={info.getValue()}
            ids={
              info.row.original.IDS === null ? undefined : info.row.original.IDS
            }
            league={
              info.row.original.League
                ? info.row.original.League.toLocaleLowerCase().replaceAll(
                    " ",
                    "_"
                  )
                : ""
            }
            season={info.row.original.Season.toString()}
            name={info.row.original.Team}
          />
        ),
        meta: { group: g++ },
      }),
      seasonBoxesHelper.accessor("Season", {
        header: () => "Season",
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: g },
      }),
      seasonBoxesHelper.accessor("seasonAge", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      seasonBoxesHelper.accessor("G", {
        header: () => "G",
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("gp", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "MIN", type), {
        id: "MIN",
        header: () => headerStrForType("Min", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("min", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "PTS", type), {
        id: "PTS",
        header: () => headerStrForType("PTS", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("pts", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => calcComplexStateForType(row, row.ORB + row.DRB, type),
        {
          id: "TRB",
          header: () => headerStrForType("REB", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("trb", type),
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "AST", type), {
        id: "AST",
        header: () => headerStrForType("AST", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("ast", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "BLK", type), {
        id: "BLK",
        header: () => headerStrForType("BLK", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("blk", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "STL", type), {
        id: "STL",
        header: () => headerStrForType("STL", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("stl", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "TOV", type), {
        id: "TOV",
        header: () => headerStrForType("TO", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: colorDomainForType("to", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => (row.PF === null ? null : row.PF / row.G),
        {
          id: "PF",
          header: () => headerStrForType("PF", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Min,
            colorDomain: colorDomainForType("pf", type),
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => row.ORB / row.ORAvail, {
        id: "ORPCT",
        header: () => "OR%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("orbpct", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.DRB / row.DRAvail, {
        id: "DRPCT",
        header: () => "DR%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("drbpct", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "ORB", type), {
        id: "ORB",
        header: () => headerStrForType("ORB", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("orb", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "DRB", type), {
        id: "DRB",
        header: () => headerStrForType("DRB", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("drb", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "F2M", type), {
        id: "F2M",
        header: () => headerStrForType("2PM", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("2pm", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "F2A", type), {
        id: "F2A",
        header: () => headerStrForType("2PA", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("2pa", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "F3M", type), {
        id: "F3M",
        header: () => headerStrForType("3PM", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("3pm", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "F3A", type), {
        id: "F3A",
        header: () => headerStrForType("3PA", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("3pa", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => calcComplexStateForType(row, row.F3M + row.F2M, type),
        {
          id: "FGM",
          header: () => headerStrForType("FGM", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("fgm", type),
          },
        }
      ),
      seasonBoxesHelper.accessor(
        (row) => calcComplexStateForType(row, row.F3A + row.F2A, type),
        {
          id: "FGA",
          header: () => headerStrForType("FGA", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("fga", type),
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "FTM", type), {
        id: "FTM",
        header: () => headerStrForType("FTM", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("ftm", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => calcStatForType(row, "FTA", type), {
        id: "FTA",
        header: () => headerStrForType("FTA", type),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("fta", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.FTA / row.PlayerPoss, {
        id: "FTARate",
        header: () => "FTA Rate",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("ftaRate", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => (row.F2M + row.F3M) / (row.F2A + row.F3A),
        {
          id: "FGPCT",
          header: () => "FG%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("fgPct", type),
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => row.F2M / row.F2A, {
        id: "F2PCT",
        header: () => "2P%",
        cell: (info) =>
          fracDecFormat(45, {
            denominator: info.row.original.F2A,
            numerator: info.row.original.F2M,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("2pct", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.F3M / row.F3A, {
        id: "F3PCT",
        header: () => "3P%",
        cell: (info) =>
          fracDecFormat(45, {
            denominator: info.row.original.F3A,
            numerator: info.row.original.F3M,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("3pct", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => (row.F2M + 1.5 * row.F3M) / (row.F3A + row.F2A),
        {
          id: "EFGPCT",
          header: () => "eFG%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("efg", type),
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => row.FTM / row.FTA, {
        id: "FTPCT",
        header: () => "FT%",
        cell: (info) =>
          fracDecFormat(20, {
            denominator: info.row.original.FTA,
            numerator: info.row.original.FTM,
          }),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("ftpct", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.TOV / row.PlayerPoss, {
        id: "TOPCT",
        header: () => "TO%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: colorDomainForType("topct", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.PTS / row.PlayerPoss, {
        id: "PPP",
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("ppp", type),
        },
      }),
      seasonBoxesHelper.accessor((row) => row.PlayerPoss / row.UsgAvail, {
        id: "USG",
        header: () => "Usg",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: colorDomainForType("usg", type),
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => calcStatForType(row, "PlayerPoss", type),
        {
          id: "PlayerPoss",
          header: () => headerStrForType("Player Poss", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("playerPoss", type),
          },
        }
      ),
      seasonBoxesHelper.accessor(
        (row) => calcStatForType(row, "EstPossPlayed", type),
        {
          id: "TeamPoss",
          header: () => headerStrForType("Team Poss", type),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: colorDomainForType("teamPoss", type),
          },
        }
      ),
    ];
  }, [type]);

  if (!playerData) return null;

  return (
    <Table
      data={playerData}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}
