import React, { useState, useMemo, useCallback } from "react";
import { Row, Col } from "react-bootstrap";

import AppContext from "../../../shared/AppContext";
import { Panel } from "../../components/core/Panel";
import { Spinner } from "../../components/core/Spinner";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../../components/core/Table";
import { TeamShootingTable } from "../../components/team/TeamShootingTable";
import { TeamFreeThrowsTable } from "../../components/team/TeamFreeThrowsTable";
import { TeamTouchesTable } from "../../components/team/TeamTouchesTable";
import { TeamThreePointShootingPanel } from "../../components/team/TeamThreePointShootingPanel";
import { TeamRunningTheFloorTable } from "../../components/team/TeamRunningTheFloorTable";
import { TeamActionsBreakdownTable } from "../../components/team/TeamActionsBreakdownTable";
import { TeamOffReboundingTable } from "../../components/team/TeamOffReboundingTable";
import {
  TeamPassesToGreatShots,
  TeamRunningTheFloor,
  TeamShooting,
  TeamTouches,
  TeamFreeThrows,
  TeamThreePointShooting,
  TeamActionBreakdown,
  TeamOffRebounding,
} from "../../../shared/routers/TeamRouter";
import { LeagueShooting } from "../../../shared/routers/LeagueRouter";
import { ChanceEfficiencyPerStartType2 } from "../../../shared/routers/ChanceRouter";
import { EfficiencyByStartType } from "../../components/team/TeamEffPerStartType";
import { decFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../../components/core/TableCell";

export function TeamSubPageOffense(props: {
  teamId: number;
  teamShooting?: TeamShooting[];
  leagueShooting?: LeagueShooting[];
  teamActions?: TeamActionBreakdown[];
  leagueActions?: TeamActionBreakdown[];
  teamStartTypeData?: ChanceEfficiencyPerStartType2[];
  leagueStartTypeData?: ChanceEfficiencyPerStartType2[];
  passesToGreatShots?: TeamPassesToGreatShots[];
  threePointShooting?: TeamThreePointShooting[];
  freeThrows?: TeamFreeThrows[];
  touches?: TeamTouches[];
  runs?: TeamRunningTheFloor[];
  offTeamRebounding?: TeamOffRebounding[];
  filteredToSpecificGames: boolean;
  filtered: boolean;
}) {
  const {
    teamId,
    teamShooting,
    leagueShooting,
    teamActions,
    leagueActions,
    teamStartTypeData,
    leagueStartTypeData,
    passesToGreatShots,
    threePointShooting,
    freeThrows,
    touches,
    runs,
    offTeamRebounding,
    filteredToSpecificGames,
    filtered,
  } = props;

  return (
    <div>
      {AppContext.inPreseason /* Preseason-warning-message */ && (
        <Row>
          <Col sm={12}>
            <Panel>
              <div
                style={{ fontSize: 22, fontWeight: 200, marginBottom: "0.8em" }}
              >
                Note: During preseason this data comes from the previous season.
              </div>
            </Panel>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel
            header="Shooting"
            panelID="shootingOffense"
            filtered={filtered}
          >
            <TeamShootingTable
              teamId={teamId}
              statType="OFF"
              leagueShooting={leagueShooting}
              teamShooting={teamShooting}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel
            header="Offensive Breakdown"
            panelID="offensiveBreakdown"
            filtered={filtered}
          >
            <TeamActionsBreakdownTable
              teamId={teamId}
              teamActions={teamActions}
              leagueActions={leagueActions}
              statType="OFF"
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel header="3Pt Shooting" panelID="threePointShooting">
            <TeamThreePointShootingPanel
              threePointShooting={threePointShooting}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={2} sm={4} xs={8}>
          <Panel header="Whom To Foul" panelID="whomToFoul">
            <TeamFreeThrowsTable freeThrows={freeThrows} />
          </Panel>
        </Col>
        <Col lg={8} md={10} sm={12} xs={12}>
          <Panel header="Touches" panelID="touches">
            <TeamTouchesTable touches={touches} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={3} sm={4} xs={8}>
          <PassesToGreatShotsPanel
            passes={passesToGreatShots}
            filtered={filtered}
          />
        </Col>
        <Col md={9} lg={9} sm={12}>
          <Panel header="Running The Floor" panelID="runningFloor">
            <TeamRunningTheFloorTable runs={runs} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel
            header="Efficiency By Start Type"
            panelID="effByStartType"
            filtered={filtered}
          >
            <EfficiencyByStartType
              teamId={teamId}
              teamData={teamStartTypeData}
              leagueData={leagueStartTypeData}
              showOffense={true}
              filteredToSpecificGames={filteredToSpecificGames}
              filtered={filtered}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          <Panel
            header="Offensive Rebounding"
            panelID="offensiveRebounding"
            filtered={filtered}
          >
            {offTeamRebounding && (
              <TeamOffReboundingTable data={offTeamRebounding} />
            )}
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

function PassesToGreatShotsPanel(props: {
  passes?: TeamPassesToGreatShots[];
  filtered: boolean;
}) {
  const { filtered, passes } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const colorDomainForStat = useCallback(
    (stat: keyof TeamPassesToGreatShots) => {
      if (!passes) return [0, 0];
      const sortedByStat = passes
        .map((p) => 0 + Number(p[stat]))
        .sort((a, b) => a - b);
      const min = sortedByStat[0] || 0;
      const max = sortedByStat[sortedByStat.length - 1] || 0;
      return [min, max];
    },
    [passes]
  );

  const columnHelper = createColumnHelper<TeamPassesToGreatShots>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.celticsId}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("n", {
        header: () => "#",
        cell: (info) => info.getValue(),
        meta: {
          highlights: Highlights.Max,
          colorDomain: colorDomainForStat("n"),
        },
      }),
      columnHelper.accessor("min", {
        header: () => "Min.",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: colorDomainForStat("min"),
        },
      }),
      columnHelper.accessor("per48", {
        header: () => "Per 48",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: colorDomainForStat("per48"),
        },
      }),
    ],
    [colorDomainForStat, columnHelper]
  );

  return (
    <Panel
      header="Passes To Great Shots"
      panelID="passesToGreatShots"
      filtered={filtered}
    >
      {passes ? (
        <Table
          sorting={sorting}
          setSorting={setSorting}
          data={passes.sort(
            (a: TeamPassesToGreatShots, b: TeamPassesToGreatShots) => {
              return b.n - a.n;
            }
          )}
          columns={columns}
          autoWidth={true}
          showColorOnHover={true}
        ></Table>
      ) : (
        <Spinner />
      )}
    </Panel>
  );
}
