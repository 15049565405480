import React, { useState, ReactNode, useMemo, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Button,
  OverlayTrigger,
  Popover,
  Form,
  Alert,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import copy from "copy-to-clipboard";
import { FaTimes, FaPlayCircle } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import cx from "classnames";

import { PlayerPreviewCard } from "../components/player/PlayerPreviewCard";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { MultiSelect } from "../components/core/MultiSelect";
import { trpc } from "../util/tRPC";
import { BoardMakerPlayer } from "../../shared/routers/BoardRouter";
import {
  DraftBoardComment,
  PlayerDraftBoardComp,
} from "../../shared/routers/DraftRouter";
import { SynergyClip } from "../../shared/routers/SynergyRouter";
import { groupBy, reduceArrayToObject } from "../../shared/util/Collections";
import {
  decFormat,
  gameClockFormat,
  dateFormat,
  period,
  makePlusMinus,
} from "../util/Format";
import { VideoControl } from "../components/query/VideoControl";
import { VideoModal } from "../components/video/VideoModal";
import { SortableList } from "../components/core/SortableList";
import { PositionIndicator } from "../components/player/PositionIndicator";

import "./DraftBoardPage.css";

// TODO(chrisbu): Add an input for scouting rating likely.
// TODO(chrisbu): Handle case when guys drop out of draft - currently they will
// just show up as blank but maybe we should show them as "withdrawn" or
// something

const MIN_BOARD_SIZE = 10;
const MAX_BOARD_SIZE = 100;

export function DraftBoardPage() {
  const { year } = useParams();
  const [infoForVideo, setInfoForVideo] = useState<{
    playerId: number;
    offDef: string;
  }>();
  const utils = trpc.useContext();
  const [, setPlayerIdToComment] = useState<number>();
  const [draftBoardState, setDraftBoardState] = useState<{
    draftBoard: (number | null)[];
    boardEndIndex: number;
  }>();

  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const copyButton = useRef<HTMLButtonElement | null>(null);

  const pageTitle = `${year} Draft Board`;

  const filters = JSON.stringify([
    { key: "PlayerType", value: [`Draft ${year}`] },
  ]);

  // Load the data from server, after this we will maintain the state locally.
  trpc.draft.getPlayerDraftBoard.useQuery(
    {
      year,
    },
    {
      onSuccess: (data) => {
        const draftBoardState = data && data[0];
        if (draftBoardState) {
          setDraftBoardState({
            draftBoard: draftBoardState.draftBoard.map((db) => db || null),
            boardEndIndex: draftBoardState.boardEndIndex,
          });
        }
      },
    }
  );

  const { draftBoard, boardEndIndex } = draftBoardState
    ? draftBoardState
    : {
        draftBoard: new Array(MAX_BOARD_SIZE).fill(null) as null[],
        boardEndIndex: MIN_BOARD_SIZE,
      };

  const { data: draftEligibile } = trpc.board.getBoardMakerPlayers.useQuery({
    direction: "desc",
    orderBy: "pNBA",
    filters,
  });

  const { data: synergyClips } = trpc.synergy.getDraftBoardClips.useQuery({
    limit: 1000,
    playerId: infoForVideo ? infoForVideo.playerId : undefined,
    offDef: infoForVideo ? infoForVideo.offDef : undefined,
  });

  const { data: playerComments } =
    trpc.draft.getPlayerDraftBoardComments.useQuery({
      playerIds: draftBoard.filter((db) => !!db) as number[],
    });

  const { data: playerComps } = trpc.draft.getPlayerDraftBoardComps.useQuery({
    playerIds: draftBoard.filter((db) => !!db) as number[],
    year,
  });

  const saveCommentMutation =
    trpc.draft.insertDraftBoardPlayerComment.useMutation({
      onSuccess: () => {
        setPlayerIdToComment(undefined);
        utils.draft.invalidate();
        utils.draft.getPlayerDraftBoardComments.refetch();
      },
      onError: () => {
        setPlayerIdToComment(undefined);
        alert("Unable to save comment.");
      },
    });

  const onUpdateComment = (playerId: number, comment: string) => {
    saveCommentMutation.mutate({ playerId, comment });
  };

  const updateDraftBoardMutation =
    trpc.draft.insertPlayerDraftBoard.useMutation({
      onError: () => {
        alert("Failed to save update to draft board.");
      },
    });

  const commentsByPlayerMap = useMemo(
    () => groupBy(playerComments || [], (pc) => pc.playerId.toString()),
    [playerComments]
  );

  const compsByPlayerMap = useMemo(
    () => groupBy(playerComps || [], (pc) => pc.playerId.toString()),
    [playerComps]
  );

  if (!draftEligibile) return null;

  const draftEligiblePlayerMap = reduceArrayToObject(draftEligibile, (de) =>
    de.playerId.toString()
  );

  const rankedPlayerIdSet = new Set<number>();
  for (const db of draftBoard) {
    if (db) {
      rankedPlayerIdSet.add(db);
    }
  }

  const playerForVideo =
    infoForVideo && draftEligiblePlayerMap[infoForVideo.playerId];

  const updateBoard = (newBoard: (number | null)[]) => {
    setDraftBoardState({
      draftBoard: newBoard,
      boardEndIndex,
    });
    updateDraftBoardMutation.mutate({
      year,
      draftBoard: newBoard,
      boardEndIndex,
    });
  };

  return (
    <Page header={{ text: pageTitle }} title={pageTitle}>
      <Panel header="Draft Board">
        <>
          <div
            style={{
              display: "flex",
              gap: 8,
              marginBottom: 8,
              alignItems: "flex-end",
            }}
          >
            <div>
              <b>Board Size</b>
              <Form.Control
                style={{ width: "4em" }}
                min={1}
                max={60}
                width="auto"
                type="number"
                value={boardEndIndex}
                onChange={(evt) => {
                  const newEndIndex = Math.min(
                    parseInt(evt.target.value) || MIN_BOARD_SIZE,
                    60 // No one should have a board end index greater than 60.
                  );
                  setDraftBoardState({
                    draftBoard: draftBoard,
                    boardEndIndex: newEndIndex,
                  });
                  updateDraftBoardMutation.mutate({
                    year,
                    draftBoard: draftBoard,
                    boardEndIndex: newEndIndex,
                  });
                }}
              />
            </div>
            <Button
              onClick={() => {
                const newBoard = new Array(MAX_BOARD_SIZE).fill(null);
                const eligibleCopy = [
                  ...draftEligibile.filter(
                    (de) => !rankedPlayerIdSet.has(de.playerId)
                  ),
                ].sort((a, b) => {
                  const dxmA = a.dxModel5050;
                  const dxmB = b.dxModel5050;
                  if (dxmA === dxmB) return 0;
                  if (dxmA === null) return 1;
                  if (dxmB === null) return -1;
                  return dxmB - dxmA;
                });
                for (let i = 0; i < draftBoard.length; i++) {
                  if (draftBoard[i] === null) {
                    const nextPlayer = eligibleCopy.shift();
                    if (nextPlayer) {
                      newBoard[i] = nextPlayer.playerId;
                    }
                  } else {
                    newBoard[i] = draftBoard[i];
                  }
                }
                updateBoard(newBoard);
              }}
            >
              Fill Empty Spots
            </Button>
            <Button
              ref={copyButton}
              variant="secondary"
              onClick={() => {
                copy(
                  draftBoard
                    .slice(0, boardEndIndex)
                    .map((db, i) => {
                      const player =
                        db === null ? null : draftEligiblePlayerMap[db];
                      return player
                        ? `${i + 1}. ${
                            player === null ? "Withdrawn Player" : player.player
                          }`
                        : `${i + 1}.`;
                    })
                    .join("\n")
                );
                setShowCopyTooltip(true);
                setTimeout(() => setShowCopyTooltip(false), 1500);
              }}
            >
              Copy
            </Button>
            <Overlay
              target={copyButton.current}
              show={showCopyTooltip}
              placement="top"
            >
              <Tooltip>Draft Board Copied to Clipboard!</Tooltip>
            </Overlay>
            <Button
              onClick={() => updateBoard(new Array(MAX_BOARD_SIZE).fill(null))}
              variant="danger"
            >
              Clear Board
            </Button>
          </div>
          <SortableList
            items={draftBoard.map((db, i) => {
              // If there is no player selected for this slot use a rand number.
              return { id: db || -1 * Math.random(), idx: i };
            })}
            renderItem={(item) => (
              <div>
                <SortableList.Item id={item.id}>
                  <SortableList.DragHandle />
                  <PlayerRow
                    boardEndIndex={boardEndIndex}
                    rank={item.idx + 1}
                    draftElibilePlayers={draftEligibile}
                    rankedPlayerIdSet={rankedPlayerIdSet}
                    selectedPlayer={draftEligiblePlayerMap[item.id] || null}
                    setSelectedPlayerId={(playerId: number | null) => {
                      const oldDraftBoard = [...draftBoard];
                      oldDraftBoard[item.idx] = playerId || null;
                      updateBoard(oldDraftBoard);
                    }}
                    isLast={item.idx === draftBoard.length - 1}
                    setInfoForVideo={setInfoForVideo}
                    clickDelete={() => {
                      const oldDraftBoard = [...draftBoard];
                      oldDraftBoard.splice(item.idx, 1);
                      oldDraftBoard.push(null);
                      updateBoard(oldDraftBoard);
                    }}
                    playerComments={(commentsByPlayerMap[item.id] || []).sort(
                      (a, b) => b.timestamp - a.timestamp
                    )}
                    onUpdateComment={onUpdateComment}
                    playerComps={compsByPlayerMap[item.id] || []}
                  />
                </SortableList.Item>
              </div>
            )}
            onChange={(items) => {
              // Even though we use rand numbers locally for item identify of
              // non-selected rows, convert to NULL for the URL.
              updateBoard(items.map((item) => (item.id > 0 ? item.id : null)));
            }}
          />
          {synergyClips && infoForVideo && (
            <VideoModal
              title={`${playerForVideo ? playerForVideo.player : "Unknown"} ${
                infoForVideo.offDef === "offense" ? "Offense" : "Defense"
              } Clips`}
              show={infoForVideo !== undefined}
              clips={(synergyClips || []).map((c) => {
                return {
                  label: labelForClip(c),
                  url: c.url,
                };
              })}
              handleClose={() => setInfoForVideo(undefined)}
              upDownClipSkip={true}
              showSynergyEditor={false}
            />
          )}
        </>
      </Panel>
    </Page>
  );
}

function PlayerRow(props: {
  boardEndIndex: number;
  rank: number;
  draftElibilePlayers: BoardMakerPlayer[];
  rankedPlayerIdSet: Set<number>;
  selectedPlayer: BoardMakerPlayer | null;
  setSelectedPlayerId: (val: number | null) => void;
  isLast: boolean;
  clickDelete: () => void;
  setInfoForVideo: (val: { playerId: number; offDef: string }) => void;
  playerComments: DraftBoardComment[];
  onUpdateComment: (playerId: number, comment: string) => void;
  playerComps: PlayerDraftBoardComp[];
}) {
  const {
    boardEndIndex,
    rank,
    draftElibilePlayers,
    rankedPlayerIdSet,
    selectedPlayer,
    setSelectedPlayerId,
    clickDelete,
    setInfoForVideo,
    playerComments,
    onUpdateComment,
    playerComps,
  } = props;
  const [notesFocused, setNotesFocused] = useState(false);

  const selectedPlayerId = selectedPlayer ? selectedPlayer.playerId : null;

  // Grab the first 3 physical comps sorted by impact.
  const physicalComps = (playerComps || [])
    .sort((a, b) => {
      const bImpact = b.netImpact;
      const aImpact = a.netImpact;
      if (bImpact === aImpact) return 0;
      if (bImpact === null) return -1;
      if (aImpact === null) return 1;
      return bImpact - aImpact;
    })
    .slice(0, 3);

  const selectablePlayers = draftElibilePlayers.filter(
    (de) =>
      de.playerId === selectedPlayerId || !rankedPlayerIdSet.has(de.playerId)
  );

  const rowIncludedInBoard = rank <= boardEndIndex;

  const multiSelect = (
    <div>
      <MultiSelect
        values={selectablePlayers
          .map((de) => {
            return {
              label: de.player || "Unknown",
              value: de.playerId.toString(),
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1))}
        selected={selectedPlayerId ? [selectedPlayerId.toString()] : []}
        onChange={(vals) => {
          const playerId = vals[0];
          if (playerId && parseInt(playerId)) {
            setSelectedPlayerId(parseInt(playerId));
          } else {
            setSelectedPlayerId(null);
          }
        }}
        limit={1}
      />
      <Alert
        variant={rowIncludedInBoard ? "success" : "danger"}
        style={{ textAlign: "center", padding: 2, marginTop: 4 }}
      >
        {rowIncludedInBoard
          ? rank <= 14
            ? "Lottery"
            : rank <= 30
            ? "1st Round"
            : "2nd Round"
          : "Not Included"}
      </Alert>
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <PositionIndicator
          position={(selectedPlayer && selectedPlayer.position) || null}
        />
        {selectedPlayer && (
          <a
            href={`/player/${selectedPlayer.playerId}`}
            target="_blank"
            rel="noreferrer"
          >
            {/* 19 is the magic number to match FaPlayCircle size. */}
            <MdAccountCircle size={19} />
          </a>
        )}
        {selectedPlayer && (
          <a
            href={`/video?players=${selectedPlayer.playerId}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaPlayCircle />
          </a>
        )}
      </div>
    </div>
  );

  const playerCell = selectedPlayerId ? (
    <OverlayTrigger
      delay={{ show: 200, hide: 0 }}
      placement={"right"}
      overlay={
        <Popover style={{ maxWidth: "none" }}>
          <PlayerPreviewCard playerId={selectedPlayerId} />
        </Popover>
      }
    >
      <div style={{ position: "relative" }}>{multiSelect}</div>
    </OverlayTrigger>
  ) : (
    multiSelect
  );

  const contentColumns: {
    label: string;
    content: ReactNode;
    isNotes?: boolean;
  }[] = [
    {
      label: "Age",
      content: selectedPlayer ? decFormat(selectedPlayer.Age) : null,
    },
    {
      label: "Pred. Impact",
      content: selectedPlayer
        ? makePlusMinus(decFormat)(selectedPlayer.peakImpactPred)
        : null,
    },
    {
      label: "Dx Rank",
      content: selectedPlayer ? selectedPlayer.dxRank : null,
    },
  ];

  const mostRecentComment =
    playerComments && playerComments[0] && playerComments[0].comment;

  contentColumns.push({
    label: "Player Clips",
    content: selectedPlayer && (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: 8 }}>
          <div>Off</div>
          <VideoControl
            onVideo={(selectedPlayer) => {
              setInfoForVideo({
                playerId: selectedPlayer.playerId,
                offDef: "offense",
              });
            }}
            data={selectedPlayer}
          />
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <div>Def</div>
          <VideoControl
            onVideo={(selectedPlayer) => {
              setInfoForVideo({
                playerId: selectedPlayer.playerId,
                offDef: "defense",
              });
            }}
            data={selectedPlayer}
          />
        </div>
      </div>
    ),
  });

  contentColumns.push({
    label: "Physical Comps",
    content: (
      <div>
        {physicalComps.map((pc, i) => (
          <div
            key={i}
            style={{
              textAlign: "left",
              whiteSpace: "nowrap",
            }}
          >
            <Link to={`/player/${pc.compPlayerId}`} target="_blank">
              {pc.compPlayerName}
            </Link>
          </div>
        ))}
      </div>
    ),
  });
  contentColumns.push({
    label: "Notes",
    content: (
      <Form.Control
        rows={3}
        className="draft-board-notes"
        readOnly={!selectedPlayerId}
        onFocus={() => setNotesFocused(true)}
        onBlur={(evt) => {
          setNotesFocused(false);
          const curVal = evt.target.value;
          if (selectedPlayerId && curVal !== mostRecentComment) {
            onUpdateComment(selectedPlayerId, curVal);
          }
        }}
        defaultValue={mostRecentComment}
        as="textarea"
      />
    ),
    isNotes: true,
  });
  return (
    <div
      className="draft-board-row"
      style={{
        // Make the resizing look nice and smooth.
        transition: "all 0.5s ease",
        display: "flex",
        gap: 8,
        paddingLeft: 24,
        width: "100%",
      }}
    >
      <div style={{ width: 32 }}>
        <b style={{ color: rank > 60 ? "lightgray" : "black" }}>{rank}.</b>
      </div>
      {playerCell}
      {contentColumns.map((cc, i) => (
        <div
          className={cx("draft-board-item", {
            "draft-board-item-text-focused": notesFocused,
          })}
          style={{
            transition: "all .5s ease",
            flex: cc.isNotes ? "1 1 auto" : "0 1 auto",
            display: "flex",
            flexDirection: "column",
            borderRight:
              i < contentColumns.length - 1 ? "1px solid lightgray" : undefined,
          }}
          key={i}
        >
          <div>
            <b>{cc.label}</b>
          </div>
          <div
            style={{
              textAlign: cc.isNotes ? "left" : "center",
              padding: cc.isNotes ? 4 : 0,
            }}
          >
            {cc.content}
          </div>
        </div>
      ))}
      <div>
        <Button
          variant="outline-danger"
          onClick={clickDelete}
          style={{
            padding: 3,
            fontSize: ".7em",
            lineHeight: 0,
          }}
        >
          <FaTimes />
        </Button>
      </div>
    </div>
  );
}

function labelForClip(clip: SynergyClip) {
  return `${period(clip.iGameQuarter)} ${gameClockFormat(
    clip.iGameClock_start
  )} ${dateFormat(moment(clip.dtGameDate).toDate())} ${clip.string_event}`;
}
