import React, { useState, useMemo } from "react";
import { Form } from "react-bootstrap";

import { GameDetails } from "../../../shared/routers/GameRouter";
import {
  SynergyGameSegment,
  SynergyGameClip,
} from "../../../shared/routers/SynergyRouter";
import { Panel } from "../../components/core/Panel";
import { VideoPlayer } from "../../components/video/VideoPlayer";
import { getHoop1Urls } from "../../util/VideoUtil";
import { gameClipToSynergyEditorClip } from "../../components/video/utilities";
import { Spinner } from "../../components/core/Spinner";

export function GameSubPageVideo(props: {
  filtered: boolean;
  home?: { teamid: number; team: string };
  away?: { teamid: number; team: string };
  gameSegments?: SynergyGameSegment[];
  gameClips?: SynergyGameClip[];
  gameDetails?: GameDetails[];
}) {
  const { filtered, home, away, gameSegments, gameClips, gameDetails } = props;
  const [source, setSource] = useState("synergy");

  const hoop1Urls = useMemo(() => {
    const gameDetailsFirst = gameDetails && gameDetails[0];
    if (!gameDetailsFirst) return undefined;
    return getHoop1Urls(gameDetailsFirst).urls.map((u, i) => {
      return { url: u, label: `Period ${i + 1}` };
    });
  }, [gameDetails]);

  const homeTeam = home ? home.team : "Unknown";
  const awayTeam = away ? away.team : "Unknown";

  const isLoading = !gameClips || !gameSegments || !hoop1Urls;

  const clips = useMemo(() => {
    if (!gameClips || !gameSegments || !hoop1Urls) return [];
    if (source === "synergy") {
      return [
        ...new Map(gameSegments.map((gs) => [gs.order, gs])).values(),
      ].map((gs) => {
        return {
          url: gs.videoUrl,
          label: `${awayTeam} @ ${homeTeam}`,
        };
      });
    } else if (source === "synergyByPoss") {
      return gameClips.map(gameClipToSynergyEditorClip);
    } else {
      return hoop1Urls;
    }
  }, [awayTeam, gameClips, gameSegments, homeTeam, hoop1Urls, source]);

  const sources = [
    { value: "synergy", label: "Synergy" },
    { value: "synergyByPoss", label: "Synergy (by possession)" },
    { value: "hoop1", label: "Hoop 1" },
  ];

  const numOfGames = gameDetails ? gameDetails.length : 0;

  return (
    <Panel header="Video" panelID="video" filtered={filtered}>
      {numOfGames > 1 ? (
        <div>To see game video panel select a single game from the series.</div>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div
            style={{
              marginBottom: 8,
            }}
          >
            <Form.Select
              style={{ width: "auto", display: "inline" }}
              onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                setSource(evt.target.value);
              }}
            >
              {sources.map((s, i) => (
                <option key={i} value={s.value}>
                  {s.label}
                </option>
              ))}
            </Form.Select>
          </div>
          {clips.length > 0 ? (
            <VideoPlayer
              key={source}
              title={`${awayTeam} @ ${homeTeam}`}
              prefetchLength={source === "synergyByPoss" ? 5 : 1}
              clips={clips}
              upDownClipSkip={source === "synergyByPoss"}
              showSynergyEditor={source === "synergyByPoss"}
            />
          ) : (
            <div>No video available for this source.</div>
          )}
        </div>
      )}
    </Panel>
  );
}
