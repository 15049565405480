import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { complexShotTypeMap } from "../../constants/AppConstants";
import { Panel } from "../../components/core/Panel";
import { Shot } from "../../../shared/routers/ShotRouter";
import { ShotQuality } from "../../components/shots/ShotQuality";
import { ShotChartMakeMiss } from "../../components/shots/ShotChartMakeMiss";
import { groupBy } from "../../../shared/util/Collections";

export function GameSubPageShooting(props: {
  filtered: boolean;
  home?: { teamCity: string; teamid: number; team: string };
  away?: { teamCity: string; teamid: number; team: string };
  shots?: Shot[];
}) {
  const { shots, home, away, filtered } = props;

  if (!home || !away || !shots) return null;

  const awayCity = away.teamCity;
  const awayTeamId = away.teamid;
  const homeCity = home.teamCity;
  const homeTeamId = home.teamid;
  return (
    <div>
      <Row>
        <Col md={10}>
          <Row>
            <Col md={6}>
              <Panel
                header={`Shot Quality · ${awayCity}`}
                panelID="shotQualityAway"
                filtered={filtered}
              >
                {shots && (
                  <ShotQualityPlot
                    teamId={awayTeamId}
                    shots={shots.filter((s) => s.oteamId === awayTeamId)}
                  />
                )}
              </Panel>
            </Col>
            <Col md={6}>
              <Panel
                header={`Shot Quality · ${homeCity}`}
                panelID="shotQualityHome"
                filtered={filtered}
              >
                {shots && (
                  <ShotQualityPlot
                    teamId={homeTeamId}
                    shots={shots.filter((s) => s.oteamId === homeTeamId)}
                  />
                )}
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {shots && (
          <ShotChartPanels
            filtered={filtered}
            shots={shots}
            home={home}
            away={away}
          />
        )}
      </Row>
    </div>
  );
}

function ShotQualityPlot(props: { shots: Shot[]; teamId: number }) {
  const { shots, teamId } = props;
  const [player, setPlayer] = useState("All Players");
  const [shotType, setShotType] = useState("all");

  const players = [...new Set(shots.map((s) => s.shooter))];
  const complexShotTypes = Object.keys(complexShotTypeMap)
    .filter((key) => key !== "post" && key !== "catchAndShootOnMove")
    .map((key) => {
      const cst = complexShotTypeMap[key];
      return { value: key, label: cst ? cst.label : "" };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const shotTypes = [
    { label: "All Shot Types", value: "all" },
    { label: "All Made Shots", value: "made" },
    { label: "All Missed Shots", value: "miss" },
  ];

  const shotTypeGroup2 = [
    { label: "Layups", value: "layups" },
    { label: "NL2s", value: "nl2s" },
    { label: "Threes", value: "threes" },
  ];

  const byShotType = (s: Shot) => {
    if (shotType === "all") {
      return true;
    } else if (shotType === "made") {
      return s.made;
    } else if (shotType === "miss") {
      return !s.made;
    } else if (shotType === "threes") {
      return s.isThree;
    } else if (shotType === "layups") {
      return s.generalShotType === "layup" && !s.isThree;
    } else if (shotType === "nl2s") {
      return s.generalShotType !== "layup" && !s.isThree;
    }
    return s.complexShotType === shotType;
  };

  const byShooter = (s: Shot) => {
    if (player === "All Players") {
      return true;
    }
    return s.shooter === player;
  };

  const filteredShots = shots.filter(byShotType).filter(byShooter);
  const filteredOutShots = shots.filter(
    (s) => !(byShotType(s) && byShooter(s))
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: 15,
        }}
      >
        <Form.Select
          style={{ width: "auto" }}
          value={player}
          onChange={(e) => setPlayer(e.target.value)}
        >
          <option key={"All Players"} value={"All Players"}>
            {"All Players"}
          </option>
          {players.sort().map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          style={{ width: "auto" }}
          value={shotType}
          onChange={(e) => setShotType(e.target.value)}
        >
          {shotTypes.map((p) => (
            <option key={p.value} value={p.value}>
              {p.label}
            </option>
          ))}
          <optgroup label="Shot Location">
            {shotTypeGroup2.map((p) => (
              <option key={p.value} value={p.value}>
                {p.label}
              </option>
            ))}
          </optgroup>
          <optgroup label="Shot Type">
            {complexShotTypes.map((p) => (
              <option key={p.value} value={p.value}>
                {p.label}
              </option>
            ))}
          </optgroup>
        </Form.Select>
      </div>
      <ShotQuality
        shots={filteredShots}
        filteredOutShots={filteredOutShots}
        teamId={teamId}
      />
    </div>
  );
}

function ShotChartPanels(props: {
  filtered: boolean;
  shots: Shot[];
  home?: { teamCity: string; teamid: number; team: string };
  away?: { teamCity: string; teamid: number; team: string };
}) {
  const { filtered, shots, home, away } = props;
  const teams = [away, home];
  const panelIds = ["shotChartsAway", "shotChartsHome"];
  return (
    <Col md={12}>
      {teams.map((t, i) => {
        if (!t) return null;

        const teamShotsByPlayer: Record<string, Shot[]> = groupBy(
          shots.filter((s) => s.oteamId === t.teamid),
          (s) => s.shooterId.toString()
        );

        // Get a list of player ids sorted by the number of shots a player took
        // in the game so that more relevant shot charts appear first.
        const sortedPlayerIds = Object.keys(teamShotsByPlayer).sort((a, b) => {
          const playerBShots = teamShotsByPlayer[b];
          const playerAShots = teamShotsByPlayer[a];
          return (
            (playerBShots ? playerBShots.length : 0) -
            (playerAShots ? playerAShots.length : 0)
          );
        });
        return (
          <Panel
            key={i}
            header={`Shot Charts · ${t.teamCity}`}
            panelID={panelIds[i]}
            filtered={filtered}
          >
            <div>
              <h4 className="text-center">{t.team}</h4>
              <Row>
                {sortedPlayerIds.map((p, j) => {
                  const playerShots = teamShotsByPlayer[p] || [];

                  const playerShotsFirst = playerShots[0];
                  const name = playerShotsFirst
                    ? playerShotsFirst.shooter
                    : "Unknown";

                  return (
                    <Col key={j} md={3} sm={4}>
                      <div>
                        <h5 className="text-center">{name}</h5>
                        <ShotChartMakeMiss shots={playerShots} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Panel>
        );
      })}
    </Col>
  );
}
