import React from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

export function Spinner(props: {
  message?: string;
  small?: boolean;
  white?: boolean;
}) {
  const { message, small, white } = props;
  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        marginRight: 12,
      }}
    >
      <BootstrapSpinner
        variant={white ? "light" : "primary"}
        animation="border"
        role="status"
        size={small ? "sm" : undefined}
      />
      <span style={{ marginLeft: 4 }}>{message}</span>
    </div>
  );
}
