import React, { useContext } from "react";

import { Periods } from "../../constants/AppConstants";
import { MultiSelect } from "../core/MultiSelect";
import AppContext from "../../../shared/AppContext";
import { TeamContext } from "../../TeamContext";
import { PossessionFilters } from "../../../shared/routers/PossessionRouter";
import { WowyLineupInput } from "../query/WowyLineupInput";
import { FilterForm } from "../query/FilterForm";

export function PossessionFilterForm(props: {
  filters: PossessionFilters;
  onFilterChange: (f: PossessionFilters) => void;
}) {
  const { filters, onFilterChange } = props;

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;

  return (
    <FilterForm
      filters={filters}
      setFilters={onFilterChange}
      controls={[
        // Group 1 - Season/Period
        [
          // Row 1
          [
            {
              label: "Season",
              key: "season",
              type: "multiselect",
              options: AppContext.seasons.map((s) => {
                return { value: s.value.toString(), label: s.label };
              }),
            },
            {
              label: "Period",
              key: "period",
              type: "multiselect",
              options: Object.keys(Periods).map((p) => {
                return { value: p, label: Periods[parseInt(p)] || "" };
              }),
            },
          ],
        ],
        // Group 2 - Dates and leverage.
        [
          // Row 1
          [
            {
              label: "Date",
              key: "fromDate",
              key2: "toDate",
              type: "daterange",
            },
            {
              label: "Leverage",
              key: "fromLeverage",
              key2: "toLeverage",
              type: "leverage",
            },
          ],
        ],
        // Group 3 - Offense
        [
          // Row 1
          [
            {
              label: "Team",
              key: "teamId",
              type: "custom",
              render: () => (
                <MultiSelect
                  limit={1}
                  values={teams.map((t) => {
                    return {
                      label: `${t.teamcity} ${t.teamname}`,
                      value: t.teamid.toString(),
                    };
                  })}
                  selected={filters.teamId?.map((s) => s.toString()) || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      wowyLineup: undefined,
                      teamId: vals.length ? vals : undefined,
                    })
                  }
                />
              ),
            },
            {
              label: "# Bigs ",
              key: "numBigs",
              type: "number",
              biaonly: true,
            },
            {
              label: "# Fives",
              key: "numFives",
              type: "number",
              biaonly: true,
            },
          ],
          // Row 2
          [
            {
              label: "Lineup",
              type: "custom",
              key: "wowyLineup",
              render: () => (
                <WowyLineupInput
                  disabled={filters.teamId === undefined}
                  teamId={filters.teamId ? filters.teamId[0] : undefined}
                  seasons={filters.season}
                  value={filters.wowyLineup}
                  setValue={(val) =>
                    onFilterChange({ ...filters, wowyLineup: val })
                  }
                />
              ),
            },
          ],
        ],
        [
          // Group 4 - Defense
          [
            {
              label: "Opponent",
              key: "oppTeamId",
              type: "multiselect",
              options: teams.map((t) => {
                return {
                  label: `${t.teamcity} ${t.teamname}`,
                  value: t.teamid.toString(),
                };
              }),
            },
            {
              label: "# Bigs",
              key: "oppNumBigs",
              type: "number",
              biaonly: true,
            },
            {
              label: "# Fives",
              key: "oppNumFives",
              type: "number",
              biaonly: true,
            },
          ],
        ],
        // Group 5 - Booleans, part 1.
        [
          // Row 1
          [
            {
              label: "Playoffs",
              key: "isPlayoff",
              type: "boolean",
            },
            {
              label: "Transition",
              key: "transition",
              type: "boolean",
            },
          ],
        ],
        // Group 6 - Booleans, part 2.
        [
          // Row 1
          [
            {
              label: "Turnover",
              key: "turnover",
              type: "boolean",
            },
            {
              label: "Zone",
              key: "zone",
              type: "boolean",
            },
          ],
        ],
      ]}
    />
  );
}
