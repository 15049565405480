import React, { useMemo, useState } from "react";

import { Table, createColumnHelper } from "../core/Table";
import {
  GameEndOfPeriodScoring,
  GameEndOfPeriodVideo,
} from "../../../shared/routers/GameRouter";
import {
  intFormat,
  decFormat,
  plusMinusFormat,
  makePlusMinus,
} from "../../util/Format";
import { sumFromField } from "../../util/Util";
import { VideoControl } from "../query/VideoControl";
import { VideoModal } from "../video/VideoModal";

interface EndOfPeriodScoringRow {
  homeTeamId: number | null;
  homeTeam: string | null;
  homeTeamNumPoss: number | null;
  homeTeamPts: number | null;
  homeTeamXpts: number | null;
  homeTeamNetNumPoss: number | null;
  homeTeamNetPts: number | null;
  homeTeamNetXpts: number | null;
  period: number | null;
  awayTeamId: number | null;
  awayTeam: string | null;
  awayTeamNumPoss: number | null;
  awayTeamPts: number | null;
  awayTeamXpts: number | null;
  awayTeamNetNumPoss: number | null;
  awayTeamNetPts: number | null;
  awayTeamNetXpts: number | null;
  video: GameEndOfPeriodVideo[] | null;
}

const columnHelper = createColumnHelper<EndOfPeriodScoringRow>();

export function GameEndOfPeriodScoringTable(props: {
  homeTeamId: number;
  homeTeam: string;
  awayTeamId: number;
  awayTeam: string;
  data: GameEndOfPeriodScoring[];
  videos: GameEndOfPeriodVideo[];
}) {
  const { data, homeTeamId, awayTeamId, homeTeam, awayTeam, videos } = props;
  const [clips, setClips] = useState<{ url: string; label: string }[] | null>(
    null
  );

  const dataByPeriod: EndOfPeriodScoringRow[] = [1, 2, 3].map((p) => {
    const homeTeamData = data.find(
      (d) => d.period === p && d.offTeamId === homeTeamId
    );
    const awayTeamData = data.find(
      (d) => d.period === p && d.offTeamId === awayTeamId
    );

    // API calls returns video data sorted, so shouldn't need to re-sort here.
    const video = videos.filter((d) => d.period === p);

    return {
      period: p,
      homeTeamId: homeTeamData ? homeTeamData.offTeamId : null,
      homeTeam: homeTeamData ? homeTeamData.offTeam : null,
      homeTeamNumPoss: homeTeamData ? homeTeamData.numPoss : null,
      homeTeamPts: homeTeamData ? homeTeamData.pts : null,
      homeTeamXpts: homeTeamData ? homeTeamData.xPts : null,
      homeTeamNetNumPoss:
        homeTeamData && awayTeamData
          ? homeTeamData.numPoss - awayTeamData.numPoss
          : null,
      homeTeamNetPts:
        homeTeamData && awayTeamData
          ? homeTeamData.pts - awayTeamData.pts
          : null,
      homeTeamNetXpts:
        homeTeamData && awayTeamData
          ? homeTeamData.xPts - awayTeamData.xPts
          : null,
      awayTeamId: awayTeamData ? awayTeamData.offTeamId : null,
      awayTeam: awayTeamData ? awayTeamData.offTeam : null,
      awayTeamNumPoss: awayTeamData ? awayTeamData.numPoss : null,
      awayTeamPts: awayTeamData ? awayTeamData.pts : null,
      awayTeamXpts: awayTeamData ? awayTeamData.xPts : null,
      awayTeamNetNumPoss:
        homeTeamData && awayTeamData
          ? awayTeamData.numPoss - homeTeamData.numPoss
          : null,
      awayTeamNetPts:
        homeTeamData && awayTeamData
          ? awayTeamData.pts - homeTeamData.pts
          : null,
      awayTeamNetXpts:
        homeTeamData && awayTeamData
          ? awayTeamData.xPts - homeTeamData.xPts
          : null,
      video,
    };
  });

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("period", {
        header: () => "Period",
        meta: { group: g++ },
        footer: () => "Total",
      }),
      columnHelper.group({
        id: "homeTeam",
        header: () => homeTeam,
        meta: { group: g++ },
        columns: [
          columnHelper.accessor("homeTeamNumPoss", {
            header: () => "Poss",
            cell: (info) => intFormat(info.getValue()),
            footer: () =>
              intFormat(sumFromField("homeTeamNumPoss", dataByPeriod)),
          }),
          columnHelper.accessor("homeTeamXpts", {
            header: () => "xPts",
            cell: (info) => decFormat(info.getValue()),
            footer: () => decFormat(sumFromField("homeTeamXpts", dataByPeriod)),
          }),
          columnHelper.accessor("homeTeamPts", {
            header: () => "Pts",
            cell: (info) => intFormat(info.getValue()),
            footer: () => intFormat(sumFromField("homeTeamPts", dataByPeriod)),
          }),
          columnHelper.accessor("homeTeamNetNumPoss", {
            header: () => "Net Poss",
            cell: (info) => makePlusMinus(intFormat)(info.getValue()),
            footer: () =>
              makePlusMinus(intFormat)(
                sumFromField("homeTeamNetNumPoss", dataByPeriod)
              ),
          }),
          columnHelper.accessor("homeTeamNetPts", {
            header: () => "Net Pts",
            cell: (info) => makePlusMinus(intFormat)(info.getValue()),
            footer: () =>
              makePlusMinus(intFormat)(
                sumFromField("homeTeamNetPts", dataByPeriod)
              ),
          }),
          columnHelper.accessor("homeTeamNetXpts", {
            header: () => "Net xPts",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: { colorDomain: [-2, 2], heatmap: true, group: g++ },
            footer: () =>
              plusMinusFormat(sumFromField("homeTeamNetXpts", dataByPeriod)),
          }),
        ],
      }),
      columnHelper.group({
        id: "awayTeam",
        header: () => awayTeam,
        meta: { group: g++ },
        columns: [
          columnHelper.accessor("awayTeamNumPoss", {
            header: () => "Poss",
            cell: (info) => intFormat(info.getValue()),
            footer: () =>
              intFormat(sumFromField("awayTeamNumPoss", dataByPeriod)),
          }),
          columnHelper.accessor("awayTeamXpts", {
            header: () => "xPts",
            cell: (info) => decFormat(info.getValue()),
            footer: () => decFormat(sumFromField("awayTeamXpts", dataByPeriod)),
          }),
          columnHelper.accessor("awayTeamPts", {
            header: () => "Pts",
            cell: (info) => intFormat(info.getValue()),
            footer: () => intFormat(sumFromField("awayTeamPts", dataByPeriod)),
          }),
          columnHelper.accessor("awayTeamNetNumPoss", {
            header: () => "Net Poss",
            cell: (info) => makePlusMinus(intFormat)(info.getValue()),
            footer: () =>
              makePlusMinus(intFormat)(
                sumFromField("awayTeamNetNumPoss", dataByPeriod)
              ),
          }),
          columnHelper.accessor("awayTeamNetPts", {
            header: () => "Net Pts",
            cell: (info) => makePlusMinus(intFormat)(info.getValue()),
            footer: () =>
              makePlusMinus(intFormat)(
                sumFromField("awayTeamNetPts", dataByPeriod)
              ),
          }),
          columnHelper.accessor("awayTeamNetXpts", {
            header: () => "Net xPts",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: { colorDomain: [-2, 2], heatmap: true, group: g++ },
            footer: () =>
              plusMinusFormat(sumFromField("awayTeamNetXpts", dataByPeriod)),
          }),
        ],
      }),
      columnHelper.accessor("video", {
        header: () => "Video",
        cell: (info) => {
          const videos = info.getValue();
          if (!videos) return null;
          const clips = (videos || []).map((v, i) => ({
            url: v.SynergyURL,
            label: `Scoring Chance #${i + 1}`,
            auxData: {
              clipId: v.clipId,
              prevClipId: v.prevClipId || "",
              skipTime: v.skipTime.toString(),
            },
          }));

          return (
            <VideoControl
              data={null}
              onVideo={() => {
                setClips(clips);
              }}
            />
          );
        },
        footer: () => {
          const videos = dataByPeriod.flatMap((d) => d.video);
          if (!videos) return null;
          const clips = (
            (videos || []) as unknown as GameEndOfPeriodVideo[]
          ).map((v, i) => ({
            url: v.SynergyURL,
            label: `Scoring Chance #${i + 1}`,
            auxData: {
              clipId: v.clipId,
              prevClipId: v.prevClipId || "",
              skipTime: v.skipTime.toString(),
            },
          }));
          return (
            <VideoControl
              data={null}
              onVideo={() => {
                setClips(clips);
              }}
            />
          );
        },
        meta: { group: g },
      }),
    ];
  }, [dataByPeriod, homeTeam, awayTeam]);

  return (
    <>
      <Table
        data={dataByPeriod}
        columns={columns}
        autoWidth={true}
        showRowIndex={false}
      />
      <VideoModal
        title={"End of Quarter Scoring"}
        clips={clips || []}
        handleClose={() => setClips(null)}
        show={!!clips}
        upDownClipSkip={true}
        showSynergyEditor={false}
      />
    </>
  );
}
