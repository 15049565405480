import React, { useMemo, useState } from "react";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import { PlayerTableCell } from "../core/TableCell";
import { TeamOffRebounding } from "../../../shared/routers/TeamRouter";
import { intFormat, makePlusMinus, pctFormat } from "../../util/Format";
import { PlayerStatColorDomains } from "../../constants/ColorDomains";
import { Highlights } from "../../constants/AppConstants";

const columnHelper = createColumnHelper<TeamOffRebounding>();

export function TeamOffReboundingTable(props: { data: TeamOffRebounding[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: "Player",
        cell: ({ row }) => (
          <PlayerTableCell
            id={row.original.celticsId}
            name={row.original.player}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("orbs", {
        header: "ORBs",
        cell: (info) => intFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: PlayerStatColorDomains.totals.orb,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("orbRate", {
        header: "OR %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: (PlayerStatColorDomains.perGame.orbpct as number[]).map(
            (d) => d / 100
          ),
        },
      }),
      columnHelper.accessor("crashRate", {
        header: "Crash %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains.perGame.crashRate,
        },
      }),
      columnHelper.accessor("crashRateOverExp", {
        header: "vs Exp",
        cell: (info) => makePlusMinus(pctFormat)(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains.perGame.crashRateDiff,
        },
      }),
      columnHelper.accessor("deepCrashRate", {
        header: "Deep Crash %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains.perGame.deepCrashRate,
        },
      }),
      columnHelper.accessor("deepCrashRateOverExp", {
        header: "vs Exp",
        cell: (info) => makePlusMinus(pctFormat)(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains.perGame.deepCrashRateDiff,
        },
      }),
    ];
  }, []);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}
