import React, { ReactNode, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { UserPreferenceContext, UserContext } from "../../UserContext";
import { minutesFormat, TABLE_EMPTY_VALUE_STR } from "../../util/Format";
import { LineChart } from "../chart/LineChart";
import { PlayerPreviewCard } from "../player/PlayerPreviewCard";
import { PlayerStatusContext } from "../../PlayerStatusContext";
import {
  FaArrowTrendUp,
  FaArrowTrendDown,
  FaArrowRightLong,
} from "react-icons/fa6";
import { BiPlusMedical, BiError, BiStar } from "react-icons/bi";
import { BsRadioactive } from "react-icons/bs";
import {
  blueLow,
  darkGrayMid,
  Green1,
  lineChartColors,
  purple,
  redHigh,
} from "../../constants/ColorConstants";
import { Restrict } from "./Restrict";
import AppContext from "../../../shared/AppContext";
import { BANNED_PLAYER_IDS } from "../../constants/BannedPlayers";

export function PlayerTableCell(props: {
  id?: string | number;
  name?: string | JSX.Element;
  bold?: boolean;
  useSpans?: boolean;
  // Whether or not to show the player status icons (injuryies, trending, hot/cold, etc).
  // The generally rule of thumb is that if a player will appear in multiple rows in
  // the table turn these off.
  showPlayerStatusIcons: boolean;
}) {
  const { id, name, bold, useSpans, showPlayerStatusIcons } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  const user = useContext(UserContext);

  const shouldShowPreviewCard =
    useContext(UserPreferenceContext)["Player Preview Cards"] || 0;

  const { playerInjuries, playerMissedGames, trendingPlayers, playerHotCold } =
    useContext(PlayerStatusContext);

  const isInjured = id && playerInjuries[parseInt(id.toString())];
  const missedgames = id && playerMissedGames[parseInt(id.toString())];
  const isTrending = id && trendingPlayers[parseInt(id.toString())];
  const hotCold = id && playerHotCold[parseInt(id.toString())];
  const isHot = !!(hotCold && hotCold.luck > 0);
  const isCold = !!(hotCold && hotCold.luck < 0);

  let innerContent = id ? (
    <Link to={`/player/${id}`}>
      {name}
      {showPlayerStatusIcons && isInjured && (
        <BiPlusMedical
          style={{
            borderRadius: 2,
            backgroundColor: "white",
            color: redHigh,
            marginTop: 2,
            marginLeft: 4,
            verticalAlign: "top",
            width: 8,
            height: 8,
          }}
        />
      )}
      {showPlayerStatusIcons && !isInjured && missedgames && (
        <BiError
          style={{
            color:
              missedgames.gamesMissed >= 5 ? redHigh : lineChartColors.yellow,
            marginLeft: 4,
            verticalAlign: "top",
            width: 12,
            height: 12,
          }}
        />
      )}
      <Restrict roles={["bia"]}>
        {showPlayerStatusIcons && (isHot || isCold) && (
          <CustomDiceIcon hot={isHot} />
        )}
      </Restrict>
      {showPlayerStatusIcons && isTrending && (
        <Restrict
          roles={[]}
          override={
            user &&
            ["dcannon@celtics.com", "chrisbu@celtics.com"].includes(user.email)
          }
        >
          <BiStar
            style={{
              color: purple,
              marginLeft: 4,
              verticalAlign: "top",
              width: 12,
              height: 12,
            }}
          />
        </Restrict>
      )}
    </Link>
  ) : (
    name
  );

  // Override inner content for banned players.
  if (showPlayerStatusIcons && id && BANNED_PLAYER_IDS.has(id as number)) {
    innerContent = (
      <Link to={`/player/${id}`}>
        {name}
        <BsRadioactive
          style={{
            color: Green1,
            marginLeft: 4,
            verticalAlign: "top",
            width: 12,
            height: 12,
          }}
        />
      </Link>
    );
  }

  const wrappedContent = useSpans ? (
    <span
      ref={spanRef}
      style={{ textAlign: "left", fontWeight: bold ? "bold" : "normal" }}
    >
      {innerContent}
    </span>
  ) : (
    <div
      ref={divRef}
      style={{ textAlign: "left", fontWeight: bold ? "bold" : "normal" }}
    >
      {innerContent}
    </div>
  );

  if (!id || !shouldShowPreviewCard) return wrappedContent;

  let placement: "left" | "right" = "right";

  if (
    spanRef.current &&
    spanRef.current.getBoundingClientRect().x > window.innerWidth / 2
  ) {
    placement = "left";
  } else if (
    divRef.current &&
    divRef.current.getBoundingClientRect().x > window.innerWidth / 2
  ) {
    placement = "left";
  }

  return (
    <OverlayTrigger
      delay={{ show: 200, hide: 0 }}
      placement={placement}
      overlay={
        <Popover style={{ maxWidth: "none" }}>
          <PlayerPreviewCard playerId={parseInt(id.toString())} />
        </Popover>
      }
    >
      {wrappedContent}
    </OverlayTrigger>
  );
}

export function TeamTableCell(props: {
  id?: string | number;
  name?: string;
  ids?: number;
  season?: string;
  league?: string;
  useSpan?: boolean;
}) {
  const { id, name, ids, league, season, useSpan } = props;

  if (!name) return null;

  const isOldSeason = season && season !== AppContext.currentSeason;

  const isNBA = ids && ids >= 1610612737 && ids <= 1610612766;
  // Most things are passing in leagueKey but just to be safe convert everything
  // from league format to leagueKey.
  const leagueKey = league
    ? league.replaceAll(" ", "_").toLocaleLowerCase()
    : undefined;
  const leagueStr = leagueKey ? `league=${leagueKey}` : "";
  const seasonStr = season ? `season=${season}` : "";

  const innerContent =
    isNBA && !isOldSeason ? (
      <Link to={`/team/${ids}`}>{name}</Link>
    ) : id ? (
      <Link
        to={[
          `/team/ml/${id}`,
          [leagueStr, seasonStr].filter((x) => x).join("&"),
        ].join("?")}
      >
        {name}
      </Link>
    ) : (
      name
    );

  if (useSpan) {
    return <span style={{ textAlign: "left" }}>{innerContent}</span>;
  }

  return <div style={{ textAlign: "left" }}>{innerContent}</div>;
}

export function PlayerMinutesTableCell(props: {
  min: number;
  gameId: number;
  playerId: number | null;
  playerMinutesExist: boolean;
}) {
  const { min, gameId, playerId, playerMinutesExist } = props;

  if (playerMinutesExist && playerId !== null) {
    return (
      <Link
        title={"Go to player minutes"}
        to={`/player-minutes/${gameId}/${playerId}`}
      >
        {minutesFormat(min)}
      </Link>
    );
  }
  return <span>{minutesFormat(min)}</span>;
}

export function ArrowUpDownTableCell(props: {
  data: number[];
  minPointsRequired: number;
  significantChangeVal: number;
}) {
  const { data, minPointsRequired, significantChangeVal } = props;
  if (data.length < minPointsRequired)
    return <span>{TABLE_EMPTY_VALUE_STR}</span>;

  const first = data[0];
  const last = data[data.length - 1];

  if (first === undefined || last === undefined)
    return <span>{TABLE_EMPTY_VALUE_STR}</span>;

  const diff = last - first;
  const isSignificant = Math.abs(diff) >= significantChangeVal;
  if (!isSignificant)
    return <FaArrowRightLong style={{ color: darkGrayMid, opacity: 0.5 }} />;
  return last > first ? (
    <FaArrowTrendUp style={{ color: lineChartColors.green }} />
  ) : (
    <FaArrowTrendDown style={{ color: lineChartColors.red }} />
  );
}

export function SparkLineTableCell(props: {
  data: number[];
  color: string;
  minYDomain?: number;
}) {
  const { data, color, minYDomain } = props;

  const line = {
    color,
    label: "",
    segments: [
      data.map((d, i) => {
        return {
          x: i,
          y: d,
        };
      }),
    ],
  };

  let yMin = Math.min(...data);
  let yMax = Math.max(...data);
  const yMid = (yMin + yMax) / 2;
  if (minYDomain && Math.abs(yMax - yMin) < minYDomain) {
    yMin = yMid - minYDomain / 2;
    yMax = yMid + minYDomain / 2;
  }

  return (
    <LineChart
      height={16}
      width={16}
      lines={[line]}
      yDomain={[yMin, yMax]}
      showLegend={false}
      hideAxis={true}
      hideGrid={true}
      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
    />
  );
}

export function TruncatedTableCell(props: {
  children: ReactNode;
  width?: number;
  tooltip?: string;
}) {
  const maxWidth = props.width || 100;

  return (
    <div
      title={props.tooltip}
      style={{
        maxWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.children}
    </div>
  );
}

// Normally we would use BsDice6 or BsDice1 but we want that extra dice in
// the background, so I sort of just stole their SVG and added a background dice.
function CustomDiceIcon(props: { hot: boolean }) {
  const { hot } = props;
  const backgroundDice = (
    <path
      d="
M 4 16
H 2
H 1.5
C 1.22 16 1 15.78 1 15.5
V 2
C 1 1.45 1.45 1 2 1
H 15
C 15.55 1 16 1.45 16 2
V 5"
      fill="none"
      strokeWidth="2"
      stroke={hot ? redHigh : blueLow}
    />
  );
  if (hot) {
    return (
      <svg
        style={{ marginLeft: 4, marginTop: 1, verticalAlign: "top" }}
        stroke={redHigh}
        fill={redHigh}
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="https://www.w3.org/2000/svg"
      >
        {backgroundDice}
        <path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"></path>
        <circle cx="8" cy="8" r="1.5"></circle>
        <circle cx="8" cy="12" r="1.5"></circle>
        <circle cx="16" cy="12" r="1.5"></circle>
        <circle cx="16" cy="16" r="1.5"></circle>
        <circle cx="8" cy="16" r="1.5"></circle>
        <circle cx="16" cy="8" r="1.5"></circle>
      </svg>
    );
  } else {
    return (
      <svg
        style={{ marginLeft: 4, marginTop: 1, verticalAlign: "top" }}
        stroke={blueLow}
        fill={blueLow}
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="https://www.w3.org/2000/svg"
      >
        {backgroundDice}
        <circle cx="12" cy="12" r="1.5"></circle>
        <path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"></path>{" "}
      </svg>
    );
  }
}
