import React, { useMemo, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";

import { Page } from "../components/core/Page";
import { trpc } from "../util/tRPC";
import { Panel } from "../components/core/Panel";
import { VideoModal } from "../components/video/VideoModal";
import {
  Table,
  createColumnHelper,
  SortingState,
} from "../components/core/Table";
import { AggPlayerReads } from "../../shared/routers/PlayCallRouter";
import { Highlights } from "../constants/AppConstants";
import { pctFormat } from "../util/Format";
import { TruncatedTableCell } from "../components/core/TableCell";
import AppContext from "../../shared/AppContext";
import { groupBy } from "../../shared/util/Collections";
import { getFirstForField, sumFromField } from "../util/Util";

// TODO(chrisbu): Add back xPPP, PPP, xPPS, PPS, xTOV, TOV, xORB, ORB
// when we get better at tying reads <-> chances.

export function ReadsPage() {
  const { data } = trpc.playCall.getAggPlayerReads.useQuery();

  if (!data) return null;

  return (
    <Page header={{ text: "Player Reads" }} title="Player Reads">
      <Row>
        <Col>
          <Panel header={"Player Reads"}>
            <ActionsTable data={data} />
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}

const columnHelper = createColumnHelper<{
  type: string;
  num: number;
  rightPct: number;
  numRightVideo: number;
  numWrongVideo: number;
}>();

function ActionsTable(props: { data: AggPlayerReads[] }) {
  const { data } = props;
  const [playerId, setPlayerId] = useState(0);
  const [readGroupBy, setReadGroupBy] = useState("action");
  const [season, setSeason] = useState(0);
  const [sorting, setSorting] = useState<SortingState>();

  const [videoFilters, setVideoFilters] = useState<{
    playerId: number | null;
    actionOrRead: string | null;
    name: string | null;
    rightRead: number | null;
  }>({
    playerId: null,
    actionOrRead: null,
    name: null,
    rightRead: null,
  });

  const { data: videos } =
    trpc.playCall.getPlayerReadVideos.useQuery(videoFilters);

  let filteredData: {
    type: string;
    num: number;
    rightPct: number;
    numRightVideo: number;
    numWrongVideo: number;
  }[] = data
    .filter((d) => season === 0 || d.season === season)
    .filter((d) =>
      readGroupBy === "action"
        ? d.actionOrRead === "action"
        : d.actionOrRead === "read"
    )
    .filter((d) => d.playerId === playerId);

  // If we are looking at all seasons we need to collapse down the data b/c
  // by default it's grouped at the season level.
  if (season === 0) {
    const grouped = groupBy(filteredData, (d) => d.type);
    filteredData = Object.values(grouped).map((g) => {
      const num = g.reduce((acc, d) => acc + d.num, 0);
      const numRightVideo = sumFromField("numRightVideo", g) || 0;
      const numWrongVideo = sumFromField("numWrongVideo", g) || 0;
      const totalNum = sumFromField("num", g) || 0;
      const totalRight = g.reduce((acc, d) => acc + d.rightPct * d.num, 0);
      return {
        type: getFirstForField("type", g, "Unknown"),
        num: num,
        rightPct: totalRight / totalNum,
        numRightVideo,
        numWrongVideo,
      };
    });
  }

  const players = useMemo(() => {
    const returnSet: Record<
      string,
      { playerId: number; player: string; num: number }
    > = {};
    for (const d of data) {
      const entry = returnSet[d.playerId];
      if (d.playerId && !entry) {
        returnSet[d.playerId] = {
          playerId: d.playerId,
          player: d.playerName,
          num: d.num,
        };
      } else if (entry) {
        entry.num += d.num;
      }
    }
    return Object.values(returnSet).sort((a, b) => (a.num > b.num ? -1 : 1));
  }, [data]);

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("type", {
        header: readGroupBy === "action" ? "Action" : "Read",
        cell: (info) => (
          <TruncatedTableCell width={140} tooltip={info.getValue()}>
            {info.getValue()}
          </TruncatedTableCell>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("num", {
        header: "#",
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("rightPct", {
        header: "Right%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("numRightVideo", {
        header: () => "Right",
        cell: (info) =>
          info.getValue() > 0 ? (
            <a
              href="#"
              onClick={() =>
                setVideoFilters({
                  playerId: playerId,
                  actionOrRead: readGroupBy === "action" ? "action" : "read",
                  name: info.row.original.type,
                  rightRead: 1,
                })
              }
            >
              {info.getValue().toLocaleString()} <FaPlayCircle />
            </a>
          ) : (
            ""
          ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("numWrongVideo", {
        header: () => "Wrong",
        cell: (info) =>
          info.getValue() > 0 ? (
            <a
              href="#"
              onClick={() =>
                setVideoFilters({
                  playerId: playerId,
                  actionOrRead: readGroupBy === "action" ? "action" : "read",
                  name: info.row.original.type,
                  rightRead: 0,
                })
              }
            >
              {info.getValue().toLocaleString()} <FaPlayCircle />
            </a>
          ) : (
            ""
          ),
        meta: { group: g++ },
      }),
    ];
  }, [playerId, readGroupBy]);

  const totalRight = filteredData.reduce(
    (acc, d) => acc + d.rightPct * d.num,
    0
  );
  const totalReads = filteredData.reduce((acc, d) => acc + d.num, 0);

  return (
    <div>
      <div
        style={{
          marginBottom: 12,
        }}
      >
        <Form.Select
          style={{
            width: "auto",
            display: "inline",
            marginRight: 12,
          }}
          value={readGroupBy}
          onChange={(e) => setReadGroupBy(e.target.value)}
        >
          <option value={"action"}>By Action</option>
          <option value={"read"}>By Read</option>
        </Form.Select>
        <Form.Select
          style={{
            width: "auto",
            display: "inline",
            marginRight: 12,
          }}
          value={season.toString()}
          onChange={(e) => setSeason(parseInt(e.target.value))}
        >
          <option value={0}>All Seasons</option>
          <optgroup label="-----------"></optgroup>
          {AppContext.seasons
            .filter((s) => s.value >= 2024)
            .map((s) => {
              return (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              );
            })}
        </Form.Select>
        <Form.Select
          style={{
            width: "auto",
            display: "inline",
            marginRight: 12,
          }}
          value={playerId}
          onChange={(e) => setPlayerId(parseInt(e.target.value))}
        >
          <option value={0}>All Players</option>
          <optgroup label="-----------"></optgroup>
          {players.map((p) => {
            return (
              <option key={p.player} value={p.playerId}>
                {p.playerId === -1 ? "Unknown" : p.player}
              </option>
            );
          })}
        </Form.Select>
        <Form.Label style={{ display: "inline" }}>
          Total Right Reads: {Math.round(totalRight).toLocaleString()} (
          {pctFormat(totalRight / totalReads)})
        </Form.Label>
      </div>
      <Table
        data={filteredData.sort((a, b) => (a.num > b.num ? -1 : 1))}
        columns={columns}
        autoWidth={true}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        showColorOnHover={true}
        showRowIndex={false}
      />
      <VideoModal
        clips={(videos || []).map((v) => {
          return {
            label: `${v.playerName} ${v.action} ${v.read}`,
            url: v.synergyUrl,
          };
        })}
        show={videos ? videos.length > 0 : false}
        title={videoTitleStr(videoFilters)}
        handleClose={() => {
          setVideoFilters({
            playerId: null,
            actionOrRead: null,
            name: null,
            rightRead: null,
          });
        }}
        upDownClipSkip={true}
        showSynergyEditor={false}
      />
    </div>
  );
}

function videoTitleStr(filters: {
  playerId: number | null;
  actionOrRead: string | null;
  name: string | null;
  rightRead: number | null;
}) {
  const { playerId, actionOrRead, name, rightRead } = filters;
  if (
    playerId === null ||
    actionOrRead === null ||
    name === null ||
    rightRead === null
  )
    return "";

  const rightReadStr = rightRead === 1 ? "Right" : "Wrong";
  return `${rightReadStr} ${name} Reads`;
}
