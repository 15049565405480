import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import { Form } from "react-bootstrap";
import moment from "moment";
import { createStyles, makeStyles } from "@material-ui/styles";

import { GameSubPagePostgame } from "../subpages/game/GameSubPagePostgame";
import { GameSubPageShooting } from "../subpages/game/GameSubPageShooting";
import { GameSubPageLineups } from "../subpages/game/GameSubPageLineups";
import { GameSubPageGameFlow } from "../subpages/game/GameSubPageGameFlow";
import { GameSubPageSchedule } from "../subpages/game/GameSubPageSchedule";
import { GameSubPageVideo } from "../subpages/game/GameSubPageVideo";
import { GamePageHeader } from "../components/games/GamePageHeader";
import { Page } from "../components/core/Page";
import { Tabs } from "../components/core/PageTabs";
import { trpc } from "../util/tRPC";
import {
  GameExpectedStats,
  GameBoxScore,
  GameTeamReboundModel,
  GamePlayerReboundModel,
  GameAttackAvoid,
  GameEndOfPeriodScoring,
} from "../../shared/routers/GameRouter";
import { groupBy } from "../../shared/util/Collections";
import { sumFromField, getFirstForField, weightedAverage } from "../util/Util";
import { dateFormatShort } from "../util/Format";
import AppContext from "../../shared/AppContext";
import { CELTICS_TEAM_ID } from "../constants/AppConstants";

const useStyles = makeStyles(() =>
  createStyles({
    scrollContainer: {
      display: "block",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);

interface SeriesTeam {
  team: string;
  teamid: number;
  teamName: string;
  teamCity: string;
  teamabbreviation: string;
  gameDateTimeStr: string;
}

export function SeriesPage() {
  const { id } = useParams();
  const classes = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    tab: withDefault(StringParam, "postgame"),
    scrollTo: StringParam,
    game: withDefault(StringParam, "all"),
  });

  const { tab, scrollTo, game: selectedGameId } = queryParams;

  useEffect(() => {
    const el = document.querySelector(`#${scrollTo}`);
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, [scrollTo]);

  const onSubLinkClick = (subLink: string) => {
    const tab = Object.keys(pageTabs.tabs).find((pt) => {
      const tarTab = pageTabs.tabs[pt];
      if (!tarTab) return;
      return tarTab.sublinks.find((sl) => sl.refId === subLink);
    });
    if (!tab) return;
    setQueryParams({ tab, scrollTo: subLink });
  };

  const { data: games } = trpc.series.getGamesInSeries.useQuery({
    seriesId: id,
  });

  let team1: SeriesTeam | undefined = undefined;
  let team2: SeriesTeam | undefined = undefined;
  if (games && games[0]) {
    team1 = {
      team: `${games[0].homeTeamCity} ${games[0].homeTeam}`,
      teamid: games[0].homeTeamId,
      teamName: games[0].homeTeam,
      teamCity: games[0].homeTeamCity,
      teamabbreviation: games[0].homeTeamAbbr,
      gameDateTimeStr: games[0].gameDate || "",
    };
    team2 = {
      team: `${games[0].awayTeamCity} ${games[0].awayTeam}`,
      teamid: games[0].awayTeamId,
      teamName: games[0].awayTeam,
      teamCity: games[0].awayTeamCity,
      teamabbreviation: games[0].awayTeamAbbr,
      gameDateTimeStr: games[0].gameDate || "",
    };
  }

  // If games exists find the earliest and latest game dates.
  const sortedGames = (games || []).sort((a, b) => {
    if (a.gameDate === b.gameDate) return 0;
    if (a.gameDate === null) return 1;
    if (b.gameDate === null) return -1;
    return moment(a.gameDate) > moment(b.gameDate) ? 1 : -1;
  });
  const sortedGameDates = sortedGames
    .map((g) => g.gameDate)
    .filter((g) => g !== null) as string[];

  const gameIds = games ? games.map((g) => g.gameId) : [];

  const season =
    games && games[0] ? games[0].season : parseInt(AppContext.currentSeason);

  const { data: team1Series } = trpc.series.getSeriesForSeason.useQuery({
    teamId: team1 && team1.teamid,
    season,
  });

  const { data: team2Series } = trpc.series.getSeriesForSeason.useQuery({
    teamId: team2 && team2.teamid,
    season,
  });

  const { data: expectedStats } = trpc.game.getGameExpectedStats.useQuery({
    gameIds,
  });

  const filteredExpectedStats = filterForSelectedGame(
    expectedStats,
    selectedGameId
  );

  const mergedExpectedStats = filteredExpectedStats
    ? mergeExpectedStats(filteredExpectedStats)
    : undefined;

  const pageTabs = getPageTabs(
    season.toString(),
    gameIds,
    sortedGameDates[0] || "",
    sortedGameDates[sortedGameDates.length - 1] || "",
    selectedGameId,
    team1,
    team2,
    mergedExpectedStats
  );

  const pastGames = (sortedGames || []).filter(
    (g) => g.homePts !== 0 && g.awayPts !== 0
  );
  const futureGames = (sortedGames || []).filter(
    (g) => g.homePts === 0 && g.awayPts === 0
  );

  // The APIs will return future games that might be neccessary, do this
  // check to see if we should show the upcoming games or if they are not real.
  const team1Wins = pastGames.filter((g) =>
    (team1 && team1.teamid) === g.homeTeamId
      ? g.homePts > g.awayPts
      : g.homePts < g.awayPts
  ).length;
  const team2Wins = pastGames.filter((g) =>
    (team2 && team2.teamid) === g.homeTeamId
      ? g.homePts > g.awayPts
      : g.homePts < g.awayPts
  ).length;
  const isSeriesOver = team1Wins === 4 || team2Wins === 4;

  const seriesContent = (
    <>
      <Form.Select
        style={{ width: "auto", marginBottom: 8 }}
        value={selectedGameId}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
          setQueryParams({ game: evt.target.value });
        }}
      >
        <option value="all">Full Series Stats</option>
        {pastGames.map((g) => (
          <option value={g.gameId} key={g.gameId}>
            {g.awayTeamAbbr} @ {g.homeTeamAbbr} {g.awayPts} - {g.homePts}
          </option>
        ))}
      </Form.Select>
      <div className={classes.scrollContainer}>
        <div style={{ display: "flex", gap: 32, alignItems: "flex-start" }}>
          <table>
            <tbody style={{ whiteSpace: "nowrap" }}>
              {pastGames.map((g, i) => (
                <tr key={i}>
                  <td style={{ width: 32 }}>
                    {dateFormatShort(moment(g.gameDate).toDate())}
                  </td>
                  <td style={{ width: 32 }}>G{i + 1}:</td>
                  <td style={{ width: 32 }}>
                    <Link to={`/team/${g.awayTeamId}`}>{g.awayTeamAbbr}</Link>
                  </td>
                  <td style={{ width: 16, textAlign: "center" }}> @ </td>
                  <td style={{ width: 32 }}>
                    <Link to={`/team/${g.homeTeamId}`}>{g.homeTeamAbbr}</Link>
                  </td>
                  <td style={{ textAlign: "right", width: 64 }}>
                    <Link to={`/game/${g.gameId}`}>
                      {g.awayPts} - {g.homePts}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!isSeriesOver && (
            <table>
              <tbody style={{ whiteSpace: "nowrap" }}>
                {futureGames.map((g, i) => (
                  <tr key={i}>
                    <td style={{ width: 32 }}>
                      {dateFormatShort(moment(g.gameDate).toDate())}
                    </td>
                    <td style={{ width: 32 }}>G{i + 1 + pastGames.length}:</td>
                    <td style={{ width: 32 }}>
                      <Link to={`/team/${g.awayTeamId}`}>{g.awayTeamAbbr}</Link>
                    </td>
                    <td style={{ width: 16, textAlign: "center" }}> @ </td>
                    <td style={{ width: 32 }}>
                      <Link to={`/team/${g.homeTeamId}`}>{g.homeTeamAbbr}</Link>
                    </td>
                    <td style={{ textAlign: "right", width: 64 }}>
                      <Link to={`/preview/${g.gameId}`}>(Preview)</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );

  const team1FullGame =
    team1 && mergedExpectedStats
      ? mergedExpectedStats.find(
          (e) => e.teamId === team1.teamid && e.period === 0
        )
      : undefined;

  const team2FullGame =
    team2 && mergedExpectedStats
      ? mergedExpectedStats.find(
          (e) => e.teamId === team2.teamid && e.period === 0
        )
      : undefined;

  const ptsByQuarter =
    mergedExpectedStats && team1
      ? mergedExpectedStats
          .filter((e) => e.period !== 0)
          .reduce(
            (prev, cur) => {
              const isHome = cur.teamId === team1.teamid;
              const period = cur.period;
              if (!prev[period]) {
                prev[period] = {
                  homePts: 0,
                  awayPts: 0,
                };
              }
              const prevPeriod = prev[period];
              if (prevPeriod) {
                if (isHome) {
                  prevPeriod.homePts = cur.ptsScoredClean;
                } else {
                  prevPeriod.awayPts = cur.ptsScoredClean;
                }
              }
              return prev;
            },
            {} as Record<
              number,
              {
                homePts: number;
                awayPts: number;
              }
            >
          )
      : {};

  const homeTeamContent = (
    <div>
      <div>{team1 && team1.teamCity}</div>
      <div
        style={{
          display: "flex",
          gap: 8,
          textTransform: "none",
          justifyContent: "end",
        }}
      >
        {(team1Series || [])
          .sort((a, b) => a.seriesId - b.seriesId)
          .map((s, i) => (
            <>
              <Link
                to={`/series/${s.seriesId}`}
                style={{ whiteSpace: "nowrap" }}
              >
                vs {s.oppTeamAbb}
              </Link>
              {i < (team1Series || []).length - 1 && <span>•</span>}
            </>
          ))}
      </div>
    </div>
  );

  const awayTeamContent = (
    <div>
      <div>{team2 && team2.teamCity}</div>
      <div
        style={{
          display: "flex",
          gap: 8,
          textTransform: "none",
        }}
      >
        {(team2Series || [])
          .sort((a, b) => a.seriesId - b.seriesId)
          .map((s, i) => (
            <>
              <Link
                to={`/series/${s.seriesId}`}
                style={{ whiteSpace: "nowrap" }}
              >
                vs {s.oppTeamAbb}
              </Link>
              {i < (team2Series || []).length - 1 && <span>•</span>}
            </>
          ))}
      </div>
    </div>
  );

  const header =
    team1 && team2 ? (
      <GamePageHeader
        gameId={undefined}
        homeTeamId={team1.teamid}
        awayTeamId={team2.teamid}
        homeAbbr={team1.teamabbreviation}
        awayAbbr={team2.teamabbreviation}
        ptsByQuarter={ptsByQuarter}
        xPPPHome={team1FullGame ? team1FullGame.xPPP : null}
        xPPPAway={team2FullGame ? team2FullGame.xPPP : null}
        xPtsHome={team1FullGame ? team1FullGame.xPTS : null}
        xPtsAway={team2FullGame ? team2FullGame.xPTS : null}
        xWinPctAway={null}
        xWinPctHome={null}
        xBaselineWinPctHome={null}
        gameDateTimeStr={null}
        arenaname={null}
        location={null}
        lastUpdatedStr={undefined}
        homeTeamContent={homeTeamContent}
        awayTeamContent={awayTeamContent}
        seriesContent={seriesContent}
      />
    ) : (
      <></>
    );

  const pageTitle =
    team1 && team2 && id
      ? `${team1.teamabbreviation} vs ${team2.teamabbreviation} Round ${id[5]}`
      : "";

  return (
    <Page
      title={pageTitle}
      header={{ component: header }}
      tabs={pageTabs}
      activeTab={tab}
      onTabClick={(newTab) => setQueryParams({ tab: newTab })}
      onSubLinkClick={(subLink) => onSubLinkClick(subLink)}
    />
  );
}

function getPageTabs(
  season: string,
  gameIds: number[],
  fromDate: string,
  toDate: string,
  selectedGameId: string,
  team1?: SeriesTeam,
  team2?: SeriesTeam,
  expectedStats?: GameExpectedStats[]
): Tabs {
  const team1Id = team1 && team1.teamid.toString();
  const team1City = team1 && team1.teamCity;
  const team2Id = team2 && team2.teamid.toString();
  const team2City = team2 && team2.teamCity;

  const teamIds = [team1Id, team2Id].filter((i) => i !== undefined) as string[];

  // Overview
  const { data: boxscores } = trpc.game.getGameBoxScores.useQuery({
    gameIds,
  });

  const leagueFilters = {
    seasonStart: season,
    seasonEnd: season,
    preseason: AppContext.inPreseason ? 1 : 0,
    regularSeason: 1,
    postseason: 1,
  };

  const { data: leagueStats } = trpc.team.getTeamStats.useQuery(leagueFilters);

  const { data: team1Stats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameIds
      ? gameIds
          .filter(
            (g) => selectedGameId === "all" || g.toString() === selectedGameId
          )
          .join(",")
      : undefined,
    teamId: team1 ? team1.teamid.toString() : "",
  });

  const { data: team2Stats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameIds
      ? gameIds
          .filter(
            (g) => selectedGameId === "all" || g.toString() === selectedGameId
          )
          .join(",")
      : undefined,
    teamId: team2 ? team2.teamid.toString() : "",
  });

  const { data: gameActions } = trpc.team.getTeamActionsBreakdown.useQuery({
    gameIds: gameIds
      ? gameIds
          .filter(
            (g) => selectedGameId === "all" || g.toString() === selectedGameId
          )
          .join(",")
      : undefined,
  });
  const { data: seasonActions } =
    trpc.team.getTeamActionsBreakdown.useQuery(leagueFilters);

  const { data: teamRebounds } = trpc.game.getGameTeamReboundModel.useQuery({
    gameIds: gameIds,
  });
  const { data: crashAttempts } = trpc.game.getCrashAttempts.useQuery({
    gameIds: gameIds,
  });
  const { data: playerRebounds } = trpc.game.getGamePlayerReboundModel.useQuery(
    {
      gameIds: gameIds,
    }
  );
  const { data: expectedTovs } = trpc.game.getGameExpectedTurnovers.useQuery({
    gameIds: gameIds,
  });

  const { data: attackAvoid } = trpc.game.getGameAttackAvoid.useQuery({
    gameIds: gameIds,
  });

  const { data: chanceStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType.useQuery({
      gameIds: gameIds,
    });

  const { data: chanceStartTypeCompData } =
    trpc.chance.geteffPerStartTypeForCompGameByPeriod.useQuery({
      teamId: CELTICS_TEAM_ID,
    });

  const { data: endOfPeriodScoringData } =
    trpc.game.getGameEndOfPeriodScoring.useQuery({
      gameIds,
    });

  const { data: endOfPeriodVideo } = trpc.game.getGameEndOfPeriodVideo.useQuery(
    {
      gameIds,
    }
  );

  const filteredBoxscores = filterForSelectedGame(
    fixHomeAway(boxscores, parseInt(team1Id || "0")),
    selectedGameId
  );
  const filteredTeamRebounds = filterForSelectedGame(
    teamRebounds,
    selectedGameId
  );
  const filteredCrashAttempts = filterForSelectedGame(
    crashAttempts,
    selectedGameId
  );
  const filteredPlayerRebounds = filterForSelectedGame(
    playerRebounds,
    selectedGameId
  );
  const filteredExpectedTovs = filterForSelectedGame(
    expectedTovs,
    selectedGameId
  );
  const filteredChanceStartTypeData = filterForSelectedGame(
    chanceStartTypeData,
    selectedGameId
  );
  const filteredAttackAvoid = filterForSelectedGame(
    attackAvoid,
    selectedGameId
  );

  // Shots
  const { data: shots } = trpc.shot.getShots.useQuery({
    filters: {
      offTeamIds: teamIds,
      // Throw a dummy game id in there so it quickly returns no results.
      gameIds: gameIds.length ? gameIds.map((g) => g.toString()) : ["-1"],
    },
  });

  const filteredShots = filterForSelectedGame(shots, selectedGameId);

  // Lineups
  const { data: lineupBreakdowns } = trpc.game.getLineupBreakdowns.useQuery({
    gameIds,
  });

  const filteredLineupBreakdowns = filterForSelectedGame(
    lineupBreakdowns,
    selectedGameId
  );

  // Game Flow
  const { data: lineups } = trpc.game.getGameLineups.useQuery({
    gameIds,
  });
  const { data: players } = trpc.game.getGamePlayers.useQuery({
    gameIds,
  });
  const { data: possessions } = trpc.game.getGamePossessions.useQuery({
    gameIds,
  });

  const filteredLineups = filterForSelectedGame(lineups, selectedGameId);
  const filteredPlayers = filterForSelectedGame(players, selectedGameId);
  const filteredPossessions = filterForSelectedGame(
    possessions,
    selectedGameId
  );

  const { data: team1Subs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: team1Id,
    games: gameIds.join(","),
  });

  const { data: team2Subs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: team2Id,
    games: gameIds.join(","),
  });

  const filteredTeam1Subs = filterForSelectedGame(team1Subs, selectedGameId);
  const filteredTeam2Subs = filterForSelectedGame(team2Subs, selectedGameId);

  const { data: gameWinProb } = trpc.game.getGameWinProbability.useQuery({
    gameIds,
  });

  const filteredWinProb = filterForSelectedGame(gameWinProb, selectedGameId);

  // Schedule
  const { data: team1Schedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: team1 ? team1.teamid.toString() : "0",
    seasonStart: season,
    seasonEnd: season,
  });
  const { data: team2Schedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: team2 ? team2.teamid.toString() : "0",
    seasonStart: season,
    seasonEnd: season,
  });

  const { data: gameSegments } = trpc.synergy.getSynergyGameSegments.useQuery({
    gameIds,
  });
  const { data: gameClips } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds,
  });
  const { data: gameDetails } = trpc.game.getGameDetails.useQuery({
    gameIds,
  });

  const filteredGameSegments = filterForSelectedGame(
    gameSegments,
    selectedGameId
  );
  const filteredGameClips = filterForSelectedGame(gameClips, selectedGameId);
  const filteredGameDetails = filterForSelectedGame(
    gameDetails,
    selectedGameId
  );

  const pageTabs = {
    tabs: {
      postgame: {
        label: "Overview",
        sublinks: [
          { label: "Team Shot Quality", refId: "teamShotQuality" },
          { label: "Player Shot Quality", refId: "playerShotQuality" },
          { label: "Box Scores", refId: "boxScores" },
          { label: "Offensive and Defensive Breakdown", refId: "breakdown" },
          { label: "Team Stat Comparison", refId: "teamStatComparison" },
          { label: "Team Rebounding", refId: "teamRebounds" },
          { label: "Player Rebounding", refId: "playerRebounds" },
          {
            label: "Team Shot Quality Breakdown",
            refId: "teamShotQualityBreakdown",
          },
          {
            label: "Possession Start Type Efficiency Breakdown",
            refId: "startTypeEfficiency",
          },
        ],
        content: (
          <GameSubPagePostgame
            filtered={false}
            home={team1}
            away={team2}
            fromDate={fromDate}
            toDate={toDate}
            shots={filteredShots}
            leagueStats={leagueStats}
            homeTeamStats={team1Stats}
            awayTeamStats={team2Stats}
            gameExpectedStats={expectedStats}
            boxscores={
              selectedGameId === "all"
                ? mergeBoxScoreData(filteredBoxscores || []).sort((a, b) => {
                    if (a.gs === b.gs) {
                      return b.min - a.min;
                    }
                    return b.gs - a.gs;
                  })
                : filteredBoxscores
            }
            gameActions={gameActions}
            seasonActions={seasonActions}
            teamRebounds={
              selectedGameId === "all"
                ? mergeTeamRebounds(filteredTeamRebounds || [])
                : filteredTeamRebounds
            }
            crashAttempts={filteredCrashAttempts}
            playerRebounds={
              selectedGameId === "all"
                ? mergePlayerRebounds(filteredPlayerRebounds || [])
                : filteredPlayerRebounds
            }
            expectedTovs={filteredExpectedTovs}
            attackAvoid={
              selectedGameId === "all"
                ? mergeAttackAvoid(filteredAttackAvoid || [])
                : filteredAttackAvoid
            }
            chanceStartTypeData={filteredChanceStartTypeData}
            chanceStartTypeCompData={chanceStartTypeCompData}
            endOfPeriodScoring={
              selectedGameId === "all"
                ? mergeEndOfPeriodScoring(endOfPeriodScoringData || [])
                : endOfPeriodScoringData
            }
            endOfPeriodVideo={endOfPeriodVideo || []}
          />
        ),
      },
      shooting: {
        label: "Shooting",
        content: (
          <GameSubPageShooting
            filtered={false}
            home={team1}
            away={team2}
            shots={filteredShots}
          />
        ),
        sublinks: [
          { label: `Shot Quality · ${team2City}`, refId: "shotQualityAway" },
          { label: `Shot Quality · ${team1City}`, refId: "shotQualityHome" },
          { label: `Shot Charts · ${team2City}`, refId: "shotChartsAway" },
          { label: `Shot Charts · ${team1City}`, refId: "shotChartsHome" },
        ],
      },
      lineups: {
        label: "Lineups",
        content: (
          <GameSubPageLineups
            filtered={false}
            lineupBreakdowns={filteredLineupBreakdowns}
            home={team1}
            away={team2}
          />
        ),
        sublinks: [
          { label: "Lineups Summary", refId: "lineupsSummary" },
          { label: "Lineups By Time", refId: "lineupsByTime" },
        ],
      },
      gameFlow: {
        label: "Game Flow",
        content: (
          <GameSubPageGameFlow
            filtered={false}
            home={team1}
            away={team2}
            lineups={filteredLineups}
            players={filteredPlayers}
            possessions={filteredPossessions}
            awaySubs={filteredTeam2Subs}
            homeSubs={filteredTeam1Subs}
            gameWinProb={
              filteredWinProb
                ? filteredWinProb.map((d) => {
                    // Modify each item so that they are relative to our
                    // team1/team2;
                    const homeTeamId = team1 ? team1.teamid : 0;
                    const awayTeamId = team2 ? team2.teamid : 0;
                    return {
                      ...d,
                      homeTeamId,
                      awayTeamId,
                      homeWinProb:
                        d.homeTeamId === homeTeamId
                          ? d.homeWinProb
                          : 1 - d.homeWinProb,
                    };
                  })
                : undefined
            }
          />
        ),
        sublinks: [
          { label: "Game Flow", refId: "gameFlow" },
          { label: "Sub Pattern", refId: "subPattern" },
          // TODO(chrisbu): Uncomment when/if this is launched.
          // { label: "Win Probability", refId: "winProbability" },
        ],
      },
      schedules: {
        label: "Schedules",
        content: (
          <GameSubPageSchedule
            gameId={parseInt(selectedGameId) || 0}
            home={team1}
            away={team2}
            awaySchedule={team2Schedule}
            homeSchedule={team1Schedule}
          />
        ),
        sublinks: [
          { label: `${team2City} Schedule`, refId: "awaySchedule" },
          { label: `${team1City} Schedule`, refId: "homeSchedule" },
        ],
      },
      video: {
        label: "Video",
        content: (
          <GameSubPageVideo
            filtered={false}
            home={team1}
            away={team2}
            gameSegments={filteredGameSegments}
            gameClips={filteredGameClips}
            gameDetails={filteredGameDetails}
          />
        ),
        sublinks: [{ label: "Video", refId: "video" }],
      },
    },
  };

  return pageTabs;
}

// For all the data that we fetch once and then filter as needed we need to
// do the merging clientside. These helpers below do that.

function mergeBoxScoreData(data: GameBoxScore[]): GameBoxScore[] {
  // Need team in the key to separate the two team rows which are playerId 0.
  const dataByPlayer = groupBy(
    data,
    (d) => `${d.playerId!.toString()}-${d.teamid}`
  );
  return Object.values(dataByPlayer).map((arr) => {
    const pts = sumFromField("pts", arr) || 0;
    const fga = sumFromField("fga", arr) || 0;
    const fta = sumFromField("fta", arr) || 0;
    const turn = sumFromField("turn", arr) || 0;
    const denominator = fga + turn + 0.44 * fta;
    const ppt = denominator === 0 ? null : pts / denominator;
    const turnRate = denominator === 0 ? null : turn / denominator;

    const orb = sumFromField("roff", arr) || 0;
    const drb = sumFromField("rdef", arr) || 0;
    const orbAvailable = sumFromField("roffavail", arr) || 0;
    const drbAvailable = sumFromField("rdefavail", arr) || 0;
    const orbPct = orbAvailable === 0 ? null : (100 * orb) / orbAvailable;
    const drbPct = drbAvailable === 0 ? null : (100 * drb) / drbAvailable;

    return {
      playerId: getFirstForField("playerId", arr, 0),
      name: getFirstForField("name", arr, ""),
      teamid: getFirstForField("teamid", arr, 0),
      homeAway: getFirstForField("homeAway", arr, ""),
      gameId: getFirstForField("gameId", arr, 0),
      gs: arr.find((d) => d.gs === 1) ? 1 : 0,
      min: sumFromField("min", arr) || 0,
      playerMinutesExist: 0,
      pts: sumFromField("pts", arr) || 0,
      plusminus: sumFromField("plusminus", arr) || 0,
      roff: sumFromField("roff", arr) || 0,
      roffavail: sumFromField("roffavail", arr) || 0,
      rdef: sumFromField("rdef", arr) || 0,
      rdefavail: sumFromField("rdefavail", arr) || 0,
      rtot: sumFromField("rtot", arr) || 0,
      ast: sumFromField("ast", arr) || 0,
      blk: sumFromField("blk", arr) || 0,
      stl: sumFromField("stl", arr) || 0,
      turn: sumFromField("turn", arr) || 0,
      liveBallTov: sumFromField("liveBallTov", arr) || 0,
      pf: sumFromField("pf", arr) || 0,
      roffpct: orbPct,
      rdefpct: drbPct,
      turnrate: turnRate,
      fga: sumFromField("fga", arr) || 0,
      fgm: sumFromField("fgm", arr) || 0,
      fg2a: sumFromField("fg2a", arr) || 0,
      fg2m: sumFromField("fg2m", arr) || 0,
      tpa: sumFromField("tpa", arr) || 0,
      tpm: sumFromField("tpm", arr) || 0,
      fta: sumFromField("fta", arr) || 0,
      ftm: sumFromField("ftm", arr) || 0,
      ppt: ppt,
      numCrashes: sumFromField("numCrashes", arr) || 0,
      numDeepCrashes: sumFromField("numDeepCrashes", arr) || 0,
      numCrashOpps: sumFromField("numCrashOpps", arr) || 0,
      xPlusMinus: sumFromField("xPlusMinus", arr) || 0,
    };
  });
}

function mergeExpectedStats(data: GameExpectedStats[]): GameExpectedStats[] {
  const groupedByPeriod = groupBy(data, (d) => `${d.teamId}-${d.period}`);
  return Object.values(groupedByPeriod).map((arr) => {
    arr[0]?.numFirstShot;
    return {
      gameId: getFirstForField("gameId", arr, 0),
      period: getFirstForField("period", arr, 0),
      teamId: getFirstForField("teamId", arr, 0),
      team: getFirstForField("team", arr, ""),
      xPTS: sumFromField("xPTS", arr) || 0,
      ptsScoredClean: sumFromField("ptsScoredClean", arr) || 0,
      PPP: weightedAverage("numPoss", "PPP", arr) || 0,
      xPPP: weightedAverage("numPoss", "xPPP", arr) || 0,
      total_pts_variance: sumFromField("total_pts_variance", arr) || 0,
      first_shot_actual_pps_exp_ft:
        weightedAverage("numFirstShot", "first_shot_actual_pps_exp_ft", arr) ||
        0,
      first_shot_xpps:
        weightedAverage("numFirstShot", "first_shot_xpps", arr) || 0,
      shooting_variance: sumFromField("shooting_variance", arr) || 0,
      pts_from_OR_exp_ft: sumFromField("pts_from_OR_exp_ft", arr) || 0,
      xpts_from_OR: sumFromField("xpts_from_OR", arr) || 0,
      off_reb_variance: sumFromField("off_reb_variance", arr) || 0,
      TOp: weightedAverage("numPoss", "TOp", arr) || 0,
      xTOp: weightedAverage("numPoss", "xTOp", arr) || 0,
      turnover_variance: sumFromField("turnover_variance", arr) || 0,
      pts_from_nsfs_exp_ft: sumFromField("pts_from_nsfs_exp_ft", arr) || 0,
      xpts_from_nsfs: sumFromField("xpts_from_nsfs", arr) || 0,
      nsf_variance: sumFromField("nsf_variance", arr) || 0,
      FTp: weightedAverage("fta", "FTp", arr) || 0,
      xFTp: weightedAverage("fta", "xFTp", arr) || 0,
      ft_variance: sumFromField("ft_variance", arr) || 0,
      numPoss: sumFromField("numPoss", arr) || 0,
      numFirstShot: sumFromField("numFirstShot", arr) || 0,
      fta: sumFromField("fta", arr) || 0,
      first_shot_xPPS_pts_above_league:
        weightedAverage("numPoss", "first_shot_xPPS_pts_above_league", arr) ||
        0,
      xPPP_above_league:
        weightedAverage("numPoss", "xPPP_above_league", arr) || 0,
      xnsf_pts_above_league:
        weightedAverage("numPoss", "xnsf_pts_above_league", arr) || 0,
      xto_above_league:
        weightedAverage("numPoss", "xto_above_league", arr) || 0,
      xpts_from_OR_above_league:
        weightedAverage("numPoss", "xpts_from_OR_above_league", arr) || 0,
    };
  });
}

function mergeTeamRebounds(teamRebounds: GameTeamReboundModel[]) {
  const groupedByTeam = groupBy(teamRebounds, (d) => `${d.teamId}-${d.offDef}`);
  return Object.values(groupedByTeam).map((arr) => {
    return {
      gameId: getFirstForField("gameId", arr, 0),
      teamId: getFirstForField("teamId", arr, 0),
      offDef: getFirstForField("offDef", arr, "Off"),
      Actual: sumFromField("Actual", arr) || 0,
      Expected: sumFromField("Expected", arr) || 0,
    };
  });
}

function mergePlayerRebounds(playerRebounds: GamePlayerReboundModel[]) {
  const groupedByPlayer = groupBy(
    playerRebounds,
    (d) => `${d.playerId}-${d.offDef}`
  );
  return Object.values(groupedByPlayer).map((arr) => {
    return {
      gameId: getFirstForField("gameId", arr, 0),
      playerId: getFirstForField("playerId", arr, 0),
      player: getFirstForField("player", arr, ""),
      team: getFirstForField("team", arr, 0),
      Actual: sumFromField("Actual", arr) || 0,
      Expected: sumFromField("Expected", arr) || 0,
      offDef: getFirstForField("offDef", arr, "Off"),
    };
  });
}

function mergeAttackAvoid(attackAvoid: GameAttackAvoid[]): GameAttackAvoid[] {
  const groupedByPlayer = groupBy(attackAvoid, (d) => `${d.celticsID}`);
  return Object.values(groupedByPlayer).map((arr) => {
    return {
      off_team: getFirstForField("off_team", arr, ""),
      season: getFirstForField("season", arr, 0),
      gameId: getFirstForField("gameId", arr, 0),
      celticsID: getFirstForField("celticsID", arr, 0),
      def_player: getFirstForField("def_player", arr, ""),
      n: sumFromField("n", arr) || 0,
      n_total: sumFromField("n_total", arr) || 0,
      total_time: sumFromField("total_time", arr),
      n_pnr: sumFromField("n_pnr", arr) || 0,
      pnr_time: sumFromField("pnr_time", arr),
      n_pnr_bhr: sumFromField("n_pnr_bhr", arr) || 0,
      pnr_bhr_time: sumFromField("pnr_bhr_time", arr),
      PNR_ballhandler_defender: getFirstForField(
        "PNR_ballhandler_defender",
        arr,
        0
      ),
      n_pnr_scr: sumFromField("n_pnr_scr", arr) || 0,
      pnr_scr_time: sumFromField("pnr_scr_time", arr),
      PNR_screener_defender_switch: getFirstForField(
        "PNR_screener_defender_switch",
        arr,
        0
      ),
      n_iso_iso: sumFromField("n_iso_iso", arr) || 0,
      iso_iso_time: sumFromField("iso_iso_time", arr),
      iso_iso_defender: getFirstForField("iso_iso_defender", arr, 0),
      n_post_post: sumFromField("n_post_post", arr) || 0,
      post_post_time: sumFromField("post_post_time", arr),
      post_post_defender: getFirstForField("post_post_defender", arr, 0),
      n_ros_ros: sumFromField("n_ros_ros", arr) || 0,
      ros_ros_time: sumFromField("ros_ros_time", arr),
      run_off_screen_run_off_screen_defender: getFirstForField(
        "run_off_screen_run_off_screen_defender",
        arr,
        0
      ),
      n_cag_driver: sumFromField("n_cag_driver", arr) || 0,
      cag_driver_time: sumFromField("cag_driver_time", arr),
      catch_and_go_drive_drive_defender: getFirstForField(
        "catch_and_go_drive_drive_defender",
        arr,
        0
      ),
      n_cut_cutter: sumFromField("n_cut_cutter", arr) || 0,
      cut_cutter_time: sumFromField("cut_cutter_time", arr),
      cut_cutter_defender: getFirstForField("cut_cutter_defender", arr, 0),
    };
  });
}

function mergeEndOfPeriodScoring(endOfPeriodScoring: GameEndOfPeriodScoring[]) {
  const groupedByTeam = groupBy(
    endOfPeriodScoring,
    (d) => `${d.period}-${d.offTeam}`
  );
  return Object.values(groupedByTeam).map((arr) => {
    return {
      gameId: getFirstForField("gameId", arr, 0),
      offTeam: getFirstForField("offTeam", arr, ""),
      offTeamId: getFirstForField("offTeamId", arr, 0),
      period: getFirstForField("period", arr, 0),
      pts: sumFromField("pts", arr) || 0,
      xPts: sumFromField("xPts", arr) || 0,
      numPoss: sumFromField("numPoss", arr) || 0,
    };
  });
}

function filterForSelectedGame<T extends { gameId: number }>(
  data: T[] | undefined,
  gameId: string
) {
  return data
    ? data.filter((d) => gameId === "all" || gameId === d.gameId.toString())
    : undefined;
}

function fixHomeAway(boxscores: GameBoxScore[] | undefined, team1Id: number) {
  if (!boxscores) {
    return undefined;
  }
  return boxscores.map((b) => {
    return { ...b, homeAway: b.teamid === team1Id ? "home" : "away" };
  });
}
