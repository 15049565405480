import React, { useCallback, useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PlayerSynergyBox } from "../../../shared/routers/PlayerRouter";
import { decFormat, pctFormat, seasonString } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";

const columnHelper = createColumnHelper<PlayerSynergyBox>();

export function PlayerSynergySeasonBox(props: {
  data: PlayerSynergyBox[];
  type: "totals" | "percentages" | "per100Possessions";
}) {
  const { data, type } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const valFn = useCallback(
    (stat: keyof PlayerSynergyBox, row: PlayerSynergyBox) => {
      if (type === "percentages") {
        const denominator =
          row.n_spot_up_poss +
          row.n_pnr_bh_poss +
          row.n_pnr_roll_poss +
          row.n_pnr_pop_poss +
          row.n_cut_poss +
          row.n_trans_poss +
          row.n_iso_poss +
          row.n_post_up_poss +
          row.n_handoff_poss +
          row.n_off_screen_poss;
        return row[stat] / denominator;
      } else if (type === "per100Possessions") {
        return (100 * row[stat]) / row.onCourtPoss;
      }
      return row[stat];
    },
    [type]
  );

  const fmtFn = useCallback(
    (val: number) => {
      if (type === "percentages") {
        return pctFormat(val);
      } else if (type === "per100Possessions") {
        return decFormat(val);
      }
      return val;
    },
    [type]
  );

  const columns = useMemo(() => {
    let domains = {
      n_spot_up_poss: [0, Math.max(...data.map((d) => d.n_spot_up_poss))],
      n_pnr_bh_poss: [0, Math.max(...data.map((d) => d.n_pnr_bh_poss))],
      n_pnr_roll_poss: [0, Math.max(...data.map((d) => d.n_pnr_roll_poss))],
      n_pnr_pop_poss: [0, Math.max(...data.map((d) => d.n_pnr_pop_poss))],
      n_cut_poss: [0, Math.max(...data.map((d) => d.n_cut_poss))],
      n_trans_poss: [0, Math.max(...data.map((d) => d.n_trans_poss))],
      n_iso_poss: [0, Math.max(...data.map((d) => d.n_iso_poss))],
      n_post_up_poss: [0, Math.max(...data.map((d) => d.n_post_up_poss))],
      n_handoff_poss: [0, Math.max(...data.map((d) => d.n_handoff_poss))],
      n_off_screen_poss: [0, Math.max(...data.map((d) => d.n_off_screen_poss))],
      n_dunk: [0, Math.max(...data.map((d) => d.n_dunk))],
    };
    if (type === "per100Possessions") {
      domains = {
        n_spot_up_poss: [0.382, 9.6259],
        n_pnr_bh_poss: [0.0, 18.5605],
        n_pnr_roll_poss: [0.0, 3.0331],
        n_pnr_pop_poss: [0.0, 1.9927],
        n_cut_poss: [0.0939, 5.4706],
        n_trans_poss: [0.6076, 6.3787],
        n_iso_poss: [0.0, 5.9402],
        n_post_up_poss: [0.0, 10.5034],
        n_handoff_poss: [0.0, 1.63],
        n_off_screen_poss: [0.0, 3.6206],
        n_dunk: [0, 5],
      };
    } else if (type == "percentages") {
      domains = {
        n_spot_up_poss: [0.0279, 0.5083],
        n_pnr_bh_poss: [0.0, 0.5705],
        n_pnr_roll_poss: [0.0, 0.1697],
        n_pnr_pop_poss: [0.0, 0.1011],
        n_cut_poss: [0.0042, 0.3371],
        n_trans_poss: [0.0398, 0.262],
        n_iso_poss: [0.0, 0.2171],
        n_post_up_poss: [0.0, 0.5054],
        n_handoff_poss: [0.0, 0.0648],
        n_off_screen_poss: [0.0, 0.1526],
        // These don't exist in % version of table.
        n_dunk: [0, 0],
      };
    }

    let g = 0;
    return [
      columnHelper.accessor("Season", {
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: g++ },
      }),
      columnHelper.accessor((row) => valFn("n_pnr_bh_poss", row), {
        header: "PnR Ballhandler",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_pnr_bh_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_handoff_poss", row), {
        header: "Handoff",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_handoff_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_iso_poss", row), {
        header: "Iso",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_iso_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_off_screen_poss", row), {
        header: "Off Screen",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_off_screen_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_spot_up_poss", row), {
        header: "Spot Up",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_spot_up_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_cut_poss", row), {
        header: "Cut",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_cut_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_post_up_poss", row), {
        header: "Post",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_post_up_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_trans_poss", row), {
        header: "Transition",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_trans_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_pnr_pop_poss", row), {
        header: "PnR Pop",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_pnr_pop_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_pnr_roll_poss", row), {
        header: "PnR Roll",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_pnr_roll_poss,
        },
      }),
      columnHelper.accessor((row) => valFn("n_dunk", row), {
        id: "n_dunk",
        header: "Dunks",
        cell: (info) => fmtFn(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          neutralColorScheme: type === "totals",
          colorDomain: domains.n_dunk,
        },
      }),
    ];
  }, [data, fmtFn, type, valFn]);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
      hiddenColumns={{
        n_dunk: type !== "percentages",
      }}
    />
  );
}
