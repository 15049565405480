import React, { useMemo, useState } from "react";

import { decFormat, pctFormatRound } from "../../util/Format";
import AppContext from "../../../shared/AppContext";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Highlights } from "../../constants/AppConstants";
import { DraftModel } from "../../../shared/routers/DraftRouter";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<DraftModel>();

export function DraftClassTable(props: {
  data: DraftModel[];
  year: number;
  includeYear: boolean;
  autoWidth: boolean;
}) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("pick", {
        header: () => "Pick",
        sortingFn: (a, b) => {
          const aVal = a.original.pick === 1000 ? 61 : a.original.pick;
          const bVal = b.original.pick === 1000 ? 61 : b.original.pick;
          return bVal - aVal;
        },
        cell: (info) => (info.getValue() === 1000 ? null : info.getValue()),
        meta: { highlights: Highlights.Min },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
      }),
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
      }),
      columnHelper.accessor("isNcaa", {
        header: () => "NCAA",
        cell: (info) => (info.getValue() ? "Yes" : ""),
      }),
      columnHelper.accessor("off_pred", {
        header: () => "Off.",
        sortingFn: (a, b) => {
          const aVal = a.original.off_pred;
          const bVal = b.original.off_pred;

          if (aVal === bVal) return 0;
          else if (aVal === null) return -1;
          else if (bVal === null) return 1;
          else return aVal - bVal;
        },
        cell: (info) => decFormat(info.getValue()),
      }),
      columnHelper.accessor("def_pred", {
        header: () => "Def.",
        sortingFn: (a, b) => {
          const aVal = a.original.def_pred;
          const bVal = b.original.def_pred;

          if (aVal === bVal) return 0;
          else if (aVal === null) return -1;
          else if (bVal === null) return 1;
          else return aVal - bVal;
        },
        cell: (info) => decFormat(info.getValue()),
      }),
      columnHelper.accessor("net_pred", {
        header: () => "Net",
        sortingFn: (a, b) => {
          const aVal = a.original.net_pred;
          const bVal = b.original.net_pred;

          if (aVal === bVal) return 0;
          else if (aVal === null) return -1;
          else if (bVal === null) return 1;
          else return aVal - bVal;
        },
        cell: (info) => decFormat(info.getValue()),
      }),
      columnHelper.accessor("pNBA", {
        header: () => "NBA",
        cell: (info) => pctFormatRound(info.getValue()),
      }),
    ];
  }, []);

  const hiddenColumns = {
    pick: props.year !== AppContext.latestDraftYear,
    team: props.year !== AppContext.latestDraftYear,
  };

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      hiddenColumns={hiddenColumns}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
