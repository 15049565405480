import React, { useState, useMemo } from "react";
import { Form } from "react-bootstrap";

import {
  DoubleFooter,
  Table,
  createColumnHelper,
  SortingState,
} from "../core/Table";
import { TeamRosterGameBoxesPlayer } from "../../../shared/routers/TeamRouter";
import { Highlights } from "../../constants/AppConstants";
import {
  atMostDecFormat,
  dec100Format,
  decFormat,
  decFormat2,
  fracDecFormat,
  makeParen,
  makePlusMinus,
} from "../../util/Format";
import { PlayerTableCell } from "../core/TableCell";
import { perGame } from "../../constants/ColorDomains";
import { TeamStats } from "../../../shared/routers/TeamRouter";

const columnHelper = createColumnHelper<TeamRosterGameBoxesPlayer>();

export function TeamRosterSeasonBoxes(props: {
  showRosterToggle: boolean;
  data: {
    roster: TeamRosterGameBoxesPlayer[];
  };
  teamStats: TeamStats;
}) {
  const { showRosterToggle, data, teamStats } = props;
  const [showFullRoster, setShowFullRoster] = useState(false);
  const [sorting, setSorting] = useState<SortingState>();

  // Filter to just active roster if necessary.
  let roster = data.roster;
  if (!showFullRoster) {
    roster = roster.filter((p) => p.onTeam);
  }

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.celticsId}
            name={`${info.getValue()}${!info.row.original.onTeam ? "*" : ""}`}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g },
        footer: () => (
          <DoubleFooter
            primary={"Team Total"}
            secondary={"Opponent Total"}
            textAlign="left"
          />
        ),
      }),
      columnHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => atMostDecFormat(info.getValue()),
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: { group: g++ },
      }),
      columnHelper.accessor("gp", {
        header: () => "G",
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: { group: g, highlights: Highlights.Max, colorDomain: perGame.gp },
      }),
      columnHelper.accessor("gs", {
        header: () => "GS",
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: { group: g, highlights: Highlights.Max, colorDomain: perGame.gs },
      }),
      columnHelper.accessor("mpg", {
        header: () => "MPG",
        cell: (info) => decFormat(info.getValue()),
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.min,
        },
      }),
      columnHelper.accessor("ppg", {
        header: () => "PTS",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.ptsPerGame)}
            secondary={decFormat(teamStats.ptsPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.pts,
        },
      }),
      columnHelper.accessor("orbpg", {
        header: () => "ORB",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.orbPerGame)}
            secondary={decFormat(teamStats.orbPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.orb,
        },
      }),
      columnHelper.accessor("trbpg", {
        header: () => "TRB",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.trbPerGame)}
            secondary={decFormat(teamStats.trbPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.trb,
        },
      }),
      columnHelper.accessor("apg", {
        header: () => "AST",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.astPerGame)}
            secondary={decFormat(teamStats.astPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.ast,
        },
      }),
      columnHelper.accessor("bpg", {
        header: () => "BLK",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.blkPerGame)}
            secondary={decFormat(teamStats.blkPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.blk,
        },
      }),
      columnHelper.accessor("spg", {
        header: () => "STL",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.stlPerGame)}
            secondary={decFormat(teamStats.stlPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.stl,
        },
      }),
      columnHelper.accessor("tovpg", {
        header: () => "TOV",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.tovPerGame)}
            secondary={decFormat(teamStats.tovPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: perGame.to,
        },
      }),
      columnHelper.accessor("liveTovPg", {
        header: () => "(live)",
        cell: (info) => makeParen(decFormat)(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.liveTovPerGame)}
            secondary={decFormat(teamStats.liveTovPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: perGame.liveBallTurn,
        },
      }),
      columnHelper.accessor("pfPer48", {
        header: () => "PF/48",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.pfPer48)}
            secondary={decFormat(teamStats.pfPer48Opp)}
          />
        ),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: perGame.pf,
        },
      }),
      columnHelper.accessor("orbPct", {
        header: () => "OR%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.orPct)}
            secondary={decFormat(teamStats.orPctOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.orbpct,
        },
      }),
      columnHelper.accessor("drbPct", {
        header: () => "DR%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={dec100Format(teamStats.drbPct)}
            secondary={dec100Format(teamStats.drbPctOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.drbpct,
        },
      }),
      columnHelper.accessor("tovPct", {
        header: () => "TO%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.tovPct)}
            secondary={decFormat(teamStats.tovPctOpp)}
          />
        ),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: perGame.topct,
        },
      }),
      columnHelper.accessor("crashRate", {
        header: () => "Crash %",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={dec100Format(
              teamStats.crashersPerShot === null
                ? null
                : teamStats.crashersPerShot / 5
            )}
            secondary={dec100Format(
              teamStats.crashersPerShotOpp === null
                ? null
                : teamStats.crashersPerShotOpp / 5
            )}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.crashRate,
        },
      }),
      columnHelper.accessor("crashRateOverExp", {
        header: () => "vs Exp",
        cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={makePlusMinus(dec100Format)(
              teamStats.crashersPerShot === null ||
                teamStats.predCrashersPerShot === null
                ? null
                : (teamStats.crashersPerShot - teamStats.predCrashersPerShot) /
                    5
            )}
            secondary={makePlusMinus(dec100Format)(
              teamStats.crashersPerShotOpp === null ||
                teamStats.predCrashersPerShotOpp === null
                ? null
                : (teamStats.crashersPerShotOpp -
                    teamStats.predCrashersPerShotOpp) /
                    5
            )}
          />
        ),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.crashRateDiff,
        },
      }),
      columnHelper.accessor(
        (row) => (row.fga2 === 0 ? null : row.fgm2 / row.fga2),
        {
          id: "2pct",
          header: () => "2P%",
          cell: (info) =>
            fracDecFormat(45, {
              numerator: info.row.original.fgm2,
              denominator: info.row.original.fga2,
            }),
          footer: () => (
            <DoubleFooter
              primary={decFormat(teamStats["2pPct"])}
              secondary={decFormat(teamStats["2pPctOpp"])}
            />
          ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame["2pct"],
          },
        }
      ),
      columnHelper.accessor(
        (row) => (row.fga3 === 0 ? null : row.fgm3 / row.fga3),
        {
          id: "3pct",
          header: () => "3P%",
          cell: (info) =>
            fracDecFormat(45, {
              numerator: info.row.original.fgm3,
              denominator: info.row.original.fga3,
            }),
          footer: () => (
            <DoubleFooter
              primary={decFormat(teamStats["3pPct"])}
              secondary={decFormat(teamStats["3pPctOpp"])}
            />
          ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame["3pct"],
          },
        }
      ),
      columnHelper.accessor(
        (row) => (row.fga === 0 ? null : row.fga3 / row.fga),
        {
          id: "3papct",
          header: () => "3PA%",
          cell: (info) => dec100Format(info.getValue()),
          footer: () => (
            <DoubleFooter
              primary={decFormat(teamStats["3paPct"])}
              secondary={decFormat(teamStats["3paPctOpp"])}
            />
          ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame["3papct"],
          },
        }
      ),
      columnHelper.accessor((row) => row.fga3 / row.gp, {
        id: "3pa",
        header: () => "3PA",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.fg3aPerGame)}
            secondary={decFormat(teamStats.fg3aPerGameOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame["3pa"],
        },
      }),
      columnHelper.accessor(
        (row) => (row.fta === 0 ? null : row.ftm / row.fta),
        {
          id: "ftpct",
          header: () => "FT%",
          cell: (info) =>
            fracDecFormat(20, {
              numerator: info.row.original.ftm,
              denominator: info.row.original.fta,
            }),
          footer: () => (
            <DoubleFooter
              primary={decFormat(teamStats.ftPct)}
              secondary={decFormat(teamStats.ftPctOpp)}
            />
          ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame.ftpct,
          },
        }
      ),
      columnHelper.accessor("ftaPer48", {
        header: () => "FTA/48",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(teamStats.ftaPer48)}
            secondary={decFormat(teamStats.ftaPer48Opp)}
          />
        ),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0, 8],
        },
      }),
      columnHelper.accessor("xPPS", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(teamStats.xPPS)}
            secondary={decFormat2(teamStats.xPPSOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.xpps,
        },
      }),
      columnHelper.accessor("xPPSLg", {
        header: () => "xPPS Lg",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(teamStats.xPPSLg)}
            secondary={decFormat2(teamStats.xPPSLgOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.xppsLg,
        },
      }),
      columnHelper.accessor("PPS", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(teamStats.PPS)}
            secondary={decFormat2(teamStats.PPSOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.xpps,
        },
      }),
      columnHelper.accessor("tsPct", {
        header: () => "TS%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={dec100Format(teamStats.tsPct)}
            secondary={dec100Format(teamStats.tsPctOpp)}
          />
        ),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.ts,
        },
      }),
      columnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(teamStats.PPP)}
            secondary={decFormat2(teamStats.PPPOpp)}
          />
        ),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.ppp,
        },
      }),
      columnHelper.accessor("usg", {
        header: () => "Usg",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.usg,
        },
      }),
      columnHelper.accessor("kbhUsg", {
        header: () => "KBH",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => <DoubleFooter primary={<br />} secondary={""} />,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.usg,
        },
      }),
    ];
  }, [teamStats]);

  let rosterToggle;
  if (showRosterToggle) {
    rosterToggle = (
      <div>
        <Form.Check
          style={{ display: "inline-block", paddingRight: 4 }}
          type="checkbox"
          checked={showFullRoster}
          onChange={() => setShowFullRoster(!showFullRoster)}
        />
        Show Full Roster
      </div>
    );
  }

  return (
    <div>
      {rosterToggle}
      <Table
        data={roster}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
      />
    </div>
  );
}
