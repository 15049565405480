import React, { useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Modal, Form, Alert } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FaChartArea, FaPlayCircle } from "react-icons/fa";
import {
  QueryParamConfig,
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
  NumberParam,
} from "use-query-params";
import moment from "moment";

import AppContext from "../../shared/AppContext";
import { SSPlayerContext } from "../PlayerContext";
import { GameFilters } from "../components/games/GameFilters";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { TeamPicker } from "../components/team/TeamPicker";
import {
  AggregatePlayCall,
  ListPlayCall,
} from "../../shared/routers/PlayCallRouter";
import { groupBy } from "../../shared/util/Collections";
import { Highlights } from "../constants/AppConstants";
import { dec100Format, decFormat2, pctFormat, seFormat2 } from "../util/Format";
import { VideoModal } from "../components/video/VideoModal";
import { ShotChartMakeMiss } from "../components/shots/ShotChartMakeMiss";
import { Shot } from "../../shared/routers/ShotRouter";
import { TeamContext } from "../TeamContext";
import { trpc } from "../util/tRPC";
import { playCallToSynergyEditorClip } from "../components/video/utilities";
import { PlayerTableCell } from "../components/core/TableCell";

const useStyles = makeStyles(() =>
  createStyles({
    filterContainer: {
      flexDirection: "column",
      display: "flex",
      gap: 16,
    },
    selector: {
      width: "auto",
      display: "inline",
      marginRight: 10,
    },
  })
);

export function PlayCallPage() {
  const { id } = useParams();
  const classes = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    seasonStart: withDefault(StringParam, AppContext.currentSeason),
    seasonEnd: withDefault(StringParam, AppContext.currentSeason),
    quarters: withDefault(DelimitedArrayParam, [
      "1",
      "2",
      "3",
      "4",
      "OT",
    ]) as QueryParamConfig<string[]>,
    games: withDefault(DelimitedArrayParam, []) as QueryParamConfig<string[]>,
    playThreshold: withDefault(NumberParam, 10),
  });
  const { seasonStart, seasonEnd, quarters, games, playThreshold } =
    queryParams;

  const players = useContext(SSPlayerContext);

  const playerIdMap = useMemo(() => {
    const retObj: Record<string, string> = {};
    for (const player of players) {
      retObj[player.playerId.toString()] = player.player;
    }
    return retObj;
  }, [players]);

  const teams = useContext(TeamContext).teams;
  const { data: teamSchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: id,
    seasonStart,
    seasonEnd,
  });

  const params = {
    teamId: id,
    seasonStart,
    seasonEnd,
    gameIds: games.join(","),
    quarters: quarters.join(","),
  };

  const { data: aggregatePlayCalls } =
    trpc.playCall.getAggregatePlayCalls.useQuery(params);

  const { data: listPlayCalls } =
    trpc.playCall.getListPlayCalls.useQuery(params);

  const playCallsByPlayer = useMemo(() => {
    if (!listPlayCalls) return;
    const playerIds = listPlayCalls
      .map((lpc) => lpc.celticsId)
      .filter((id) => id);
    const retObj: Record<string, ListPlayCall[]> = {};
    for (const playerId of playerIds) {
      if (playerId) {
        retObj[playerId.toString()] = [];
      }
    }
    for (const lpc of listPlayCalls) {
      if (lpc.Offense1 && retObj[lpc.Offense1.toString()]) {
        const arr = retObj[lpc.Offense1.toString()];
        if (arr) {
          arr.push(lpc);
        }
      }
      if (lpc.Offense2 && retObj[lpc.Offense2.toString()]) {
        const arr = retObj[lpc.Offense2.toString()];
        if (arr) {
          arr.push(lpc);
        }
      }
      if (lpc.Offense3 && retObj[lpc.Offense3.toString()]) {
        const arr = retObj[lpc.Offense3.toString()];
        if (arr) {
          arr.push(lpc);
        }
      }
      if (lpc.Offense4 && retObj[lpc.Offense4.toString()]) {
        const arr = retObj[lpc.Offense4.toString()];
        if (arr) {
          arr.push(lpc);
        }
      }
      if (lpc.Offense5 && retObj[lpc.Offense5.toString()]) {
        const arr = retObj[lpc.Offense5.toString()];
        if (arr) {
          arr.push(lpc);
        }
      }
    }
    return retObj;
  }, [listPlayCalls]);

  const team = teams.find((t) => t.teamid.toString() === id);

  const titleString = team ? `${team.teamcity} ${team.teamname}` : "";

  const aggPlayCallsByType = useMemo(() => {
    if (!aggregatePlayCalls) {
      return undefined;
    }

    return groupBy(aggregatePlayCalls, (apc) => apc.typeofplay);
  }, [aggregatePlayCalls]);

  const listplayCallsByType = useMemo(() => {
    if (!listPlayCalls) {
      return undefined;
    }

    const byType = groupBy(listPlayCalls, (lpc) => lpc.typeofplay);
    // Add these in after.
    byType["AfterAction"] = listPlayCalls.filter(
      (lpc) => lpc.AfterAction !== null
    );
    return byType;
  }, [listPlayCalls]);

  const noDataForSeason =
    (listPlayCalls && listPlayCalls.length === 0) ||
    (aggregatePlayCalls && aggregatePlayCalls.length === 0);

  if (!id) return null;

  const msg = `Minimum Play Calls: ${playThreshold.toLocaleString()}`;

  return (
    <Page header={{ text: "Play Calls" }} title={titleString}>
      <div>
        <Row>
          <Col>
            <Panel header="Game Filters">
              <div className={classes.filterContainer}>
                <TeamPicker linkPrefix="play-calls" selectedTeamId={id} />
                <Form>
                  <Form.Label>Seasons</Form.Label>
                  <br />
                  <Form.Select
                    className={classes.selector}
                    value={seasonStart}
                    onChange={(e) =>
                      setQueryParams({
                        seasonStart: e.target.value,
                        seasonEnd:
                          e.target.value > seasonEnd
                            ? e.target.value
                            : seasonEnd,
                        games: [],
                      })
                    }
                  >
                    {AppContext.seasons
                      .filter((s) => s.value > 2016)
                      .map((s) => {
                        return (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <span style={{ marginRight: 10 }}>to</span>
                  <Form.Select
                    className={classes.selector}
                    value={seasonEnd}
                    onChange={(e) =>
                      setQueryParams({
                        seasonStart:
                          seasonStart > e.target.value
                            ? e.target.value
                            : seasonStart,
                        seasonEnd: e.target.value,
                        games: [],
                      })
                    }
                  >
                    {AppContext.seasons
                      .filter((s) => s.value > 2016)
                      .map((s) => {
                        return (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {id && teamSchedule && (
                    <GameFilters
                      teamId={parseInt(id)}
                      onChange={(field, val) =>
                        setQueryParams({ [field]: val })
                      }
                      filters={{
                        seasonStart: "",
                        seasonEnd: "",
                        preseason: 0,
                        regularSeason: 0,
                        postseason: 0,
                        wins: 0,
                        losses: 0,
                        home: 0,
                        away: 0,
                        quarters,
                        games,
                      }}
                      hideResetButton={true}
                      hideSeasonFilter={true}
                      hideQuartersFilter={false}
                      hideStatusMessage={true}
                      hideGameSelectorCategories={true}
                      // If we are in the pre-season and the season is set to
                      // show this season's data, show ONLY pre season data.
                      // Otherwise show everything EXCEPT pre season data.
                      games={teamSchedule.filter(
                        (ts) =>
                          moment(ts.gameDateTime).isSameOrBefore(new Date()) &&
                          (AppContext.inPreseason &&
                          seasonStart === seasonEnd &&
                          seasonStart === AppContext.currentSeason
                            ? ts.gametypeid === "X"
                            : ts.gametypeid !== "X")
                      )}
                      defaultGameSelectorVisible={true}
                    />
                  )}
                  <div style={{ width: 240 }}>
                    <Form.Label>{msg}</Form.Label>
                    <Form.Range
                      min={0}
                      max={100}
                      value={playThreshold}
                      onChange={(evt) => {
                        setQueryParams({
                          playThreshold: parseInt(evt.target.value),
                        });
                      }}
                    />
                  </div>
                </Form>
                {noDataForSeason && (
                  <Alert variant="warning">
                    No play calls were found for your search criteria.
                  </Alert>
                )}
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          {[
            { header: "All Plays (Excluding SOB and BOB)", type: "other" },
            { header: "Sideline Out of Bounds", type: "SOB" },
            { header: "Baseline Out of Bounds", type: "BOB" },
            { header: "After Action", type: "AfterAction" },
          ].map((c) => {
            return (
              <Col key={c.type} lg={6}>
                <Panel header={c.header}>
                  <div>
                    {aggPlayCallsByType && listplayCallsByType ? (
                      !noDataForSeason &&
                      aggPlayCallsByType[c.type] &&
                      listplayCallsByType[c.type] && (
                        <PlayCallTable
                          data={(aggPlayCallsByType[c.type] || [])
                            .sort((a, b) => b.n - a.n)
                            .filter((a) => a.n >= playThreshold)}
                          auxData={listplayCallsByType[c.type] || []}
                        />
                      )
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </Panel>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <Panel header="Breakdown By Playcall">
              <div>
                {playCallsByPlayer ? (
                  !noDataForSeason && (
                    <PlayCallBreakdownTable
                      data={playCallsByPlayer}
                      playerIdMap={playerIdMap}
                    />
                  )
                ) : (
                  <Spinner />
                )}
              </div>
            </Panel>
          </Col>
          <Col>
            <Panel header="Breakdown By Player">
              <div>
                {playCallsByPlayer ? (
                  !noDataForSeason && (
                    <PlayCallPlayerBreakdown
                      data={playCallsByPlayer}
                      playerIdMap={playerIdMap}
                    />
                  )
                ) : (
                  <Spinner />
                )}
              </div>
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

const columnHelper = createColumnHelper<AggregatePlayCall>();

function PlayCallTable(props: {
  data: AggregatePlayCall[];
  auxData: ListPlayCall[];
}) {
  const { data, auxData } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [showShotChart, setShowShotChart] = useState(false);
  const [playType, setPlayType] = useState("");
  const [sorting, setSorting] = useState<SortingState>();

  const firstData = data[0];
  const isAfterAction = firstData
    ? firstData.typeofplay === "AfterAction"
    : undefined;

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("playcall", {
        header: () => (isAfterAction ? "After Action" : "Play"),
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("n", {
        header: () => "# Plays",
        cell: (info) => info.getValue().toLocaleString(),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor("xPPP", {
        header: () => "xPPP",
        cell: (info) => {
          const val = info.getValue();
          if (val === null) return decFormat2(val);
          // Make sure we don't show negative values for xPPP.
          return decFormat2(Math.max(0, val));
        },
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [1.1, 1.2],
        },
      }),
      columnHelper.accessor("stdErrxPPP", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("PPP", {
        header: () => "PPP",
        // Make sure we don't show negative values for PPP.
        cell: (info) => decFormat2(Math.max(0, info.getValue())),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [1.1, 1.2],
        },
      }),
      columnHelper.accessor("stdErrPPP", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
        },
      }),
      columnHelper.accessor("xPPS", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g, highlights: Highlights.Max, colorDomain: [1.1, 1.2] },
      }),
      columnHelper.accessor("PPS", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [1.1, 1.2],
        },
      }),
      columnHelper.accessor("xTovPct", {
        header: () => "xTO%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: [0.16, 0.11],
        },
      }),
      columnHelper.accessor("tovPct", {
        header: () => "TOV%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: [0.16, 0.11],
        },
      }),
      columnHelper.accessor("xOrbPct", {
        header: () => "xOR%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.23, 0.35],
        },
      }),
      columnHelper.accessor("orbPct", {
        header: () => "OR%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.23, 0.35],
        },
      }),
      columnHelper.display({
        id: "chart",
        header: () => "",
        cell: (info) =>
          // If we don't have any plays with location info show nothing.
          auxData.filter(
            (p) => p.playcall === info.row.original.playcall && p.location_x
          ).length ? (
            <a
              href="#"
              onClick={() => onShowShotChart(info.row.original.playcall)}
            >
              <FaChartArea />
            </a>
          ) : (
            ""
          ),
      }),
      columnHelper.display({
        id: "video",
        header: () => "",
        cell: (info) =>
          // If we don't have any plays with video show nothing.
          auxData.filter(
            (p) => p.playcall === info.row.original.playcall && p.synergyURL
          ).length ? (
            <a
              href="#"
              onClick={() => onShowVideoPlayer(info.row.original.playcall)}
            >
              <FaPlayCircle />
            </a>
          ) : (
            ""
          ),
      }),
    ];
  }, [auxData, isAfterAction]);

  const onShowShotChart = (playType: string) => {
    setPlayType(playType);
    setShowShotChart(true);
  };
  const onShowVideoPlayer = (playType: string) => {
    setPlayType(playType);
    setShowVideo(true);
  };

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        showColorOnHover={true}
        virtualScroll={true}
        autoWidth={true}
      />
      <Modal
        show={showShotChart}
        onHide={() => setShowShotChart(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{playType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShotChartMakeMiss
            maxMarkWidth={12}
            // TODO(chrisbu): Make ShotChartMakeMiss use a more general data
            // type so we can remove this hacky cast.
            shots={
              auxData
                .filter((p) => p.playcall === playType)
                .map((p) => {
                  return {
                    locationY: p.location_y,
                    locationX: p.location_x,
                    made: p.made,
                    fouled: p.fouled,
                    epps: p.shotModelPts,
                    eppsActualFoul: p.shotModelPts,
                    isThree: p.three,
                    period: p.period,
                    gameClock: p.endGameClock,
                    complexShotType: p.complexShotType,
                    shotDist: p.dist,
                    url: p.synergyURL,
                  };
                }) as Shot[]
            }
          />
        </Modal.Body>
      </Modal>
      <VideoModal
        clips={auxData
          .filter((p) => p.playcall === playType && p.synergyURL)
          .map(playCallToSynergyEditorClip)}
        show={showVideo}
        title={playType}
        handleClose={() => {
          setShowVideo(false);
        }}
        upDownClipSkip={true}
        showSynergyEditor={true}
      />
    </div>
  );
}

interface PlayCallsByCallForPlayer {
  playerId: string;
  playerName: string;
  playCalls: ListPlayCall[];
  totalPlayCalls: ListPlayCall[];
  shots: ListPlayCall[];
  created: ListPlayCall[];
}

const breakdownColumnHelper = createColumnHelper<PlayCallsByCallForPlayer>();

function PlayCallBreakdownTable(props: {
  data: Record<string, ListPlayCall[]>;
  playerIdMap: Record<string, string>;
}) {
  const classes = useStyles();
  const { data, playerIdMap } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoPlays, setVideoPlays] = useState<ListPlayCall[]>([]);

  const [sorting, setSorting] = useState<SortingState>();

  const allPlayCalls = useMemo(() => {
    const playCallSet = new Set<string>();
    for (const playerId of Object.keys(data)) {
      (data[playerId] || [])
        .map((pc) => playCallToString(pc))
        .forEach((pc) => {
          playCallSet.add(pc);
        });
    }
    return [...playCallSet].sort();
  }, [data]);

  const [selectedPlayCall, setSelectedPlayCall] = useState<string>(
    allPlayCalls[0] || ""
  );

  const dataForSelectedPlayCall = Object.entries(data)
    .map(([key, value]) => {
      const playCallsOfSelectedCall = value.filter(
        (pc) => playCallToString(pc) === selectedPlayCall
      );

      const shots = playCallsOfSelectedCall.filter(
        (pc) => pc.celticsId && pc.celticsId.toString() === key
      );
      return {
        playerId: key,
        playerName: playerIdMap[key] || "Unknown",
        playCalls: playCallsOfSelectedCall,
        totalPlayCalls: value,
        shots,
        created: playCallsOfSelectedCall.filter(
          (pc) => pc.creatorId && pc.creatorId.toString() === key
        ),
      };
    })
    .filter((d) => d.playCalls.length);

  // We have a stale play call, revert to default.
  if (dataForSelectedPlayCall.length === 0 && selectedPlayCall !== "") {
    setSelectedPlayCall(allPlayCalls[0] || "");
  }

  const columns = useMemo(
    () => [
      breakdownColumnHelper.accessor("playerId", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.playerName}
            showPlayerStatusIcons={true}
          />
        ),
      }),
      breakdownColumnHelper.accessor("playCalls", {
        header: () => "# Poss.",
        cell: (info) =>
          // If we don't have any plays with video just show the count.
          info.getValue().filter((p: ListPlayCall) => p.synergyURL).length >
          0 ? (
            <a
              href="#"
              onClick={() => {
                setShowVideo(true);
                setVideoTitle(
                  `${selectedPlayCall} - ${info.row.original.playerName} On Floor`
                );
                setVideoPlays(
                  info.getValue().filter((p: ListPlayCall) => p.synergyURL)
                );
              }}
            >
              {info.getValue().length} <FaPlayCircle />
            </a>
          ) : (
            info.getValue().length
          ),
      }),
      breakdownColumnHelper.accessor("totalPlayCalls", {
        header: () => "Total Poss.",
        cell: (info) => info.getValue().length,
      }),
      breakdownColumnHelper.accessor(
        (row) => row.playCalls.length / row.totalPlayCalls.length,
        {
          id: "calledPct",
          header: () => "Called %",
          cell: (info) => (info.getValue() ? pctFormat(info.getValue()) : ""),
        }
      ),
      breakdownColumnHelper.accessor("shots", {
        header: () => "# Shots",
        cell: (info) =>
          // If we don't have any plays with video just show the count.
          info.getValue().filter((p: ListPlayCall) => p.synergyURL).length >
          0 ? (
            <a
              href="#"
              onClick={() => {
                setShowVideo(true);
                setVideoTitle(
                  `${selectedPlayCall} - ${info.row.original.playerName} Shots`
                );
                setVideoPlays(
                  info.getValue().filter((p: ListPlayCall) => p.synergyURL)
                );
              }}
            >
              {info.getValue().length} <FaPlayCircle />
            </a>
          ) : (
            info.getValue().length
          ),
      }),
      breakdownColumnHelper.accessor(
        (row) => row.shots.length / row.playCalls.length,
        {
          id: "shotPct",
          header: () => "Shot %",
          cell: (info) => pctFormat(info.getValue()),
        }
      ),
      breakdownColumnHelper.accessor("created", {
        header: () => "# Created",
        cell: (info) =>
          // If we don't have any plays with video just show the count.
          info.getValue().filter((p: ListPlayCall) => p.synergyURL).length >
          0 ? (
            <a
              href="#"
              onClick={() => {
                setShowVideo(true);
                setVideoTitle(
                  `${selectedPlayCall} - ${info.row.original.playerName} Created`
                );
                setVideoPlays(
                  info.getValue().filter((p: ListPlayCall) => p.synergyURL)
                );
              }}
            >
              {info.getValue().length} <FaPlayCircle />
            </a>
          ) : (
            info.getValue().length
          ),
      }),
      breakdownColumnHelper.accessor(
        (row) => row.created.length / row.playCalls.length,
        {
          id: "createdPct",
          header: () => "Created %",
          cell: (info) => pctFormat(info.getValue()),
        }
      ),
    ],
    [selectedPlayCall]
  );

  return (
    <div>
      <Form.Select
        className={classes.selector}
        value={selectedPlayCall}
        onChange={(e) => setSelectedPlayCall(e.target.value)}
      >
        {allPlayCalls.map((d) => {
          return (
            <option key={d} value={d}>
              {d}
            </option>
          );
        })}
      </Form.Select>
      <Table
        data={dataForSelectedPlayCall.sort((a, b) =>
          a.playCalls.length > b.playCalls.length ? -1 : 1
        )}
        columns={columns}
        autoWidth={true}
        sorting={sorting}
        setSorting={setSorting}
        showColorOnHover={true}
      />
      <VideoModal
        clips={videoPlays
          .filter((p) => p.synergyURL)
          .map(playCallToSynergyEditorClip)}
        show={showVideo}
        title={videoTitle}
        handleClose={() => {
          setShowVideo(false);
        }}
        upDownClipSkip={true}
        showSynergyEditor={true}
      />
    </div>
  );
}

interface PlayCallsByPlayer {
  playCall: string;
  playType: string;
  playCalls: ListPlayCall[];
}

const playerBreakdownColumnHelper = createColumnHelper<PlayCallsByPlayer>();

function PlayCallPlayerBreakdown(props: {
  data: Record<string, ListPlayCall[]>;
  playerIdMap: Record<string, string>;
}) {
  const classes = useStyles();

  const { data, playerIdMap } = props;
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>(
    Object.keys(data)[0] || ""
  );

  const [sorting, setSorting] = useState<SortingState>();
  const [showVideo, setShowVideo] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoPlays, setVideoPlays] = useState<ListPlayCall[]>([]);

  // We have a stale playerId, revert to default.
  if (!data[selectedPlayerId] && selectedPlayerId !== "") {
    setSelectedPlayerId(Object.keys(data)[0] || "");
  }

  const dataByPlayer = useMemo(() => {
    const ret: PlayCallsByPlayer[] = [];
    if (selectedPlayerId && data[selectedPlayerId]) {
      const playTypes = [
        ...new Set((data[selectedPlayerId] || []).map((p) => p.typeofplay)),
      ];
      for (const type of playTypes) {
        const playCalls = [
          ...new Set(
            (data[selectedPlayerId] || [])
              .filter((p) => p.typeofplay === type)
              .map((p) => p.playcall)
          ),
        ];
        for (const call of playCalls) {
          ret.push({
            playType: type,
            playCall: call,
            playCalls: (data[selectedPlayerId] || []).filter(
              (p) => p.playcall === call && p.typeofplay === type
            ),
          });
        }
      }
    }
    return ret;
  }, [data, selectedPlayerId]);

  const columns = useMemo(
    () => [
      playerBreakdownColumnHelper.accessor("playCall", {
        header: () => "Play",
        cell: (info) => info.getValue(),
        meta: { textAlign: "left" },
      }),
      playerBreakdownColumnHelper.accessor("playType", {
        header: () => "Type",
        cell: (info) => (info.getValue() === "other" ? "" : info.getValue()),
      }),
      playerBreakdownColumnHelper.accessor((row) => row.playCalls.length, {
        id: "n",
        header: () => "# Poss.",
        cell: (info) =>
          // If we don't have any plays with video just show the count.
          info.row.original.playCalls.filter((p) => p.synergyURL).length > 0 ? (
            <a
              href="#"
              onClick={() => {
                setShowVideo(true);
                setVideoTitle(info.row.original.playCall);
                setVideoPlays(
                  info.row.original.playCalls.filter((p) => p.synergyURL)
                );
              }}
            >
              {info.getValue()} <FaPlayCircle />
            </a>
          ) : (
            info.getValue()
          ),
      }),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          dataByPlayer
            .filter((d) => d.playType === row.playType)
            .reduce((prev, cur) => prev + cur.playCalls.length, 0),
        {
          id: "total",
          header: () => "Total Poss.",
          cell: (info) => info.getValue(),
        }
      ),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          row.playCalls.length /
          dataByPlayer
            .filter((d) => d.playType === row.playType)
            .reduce((prev, cur) => prev + cur.playCalls.length, 0),
        {
          id: "pct",
          header: () => "Called %",
          cell: (info) => pctFormat(info.getValue()),
        }
      ),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          row.playCalls.filter(
            (p) =>
              p.complexShotType &&
              (p.celticsId || 0).toString() === selectedPlayerId
          ),
        {
          id: "shots",
          header: () => "# Shots",
          cell: (info) =>
            // If we don't have any plays with video just show the count.
            info.getValue().filter((p: ListPlayCall) => p.synergyURL).length >
            0 ? (
              <a
                href="#"
                onClick={() => {
                  setShowVideo(true);
                  setVideoTitle(
                    `${info.row.original.playCall} - ${
                      playerIdMap[selectedPlayerId] || "Unknown"
                    } Shots`
                  );
                  setVideoPlays(
                    info.getValue().filter((p: ListPlayCall) => p.synergyURL)
                  );
                }}
              >
                {info.getValue().length} <FaPlayCircle />
              </a>
            ) : (
              info.getValue().length
            ),
        }
      ),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          row.playCalls.filter(
            (p) =>
              p.complexShotType &&
              (p.celticsId || 0).toString() === selectedPlayerId
          ).length / row.playCalls.length,
        {
          id: "shotPct",
          header: () => "Shot %",
          cell: (info) => pctFormat(info.getValue()),
        }
      ),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          row.playCalls.filter(
            (p) => (p.creatorId || 0).toString() === selectedPlayerId
          ),
        {
          id: "created",
          header: () => "# Created",
          cell: (info) =>
            // If we don't have any plays with video just show the count.
            info.getValue().filter((p: ListPlayCall) => p.synergyURL).length >
            0 ? (
              <a
                href="#"
                onClick={() => {
                  setShowVideo(true);
                  setVideoTitle(
                    `${info.row.original.playCall} - ${
                      playerIdMap[selectedPlayerId] || "Unknown"
                    } Created`
                  );
                  setVideoPlays(
                    info.getValue().filter((p: ListPlayCall) => p.synergyURL)
                  );
                }}
              >
                {info.getValue().length} <FaPlayCircle />
              </a>
            ) : (
              info.getValue().length
            ),
        }
      ),
      playerBreakdownColumnHelper.accessor(
        (row) =>
          row.playCalls.filter(
            (p) => (p.creatorId || 0).toString() === selectedPlayerId
          ).length / row.playCalls.length,
        {
          id: "createdPct",
          header: () => "Created %",
          cell: (info) => pctFormat(info.getValue()),
        }
      ),
    ],
    [dataByPlayer, selectedPlayerId, playerIdMap]
  );

  return (
    <div>
      <Form.Select
        className={classes.selector}
        value={selectedPlayerId}
        onChange={(e) => setSelectedPlayerId(e.target.value)}
      >
        {Object.keys(data)
          .filter((d) => (data[d] || []).length > 0)
          .map((d) => {
            return (
              <option key={d} value={d}>
                {playerIdMap[d] || "Unknown"}
              </option>
            );
          })}
      </Form.Select>
      <Table
        data={dataByPlayer.sort((a, b) =>
          a.playCalls.length > b.playCalls.length ? -1 : 1
        )}
        columns={columns}
        autoWidth={true}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        showColorOnHover={true}
      />
      <VideoModal
        clips={videoPlays
          .filter((p) => p.synergyURL)
          .map(playCallToSynergyEditorClip)}
        show={showVideo}
        title={videoTitle}
        handleClose={() => {
          setShowVideo(false);
        }}
        upDownClipSkip={true}
        showSynergyEditor={true}
      />
    </div>
  );
}

function playCallToString(playCall: ListPlayCall) {
  return `${playCall.playcall}${
    playCall.typeofplay !== "other" ? ` - (${playCall.typeofplay})` : ""
  }`;
}
