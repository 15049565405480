import React, { useState, useMemo } from "react";
import { Form, Col, Row } from "react-bootstrap";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import {
  PlayerDraftModelYear,
  PlayerDraftModelComp,
} from "../../../shared/routers/DraftRouter";
import { decFormat } from "../../util/Format";
import { PlayerTableCell } from "../core/TableCell";
import { trpc } from "../../util/tRPC";
import { sortNullsToEnd } from "../../util/Sort";

const columnHelper = createColumnHelper<PlayerDraftModelComp>();

export function PlayerDraftModelCompsTables(props: {
  playerId: number;
  draftModelYears: PlayerDraftModelYear[];
}) {
  const { playerId, draftModelYears } = props;
  const [year, setYear] = useState(
    draftModelYears.find((y) => y.defaultSeason === 1)?.value.toString()
  );

  const { data: comps } = trpc.draft.getPlayerDraftModelComps.useQuery({
    playerId,
    season: year,
  });

  return (
    <div>
      <div style={{ gap: 10, display: "flex", alignItems: "center" }}>
        <Form.Label>Season</Form.Label>
        <Form.Select
          value={year}
          style={{ width: "auto", display: "inline" }}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setYear(evt.target.value);
          }}
        >
          {draftModelYears.map((y) => {
            return (
              <option key={y.value} value={y.value}>
                {y.label}
              </option>
            );
          })}
        </Form.Select>
      </div>
      {comps === undefined ? (
        <Spinner />
      ) : (
        <Row>
          {["raw", "similar", "size"].map((type) => (
            <Col key={type}>
              <div style={{ textAlign: "center" }}>
                <b>{tableTitleMap[type]}</b>
              </div>
              <CompTable
                data={comps.filter((c) => c.comptype === type)}
                type={type}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

function CompTable(props: { data: PlayerDraftModelComp[]; type: string }) {
  {
    const { data, type } = props;
    const [sorting, setSorting] = useState<SortingState>();

    const columns = useMemo(
      () => [
        columnHelper.accessor("name", {
          header: () => "Player",
          cell: (info) => (
            <PlayerTableCell
              id={info.row.original.celticsID}
              name={info.getValue()}
              showPlayerStatusIcons={false}
            />
          ),
        }),
        columnHelper.accessor("Season", {
          header: () => "Season",
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("net_impact_pred", {
          header: () =>
            type === "size" ? "Actual Peak Impact" : "Net Impact Pred",
          cell: (info) => <b>{decFormat(info.getValue())}</b>,
          meta: { colorDomain: [-5.0, 5.0] },
        }),
        columnHelper.accessor("total_difference", {
          header: () => "Similarity",
          cell: (info) => <b>{info.getValue()}</b>,
        }),
      ],
      [type]
    );

    const hiddenColumns = {
      total_difference: type !== "size",
    };

    const sortedData =
      type === "size"
        ? data.sort((a, b) => sortNullsToEnd(b, a, "net_impact_pred"))
        : data.sort((a, b) => sortNullsToEnd(b, a, "total_difference"));

    return (
      <Table
        data={sortedData}
        hiddenColumns={hiddenColumns}
        columns={columns}
        autoWidth={true}
        expandColumnId={"SkillGroup"}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
      />
    );
  }
}

const tableTitleMap: Record<string, string> = {
  raw: "Raw Comps",
  similar: "Similarly-Predicted Comps",
  size: "Size Comps",
};
