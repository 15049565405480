import React, { useContext, useMemo } from "react";
import { Badge } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { SSPlayerContext } from "../../PlayerContext";

import { WowyControlValue } from "./WowyLineupInput";
import { TeamContext } from "../../TeamContext";
import {
  pctFormat,
  period,
  seasonString,
  gteLteEqFmt,
  ordinalFormat,
  decFormat2,
  decFormat,
  gameClockFormat,
} from "../../util/Format";
import {
  Positions,
  complexShotTypeMap,
  simpleShotTypeLabelMap,
  shotLabels,
  PNR_TYPES,
  SCREEN_TYPES,
  BHR_DEFENDER_COVERAGES,
  SCR_DEFENDER_COVERAGES,
  SCR_DEFENDER_DEPTH,
} from "../../constants/AppConstants";

export function FilterChips(props: {
  filters: Record<string, unknown>;
  deletableFilters: string[];
  onRemove: (key: string) => void;
}) {
  const { filters, deletableFilters, onRemove } = props;
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerMap = useMemo(() => {
    return players.reduce((acc, p) => {
      acc[p.playerId] = p.player;
      return acc;
    }, {} as Record<number, string>);
  }, [players]);

  function formatKey(key: string) {
    switch (key) {
      case "period":
      case "periods":
        return "Period";
      case "season":
      case "seasons":
        return "Season";
      case "offTeamIds":
      case "offTeamId":
        return "Offense";
      case "defTeamIds":
      case "defTeamId":
        return "Defense";
      case "teamId":
        return "Team";
      case "oppTeamId":
        return "Opponent";
      case "withPlayers":
        return "With";
      case "withoutPlayers":
        return "Without";
      case "oneOfPlayers":
        return "With One";
      case "lineup":
        return "On/Off";
      case "dateFrom":
      case "fromDate":
        return "From";
      case "dateTo":
      case "toDate":
        return "To";
      case "gameString":
        return "Game";
      case "turnover":
        return "Turnover";
      case "transition":
        return "Transition";
      case "wowyLineup":
        return "Lineup";
      case "zone":
        return "Zone";
      case "numBigs":
        return "# Bigs";
      case "numFives":
        return "# Fives";
      case "oppNumFives":
        return "Opp # Fives";
      case "oppNumBigs":
        return "Opp # Bigs";
      case "scrDefenderCoverage":
        return "Coverage";
      case "fiveOut":
        return "5-Out";
      case "pickSlip":
        return "Pick/Slip";
      case "rollPop":
        return "Roll/Pop";
      case "rimSpacerId":
        return "Rim Spacers";
      case "perimeterSpacerId":
        return "Perimeter Spacers";
      case "fromNumPerimSpacers":
      case "toNumPerimSpacers":
        return "# Perim Spacers";
      case "fromNumRimSpacers":
      case "toNumRimSpacers":
        return "# Rim Spacers";
      case "fromPerimSpacersPctl":
      case "toPerimSpacersPctl":
        return "Perim Spacers Ability";
      case "fromShotClock":
      case "toShotClock":
      case "shotClockFrom":
      case "shotClockTo":
        return "Shot Clock";
      case "leverageFrom":
        return "From Leverage";
      case "leverageTo":
        return "To Leverage";
      case "shooterIds":
        return "Shooters";
      case "passerIds":
        return "Passers";
      case "defenderIds":
        return "Defenders";
      case "ballhandlerId":
        return "Ballhandlers";
      case "screenerId":
        return "Screeners";
      case "ballhandlerDefenderId":
        return "Ballhandler Defenders";
      case "screenerDefenderId":
        return "Screener Defenders";
      case "ballhandlerDefenderPctlFrom":
      case "ballhandlerDefenderPctlTo":
        return "Bhr Defenders Ability";
      case "screenerDefenderPctlFrom":
      case "screenerDefenderPctlTo":
        return "Scr Defenders Ability";
      case "ballhandlerPctlFrom":
      case "ballhandlerPctlTo":
        return "Bhr Ability";
      case "screenerPctlFrom":
      case "screenerPctlTo":
        return "Scr Ability";
      case "popperCatchAndShoot3PctlFrom":
      case "popperCatchAndShoot3PctlTo":
        return "Popper C&S 3 Ability";
      case "rollerOrPctlFrom":
      case "rollerOrPctlTo":
        return "Roller OR Ability";
      case "ballhandlerPocket3PctlFrom":
      case "ballhandlerPocket3PctlTo":
        return "Bhr Pocket 3 Ability";
      case "oreb2024":
        return "After Oreb 20-24";
      case "fromxpps":
      case "toxpps":
        return "xPPS";
      case "fromShotDistance":
      case "toShotDistance":
        return "Shot Distance";
      case "fromDribbles":
      case "toDribbles":
        return "Dribbles";
      case "fromDistanceFromHoopCenter":
      case "toDistanceFromHoopCenter":
        return "Inches From Hoop Center";
      case "fromDefDist":
      case "toDefDist":
        return "Defender Distance";
      case "fromPeriodTime":
      case "toPeriodTime":
        return "Period Time";
      default:
        return defaultKeyFormatter(key);
    }
  }

  function playerFromId(id: number | string) {
    // Be extra sure this is a number!
    const idAsNum = parseInt(id as string);
    return playerMap[idAsNum] || "Unknown";
  }

  function parseWowyLineup(value: WowyControlValue): string {
    const withPlayers = value.withPlayers || [];
    const withoutPlayers = value.withoutPlayers || [];
    const oneOfPlayers = value.oneOfPlayers || [];
    const withPlayersStr = withPlayers.length
      ? `With ${withPlayers.map((p) => playerFromId(parseInt(p))).join(", ")}`
      : "";
    const withoutPlayersStr = withoutPlayers.length
      ? `Without ${withoutPlayers
          .map((p) => playerFromId(parseInt(p)))
          .join(", ")}`
      : "";
    const oneOfPlayersStr = oneOfPlayers.length
      ? `With One Of ${oneOfPlayers
          .map((p) => playerFromId(parseInt(p)))
          .join(", ")}`
      : "";
    return [withPlayersStr, withoutPlayersStr, oneOfPlayersStr]
      .filter((s) => s)
      .join(", ");
  }

  function formatValue(key: string, value: unknown): string {
    switch (key) {
      case "season":
        return seasonString(value as string) || (value as string);
      case "period":
      case "periods":
        return period(value as number);
      case "withPlayers":
      case "withoutPlayers":
      case "oneOfPlayers":
      case "ballhandlerId":
      case "ballhandlerDefenderId":
      case "screenerId":
      case "screenerDefenderId":
      case "perimeterSpacerId":
      case "rimSpacerId":
      case "shooterIds":
      case "passerIds":
      case "defenderIds":
        return playerFromId(value as number);
      case "offTeamId":
      case "defTeamId":
      case "teamId":
      case "oppTeamId":
      case "offTeamIds":
      case "defTeamIds":
        return (
          teams.find((t) => t.teamid === parseInt(value as string))?.teamname ||
          "Unknown"
        );
      case "wowyLineup":
        return parseWowyLineup(value as WowyControlValue);
      case "lineup":
        return value ? "Selected" : "Everything Else";
      case "leverageFrom":
      case "leverageTo":
      case "fromLeverage":
      case "toLeverage":
        return pctFormat(value === null ? null : (value as number) / 1_000);
      case "numBigs":
      case "numFives":
      case "oppNumFives":
      case "oppNumBigs":
        return gteLteEqFmt(
          value as { gte?: string; lte?: string; eq?: string }
        );
      case "fromDistanceFromHoopCenter":
      case "fromDribbles":
      case "fromShotClock":
      case "shotClockFrom":
      case "fromNumPerimSpacers":
      case "fromNumRimSpacers":
        return ">=" + (value as string);
      case "toDistanceFromHoopCenter":
      case "toDribbles":
      case "toShotClock":
      case "shotClockTo":
      case "toNumPerimSpacers":
      case "toNumRimSpacers":
        return "<=" + (value as string);
      case "fromPerimSpacersPctl":
      case "ballhandlerDefenderPctlFrom":
      case "screenerDefenderPctlFrom":
      case "ballhandlerPctlFrom":
      case "screenerPctlFrom":
      case "popperCatchAndShoot3PctlFrom":
      case "rollerOrPctlFrom":
      case "ballhandlerPocket3PctlFrom":
        return (
          ">=" +
          ordinalFormat(Math.round((value as number) * 100)) +
          " percentile"
        );
      case "toPerimSpacersPctl":
      case "ballhandlerDefenderPctlTo":
      case "screenerDefenderPctlTo":
      case "ballhandlerPctlTo":
      case "screenerPctlTo":
      case "popperCatchAndShoot3PctlTo":
      case "rollerOrPctlTo":
      case "ballhandlerPocket3PctlTo":
        return (
          "<=" +
          ordinalFormat(Math.round((value as number) * 100)) +
          " percentile"
        );
      case "fromxpps":
        return ">=" + decFormat2(value as number);
      case "toxpps":
        return "<=" + decFormat2(value as number);
      case "fromDefDist":
      case "fromShotDistance":
        return ">=" + decFormat(value as number) + " ft";
      case "toDefDist":
      case "toShotDistance":
        return "<=" + decFormat(value as number) + " ft";
      case "ballhandlerPos":
      case "screenerPos":
      case "ballhandlerDefenderPos":
      case "screenerDefenderPos":
      case "shooterTypicalPositions":
      case "shooterPositions":
      case "defenderTypicalPositions":
      case "defenderPositions":
        return Positions[value as number] || "Unknown";
      case "type":
        if (value === "pnr") return "PNR";
        if (value === "dho") return "DHO";
        if (value === "fake_dho") return "Fake DHO";
        return "Unknown";
      case "contestLevel":
        return PNR_TYPE_MAP[value as string] || "Unknown";
      case "fromPeriodTime":
        return ">=" + gameClockFormat(value as number);
      case "toPeriodTime":
        return "<=" + gameClockFormat(value as number);
      case "generalShotTypes":
        return simpleShotTypeLabelMap[value as string] || "Unknown";
      case "specificShotTypes":
        return complexShotTypeMap[value as string]!.label || "Unknown";
      case "driveDirections":
        return shotLabels.drive_direction[value as string] || "Unknown";
      case "directions":
        return shotLabels.direction[value as string] || "Unknown";
      case "floorSide":
      case "pickSlip":
      case "rollPop":
      case "direction":
        return capitilizeFirstLetter(value as string);
      case "screenType":
        return SCREEN_TYPES_MAP[value as string] || "Unknown";
      case "bhrDefenderCoverage":
        return BHR_DEFENDER_COVERAGES_MAP[value as string] || "Unknown";
      case "scrDefenderCoverage":
        return SCR_DEFENDER_COVERAGES_MAP[value as string] || "Unknown";
      case "scrDefenderDepth":
        return SCR_DEFENDER_DEPTH_MAP[value as string] || "Unknown";
      default:
        return JSON.stringify(value);
    }
  }

  return (
    <div>
      {Object.entries(filters)
        .filter(([, v]) => v !== undefined && numberInputHasValue(v))
        .map(([key, val], i) => (
          <span key={i}>
            <Badge pill bg="info">
              {formatKey(key)}:
              <b>
                {Array.isArray(val)
                  ? val
                      .sort()
                      .map((v) => formatValue(key, v))
                      .join(", ")
                  : formatValue(key, val)}
              </b>{" "}
              {deletableFilters.includes(key) && (
                <IoMdCloseCircle onClick={() => onRemove(key)} />
              )}
            </Badge>{" "}
          </span>
        ))}
    </div>
  );
}

// For our number inputs the value type is:
//  { gte?: string; lte?: string; eq?: string }
// To determine whether we should even show this filter we need to check
// if the value is an object and if it is see if at least ONE key has a value.
// Also check if it is {}.
function numberInputHasValue(val: unknown) {
  if (JSON.stringify(val) === "{}") return false;
  const objKeys = Object.keys(val as Record<string, unknown>);
  if (objKeys.length === 0) return true;
  return objKeys.some((k) => {
    const v = (val as Record<string, unknown>)[k];
    return v !== undefined && v !== null && v !== "";
  });
}

function isUppercaseLetter(char: string) {
  return char >= "A" && char <= "Z";
}

// generally keys are going to be camelCase, so capitalize the first letter
// and then add a space whenever you see a capital letter.
function defaultKeyFormatter(s: string) {
  const letters = s.split("");
  let formatted = "";
  for (let i = 0; i < letters.length; i++) {
    const curLetter = letters[i];
    if (!curLetter) return;
    if (i === 0) {
      formatted += curLetter.toUpperCase();
    } else if (isUppercaseLetter(curLetter)) {
      formatted += ` ${curLetter}`;
    } else {
      formatted += curLetter;
    }
  }
  return formatted;
}

function capitilizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const PNR_TYPE_MAP = PNR_TYPES.reduce((acc, t) => {
  acc[t.value] = t.label;
  return acc;
}, {} as Record<string, string>);

const SCREEN_TYPES_MAP = SCREEN_TYPES.reduce((acc, t) => {
  acc[t.value] = t.label;
  return acc;
}, {} as Record<string, string>);

const BHR_DEFENDER_COVERAGES_MAP = BHR_DEFENDER_COVERAGES.reduce((acc, t) => {
  acc[t.value] = t.label;
  return acc;
}, {} as Record<string, string>);

const SCR_DEFENDER_COVERAGES_MAP = SCR_DEFENDER_COVERAGES.reduce((acc, t) => {
  acc[t.value] = t.label;
  return acc;
}, {} as Record<string, string>);

const SCR_DEFENDER_DEPTH_MAP = SCR_DEFENDER_DEPTH.reduce((acc, t) => {
  acc[t.value] = t.label;
  return acc;
}, {} as Record<string, string>);
