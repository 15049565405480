import React from "react";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "d3";

import { PlayerLastShots } from "../../../shared/routers/PlayerRouter";
import { lineChartColors } from "../../constants/ColorConstants";
import { pctFormat } from "../../util/Format";

const RIM_DIAMETER = 18;
const CHART_WIDTH = 30;

export function RimChart(props: { shots: PlayerLastShots[] | undefined }) {
  const { shots } = props;

  const xyDomain = [-CHART_WIDTH / 2, CHART_WIDTH / 2];

  return (
    <ParentSize parentSizeStyles={{ width: "100%" }}>
      {({ width }) => {
        if (!width || !shots) return null;
        const xyScale = scaleLinear().domain(xyDomain).range([0, width]);

        const filteredShotDists = (
          shots.filter(
            (s) => s.rimDepth !== null && s.rimLeftRight !== null
          ) as { rimDepth: number; rimLeftRight: number }[]
        ).map((s) =>
          Math.sqrt(
            s.rimDepth * s.rimDepth * 144 +
              s.rimLeftRight * s.rimLeftRight * 144
          )
        );

        const great = filteredShotDists.filter((d) => d <= 3).length;
        const good = filteredShotDists.filter((d) => d <= 6).length;
        const ok = filteredShotDists.filter((d) => d <= 9).length;

        return (
          <div style={{ position: "relative" }}>
            <svg width={width} height={width}>
              {/* Draw the 1 in target shaded circle */}
              <circle
                cx={xyScale(0)}
                cy={xyScale(0)}
                r={xyScale(3) - xyScale(0)}
                fillOpacity={0.5}
                fill={"yellow"}
              />
              <circle
                cx={xyScale(0)}
                cy={xyScale(0)}
                r={xyScale(6) - xyScale(0)}
                fillOpacity={0.3}
                fill={"orange"}
              />
              {shots
                .filter((s) => s.rimDepth !== null && s.rimLeftRight !== null)
                .map((shot, i) => {
                  const rimLeftRight = shot.rimLeftRight || 0;
                  // Need to flip the Y axis.
                  const rimDepth = -(shot.rimDepth || 0);
                  const glpyhSize = 3;
                  return shot.made ? (
                    <circle
                      key={i}
                      cx={xyScale(rimLeftRight * 12)}
                      cy={xyScale(rimDepth * 12)}
                      r={glpyhSize}
                      stroke={lineChartColors.green}
                      strokeWidth={2}
                      fillOpacity={0}
                      onClick={() => handleClick(shot)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    // For a miss, do a red X.
                    <g key={i}>
                      <line
                        x1={xyScale(rimLeftRight * 12) - glpyhSize}
                        y1={xyScale(rimDepth * 12) - glpyhSize}
                        x2={xyScale(rimLeftRight * 12) + glpyhSize}
                        y2={xyScale(rimDepth * 12) + glpyhSize}
                        stroke={lineChartColors.red}
                        strokeWidth={2}
                        onClick={() => handleClick(shot)}
                        style={{ cursor: "pointer" }}
                      />
                      <line
                        x1={xyScale(rimLeftRight * 12) + glpyhSize}
                        y1={xyScale(rimDepth * 12) - glpyhSize}
                        x2={xyScale(rimLeftRight * 12) - glpyhSize}
                        y2={xyScale(rimDepth * 12) + glpyhSize}
                        stroke={lineChartColors.red}
                        strokeWidth={2}
                        onClick={() => handleClick(shot)}
                        style={{ cursor: "pointer" }}
                      />
                    </g>
                  );
                })}
              {/* Draw the rim. */}
              <circle
                cx={xyScale(0)}
                cy={xyScale(0)}
                r={xyScale(RIM_DIAMETER / 2) - xyScale(0)}
                fill="none"
                stroke={lineChartColors.orange}
                strokeWidth="2"
              />
            </svg>
            {filteredShotDists.length > 0 && (
              <>
                <div>
                  {"3in from center: "}
                  {pctFormat(great / filteredShotDists.length)}
                </div>
                <div>
                  {"6in from center: "}
                  {pctFormat(good / filteredShotDists.length)}
                </div>
                <div>
                  {"9in from center: "}
                  {pctFormat(ok / filteredShotDists.length)}
                </div>
              </>
            )}
          </div>
        );
      }}
    </ParentSize>
  );
}

function handleClick(shot: PlayerLastShots) {
  if (shot.URL) window.open(shot.URL || "");
}
