import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import {
  useQueryParams,
  withDefault,
  StringParam,
  DelimitedArrayParam,
  QueryParamConfig,
} from "use-query-params";
import moment from "moment";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { GameFilters } from "../components/games/GameFilters";
import { TeamPicker } from "../components/team/TeamPicker";
import { TeamSubstitutionPatternChart } from "../components/team/TeamSubstitutionPatternChart";
import AppContext from "../../shared/AppContext";
import { trpc } from "../util/tRPC";
import { MultiSelect } from "../components/core/MultiSelect";
import { SSPlayerContext } from "../PlayerContext";
import { NbaEaglePlayer } from "../../shared/routers/RosterRouter";
import { itemsAsList } from "../util/Format";

export function SubstitutionsPage() {
  const { id } = useParams();
  const [queryParams, setQueryParams] = useQueryParams({
    season: withDefault(StringParam, AppContext.currentSeason),
    games: withDefault(DelimitedArrayParam, []) as QueryParamConfig<string[]>,
    withoutPlayers: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
  });
  const { season, games, withoutPlayers } = queryParams;

  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const { data: teamSchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: id,
    seasonStart: season,
    seasonEnd: season,
  });

  const { data: teamSubData } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: id,
    season,
    games: games.join(","),
    withoutPlayerIds: withoutPlayers,
  });

  const numGames = teamSubData
    ? [...new Set(teamSubData.map((t) => t.gameId))].length
    : 0;

  if (!id || players.length === 0) return null;

  return (
    <Page header={{ text: "Substitutions" }} title="Substitutions">
      <div>
        <Row>
          <Col>
            <Panel header={"Game Filters"}>
              <div>
                <TeamPicker selectedTeamId={id} linkPrefix="substitutions" />
                <Form>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Form.Label>Season</Form.Label>
                      <br />
                      <Form.Select
                        style={{
                          width: "auto",
                          display: "inline",
                          marginRight: 10,
                        }}
                        value={season}
                        onChange={(e) =>
                          setQueryParams({ season: e.target.value, games: [] })
                        }
                      >
                        {AppContext.seasons.map((s) => {
                          return (
                            <option key={s.value} value={s.value}>
                              {s.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                    <div>
                      <Form.Label>Without Player(s)</Form.Label>
                      <br />
                      <MultiSelect
                        values={players
                          .filter((p) => {
                            const entry =
                              playerSeasonTeamMap[p.playerId.toString()];
                            if (!entry) return false;
                            const teams = entry[season];
                            if (!teams) return false;
                            return teams.has(id);
                          })
                          .map((p) => {
                            return {
                              label: p.player,
                              value: p.playerId.toString(),
                            };
                          })
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        selected={withoutPlayers}
                        onChange={(vals) =>
                          setQueryParams({ withoutPlayers: vals })
                        }
                      />
                    </div>
                  </div>
                  {id && teamSchedule && (
                    <GameFilters
                      teamId={parseInt(id)}
                      onChange={(field, val) =>
                        setQueryParams({ [field]: val })
                      }
                      filters={{
                        seasonStart: "",
                        seasonEnd: "",
                        preseason: 0,
                        regularSeason: 0,
                        postseason: 0,
                        wins: 0,
                        losses: 0,
                        home: 0,
                        away: 0,
                        quarters: [],
                        games,
                      }}
                      hideResetButton={true}
                      hideSeasonFilter={true}
                      hideQuartersFilter={true}
                      hideStatusMessage={true}
                      hideGameSelectorCategories={true}
                      games={teamSchedule.filter(
                        (ts) =>
                          ts.gametypeid !== "X" &&
                          moment(ts.gameDateTime).isSameOrBefore(new Date())
                      )}
                      defaultGameSelectorVisible={true}
                    />
                  )}
                </Form>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel header={"Sub Pattern"}>
              {teamSubData ? (
                <div>
                  <TeamSubstitutionPatternChart
                    numGames={numGames}
                    data={teamSubData}
                  />
                  {games.length === 0 && (
                    <p>
                      Default: Last 10 Games
                      {withoutPlayers.length > 0
                        ? ` without ${itemsAsList(
                            players
                              .filter((p) =>
                                withoutPlayers.some(
                                  (wp) => wp === p.playerId.toString()
                                )
                              )
                              .map((p) => p.player)
                          )}`
                        : ""}
                    </p>
                  )}
                </div>
              ) : (
                <Spinner />
              )}
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const yearObj = map[year];
    if (!yearObj) {
      map[year] = new Set([team]);
    } else {
      yearObj.add(team);
    }
  }
  return map;
}
