import React, { useState, useMemo } from "react";
import { Alert, Col, Form, Row, Collapse } from "react-bootstrap";
import {
  BooleanParam,
  DelimitedArrayParam,
  JsonParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { MostRecentLeagues, Positions } from "../constants/AppConstants";
import { PlayerSeasonSearchResult } from "../../shared/routers/SearchRouter";
import { DynamicFilterGroup } from "../components/query/DynamicFilterGroup";
import { DynamicFilterType } from "../components/query/DynamicFilter";
import { ExpandCollapseButton } from "../components/core/ExpandCollapseButton";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import {
  decFormat,
  decFormat2,
  footFormat,
  pctFormat,
  poundsFormat,
  intFormat,
} from "../util/Format";
import { trpc } from "../util/tRPC";
import { PlayerTableCell, TeamTableCell } from "../components/core/TableCell";
import AppContext from "../../shared/AppContext";
import { groupBy } from "../../shared/util/Collections";

// Treat nba playoffs and regular season as just nba + remove all preseason
// leagues (these are also removed on the backend).
const ALL_LEAGUES = MostRecentLeagues.filter(
  (l) =>
    l !== "NBA Regular Season" &&
    l !== "NBA Playoffs" &&
    !l.toLowerCase().includes("preseason")
).concat(["NBA"]);

interface PlayerSearchFilter {
  value: keyof PlayerSeasonSearchResult;
  label: string;
  orderBy?: boolean;
  type?: DynamicFilterType;
  options?: string[];
  group: "popular" | "total" | "pergame" | "advanced" | "measurements";
}

const ALL_FILTERS: PlayerSearchFilter[] = [
  {
    value: "ageToday",
    label: "Age",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  // {
  //   value: "eligibility",
  //   label: "Eligibility",
  //   orderBy: true,
  //   type: "multiselect",
  // },
  {
    value: "league",
    label: "League",
    orderBy: true,
    type: "multiselect",
    options: ALL_LEAGUES,
    group: "popular",
  },
  {
    value: "team",
    label: "Team",
    orderBy: true,
    type: "multiselect",
    group: "popular",
  },
  {
    value: "position",
    label: "Position",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  {
    value: "season",
    label: "Season",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  {
    value: "seasonAge",
    label: "Season Age",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  // "playedInNcaa",
  {
    value: "nbaPred",
    label: "pNBA",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  // draftYear
  { value: "gp", label: "GP", orderBy: true, type: "number", group: "total" },
  { value: "gs", label: "GS", orderBy: true, type: "number", group: "total" },
  { value: "min", label: "Min", orderBy: true, type: "number", group: "total" },
  { value: "pts", label: "Pts", orderBy: true, type: "number", group: "total" },
  {
    value: "rtot",
    label: "Reb",
    orderBy: true,
    type: "number",
    group: "total",
  },
  { value: "ast", label: "Ast", orderBy: true, type: "number", group: "total" },
  { value: "blk", label: "Blk", orderBy: true, type: "number", group: "total" },
  { value: "stl", label: "Stl", orderBy: true, type: "number", group: "total" },
  {
    value: "turn",
    label: "Tov",
    orderBy: true,
    type: "number",
    group: "total",
  },
  { value: "pf", label: "PF", orderBy: true, type: "number", group: "total" },
  {
    value: "roff",
    label: "Orb",
    orderBy: true,
    type: "number",
    group: "total",
  },
  {
    value: "rdef",
    label: "Drb",
    orderBy: true,
    type: "number",
    group: "total",
  },
  // "roffavail",
  // "rdefavail",
  {
    value: "fg2m",
    label: "FG2M",
    orderBy: true,
    type: "number",
    group: "total",
  },
  {
    value: "fg2a",
    label: "FG2A",
    orderBy: true,
    type: "number",
    group: "total",
  },
  {
    value: "fg3m",
    label: "FG3M",
    orderBy: true,
    type: "number",
    group: "total",
  },
  {
    value: "fg3a",
    label: "FG3A",
    orderBy: true,
    type: "number",
    group: "total",
  },
  { value: "fgm", label: "FGM", orderBy: true, type: "number", group: "total" },
  { value: "fga", label: "FGA", orderBy: true, type: "number", group: "total" },
  { value: "ftm", label: "FTM", orderBy: true, type: "number", group: "total" },
  { value: "fta", label: "FTA", orderBy: true, type: "number", group: "total" },
  // "playerPoss",
  // "estPossPlayed",
  // "usgAvail",
  {
    value: "svbm",
    label: "BPM",
    orderBy: true,
    type: "number",
    group: "popular",
  },
  {
    value: "dunks",
    label: "Dunks",
    orderBy: true,
    type: "number",
    group: "total",
  },
  {
    value: "minPerGame",
    label: "Min/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "ptsPerGame",
    label: "Pts/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "rtotPerGame",
    label: "Reb/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "astPerGame",
    label: "Ast/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "blkPerGame",
    label: "Blk/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "stlPerGame",
    label: "Stl/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "turnPerGame",
    label: "Tov/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "pfPerGame",
    label: "PF/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "roffPerGame",
    label: "Orb/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "rdefPerGame",
    label: "Drb/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  // "roffavailPerGame",
  // "rdefavailPerGame",
  {
    value: "fg2mPerGame",
    label: "FG2M/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "fg2aPerGame",
    label: "FG2A/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "fg3mPerGame",
    label: "FG3M/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "fg3aPerGame",
    label: "FG3A/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "fgmPerGame",
    label: "FGM/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "fgaPerGame",
    label: "FGA/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "ftmPerGame",
    label: "FTM/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "ftaPerGame",
    label: "FTA/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  {
    value: "dunksPerGame",
    label: "Dunks/G",
    orderBy: true,
    type: "number",
    group: "pergame",
  },
  // "playerPossPerGame",
  // "estPossPlayedPerGame",
  // "usgAvailPerGame",
  // "minPer100Poss",
  // "ptsPer100Poss",
  // "rtotPer100Poss",
  // "astPer100Poss",
  // "blkPer100Poss",
  // "stlPer100Poss",
  // "turnPer100Poss",
  // "pfPer100Poss",
  // "roffPer100Poss",
  // "rdefPer100Poss",
  // "roffavailPer100Poss",
  // "rdefavailPer100Poss",
  // "fg2mPer100Poss",
  // "fg2aPer100Poss",
  // "fg3mPer100Poss",
  // "fg3aPer100Poss",
  // "fgmPer100Poss",
  // "fgaPer100Poss",
  // "ftmPer100Poss",
  // "ftaPer100Poss",
  // "playerPossPer100Poss",
  // "usgAvailPer100Poss",
  // "dunksPer100Poss",
  {
    value: "pfPer48",
    label: "PF/48",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "ftaPer48",
    label: "FT/48",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "roffpct",
    label: "Orb%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "rdefpct",
    label: "Drb%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "ftarate",
    label: "FTA Rate",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "ppp",
    label: "PPP",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "usg",
    label: "Usg",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "turnrate",
    label: "Tov%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "fg2pct",
    label: "FG2%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "fg3pct",
    label: "FG3%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "ftpct",
    label: "FT%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "fgpct",
    label: "FG%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "efgpct",
    label: "eFG%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "fg3apct",
    label: "FG3A%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "tspct",
    label: "TS%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "rebpct",
    label: "Reb%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "astpct",
    label: "Ast%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "blkpct",
    label: "Blk%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  {
    value: "stlpct",
    label: "Stl%",
    orderBy: true,
    type: "number",
    group: "advanced",
  },
  // "astTurn",
  // "blkPlusStlOverPf",
  // "heightNoShoes",
  {
    value: "heightWithShoes",
    label: "Height (In Shoes)",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "weight",
    label: "Weight",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "wingspan",
    label: "Wingspan",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  // "wingspanDelta",
  {
    value: "standReach",
    label: "St. Reach",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "handLength",
    label: "Hand Length",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "handWidth",
    label: "Hand Width",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "bodyFat",
    label: "Body Fat",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "verticalJumpNoStep",
    label: "Vert. No Step",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "verticalJumpOneStep",
    label: "Vert. Max",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "benchPress",
    label: "Bench Press",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "laneAgility",
    label: "Lane Agility",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
  {
    value: "run34",
    label: "Run 3/4",
    orderBy: true,
    type: "number",
    group: "measurements",
  },
];

const DEFAULT_SELECTED_COLUMNS: (keyof PlayerSeasonSearchResult)[] = [
  "ageToday",
  "heightWithShoes",
  "weight",
  "season",
  "seasonAge",
  "gp",
  "gs",
  "min",
  "ptsPerGame",
  "rtotPerGame",
  "astPerGame",
  "blkPerGame",
  "stlPerGame",
  "turnPerGame",
  "pfPer48",
  "roffpct",
  "rdefpct",
  "turnrate",
  "efgpct",
  "fg2pct",
  "fg2m",
  "fg2a",
  "fg3pct",
  "fg3m",
  "fg3a",
  "fg3aPerGame",
  "fg3apct",
  "ftpct",
  "ftaPer48",
  "ftarate",
  "ppp",
  "usg",
  "svbm",
  // "apmForecastNet",
  // "playerPoss",
  // "estPossPlayed",
  // "usgAvail",
];

export function PlayerSearchPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    orderBy: withDefault(StringParam, "svbm"),
    desc: withDefault(BooleanParam, true),
    selectedColumns: withDefault(
      DelimitedArrayParam,
      DEFAULT_SELECTED_COLUMNS
    ) as QueryParamConfig<string[]>,
    filters: withDefault(JsonParam, [
      { key: "league", value: "" },
      { key: "season", value: { eq: parseInt(AppContext.currentSeason) } },
      { key: "min", value: { gte: 100 } },
      { key: "weight", value: "" },
      { key: "heightWithShoes", value: "" },
      { key: "wingspan", value: "" },
      { key: "ageToday", value: "" },
      // { key: "eligibility", value: "" },
      { key: "fg3pct", value: "" },
      { key: "dunks", value: "" },
    ]) as QueryParamConfig<{ key: string; value: unknown }[]>,
  });

  const { orderBy, desc, filters } = queryParams;
  const selectedColumns = new Set(queryParams.selectedColumns);

  const [showColumnChooser, setShowColumnChooser] = useState(false);

  const allFilters = ALL_FILTERS;
  const allFiltersByGroup = groupBy(allFilters, (f) => f.group);

  const { data: players } = trpc.search.getPlayerSeasons.useQuery({
    orderBy,
    direction: desc ? "desc" : "asc",
    filters: JSON.stringify(filters),
  });

  const onSelectColumnChange = (col: { value: string; label: string }) => {
    const checked = selectedColumns.has(col.value);
    if (checked) {
      selectedColumns.delete(col.value);
    } else {
      selectedColumns.add(col.value);
    }
    setQueryParams({ selectedColumns: [...selectedColumns.values()] });
  };

  return (
    <Page header={{ text: "Player Search" }} title={"Player Search"}>
      <div>
        <Row>
          <Col>
            <Panel header={"Filters"}>
              <div>
                <DynamicFilterGroup
                  allFilters={allFilters.map((a) => {
                    return {
                      ...a,
                      group: groupToLabel(a.group),
                    };
                  })}
                  filters={filters}
                  setFilters={(val) => setQueryParams({ filters: val })}
                />
                <Row style={{ marginTop: 12 }}>
                  <Col>
                    <Form.Label>Order By</Form.Label>
                    <div>
                      <Form.Select
                        style={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 10,
                        }}
                        value={orderBy}
                        onChange={(e) => {
                          // Assume that when someone sets the order by they
                          // want to see that column in the table.
                          selectedColumns.add(e.target.value);
                          setQueryParams({
                            orderBy: e.target.value,
                            selectedColumns: [...selectedColumns.values()],
                          });
                        }}
                      >
                        {[
                          "popular",
                          "total",
                          "pergame",
                          "advanced",
                          "measurements",
                        ].map((group) => {
                          return (
                            <optgroup key={group} label={groupToLabel(group)}>
                              {(allFiltersByGroup[group] || [])
                                .filter((f) => f.orderBy)
                                .map((f) => {
                                  return (
                                    <option key={f.value} value={f.value}>
                                      {f.label}
                                    </option>
                                  );
                                })}
                            </optgroup>
                          );
                        })}
                      </Form.Select>
                      <Form.Select
                        value={desc ? "desc" : "asc"}
                        style={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 10,
                        }}
                        onChange={(e) =>
                          setQueryParams({ desc: e.target.value === "desc" })
                        }
                      >
                        <option value={"asc"}>Asc</option>
                        <option value={"desc"}>Desc</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }}>
                  <Col>
                    <Form style={{ marginTop: 12 }}>
                      <div>
                        <Form.Label>Select Columns</Form.Label>{" "}
                        <ExpandCollapseButton
                          onClick={() =>
                            setShowColumnChooser(!showColumnChooser)
                          }
                          expanded={showColumnChooser}
                        />
                      </div>
                      <Collapse in={showColumnChooser}>
                        <div>
                          {[
                            "popular",
                            "total",
                            "pergame",
                            "advanced",
                            "measurements",
                          ].map((group) => {
                            return (
                              <div key={group} style={{ marginBottom: 8 }}>
                                <Form.Label>{groupToLabel(group)}</Form.Label>
                                <div style={{ display: "flex", gap: 8 }}>
                                  {(allFiltersByGroup[group] || []).map(
                                    (f, i) => {
                                      return (
                                        <div
                                          style={{ display: "flex" }}
                                          key={i}
                                        >
                                          <Form.Check
                                            style={{ marginRight: 4 }}
                                            key={f.value}
                                            inline
                                            onChange={() =>
                                              onSelectColumnChange(f)
                                            }
                                            checked={selectedColumns.has(
                                              f.value
                                            )}
                                          />
                                          <div>{f.label}</div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Collapse>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel header={"Results"}>
              {players ? (
                <PlayerSearchResultsPanel
                  players={players}
                  selectedColumns={selectedColumns}
                />
              ) : (
                <Spinner />
              )}
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

function PlayerSearchResultsPanel(props: {
  players: PlayerSeasonSearchResult[];
  selectedColumns: Set<string>;
}) {
  const { players, selectedColumns } = props;

  if (players.length === 0) {
    return <Alert variant="warning">No players match your filters.</Alert>;
  }

  return (
    <PlayerSearchResultsTable
      players={players}
      selectedColumns={selectedColumns}
    />
  );
}

const columnHelper = createColumnHelper<PlayerSeasonSearchResult>();

function PlayerSearchResultsTable(props: {
  players: PlayerSeasonSearchResult[];
  selectedColumns: Set<string>;
}) {
  const { players, selectedColumns } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;

    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ageToday", {
        header: () => "Age Today",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // eligibility
      columnHelper.accessor("league", {
        header: () => "League",
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) =>
          info.getValue() ? (
            <TeamTableCell
              id={info.row.original.teamId || undefined}
              name={info.getValue() || undefined}
              ids={info.row.original.teamIdsId || undefined}
            />
          ) : null,
        meta: { group: g++ },
      }),
      columnHelper.accessor("position", {
        header: () => "Position",
        cell: (info) =>
          info.getValue() === null
            ? null
            : Positions[info.getValue() as number],
        meta: { group: g++ },
      }),
      columnHelper.accessor("season", {
        header: () => "Season",
        meta: { group: g++ },
      }),
      columnHelper.accessor("seasonAge", {
        header: () => "Season Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // // "playedInNcaa",
      columnHelper.accessor("nbaPred", {
        header: () => "pNBA",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // draftYear
      columnHelper.accessor("gp", {
        header: () => "GP",
        meta: { group: g++ },
      }),
      columnHelper.accessor("gs", {
        header: () => "GS",
        meta: { group: g++ },
      }),
      columnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("pts", {
        header: () => "Pts",
        meta: { group: g++ },
      }),
      columnHelper.accessor("rtot", {
        header: () => "Reb",
        meta: { group: g++ },
      }),
      columnHelper.accessor("ast", {
        header: () => "Ast",
        meta: { group: g++ },
      }),
      columnHelper.accessor("blk", {
        header: () => "Blk",
        meta: { group: g++ },
      }),
      columnHelper.accessor("stl", {
        header: () => "Stl",
        meta: { group: g++ },
      }),
      columnHelper.accessor("turn", {
        header: () => "Tov",
        meta: { group: g++ },
      }),
      columnHelper.accessor("pf", {
        header: () => "PF",
        meta: { group: g++ },
      }),
      columnHelper.accessor("roff", {
        header: () => "Orb",
        meta: { group: g++ },
      }),
      columnHelper.accessor("rdef", {
        header: () => "Drb",
        meta: { group: g++ },
      }),
      // // "roffavail",
      // // "rdefavail",
      columnHelper.accessor("fg2m", {
        header: () => "FG2M",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg2a", {
        header: () => "FG2A",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3m", {
        header: () => "FG3M",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3a", {
        header: () => "FG3A",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fgm", {
        header: () => "FGM",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fga", {
        header: () => "FGA",
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftm", {
        header: () => "FTM",
        meta: { group: g++ },
      }),
      columnHelper.accessor("fta", {
        header: () => "FTA",
        meta: { group: g++ },
      }),
      // // "playerPoss",
      // // "estPossPlayed",
      // // "usgAvail",
      columnHelper.accessor("svbm", {
        header: () => "BPM",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("dunks", {
        header: () => "Dunks",
        cell: (info) => intFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("minPerGame", {
        header: () => "Min/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ptsPerGame", {
        header: () => "Pts/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("rtotPerGame", {
        header: () => "Reb/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),

      columnHelper.accessor("astPerGame", {
        header: () => "Ast/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("blkPerGame", {
        header: () => "Blk/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("stlPerGame", {
        header: () => "Stl/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("turnPerGame", {
        header: () => "Tov/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("pfPerGame", {
        header: () => "PF/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("roffPerGame", {
        header: () => "ORB/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("rdefPerGame", {
        header: () => "DRB/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // // "roffavailPerGame",
      // // "rdefavailPerGame",
      columnHelper.accessor("fg2mPerGame", {
        header: () => "FG2M/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg2aPerGame", {
        header: () => "FG2A/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3mPerGame", {
        header: () => "FG3M/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3aPerGame", {
        header: () => "FG3A/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fgmPerGame", {
        header: () => "FGM/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fgaPerGame", {
        header: () => "FGA/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftmPerGame", {
        header: () => "FTM/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftaPerGame", {
        header: () => "FTA/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // // "playerPossPerGame",
      // // "estPossPlayedPerGame",
      // // "usgAvailPerGame",
      columnHelper.accessor("dunksPerGame", {
        header: () => "Dunks/G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // "minPer100Poss",
      // "ptsPer100Poss",
      // "rtotPer100Poss",
      // "astPer100Poss",
      // "blkPer100Poss",
      // "stlPer100Poss",
      // "turnPer100Poss",
      // "pfPer100Poss",
      // "roffPer100Poss",
      // "rdefPer100Poss",
      // "roffavailPer100Poss",
      // "rdefavailPer100Poss",
      // "fg2mPer100Poss",
      // "fg2aPer100Poss",
      // "fg3mPer100Poss",
      // "fg3aPer100Poss",
      // "fgmPer100Poss",
      // "fgaPer100Poss",
      // "ftmPer100Poss",
      // "ftaPer100Poss",
      // "playerPossPer100Poss",
      // "usgAvailPer100Poss",
      // "dunksPer100Poss",
      columnHelper.accessor("pfPer48", {
        header: () => "PF/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftaPer48", {
        header: () => "FT/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("roffpct", {
        header: () => "Orb%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("rdefpct", {
        header: () => "Drb%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftarate", {
        header: () => "FTA Rate",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("usg", {
        header: () => "Usg",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("turnrate", {
        header: () => "Tov%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg2pct", {
        header: () => "2P%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3pct", {
        header: () => "3P%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ftpct", {
        header: () => "FT%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fgpct", {
        header: () => "FG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("efgpct", {
        header: () => "eFG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fg3apct", {
        header: () => "3PA%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("tspct", {
        header: () => "TS%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("rebpct", {
        header: () => "Reb%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("astpct", {
        header: () => "Ast%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("blkpct", {
        header: () => "Blk%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("stlpct", {
        header: () => "Stl%",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // // "astTurn",
      // // "blkPlusStlOverPf",
      // // "heightNoShoes",
      columnHelper.accessor("heightWithShoes", {
        header: () => "Height (Shoes)",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("weight", {
        header: () => "Weight",
        cell: (info) => poundsFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("wingspan", {
        header: () => "Wingspan",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // // "wingspanDelta",
      columnHelper.accessor("standReach", {
        header: () => "St. Reach",
        cell: (info) => footFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("handLength", {
        header: () => "Hand Length",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("handWidth", {
        header: () => "Hand Width",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("bodyFat", {
        header: () => "Body Fat",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("verticalJumpNoStep", {
        header: () => "Vert. No Step",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("verticalJumpOneStep", {
        header: () => "Vert. Max",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("benchPress", {
        header: () => "Bench Press",
        meta: { group: g++ },
      }),
      columnHelper.accessor("laneAgility", {
        header: () => "Lane Agility",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("run34", {
        header: () => "Run 3/4",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
    ];
  }, []);

  const visibility: Record<string, boolean> = {};
  for (const filter of ALL_FILTERS) {
    if (!selectedColumns.has(filter.value)) {
      visibility[filter.value] = false;
    }
  }

  return (
    <Table
      data={players}
      columns={columns}
      hiddenColumns={visibility}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
    />
  );
}

function groupToLabel(label: string) {
  switch (label) {
    case "popular":
      return "Popular";
    case "total":
      return "Total";
    case "pergame":
      return "Per Game";
    case "advanced":
      return "Advanced";
    case "measurements":
      return "Measurements";
    default:
      return label;
  }
}
