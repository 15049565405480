import React, { useEffect, useState, useMemo } from "react";
import { Link, Navigate, Routes, Route, useLocation } from "react-router-dom";
import { Anchor, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import moment from "moment";
import LogRocket from "logrocket";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink, loggerLink } from "@trpc/client";
import confetti from "canvas-confetti";

import AppContext from "../shared/AppContext";
import { UserContext, UserPreferenceContext } from "./UserContext";
import { TeamContext } from "./TeamContext";
import { SSPlayerContext } from "./PlayerContext";
import { PlayerStatusContext } from "./PlayerStatusContext";
import { CELTICS_TEAM_ID } from "./constants/AppConstants";
import { BiaUser } from "../shared/routers/AdminRouter";
import { TeamSchedule } from "../shared/routers/TeamRouter";
import { useEventListener, useWindowSize } from "./util/Hooks";
import { Footer } from "./components/core/Footer";
import { TeamQuickSwitch } from "./components/core/TeamQuickSwitch";
import Help from "./deprecated/components/core/Help";
import { SearchBar } from "./components/core/SearchBar";
import { ErrorBoundary } from "./components/core/ErrorBoundary";
import { Restrict } from "./components/core/Restrict";
import { trpc } from "./util/tRPC";

import { AdminPage } from "./pages/AdminPage";
import { BiaSpmPage } from "./pages/BiaSpmPage";
import { BoardMakerPage } from "./pages/BoardMakerPage";
import { DraftClassPage } from "./pages/DraftClassPage";
import { DraftBoardPage } from "./pages/DraftBoardPage";
import { ErrorPage } from "./pages/ErrorPage";
import { GamesPage } from "./pages/GamesPage";
import { GamePage } from "./pages/GamePage";
import { GameLivePage } from "./pages/GameLivePage";
import { GameGLeaguePage } from "./pages/GameGLeaguePage";
import { GameMultiLeaguePage } from "./pages/GameMultiLeaguePage";
import { GamePreviewPage } from "./pages/GamePreviewPage";
import { ImpactExplorerPage } from "./pages/ImpactExplorerPage";
import { LeaguePage } from "./pages/LeaguePage";
import { LineupsPage } from "./pages/LineupsPage";
import { LoginPage } from "./pages/LoginPage";
import { MappingPage } from "./pages/MappingPage";
import { MultiLeagueTeamPage } from "./pages/MultiLeagueTeamPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PlayCallPage } from "./pages/PlayCallPage";
import { PlayerComparePage } from "./pages/PlayerComparePage";
import { PlayerSearchPage } from "./pages/PlayerSearchPage";
import { PlayerPage } from "./pages/PlayerPage";
import { PlayersPage } from "./pages/PlayersPage";
import { PlayerLinksPage } from "./pages/PlayerLinksPage";
import { PlayerMinutesPage } from "./pages/PlayerMinutesPage";
import { PlayerSimilarityPage } from "./pages/PlayerSimilarityPage";
import { PnrExplorerPage } from "./pages/PnrExplorerPage";
import { PnrExplorerPage as PnrExplorerPage2 } from "./pages/PnrExplorerPage_2";
import { PossessionExplorerPage } from "./pages/PossessionExplorerPage";
import { ReadsPage } from "./pages/ReadsPage";
import { SearchPage } from "./pages/SearchPage";
import { ScoutingPage } from "./pages/ScoutingPage";
import { ScoutRankingsPage } from "./pages/ScoutRankingsPage";
import { SkillDevelopmentPage } from "./pages/SkillDevelopmentPage";
import { ShotDetailsPage } from "./pages/ShotDetailsPage";
import { ReboundDetailsPage } from "./pages/ReboundDetailsPage";
import { SeriesPage } from "./pages/SeriesPage";
import { ShotExplorerPage } from "./pages/ShotExplorerPage";
import { SiteUsagePage } from "./pages/SiteUsagePage";
import { StandingsPage } from "./pages/StandingsPage";
import { StatsPage } from "./pages/StatsPage";
import { SubstitutionsPage } from "./pages/SubstitutionsPage";
import { TeamPage } from "./pages/TeamPage";
import { UserPage } from "./pages/UserPage";
import { VideoPage } from "./pages/VideoPage";
import { WowPage } from "./pages/WowPage";
import { TopPerformersPage } from "./pages/TopPerformersPage";
import { ISTPage } from "./pages/ISTPage";
import { TEAM_COLORS } from "./constants/ColorConstants";
import { reduceArrayToObject } from "../shared/util/Collections";
import { PlayerMatchupPage } from "./pages/PlayerMatchupPage";
import { VideoProxyPage } from "./pages/VideoProxyPage";

const isLocalHost = window.location.href.includes("localhost");
const isBia2 = window.location.href.includes("bia2");

const logger = isLocalHost ? console : LogRocket;

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink({
          logger: (opt) => {
            if (opt.direction === "up") return;
            logger.log(
              `[API ${opt.type}] ${opt.path} ${
                opt.input ? JSON.stringify(opt.input) : ""
              } (${opt.elapsedMs.toLocaleString()}ms)`
            );
            if (opt.result instanceof Error || "error" in opt.result.result) {
              logger.error(opt.result);
            } else if (opt.result.result.type === "data") {
              // Only log result from data queries, don't care about mutations.
              if (opt.type === "query") {
                const data = opt.result.result.data;
                if (Array.isArray(data) && data.length > 1_000) {
                  logger.log(data.slice(0, 1_000));
                  logger.warn(
                    `Row data was truncated for logging as it's count ${data.length.toLocaleString()} exceeded the logging threshold of 1,000.`
                  );
                } else {
                  logger.log(data);
                }
              }
            }
          },
          // Only log on return.
          enabled: (opt) => opt.direction == "down",
        }),
        // TODO(chrisbu): Explore switching to batching at some point for better
        // performance / code ergonomics.
        httpLink({
          url: "/api",
        }),
      ],
    })
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AppInner />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

function AppInner() {
  const [user, setUser] = useState<BiaUser | null>();
  const [helpVisible, setHelpVisible] = useState(false);
  const [confettiInterval, setConfettiInterval] = useState<NodeJS.Timer>();
  const location = useLocation();
  const windowSize = useWindowSize();

  const isMobile = windowSize.width < 992;

  const mutation = trpc.admin.postSiteUsage.useMutation();

  useEffect(() => {
    // If no user or we are running a local server don't log anything.
    if (!user || window.location.href.includes("localhost")) return;
    const email = user.email;
    const route = location.pathname.split("/")[1];
    // This will be undefined when users navigate to the root. We want to ignore
    // those pageviews as they just get re-routed to the BOS team page.
    if (!route) return;
    const fullPath = location.pathname + location.search;
    const dateTime = new Date();
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    mutation.mutate({ email, route, fullPath, dateTime, isMobile });
    // Because every render mutation changes including it in the deps causes
    // an infinite loop of posting.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location]);

  useEffect(() => {
    if (location.pathname === "/game/42300405") {
      if (confettiInterval) return;
      // Initiate the confetti!!
      setConfettiInterval(
        setInterval(() => {
          confetti({
            particleCount: 100,
            startVelocity: 30,
            spread: 360,
            colors: ["#007A33", "#BA9653", "#963821", "#ffffff"],
            origin: {
              x: Math.random(),
              // since they fall down, start a bit higher than random
              y: Math.random() - 0.2,
            },
          });
        }, 500)
      );
    } else {
      if (confettiInterval) {
        clearInterval(confettiInterval);
        setConfettiInterval(undefined);
      }
    }
  }, [location, confettiInterval]);

  // Handles global hotkeys.
  const handleKeyDown = (event: KeyboardEvent) => {
    // Ignore if the user is typing in an input/textarea.
    if (
      document.activeElement &&
      ["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)
    ) {
      return;
    }

    switch (event.key) {
      case "]":
        // TODO(chrisbu): Implement next page.
        break;
      case "[":
        // TODO(chrisbu): Implement prev page.
        break;
      case "?": // ? key to display help about keyboard shortcuts
        setHelpVisible(!helpVisible);
        break;
      case "Escape":
        setHelpVisible(false);
        break;
    }
  };

  useEventListener("keydown", handleKeyDown);

  useEffect(() => {
    fetch("/api/auth", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((user: BiaUser) => {
          setUser(user);
          LogRocket.identify(user.email, {
            name: user.email.split("@")[0] || "Unknown",
            email: user.email,
            role: user.group || "",
          });
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  const { data: teams } = trpc.team.getTeams.useQuery(
    { isGLeague: false },
    { enabled: !!user }
  );

  const { data: schedule } = trpc.team.getTeamSchedule.useQuery({
    seasonStart: AppContext.currentSeason,
    seasonEnd: AppContext.currentSeason,
    teamId: "",
  });

  const scheduleByTeam = useMemo(() => {
    if (!schedule) return {};
    return schedule.reduce((prev, cur) => {
      if (!prev[cur.homeTeamId]) {
        prev[cur.homeTeamId] = [];
      }
      if (!prev[cur.awayTeamId]) {
        prev[cur.awayTeamId] = [];
      }
      prev[cur.homeTeamId]!.push(cur);
      prev[cur.awayTeamId]!.push(cur);
      return prev;
    }, {} as Record<string, TeamSchedule[]>);
  }, [schedule]);

  const { data: eaglePlayers } = trpc.roster.getNbaEaglePlayers.useQuery(
    undefined,
    {
      enabled: !!user,
    }
  );

  const { data: preferences } = trpc.user.getPreferences.useQuery(
    {
      email: (user && user.email) || "",
    },
    { enabled: !!user }
  );

  const { data: playerInjuryRows } = trpc.player.getAllPlayerInjuries.useQuery(
    undefined,
    { enabled: !!user }
  );

  const { data: playerMissedGamesRows } =
    trpc.player.getPlayerMissedGames.useQuery(undefined, { enabled: !!user });

  const { data: trendingPlayerRows } = trpc.admin.getTrendingPlayers.useQuery(
    {
      daysBack: 14,
      ignoreCeltics: true,
    },
    { enabled: !!user && user.isAdmin }
  );

  const { data: playerHotColdRows } = trpc.player.getPlayerHotCold.useQuery();

  const trendingPlayers = reduceArrayToObject(
    trendingPlayerRows || [],
    (p) => p.playerIdStr
  );

  const playerInjuries = reduceArrayToObject(playerInjuryRows || [], (p) =>
    p.playerId.toString()
  );

  const playerHotCold = reduceArrayToObject(playerHotColdRows || [], (p) =>
    p.playerId.toString()
  );

  const playerMissedGames = reduceArrayToObject(
    playerMissedGamesRows || [],
    (p) => p.playerId.toString()
  );

  // If user is undefined it means we are still waiting on an auth check.
  if (user === undefined) return null;

  // If user is null it means the auth check failed so show the login.
  if (user === null) {
    return <LoginPage />;
  }

  const isAdminUser = user.isAdmin;
  const isBiaUser = user.group === "bia";
  const isBiaScoutExec =
    user.group && ["bia", "scout", "executive"].includes(user.group);

  const handleLogoutClick = () => {
    fetch("/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    setUser(null);
  };

  // Transform an array of preferences into a map of preference name to value.
  const preferencesMap = preferences?.reduce((prev, cur) => {
    prev[cur.preferenceName] = cur.preferenceValue;
    return prev;
  }, {} as Record<string, number>);

  // For non-BIA users or local dev the logo link should just go to homepage.
  // For BIA users let it be a quick switcher to toggle between BIA and BIA2.
  const curPath = location.pathname + location.search;
  const logoUrl =
    !isBiaUser || isLocalHost
      ? "/"
      : isBia2
      ? `https://bia.celticscloud.com${curPath}`
      : `https://bia2.celticscloud.com${curPath}`;

  const celticsColors = TEAM_COLORS[CELTICS_TEAM_ID];
  const celticsGreen = celticsColors ? celticsColors.primary : "white";

  // To reduce jank wait on these things to be available before rendering.
  if (!teams || !preferences || !schedule) return null;

  return (
    <div style={{ color: "white" }}>
      <UserContext.Provider value={user}>
        <UserPreferenceContext.Provider value={preferencesMap || {}}>
          <TeamContext.Provider value={{ teams, schedule: scheduleByTeam }}>
            <SSPlayerContext.Provider value={eaglePlayers || []}>
              <PlayerStatusContext.Provider
                value={{
                  trendingPlayers,
                  playerInjuries,
                  playerMissedGames,
                  playerHotCold,
                }}
              >
                <header>
                  <Navbar
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      gap: isMobile ? 4 : undefined,
                    }}
                    // This + items having hrefs gives collapse behavior.
                    collapseOnSelect
                    bg="black"
                    variant="dark"
                    fixed="top"
                    expand="lg"
                  >
                    <Navbar.Brand
                      as={Anchor}
                      href={logoUrl}
                      style={{
                        fontWeight: 700,
                        fontSize: 18,
                        marginRight: isMobile ? 0 : undefined,
                      }}
                    >
                      <img
                        src="/assets/img/nav_logo.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                      />{" "}
                      BIA{" "}
                      {isBia2 || isLocalHost ? (
                        <span
                          style={{
                            color: celticsGreen,
                          }}
                        >
                          {isBia2 ? "2" : "Local"}
                        </span>
                      ) : (
                        ""
                      )}
                    </Navbar.Brand>
                    {/* On small screen show this searchbar. */}
                    <SearchBar className="d-lg-none" mobile={true} />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      {user.group === "intern" ? (
                        <Nav>
                          <Nav.Link
                            as={Link}
                            to="/standings/nba"
                            href="/standings/nba"
                          >
                            Standings
                          </Nav.Link>
                          <Nav.Link as={Link} to="/mapping" href="/mapping">
                            Mapping
                          </Nav.Link>
                        </Nav>
                      ) : (
                        <Nav>
                          <Nav.Link
                            as={Link}
                            to="/standings/nba"
                            href="/standings/nba"
                          >
                            Standings
                          </Nav.Link>
                          <NavDropdown title="Players" menuVariant="dark">
                            <NavDropdown.Item
                              as={Link}
                              to="/player"
                              href="/player"
                            >
                              NBA Players
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to={BOARD_MAKER_UPCOMING_FA}
                              href={BOARD_MAKER_UPCOMING_FA}
                            >
                              Upcoming FAs
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={BOARD_MAKER_2WAY_ELIGIBLE}
                              href={BOARD_MAKER_2WAY_ELIGIBLE}
                            >
                              2-Way Eligible Availables
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={BOARD_MAKER_AVAILABLE_FA}
                              href={BOARD_MAKER_AVAILABLE_FA}
                            >
                              Available FAs
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={BOARD_MAKER_GLOBAL_FA}
                              href={BOARD_MAKER_GLOBAL_FA}
                            >
                              Global FAs
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={BOARD_MAKER_DRAFT_RIGHTS_HELD}
                              href={BOARD_MAKER_DRAFT_RIGHTS_HELD}
                            >
                              Draft Rights Held
                            </NavDropdown.Item>
                          </NavDropdown>
                          <Nav.Link as={Link} to="/games" href="/games">
                            Games
                          </Nav.Link>
                          <Nav.Link as={Link} to="/league" href="/league">
                            League
                          </Nav.Link>
                          <Restrict roles={["bia", "scout", "executive"]}>
                            <NavDropdown title="Draft" menuVariant="dark">
                              <NavDropdown.Item
                                as={Link}
                                to={BOARD_MAKER_DRAFT_MODEL}
                                href={BOARD_MAKER_DRAFT_MODEL}
                              >
                                Upcoming Draft
                              </NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item
                                as={Link}
                                to={`/draft-class/${AppContext.latestDraftYear}`}
                                href={`/draft-class/${AppContext.latestDraftYear}`}
                              >
                                All Draft Classes
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={Link}
                                to="/scout-rankings"
                                href="/scout-rankings"
                              >
                                Pre-Draft Scout Rankings
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Restrict>
                          <Nav.Link as={Link} to="/video" href="/video">
                            Video
                          </Nav.Link>
                          <NavDropdown title="Reports" menuVariant="dark">
                            <NavDropdown.Item
                              as={Link}
                              to="/stats"
                              href="/stats"
                            >
                              Stat Leaders
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/stats/netImpact"
                              href="/stats/netImpact"
                            >
                              Impact
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to={`/play-calls/${CELTICS_TEAM_ID}`}
                              href={`/play-calls/${CELTICS_TEAM_ID}`}
                            >
                              Play Calls
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={`/reads`}
                              href={`/reads`}
                            >
                              Player Reads
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={`/substitutions/${CELTICS_TEAM_ID}`}
                              href={`/substitutions/${CELTICS_TEAM_ID}`}
                            >
                              Substitutions
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={`/wow?team=${CELTICS_TEAM_ID}`}
                              href={`/wow?team=${CELTICS_TEAM_ID}`}
                            >
                              With or Without
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to={`/lineups?team=${CELTICS_TEAM_ID}`}
                              href={`/lineups?team=${CELTICS_TEAM_ID}`}
                            >
                              Lineup Model
                            </NavDropdown.Item>
                            <Restrict roles={["bia", "scout", "executive"]}>
                              <ExternalLink
                                text="Season Sim"
                                url="https://cap.celticscloud.com/sim"
                              />
                            </Restrict>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to={`/top-performers`}
                              href={`/top-performers`}
                            >
                              Top Perfomers
                            </NavDropdown.Item>
                          </NavDropdown>
                          <NavDropdown title="Tools" menuVariant="dark">
                            <NavDropdown.Item
                              as={Link}
                              to="/board-maker"
                              href="/board-maker"
                            >
                              Board Maker
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/pnr" href="/pnr">
                              PNR Explorer
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/possessions"
                              href="/possessions"
                            >
                              Possession Explorer
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/shots"
                              href="/shots"
                            >
                              Shot Explorer
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/impact"
                              href="/impact"
                            >
                              Impact Explorer
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/skill"
                              href="/skill"
                            >
                              Skill Development
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/player-links"
                              href="/player-links"
                            >
                              Player Links
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/compare-players"
                              href="/compare-players"
                            >
                              Compare Players
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/player-search"
                              href="/player-search"
                            >
                              Player Search
                            </NavDropdown.Item>
                            <Restrict roles={["bia"]}>
                              <NavDropdown.Item
                                as={Link}
                                to={`/player-similarity`}
                                href={`/player-similarity`}
                              >
                                Player Similarity
                              </NavDropdown.Item>
                            </Restrict>
                            <Restrict roles={["bia"]}>
                              <NavDropdown.Item
                                as={Link}
                                to={`/player-matchups?teamId=${CELTICS_TEAM_ID}`}
                                href={`/player-matchups?teamId=${CELTICS_TEAM_ID}`}
                              >
                                Player Matchups
                              </NavDropdown.Item>
                            </Restrict>
                            <NavDropdown.Item
                              as={Link}
                              to={`/scouting`}
                              href={`/scouting`}
                            >
                              Scouting Guide
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/mapping"
                              href="/mapping"
                            >
                              Mapping
                            </NavDropdown.Item>
                          </NavDropdown>
                          <Nav.Link as={Link} to="/user" href="/user">
                            {user.email.split("@")[0]}
                          </Nav.Link>
                          <Restrict roles={["admin"]}>
                            <NavDropdown title="Admin" menuVariant="dark">
                              <ExternalLink
                                text="Log Rocket"
                                url="https://app.logrocket.com/sdwigt/bia"
                              />
                              <NavDropdown.Item
                                as={Link}
                                to={"/site-usage"}
                                href={"/site-usage"}
                              >
                                Site Usage
                              </NavDropdown.Item>
                              <NavDropdown.Divider />
                              <NavDropdown.Item
                                as={Link}
                                to="/users"
                                href="/user"
                              >
                                Users
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Restrict>
                        </Nav>
                      )}
                      <Nav className="ms-auto">
                        {/* On small screen hide this so it doesn't show in overflow.*/}
                        <SearchBar className="d-none d-lg-block d-xl-block" />
                        <Nav.Link
                          as={Link}
                          to="/"
                          onClick={handleLogoutClick}
                          href="/"
                        >
                          Logout
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  <TeamQuickSwitch />
                </header>
                <main>
                  <ErrorBoundary>
                    {user.group === "intern" ? (
                      <Routes>
                        <Route path="/search/:query" element={<SearchPage />} />
                        <Route
                          path="/standings/:leagueKey"
                          element={<StandingsPage />}
                        />
                        <Route path="/player/:id" element={<PlayerPage />} />
                        <Route path="/player" element={<PlayersPage />} />
                        <Route path="/mapping" element={<MappingPage />} />
                        <Route
                          path="/team/ml/:id"
                          element={<MultiLeagueTeamPage />}
                        />
                        <Route
                          path="/game/ml/:league/:id"
                          element={<GameMultiLeaguePage />}
                        />
                        <Route path="/error" element={<ErrorPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    ) : (
                      <Routes>
                        <Route path="/search/:query" element={<SearchPage />} />
                        <Route
                          path="/standings/:leagueKey"
                          element={<StandingsPage />}
                        />
                        <Route path="/team/:id" element={<TeamPage />} />
                        <Route
                          path="/team/ml/:id"
                          element={<MultiLeagueTeamPage />}
                        />
                        <Route path="/player/:id" element={<PlayerPage />} />
                        <Route path="/player" element={<PlayersPage />} />
                        <Route
                          path="/player-minutes/:gameId/:playerId"
                          element={<PlayerMinutesPage />}
                        />
                        <Route
                          path="/player-similarity"
                          element={<PlayerSimilarityPage />}
                        />
                        <Route path="/games/:date" element={<GamesPage />} />
                        <Route
                          path="/games"
                          element={
                            <Navigate
                              replace
                              to={`/games/${moment(new Date())
                                .subtract(1, "day")
                                .format("YYYY-MM-DD")}`}
                            />
                          }
                        />
                        <Route path="/game/:id" element={<GamePage />} />
                        <Route
                          path="/game/ml/g-league/:id"
                          element={<GameGLeaguePage />}
                        />
                        <Route
                          path="/game/ml/:league/:id"
                          element={<GameMultiLeaguePage />}
                        />
                        <Route
                          path="/preview/:id"
                          element={<GamePreviewPage />}
                        />
                        <Route path="/live/:id" element={<GameLivePage />} />
                        <Route path="/league" element={<LeaguePage />} />
                        <Route
                          path="/v/shot/:id"
                          element={<VideoProxyPage type={"shot"} />}
                        />
                        <Route
                          path="/v/chance/:id"
                          element={<VideoProxyPage type={"chance"} />}
                        />
                        <Route path="/video" element={<VideoPage />} />
                        <Route
                          path="/user"
                          element={<UserPage user={user} />}
                        />
                        {isAdminUser && (
                          <Route
                            path="/site-usage"
                            element={<SiteUsagePage />}
                          />
                        )}
                        {isAdminUser && (
                          <Route path="/users" element={<AdminPage />} />
                        )}
                        {isBiaScoutExec && (
                          <Route
                            path="/scout-rankings"
                            element={<ScoutRankingsPage />}
                          />
                        )}
                        {/* Routes for pages under reports. */}
                        <Route
                          path="/stats/:stat/:actionRole"
                          element={<StatsPage />}
                        />
                        <Route path="/stats/:stat" element={<StatsPage />} />
                        <Route
                          path="/stats"
                          element={<Navigate replace to={`/stats/ppg`} />}
                        />
                        <Route
                          path="/play-calls/:id"
                          element={<PlayCallPage />}
                        />
                        <Route path="/reads" element={<ReadsPage />} />
                        <Route
                          path="/substitutions/:id"
                          element={<SubstitutionsPage />}
                        />
                        <Route path="/wow" element={<WowPage />} />
                        <Route path="/lineups" element={<LineupsPage />} />
                        <Route
                          path="/draft-class/:year"
                          element={<DraftClassPage />}
                        />
                        <Route
                          path="/draft-board/:year"
                          element={<DraftBoardPage />}
                        />
                        <Route
                          path="/top-performers/:date"
                          element={<TopPerformersPage />}
                        />
                        <Route
                          path="/top-performers"
                          element={
                            <Navigate
                              replace
                              to={`/top-performers/${moment(new Date())
                                .subtract(1, "day")
                                .format("YYYY-MM-DD")}`}
                            />
                          }
                        />
                        <Route path="/bia-spm" element={<BiaSpmPage />} />

                        {/* Routes for pages under tools. */}
                        <Route
                          path="/board-maker"
                          element={<BoardMakerPage />}
                        />
                        <Route path="/pnr" element={<PnrExplorerPage />} />
                        <Route path="/pnr2" element={<PnrExplorerPage2 />} />
                        <Route
                          path="/possessions"
                          element={<PossessionExplorerPage />}
                        />
                        <Route path="/shots" element={<ShotExplorerPage />} />
                        <Route path="/shot/:id" element={<ShotDetailsPage />} />
                        <Route
                          path="/rebound/:id"
                          element={<ReboundDetailsPage />}
                        />
                        <Route
                          path="/impact"
                          element={<ImpactExplorerPage />}
                        />
                        <Route
                          path="/skill"
                          element={<SkillDevelopmentPage />}
                        />
                        <Route
                          path="/player-links"
                          element={<PlayerLinksPage />}
                        />
                        <Route
                          path="/compare-players"
                          element={<PlayerComparePage />}
                        />
                        <Route
                          path="/player-search"
                          element={<PlayerSearchPage />}
                        />
                        <Route
                          path="/player-matchups/"
                          element={<PlayerMatchupPage />}
                        />
                        <Route path="/scouting" element={<ScoutingPage />} />
                        <Route path="/series/:id" element={<SeriesPage />} />
                        <Route path="/mapping" element={<MappingPage />} />
                        <Route
                          path="/"
                          element={
                            <Navigate replace to={`/team/${CELTICS_TEAM_ID}`} />
                          }
                        />
                        <Route path="/ist" element={<ISTPage />} />
                        <Route path="/error" element={<ErrorPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    )}
                  </ErrorBoundary>
                </main>
                <Help visible={helpVisible} />
                <Footer></Footer>
              </PlayerStatusContext.Provider>
            </SSPlayerContext.Provider>
          </TeamContext.Provider>
        </UserPreferenceContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

function ExternalLink(props: { text: string; url: string }) {
  const { text, url } = props;
  return (
    <a
      // This class makes it behave like the React bootstrap nav items.
      className="dropdown-item"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <span style={{ display: "flex", alignItems: "center" }}>
        <span>{text}</span>
        <BiLinkExternal style={{ marginLeft: 4 }} />
      </span>
    </a>
  );
}

const BOARD_MAKER_DRAFT_MODEL = `board-maker?filters=%5B%7B"key"%3A"PlayerType"%2C"value"%3A%5B"Draft%202025"%5D%7D%2C%7B"key"%3A"hasAllData"%7D%5D&orderBy=peakImpactPred&selectedColumns=peakImpactPred_dxRank_Age_peakOffImpactPred_peakDefImpactPred_mostRecentTeam`;
const BOARD_MAKER_UPCOMING_FA = `board-maker?filters=%5B%7B%22key%22%3A%22PlayerType%22%2C%22value%22%3A%5B%22Trade%22%2C%22Celtics%22%5D%7D%2C%7B%22key%22%3A%22upcomingFa%22%2C%22value%22%3A%221%22%7D%5D&orderBy=estimatedSalary&selectedColumns=netImpact_currentSalary_estimatedSalary_Age_contractType`;
const BOARD_MAKER_2WAY_ELIGIBLE = `board-maker?filters=%5B%7B%22key%22%3A%22PlayerType%22%2C%22value%22%3A%5B%22Free%20Agent%22%5D%7D%2C%7B%22key%22%3A%22hasAllData%22%7D%2C%7B%22key%22%3A%22twoWayEligible%22%2C%22value%22%3A%221%22%7D%2C%7B%22key%22%3A%22playerLeagueType%22%2C%22value%22%3A%5B%22NBA%22%2C%22College%22%2C%22G-League%20%26%20U.S.%22%5D%7D%2C%7B%22key%22%3A%22playedLast6Mos%22%2C%22value%22%3A%221%22%7D%5D&selectedColumns=netImpact_Age_meanReg3PAPer100_mostRecentTeam`;
const BOARD_MAKER_AVAILABLE_FA = `board-maker?filters=%5B%7B"key"%3A"PlayerType"%2C"value"%3A%5B"Free%20Agent"%5D%7D%2C%7B"key"%3A"hasAllData"%7D%2C%7B"key"%3A"playerLeagueType"%2C"value"%3A%5B"G-League%20%26%20U.S."%2C"College"%2C"NBA"%5D%7D%5D&selectedColumns=netImpact_Age`;
const BOARD_MAKER_GLOBAL_FA = `board-maker?filters=%5B%7B%22key%22%3A%22PlayerType%22%2C%22value%22%3A%5B%22Free%20Agent%22%2C%22Rights%20Held%22%5D%7D%2C%7B%22key%22%3A%22hasAllData%22%7D%2C%7B%22key%22%3A%22Age%22%2C%22value%22%3A%7B%22lte%22%3A%2245%22%7D%7D%5D&selectedColumns=netImpact_Age_peakImpactPred_mostRecentLeague_meanReg3PAPer100`;
const BOARD_MAKER_DRAFT_RIGHTS_HELD = `board-maker?filters=%5B%7B%22key%22%3A%22PlayerType%22%2C%22value%22%3A%5B%22Rights%20Held%22%5D%7D%5D&selectedColumns=netImpact_peakImpactPred_Age_nbaRights`;

export default App;
