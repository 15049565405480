/**
 * Given an array and a key function returns an object whose keys correspond to
 * the result of the keyFunction and the values are arrays of values who
 * evaluate to the same key value when pased to the key function.
 */
export function groupBy<T>(array: T[], keyFunction: (el: T) => string) {
  const groups: { [key: string]: T[] } = {};
  array.forEach((el) => {
    const key = keyFunction(el);
    if (key in groups === false) {
      groups[key] = [];
    }
    const groupArr = groups[key];
    if (groupArr) {
      groupArr.push(el);
    }
  });
  return groups;
}

/**
 * Works like groupBy but with two keyFunctions.
 * e.g. deepGroupBy(arr, (a) => a['offDef'], (a) => a['statType']) =>
 * { off: cutter, screener, def: cutter, screener }.
 */
export function groupBy2<T>(
  array: T[],
  keyFunction: (el: T) => string,
  keyFunction2: (el: T) => string
) {
  const groups: Record<string, Record<string, T[]>> = {};
  array.forEach((el) => {
    const key1 = keyFunction(el);
    const key2 = keyFunction2(el);

    if (key1 in groups === false) {
      groups[key1] = {};
    }
    const group1Obj = groups[key1];
    if (!group1Obj) return;

    if (key2 in group1Obj === false) {
      group1Obj[key2] = [];
    }
    const key1key2Arr = group1Obj[key2];
    if (!key1key2Arr) return;
    key1key2Arr.push(el);
  });
  return groups;
}

/**
 * Given an object, returns a shallow copy with every key in the keys array
 * ommitted.
 */
export function omit(
  obj: Record<string, unknown>,
  keys: string[]
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(obj).filter(
      (val: [string, unknown]) => !keys.includes(val[0])
    )
  );
}

export function reduceArrayToObject<T>(
  array: T[],
  getKey: (item: T) => string
): { [key: string]: T } {
  return array.reduce((acc: { [key: string]: T }, currentItem: T) => {
    const key = getKey(currentItem);
    acc[key] = currentItem;
    return acc;
  }, {});
}

// Shuffles using fisher-yates algorithm.
export function shuffle<T>(array: T[]): T[] {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const vali = arrayCopy[i];
    const valj = arrayCopy[j];
    if (vali !== undefined && valj !== undefined) {
      [arrayCopy[i], arrayCopy[j]] = [valj, vali];
    }
  }
  return arrayCopy;
}
