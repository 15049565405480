import React, { useMemo, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";

import { Modal } from "../components/core/Modal";
import { Table, createColumnHelper } from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { PlayerLink } from "../../shared/routers/PlayerLinkRouter";
import { MultiSelect, MultiSelectOption } from "../components/core/MultiSelect";
import { PlayerTableCell } from "../components/core/TableCell";
import { trpc } from "../util/tRPC";

const columnHelper = createColumnHelper<PlayerLink>();

export function PlayerLinksPage() {
  const utils = trpc.useContext();

  const [showCreate, setShowCreate] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number>();
  const [idToEdit, setIdToEdit] = useState<number>();

  const [errMsg, setErrMsg] = useState<string>();
  const [url, setUrl] = useState<string>("");
  const [linkName, setLinkName] = useState<string>("");
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[]>([]);

  const [playerSearchQuery, setPlayerSearchQuery] = useState("");
  // Bit of a hack to get around the fact that after selecting an item, new searches
  // cause use to fetch new data and we throw away old search results. The multiselect
  // UI will break b/c it can only render the selected items if it has in its list
  // of options. When the search query changes these no longer exist in the list
  // of items so we just save everything selected in this session here and tack it
  // on to the end of the values passed to multiselect.
  const [prevSelectedOptions, setPrevSelectedOptions] = useState<
    MultiSelectOption[]
  >([]);

  const { data: links } = trpc.playerLink.getAllPlayerLinks.useQuery();

  const successCommon = () => {
    setErrMsg("");
    setUrl("");
    setLinkName("");
    setSelectedPlayerIds([]);
    utils.playerLink.invalidate();
    utils.playerLink.getPlayerLinks.refetch();
  };

  const createLinkMutation = trpc.playerLink.createPlayerLink.useMutation({
    onSuccess: () => {
      setShowCreate(false);
      successCommon();
    },
    onError: () => {
      setShowCreate(false);
      setErrMsg("Unable to create player link");
    },
  });

  const deleteLinkMutation = trpc.playerLink.deletePlayerLink.useMutation({
    onSuccess: () => {
      setIdToDelete(undefined);
      successCommon();
    },
    onError: () => {
      setIdToDelete(undefined);
      setErrMsg("Unable to delete player link");
    },
  });

  const editLinkMutation = trpc.playerLink.editPlayerLink.useMutation({
    onSuccess: () => {
      setIdToEdit(undefined);
      successCommon();
    },
    onError: () => {
      setIdToEdit(undefined);
      setErrMsg("Unable to edit player link");
    },
  });

  const { data: autoCompletePlayers } =
    trpc.search.getVideoSearchResults.useQuery({
      searchQuery: playerSearchQuery,
    });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "edit",
        header: "Edit",
        cell: (info) => (
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                padding: 3,
                fontSize: ".7em",
                lineHeight: 0,
              }}
              onClick={() => {
                setIdToEdit(info.row.original.id);
                setUrl(info.row.original.url);
                setLinkName(info.row.original.name);
                setSelectedPlayerIds(info.row.original.playerIds.split(", "));
              }}
            >
              <FaPen />
            </Button>
          </div>
        ),
        meta: { group: 0 },
      }),
      columnHelper.display({
        id: "delete",
        header: "Delete",
        cell: (info) => (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="danger"
              onClick={() => setIdToDelete(info.row.original.id)}
              style={{
                padding: 3,
                fontSize: ".7em",
                lineHeight: 0,
              }}
            >
              <FaTrash />
            </Button>
          </div>
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("name", {
        header: () => "Link",
        cell: (info) => (
          <div
            style={{
              width: 250,
              whiteSpace: "normal",
            }}
          >
            <a href={info.row.original.url} target="_blank" rel="noreferrer">
              {info.getValue()}
            </a>
          </div>
        ),
        meta: { group: 2, textAlign: "left" },
      }),
      columnHelper.accessor("playerNames", {
        header: () => "Players",
        cell: (info) => (
          <div
            style={{
              whiteSpace: "normal",
            }}
          >
            {info
              .getValue()
              .split(",")
              .map((p: string, i: number) => (
                <PlayerTableCell
                  key={i}
                  name={p}
                  id={info.row.original.playerIds.split(",")[i]}
                  useSpans={true}
                  showPlayerStatusIcons={false}
                />
              ))}
          </div>
        ),
        meta: { group: 3, textAlign: "left" },
      }),
    ],
    []
  );

  if (!links) return null;

  const fetchedPlayerIds = autoCompletePlayers
    ? new Set(autoCompletePlayers.map((p) => p.playerId.toString()))
    : new Set();

  if (!autoCompletePlayers) return null;

  const form = (
    <div>
      <Form.Label>Link Name</Form.Label>
      <Form.Control
        value={linkName}
        onChange={(e) => setLinkName(e.target.value)}
      ></Form.Control>
      <Form.Label>Players</Form.Label>
      <MultiSelect
        values={autoCompletePlayers
          .map((p) => {
            return {
              value: p.playerId.toString(),
              label: p.playerName,
              aux1: [p.college, p.birthYear].filter((a) => !!a).join(", "),
              aux2: "",
            } as MultiSelectOption;
          })
          .concat(
            prevSelectedOptions.filter((o) => !fetchedPlayerIds.has(o.value))
          )}
        selected={selectedPlayerIds}
        onTextChange={(text) => setPlayerSearchQuery(text)}
        onChange={(vals, _, option) => {
          if (
            option !== undefined &&
            !prevSelectedOptions.map((o) => o.value).includes(option.value)
          ) {
            setPrevSelectedOptions([...prevSelectedOptions, option]);
          }
          if (vals.length === 0) {
            // When we remove the selection also clear the search query.
            setPlayerSearchQuery("");
          }
          setSelectedPlayerIds(vals);
        }}
      />
      <Form.Label>Url</Form.Label>
      <Form.Control
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      ></Form.Control>
    </div>
  );

  return (
    <Page header={{ text: "Player Links" }} title="Player Links">
      <div>
        <Panel header={"Links"}>
          <div>
            {errMsg && (
              <Alert variant="danger">
                {`Something went wrong: ${errMsg}! Please contact chrisbu@celtics.com`}
              </Alert>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <b>{`Found ${links.length} Links`}</b>
              <Button
                onClick={() => {
                  setShowCreate(true);
                  setUrl("");
                  setLinkName("");
                  setSelectedPlayerIds([]);
                }}
              >
                Create New
              </Button>
            </div>
            <Table
              data={links}
              columns={columns}
              showRowIndex={false}
              disableStickyColumn={true}
            />
          </div>
        </Panel>
        <Modal
          title={"Create"}
          content={form}
          show={showCreate}
          disableConfirm={!(url && linkName)}
          handleClose={() => setShowCreate(false)}
          handleConfirm={() => {
            createLinkMutation.mutate({
              playerIds: selectedPlayerIds,
              linkName,
              url,
            });
          }}
        />
        <Modal
          title={"Delete"}
          content={"Are you sure you want to delete this?"}
          show={!!idToDelete}
          handleClose={() => setIdToDelete(undefined)}
          handleConfirm={() => {
            if (!idToDelete) return;
            deleteLinkMutation.mutate({ linkId: idToDelete });
          }}
        />
        <Modal
          title={"Edit"}
          content={form}
          show={!!idToEdit}
          handleClose={() => setIdToEdit(undefined)}
          handleConfirm={() => {
            const oldRef = links.find((l) => l.id === idToEdit);
            if (!oldRef || !idToEdit) return;
            const prevPlayerIds = oldRef.playerIds.split(", ");
            editLinkMutation.mutate({
              linkId: idToEdit,
              playerIdsAdd: selectedPlayerIds.filter(
                (id) => !prevPlayerIds.includes(id)
              ),
              playerIdsRemove: prevPlayerIds.filter(
                (id) => !selectedPlayerIds.includes(id)
              ),
              linkName,
              url,
            });
          }}
        />
      </div>
    </Page>
  );
}
