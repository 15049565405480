import React from "react";

export function PageHeader(props: { component?: JSX.Element; text?: string }) {
  return (
    <header
      style={{
        background: "#272831",
        color: "#fff",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: "none",
        marginBottom: 20,
      }}
    >
      {props.component ? props.component : <h1>{props.text}</h1>}
    </header>
  );
}
