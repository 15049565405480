import React from "react";
import { Col, Row } from "react-bootstrap";

import { Shot } from "../../../shared/routers/ShotRouter";
import { StatComparisonTableBars } from "../../components/table/StatComparisonTableBars";
import { decFormat, decFormat2, makePlusMinus } from "../../util/Format";

export function GameTeamShootingLuckBreakdown(props: {
  data: Shot[];
  home: { teamid: number; teamabbreviation: string };
  away: { teamid: number; teamabbreviation: string };
  homeFTPct: number | null;
  awayFTPct: number | null;
}) {
  const { home, away, data, homeFTPct, awayFTPct } = props;
  const homeTeamId = home.teamid;
  const homeTeamAbbreviation = home.teamabbreviation;
  const awayTeamId = away.teamid;
  const awayTeamAbbreviation = away.teamabbreviation;
  const pointsDomain = [0, 120];

  const homeShots = data.filter((s) => s.oteamId === homeTeamId);
  const homeThrees = homeShots.filter((s) => s.isThree === true);
  const homeLayups = homeShots.filter(
    (s) =>
      s.generalShotType === "layup" && s.isThree === false && s.isDunk === false
  );
  const homeDunks = homeShots.filter((s) => s.isDunk === true);
  const homeTwoNonLayups = homeShots.filter(
    (s) => s.generalShotType !== "layup" && s.isThree === false
  );

  const awayShots = data.filter((s) => s.oteamId === awayTeamId);
  const awayThrees = awayShots.filter((s) => s.isThree === true);
  const awayLayups = awayShots.filter(
    (s) =>
      s.generalShotType === "layup" && s.isThree === false && s.isDunk === false
  );
  const awayDunks = awayShots.filter((s) => s.isDunk === true);
  const awayTwoNonLayups = awayShots.filter(
    (s) => s.generalShotType !== "layup" && s.isThree === false
  );

  const groups = [
    [
      {
        label: "All Shots",
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeShots.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayShots.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeShots.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayShots.reduce((prev, cur) => prev + cur.epps, 0),
        },
        additionalColumns: {
          numShots: {
            format: (x: number | null) => (x ? x.toString() : ""),
            values: {
              home: homeShots.length,
              away: awayShots.length,
            },
          },
          exftper: {
            format: decFormat,
            values: {
              home:
                (homeShots.reduce((prev, cur) => prev + cur.FTpct, 0) /
                  homeShots.length) *
                100,
              away:
                (awayShots.reduce((prev, cur) => prev + cur.FTpct, 0) /
                  awayShots.length) *
                100,
            },
          },
          ftper: {
            format: decFormat,
            values: {
              home: homeFTPct,
              away: awayFTPct,
            },
          },
          PPS: {
            format: decFormat2,
            values: {
              home: homeShots.length
                ? homeShots.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeShots.length
                : 0,
              away: awayShots.length
                ? awayShots.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayShots.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeShots.length
                ? homeShots.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeShots.length
                : 0,
              away: awayShots.length
                ? awayShots.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayShots.length
                : 0,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeShots.reduce((prev, cur) => prev + cur.pps, 0) -
                homeShots.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayShots.reduce((prev, cur) => prev + cur.pps, 0) -
                awayShots.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
          eftr: {
            format: decFormat2,
            values: {
              home: homeShots
                ? homeShots.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / homeShots.length
                : homeShots,
              away: awayShots
                ? awayShots.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / awayShots.length
                : awayShots,
            },
          },
          ftr: {
            format: decFormat2,
            values: {
              home: homeShots
                ? homeShots.filter((s) => s.fouled).length / homeShots.length
                : 0,
              away: awayShots
                ? awayShots.filter((s) => s.fouled).length / awayShots.length
                : 0,
            },
          },
          efgper: {
            format: decFormat,
            values: {
              home: homeShots.length
                ? avgNumArray(homeShots.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
              away: awayShots.length
                ? avgNumArray(awayShots.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
            },
          },
          fgper: {
            format: decFormat,
            values: {
              home:
                (homeShots.filter((s) => s.made).length / homeShots.length) *
                100,
              away:
                (awayShots.filter((s) => s.made).length / awayShots.length) *
                100,
            },
          },
        },
      },
      {
        label: "2PA - Dunks",
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeDunks.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayDunks.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeDunks.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayDunks.reduce((prev, cur) => prev + cur.epps, 0),
        },
        additionalColumns: {
          numShots: {
            format: (x: number | null) => (x ? x.toString() : ""),
            values: {
              home: homeDunks.length,
              away: awayDunks.length,
            },
          },
          exftper: {
            format: decFormat,
            values: {
              home: null,
              away: null,
            },
          },
          PPS: {
            format: decFormat2,
            values: {
              home: homeDunks.length
                ? homeDunks.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeDunks.length
                : 0,
              away: awayDunks.length
                ? awayDunks.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayDunks.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeDunks.length
                ? homeDunks.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeDunks.length
                : 0,
              away: awayDunks.length
                ? awayDunks.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayDunks.length
                : 0,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeDunks.reduce((prev, cur) => prev + cur.pps, 0) -
                homeDunks.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayDunks.reduce((prev, cur) => prev + cur.pps, 0) -
                awayDunks.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
          ftper: {
            format: decFormat2,
            values: {
              home: null,
              away: null,
            },
          },
          eftr: {
            format: decFormat2,
            values: {
              home: homeDunks
                ? homeDunks.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / homeDunks.length
                : 0,
              away: awayDunks
                ? awayDunks.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / awayDunks.length
                : 0,
            },
          },
          ftr: {
            format: decFormat2,
            values: {
              home: homeDunks
                ? homeLayups.filter((s) => s.fouled).length / homeDunks.length
                : 0,
              away: awayDunks
                ? awayDunks.filter((s) => s.fouled).length / awayDunks.length
                : 0,
            },
          },
          efgper: {
            format: decFormat,
            values: {
              home: homeDunks.length
                ? avgNumArray(homeDunks.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
              away: awayDunks.length
                ? avgNumArray(awayDunks.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
            },
          },
          fgper: {
            format: decFormat,
            values: {
              home:
                (homeDunks.filter((s) => s.made).length / homeDunks.length) *
                100,
              away:
                (awayDunks.filter((s) => s.made).length / awayDunks.length) *
                100,
            },
          },
        },
      },
      {
        label: "2PA - Layups",
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeLayups.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayLayups.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeLayups.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayLayups.reduce((prev, cur) => prev + cur.epps, 0),
        },
        additionalColumns: {
          numShots: {
            format: (x: number | null) => (x ? x.toString() : ""),
            values: {
              home: homeLayups.length,
              away: awayLayups.length,
            },
          },
          exftper: {
            format: decFormat,
            values: {
              home: null,
              away: null,
            },
          },
          PPS: {
            format: decFormat2,
            values: {
              home: homeLayups.length
                ? homeLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeLayups.length
                : 0,
              away: awayLayups.length
                ? awayLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayLayups.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeLayups.length
                ? homeLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeLayups.length
                : 0,
              away: awayLayups.length
                ? awayLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayLayups.length
                : 0,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                homeLayups.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                awayLayups.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
          ftper: {
            format: decFormat2,
            values: {
              home: null,
              away: null,
            },
          },
          eftr: {
            format: decFormat2,
            values: {
              home: homeLayups
                ? homeLayups.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / homeLayups.length
                : 0,
              away: awayLayups
                ? awayLayups.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / awayLayups.length
                : 0,
            },
          },
          ftr: {
            format: decFormat2,
            values: {
              home: homeLayups
                ? homeLayups.filter((s) => s.fouled).length / homeLayups.length
                : 0,
              away: awayLayups
                ? awayLayups.filter((s) => s.fouled).length / awayLayups.length
                : 0,
            },
          },
          efgper: {
            format: decFormat,
            values: {
              home: homeLayups.length
                ? avgNumArray(homeLayups.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
              away: awayLayups.length
                ? avgNumArray(awayLayups.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
            },
          },
          fgper: {
            format: decFormat,
            values: {
              home:
                (homeLayups.filter((s) => s.made).length / homeLayups.length) *
                100,
              away:
                (awayLayups.filter((s) => s.made).length / awayLayups.length) *
                100,
            },
          },
        },
      },
      {
        label: "2PA - Non-Layups",
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
        },
        additionalColumns: {
          numShots: {
            format: (x: number | null) => (x ? x.toString() : ""),
            values: {
              home: homeTwoNonLayups.length,
              away: awayTwoNonLayups.length,
            },
          },
          exftper: {
            format: decFormat,
            values: {
              home: null,
              away: null,
            },
          },
          ftper: {
            format: decFormat2,
            values: {
              home: null,
              away: null,
            },
          },
          PPS: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups.length
                ? homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups.length
                ? awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayTwoNonLayups.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups.length
                ? homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups.length
                ? awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayTwoNonLayups.length
                : 0,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                homeTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayTwoNonLayups.reduce((prev, cur) => prev + cur.pps, 0) -
                awayTwoNonLayups.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
          eftr: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups
                ? homeTwoNonLayups.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups
                ? awayTwoNonLayups.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / awayTwoNonLayups.length
                : 0,
            },
          },
          ftr: {
            format: decFormat2,
            values: {
              home: homeTwoNonLayups
                ? homeTwoNonLayups.filter((s) => s.fouled).length /
                  homeTwoNonLayups.length
                : 0,
              away: awayTwoNonLayups
                ? awayTwoNonLayups.filter((s) => s.fouled).length /
                  awayTwoNonLayups.length
                : 0,
            },
          },
          efgper: {
            format: decFormat,
            values: {
              home: homeTwoNonLayups.length
                ? avgNumArray(
                    homeTwoNonLayups.map((s) => s.typeDefenderPlayerMake)
                  ) * 100
                : 0,
              away: awayTwoNonLayups.length
                ? avgNumArray(
                    awayTwoNonLayups.map((s) => s.typeDefenderPlayerMake)
                  ) * 100
                : 0,
            },
          },
          fgper: {
            format: decFormat,
            values: {
              home:
                (homeTwoNonLayups.filter((s) => s.made).length /
                  homeTwoNonLayups.length) *
                100,
              away:
                (awayTwoNonLayups.filter((s) => s.made).length /
                  awayTwoNonLayups.length) *
                100,
            },
          },
        },
      },
      {
        label: "3PA",
        format: decFormat2,
        domain: pointsDomain,
        values: {
          home: homeThrees.reduce((prev, cur) => prev + cur.pps, 0),
          away: awayThrees.reduce((prev, cur) => prev + cur.pps, 0),
        },
        compare: {
          home: homeThrees.reduce((prev, cur) => prev + cur.epps, 0),
          away: awayThrees.reduce((prev, cur) => prev + cur.epps, 0),
        },
        additionalColumns: {
          numShots: {
            format: (x: number | null) => (x ? x.toString() : ""),
            values: {
              home: homeThrees.length,
              away: awayThrees.length,
            },
          },
          exftper: {
            format: decFormat2,
            values: {
              home: null,
              away: null,
            },
          },
          ftper: {
            format: decFormat2,
            values: {
              home: null,
              away: null,
            },
          },
          PPS: {
            format: decFormat2,
            values: {
              home: homeThrees.length
                ? homeThrees.reduce((prev, cur) => prev + cur.pps, 0) /
                  homeThrees.length
                : 0,
              away: awayThrees.length
                ? awayThrees.reduce((prev, cur) => prev + cur.pps, 0) /
                  awayThrees.length
                : 0,
            },
          },
          ePPS: {
            format: decFormat2,
            values: {
              home: homeThrees.length
                ? homeThrees.reduce((prev, cur) => prev + cur.epps, 0) /
                  homeThrees.length
                : 0,
              away: awayThrees.length
                ? awayThrees.reduce((prev, cur) => prev + cur.epps, 0) /
                  awayThrees.length
                : 0,
            },
          },
          differential: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeThrees.reduce((prev, cur) => prev + cur.pps, 0) -
                homeThrees.reduce((prev, cur) => prev + cur.epps, 0),
              away:
                awayThrees.reduce((prev, cur) => prev + cur.pps, 0) -
                awayThrees.reduce((prev, cur) => prev + cur.epps, 0),
            },
          },
          eftr: {
            format: decFormat2,
            values: {
              home: homeThrees
                ? homeThrees.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / homeThrees.length
                : 0,
              away: awayThrees
                ? awayThrees.reduce(
                    (prev, cur) => prev + cur.typeDefenderPlayerFoul,
                    0
                  ) / awayThrees.length
                : 0,
            },
          },
          ftr: {
            format: decFormat2,
            values: {
              home: homeThrees
                ? homeThrees.filter((s) => s.fouled).length / homeThrees.length
                : 0,
              away: awayThrees
                ? awayThrees.filter((s) => s.fouled).length / awayThrees.length
                : 0,
            },
          },
          efgper: {
            format: decFormat,
            values: {
              home: homeThrees.length
                ? avgNumArray(homeThrees.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
              away: awayThrees.length
                ? avgNumArray(awayThrees.map((s) => s.typeDefenderPlayerMake)) *
                  100
                : 0,
            },
          },
          fgper: {
            format: decFormat,
            values: {
              home:
                (homeThrees.filter((s) => s.made).length / homeThrees.length) *
                100,
              away:
                (awayThrees.filter((s) => s.made).length / awayThrees.length) *
                100,
            },
          },
        },
      },
    ],
  ];

  const additionalColumns = {
    numShots: {
      width: 95,
      label: "#",
      order: 1,
    },
    exftper: {
      width: 75,
      label: "xFT%",
      order: 2,
    },
    ftper: {
      width: 75,
      label: "FT%",
      order: 3,
    },
    eftr: {
      width: 75,
      label: "xFTr",
      order: 4,
    },
    ftr: {
      width: 75,
      label: "FTr",
      order: 5,
    },
    efgper: {
      width: 75,
      label: "xMake%",
      order: 6,
    },
    fgper: {
      width: 75,
      label: "Make%",
      order: 7,
    },
    ePPS: {
      width: 75,
      label: "xPPS",
      order: 8,
    },
    PPS: {
      width: 75,
      label: "PPS",
      order: 9,
    },
    differential: {
      width: 75,
      label: "Diff.",
      order: 10,
    },
  };

  return (
    <Row>
      {groups.map((group, i) => {
        return (
          <Col key={i} md={12}>
            <StatComparisonTableBars
              fitExtent={false}
              labels={{
                home: homeTeamAbbreviation,
                away: awayTeamAbbreviation,
              }}
              data={group}
              additionalColumns={additionalColumns}
              teams={{
                home: homeTeamId,
                away: awayTeamId,
              }}
              groupColumnLabel={"Team"}
              valueColumnLabel={"Points"}
              hideValuesColumn={true}
              hideBars={true}
              columnWidths={[115, 50]}
              noBestColVal={true}
            />
          </Col>
        );
      })}
    </Row>
  );
}

function avgNumArray(arr: number[]) {
  return arr.reduce((prev, cur) => prev + cur, 0) / arr.length;
}
