import React, { useMemo, useState } from "react";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import { GameAttackAvoid } from "../../../shared/routers/GameRouter";
import { groupBy } from "../../../shared/util/Collections";
import { dec100Format, decFormat, plusMinusFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { scaleLinear } from "d3";
import { PlayerTableCell } from "../core/TableCell";

interface GameAttackAvoidRow {
  def_player: string;
  n: number;
  n_total: number;
  total_time: number;
  n_pnr_bhr: number;
  pnr_bhr_time: number;
  pnr_bhr_ability: number;
  n_pnr_scr: number;
  pnr_scr_time: number;
  pnr_scr_ability: number;
  n_one_on_one: number;
  one_on_one_time: number;
  one_on_one_ability: number;
  n_post_post: number;
  post_post_time: number;
  post_post_ability: number;
  n_off_ball: number;
  off_ball_time: number;
  off_ball_ability: number;
}

export function GameAttackAvoidTables(props: { data?: GameAttackAvoid[] }) {
  const { data } = props;
  if (!data) return null;

  const dataByTeam = groupBy(data, (row) => row.off_team);

  return (
    <div>
      {Object.keys(dataByTeam).map((t) => (
        <div key={t}>
          <div>{t}</div>
          <AttackAvoidTable data={dataByTeam[t] || []} />
        </div>
      ))}
    </div>
  );
}

const columnHelper = createColumnHelper<GameAttackAvoidRow>();

function AttackAvoidTable(props: { data: GameAttackAvoid[] }) {
  const [sorting, setSorting] = useState<SortingState>();

  const data = props.data.map(normalizeAbilities);

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("def_player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("n", {
        header: () => "# Poss",
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.group({
        id: "total",
        meta: { group: g },
        header: "All",
        columns: [
          columnHelper.accessor("n_total", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("total_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          // columnHelper.accessor((row) => attackAvoidScore(row), {
          //   id: "score",
          //   header: () => "Score",
          //   cell: (info) => dec100Format(info.getValue()),
          //   meta: { group: g++, colorDomain: [0.25, 0.75], heatmap: true },
          // }),
          columnHelper.accessor((row) => row.n_total / row.n, {
            id: "pctInvolved",
            header: () => "% Involved",
            cell: (info) => dec100Format(info.getValue()),
            meta: { group: g++, colorDomain: [0.1, 0.5], heatmap: true },
          }),
        ],
      }),
      columnHelper.group({
        id: "pnrBhr",
        meta: { group: g },
        header: "PNR Ballhandler",
        columns: [
          columnHelper.accessor("n_pnr_bhr", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("pnr_bhr_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("pnr_bhr_ability", {
            header: () => "Ability",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              group: g,
              // colorDomain: [3, -3],
              // heatmap: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              attackAvoidScoreForAction(
                row,
                row.n_pnr_bhr,
                row.pnr_bhr_ability
              ),
            {
              id: "pnrBhrScore",
              header: () => "Score",
              cell: (info) => dec100Format(info.getValue()),
              meta: {
                highlights: Highlights.Min,
                group: g++,
                colorDomain: [0.25, 0.75],
                heatmap: true,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "pnrScr",
        meta: { group: g },
        header: "PNR Screener",
        columns: [
          columnHelper.accessor("n_pnr_scr", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("pnr_scr_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("pnr_scr_ability", {
            header: () => "Ability",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              group: g,
              // colorDomain: [3, -3],
              // heatmap: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              attackAvoidScoreForAction(
                row,
                row.n_pnr_scr,
                row.pnr_scr_ability
              ),
            {
              id: "pnrScrScore",
              header: () => "Score",
              cell: (info) => dec100Format(info.getValue()),
              meta: {
                highlights: Highlights.Min,
                group: g++,
                colorDomain: [0.25, 0.75],
                heatmap: true,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "oneOnOne",
        meta: { group: g },
        header: "1 on 1",
        columns: [
          columnHelper.accessor("n_one_on_one", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("one_on_one_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("one_on_one_ability", {
            header: () => "Ability",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              group: g,
              // colorDomain: [3, -3],
              // heatmap: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              attackAvoidScoreForAction(
                row,
                row.n_one_on_one,
                row.one_on_one_ability
              ),
            {
              id: "oneOnOneScore",
              header: () => "Score",
              cell: (info) => dec100Format(info.getValue()),
              meta: {
                highlights: Highlights.Min,
                group: g++,
                colorDomain: [0.25, 0.75],
                heatmap: true,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "post",
        meta: { group: g },
        header: "Post",
        columns: [
          columnHelper.accessor("n_post_post", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("post_post_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("post_post_ability", {
            header: () => "Ability",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              group: g,
              // colorDomain: [3, -3],
              // heatmap: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              attackAvoidScoreForAction(
                row,
                row.n_post_post,
                row.post_post_ability
              ),
            {
              id: "postScore",
              header: () => "Score",
              cell: (info) => dec100Format(info.getValue()),
              meta: {
                highlights: Highlights.Min,
                group: g++,
                colorDomain: [0.25, 0.75],
                heatmap: true,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "offBall",
        meta: { group: g },
        header: "Off Ball",
        columns: [
          columnHelper.accessor("n_off_ball", {
            header: () => "#",
            cell: (info) => info.getValue(),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("off_ball_time", {
            header: () => "Avg Time",
            cell: (info) =>
              info.getValue() ? decFormat(info.getValue()) : "-",
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelper.accessor("off_ball_ability", {
            header: () => "Ability",
            cell: (info) => plusMinusFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              group: g,
              // colorDomain: [3, -3],
              //heatmap: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              attackAvoidScoreForAction(
                row,
                row.n_off_ball,
                row.off_ball_ability
              ),
            {
              id: "offBallScore",
              header: () => "Score",
              cell: (info) => dec100Format(info.getValue()),
              meta: {
                highlights: Highlights.Min,
                group: g++,
                colorDomain: [0.25, 0.75],
                heatmap: true,
              },
            }
          ),
        ],
      }),
    ];
  }, []);
  return (
    <Table
      data={data.sort((a, b) => b.n - a.n)}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showRowIndex={false}
      autoWidth={true}
    />
  );
}

function attackAvoidScoreForAction(
  row: GameAttackAvoidRow,
  timesInAction: number,
  actionAbility: number | null
) {
  // Avoid situation where a guy was attacked more times than number of
  // possessions played.
  const totalPoss = Math.max(
    row.n,
    row.n_pnr_bhr +
      row.n_pnr_scr +
      row.n_one_on_one +
      row.n_post_post +
      row.n_off_ball
  );

  const xAttacks = totalPoss * 0.2;
  const xAttacksPerAction = xAttacks / 5;
  const clampThreshold = 2;

  const clamp = (val: number) => {
    return Math.max(Math.min(val, clampThreshold), -clampThreshold);
  };

  const ability = clamp(actionAbility || 0);

  const xScore = ability * xAttacksPerAction;
  const actualScore = ability * timesInAction;
  const alwaysInActionScore = ability * totalPoss;

  const minScore = Math.min(0, alwaysInActionScore);
  const maxScore = Math.max(0, alwaysInActionScore);

  const scale = scaleLinear()
    .domain([minScore, xScore, maxScore])
    .range([0, 0.5, 1]);

  return 1 - scale(actualScore);
}

function normalizeAbilities(row: GameAttackAvoid): GameAttackAvoidRow {
  const n_one_on_one = row.n_iso_iso + row.n_cag_driver;
  const iso_ability = zScore(row.iso_iso_defender, "iso_iso_defender");
  const cag_ability = zScore(
    row.catch_and_go_drive_drive_defender,
    "catch_and_go_drive_drive_defender"
  );

  const n_off_ball = row.n_ros_ros + row.n_cut_cutter;
  const ros_ability = zScore(
    row.run_off_screen_run_off_screen_defender,
    "run_off_screen_run_off_screen_defender"
  );
  const cut_ability = zScore(row.cut_cutter_defender, "cut_cutter_defender");

  return {
    def_player: row.def_player,
    n: row.n,
    n_total: row.n_total,
    total_time: row.total_time || 0,
    n_pnr_bhr: row.n_pnr_bhr,
    pnr_bhr_time: row.pnr_bhr_time || 0,
    pnr_bhr_ability: zScore(
      row.PNR_ballhandler_defender,
      "PNR_ballhandler_defender"
    ),
    n_pnr_scr: row.n_pnr_scr,
    pnr_scr_time: row.pnr_scr_time || 0,
    pnr_scr_ability: zScore(
      row.PNR_screener_defender_switch,
      "PNR_screener_defender_switch"
    ),

    n_one_on_one,
    one_on_one_time:
      n_one_on_one === 0
        ? 0
        : ((row.iso_iso_time || 0) * row.n_iso_iso +
            (row.cag_driver_time || 0) * row.n_cag_driver) /
          n_one_on_one,
    one_on_one_ability:
      n_one_on_one === 0
        ? (iso_ability + cag_ability) / 2
        : (iso_ability * row.n_iso_iso + cag_ability * row.n_cag_driver) /
          n_one_on_one,
    n_post_post: row.n_post_post,
    post_post_time: row.post_post_time || 0,
    post_post_ability: zScore(row.post_post_defender, "post_post_defender"),
    n_off_ball,
    off_ball_time:
      n_off_ball === 0
        ? 0
        : ((row.ros_ros_time || 0) * row.n_ros_ros +
            (row.cut_cutter_time || 0) * row.n_cut_cutter) /
          n_off_ball,
    off_ball_ability:
      n_off_ball === 0
        ? (ros_ability + cut_ability) / 2
        : (ros_ability * row.n_ros_ros + cut_ability * row.n_cut_cutter) /
          n_off_ball,
  };
}

function zScore(val: number | null, stat: string) {
  const leagueVal = leagueVals[stat];
  if (val === null || leagueVal === undefined) return 0;
  return (val - leagueVal.median) / leagueVal.std;
}

const leagueVals: Record<string, { median: number; std: number }> = {
  PNR_ballhandler_defender: {
    median: -0.0004465489576,
    std: 0.004712062217,
  },
  PNR_screener_defender_switch: {
    median: -0.000290218675,
    std: 0.003526343227,
  },
  iso_iso_defender: {
    median: -0.004044971943,
    std: 0.01407813767,
  },
  post_post_defender: {
    median: -0.0082494168,
    std: 0.03464976456,
  },
  run_off_screen_run_off_screen_defender: {
    median: -0.001167417585,
    std: 0.003583011287,
  },
  catch_and_go_drive_drive_defender: {
    median: -0.0007750336935,
    std: 0.0128454535,
  },
  cut_cutter_defender: {
    median: -0.0004188653513,
    std: 0.001928525318,
  },
};
