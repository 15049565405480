import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import { Table, createColumnHelper } from "../../components/core/Table";
import { Panel } from "../../components/core/Panel";
import { TeamPersonnel } from "../../../shared/routers/TeamRouter";
import { PlayerTableCell } from "../../components/core/TableCell";

const personnelColumnHelper = createColumnHelper<TeamPersonnel>();

export function TeamSubPagePersonnel(props: { personnel?: TeamPersonnel[] }) {
  const { personnel } = props;

  const columns = useMemo(() => {
    return [
      personnelColumnHelper.accessor("player", {
        header: "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.celticsID}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
      }),
      personnelColumnHelper.accessor("Report", {
        cell: (info) => (
          <p style={{ whiteSpace: "pre-wrap" }}>{info.getValue()}</p>
        ),
        meta: { textAlign: "left" },
      }),
    ];
  }, []);

  return (
    <Row>
      <Col lg={8} md={8} sm={12} xs={12}>
        <Panel header="Team Personnel" panelID="personnel">
          {personnel && (
            <Table data={personnel} columns={columns} showRowIndex={false} />
          )}
        </Panel>
      </Col>
    </Row>
  );
}
