import { CELTICS_TEAM_ID } from "../constants/AppConstants";
import { TEAM_COLORS } from "../constants/ColorConstants";

export const contractMyc = "#f4f4f4";
export const contractTo = "#B64FD9";
export const contractPo = "#5368F2";
export const contractRfa = "#EA7400";
export const contractUfa = "#5cb85c";

export function colorFromPosition(position: number) {
  switch (position) {
    case 1:
      return "rgb(167, 105, 210)";
    case 2:
      return "rgb(101, 133, 236)";
    case 3:
      return "rgb(113, 207, 110)";
    case 4:
      return "rgb(249, 132, 79)";
    case 5:
      return "rgb(211, 55, 74)";
    default:
      return "Black";
  }
}

// Given a string (usually a team or player id) returns a primary/secondary
// color pairing to use that will be unique to that team. Also the secondary
// color should be an inverse of the primary so we should have sufficient color
// contrast.
export function strToContrastingColors(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let primary = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    primary += ("00" + value.toString(16)).substr(-2);
  }

  const secondary = (Number(`0x1${primary}`) ^ 0xffffff)
    .toString(16)
    .substr(1)
    .toUpperCase();

  return { primary, secondary };
}

// Given a team id, and the one we are comparing them to, return the color we
// should use for them. The idea is that if the two colors have too similar of a
// primary color flip the colors for the team with lower team id.
export function getContrastingTeamColor(teamId: number, oppTeamId: number) {
  let flip = false;
  const colors =
    TEAM_COLORS[teamId.toString()] || strToContrastingColors(teamId.toString());
  const oppColors =
    TEAM_COLORS[oppTeamId.toString()] ||
    strToContrastingColors(oppTeamId.toString());

  // Celtics should never flip their primary/secondary.
  if (teamId === CELTICS_TEAM_ID) {
    return colors;
  } else if (
    oppTeamId === CELTICS_TEAM_ID &&
    isSimilar(colors.primary, oppColors.primary)
  ) {
    return {
      primary: colors.secondary,
      secondary: colors.primary,
    };
  }

  if (
    oppColors &&
    isSimilar(colors.primary, oppColors.primary) &&
    teamId < oppTeamId
  ) {
    flip = true;
  }

  const primary = flip ? colors.secondary : colors.primary;
  const secondary = flip ? colors.primary : colors.secondary;
  return { primary, secondary };
}

function getRGB(color: string) {
  const colorNum = parseInt(color.substring(1), 16);
  const r = colorNum >> 16;
  const g = (colorNum - (r << 16)) >> 8;
  const b = colorNum - (r << 16) - (g << 8);
  return [r, g, b];
}

// If both colors have the same "dominant" color component then we'll say they
// are too similar.
function isSimilar(color1: string, color2: string) {
  let [r1, g1, b1] = getRGB(color1);
  let [r2, g2, b2] = getRGB(color2);

  r1 = r1 || 0;
  g1 = g1 || 0;
  b1 = b1 || 0;
  r2 = r2 || 0;
  g2 = g2 || 0;
  b2 = b2 || 0;

  if (r1 > g1 && r1 > b1 && r2 > g2 && r2 > b2) {
    return true;
  } else if (g1 > r1 && g1 > b1 && g2 > r2 && g2 > b2) {
    return true;
  } else if (b1 > r1 && b1 > g1 && b2 > r2 && b2 > g2) {
    return true;
  }
  return false;
}
