import React from "react";
import { Button, Form, Col, Row } from "react-bootstrap";

import { GamePageFilters } from "../../../shared/routers/GameRouter";

export function GamePageFilter(props: {
  onChange: (
    key: string,
    newVal: string | number | null | string[] | number[]
  ) => void;
  onReset: () => void;
  filters: GamePageFilters;
}) {
  const { onChange, onReset, filters } = props;

  const handleQuarterClick = (quarter: string) => {
    const quarterSet = new Set<string>(filters.quarters);
    if (quarterSet.has(quarter)) {
      quarterSet.delete(quarter);
    } else {
      quarterSet.add(quarter);
    }
    onChange("quarters", Array.from(quarterSet).sort());
  };

  return (
    <div>
      <Row>
        <Col>
          <div style={{ marginBottom: 15 }}>
            <label>Quarters</label>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <Form.Check
                    style={{ display: "inline-block", paddingRight: 4 }}
                    checked={filters.quarters.includes("1")}
                    onChange={() => handleQuarterClick("1")}
                  />
                  1<sup>st</sup>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <Form.Check
                    style={{ display: "inline-block", paddingRight: 4 }}
                    checked={filters.quarters.includes("2")}
                    onChange={() => handleQuarterClick("2")}
                  />
                  2<sup>nd</sup>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <Form.Check
                    style={{ display: "inline-block", paddingRight: 4 }}
                    checked={filters.quarters.includes("3")}
                    onChange={() => handleQuarterClick("3")}
                  />
                  3<sup>rd</sup>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <Form.Check
                    style={{ display: "inline-block", paddingRight: 4 }}
                    checked={filters.quarters.includes("4")}
                    onChange={() => handleQuarterClick("4")}
                  />
                  4<sup>th</sup>
                </li>
                <li
                  style={{
                    display: "inline-block",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  <Form.Check
                    style={{ display: "inline-block", paddingRight: 4 }}
                    checked={filters.quarters.includes("OT")}
                    onChange={() => handleQuarterClick("OT")}
                  />
                  OT
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col>
          <Form.Label>Leverage</Form.Label>
          <div>
            <Form.Select
              style={{ width: "auto" }}
              value={filters.leverage || "low"}
              onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                onChange("leverage", evt.target.value)
              }
            >
              {[
                { value: "low", label: "All Possessions" },
                { value: "med", label: "No Garbage Time" },
                { value: "high", label: "Close & Late" },
              ].map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <Button onClick={onReset}>Reset</Button>
        </Col>
      </Row>
    </div>
  );
}
