import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { GameLineupsSummary } from "../../components/games/GameLineupsSummary";
import { GameLineupsByTime } from "../../components/games/GameLineupsByTime";
import { GameLineupBreakdown } from "../../../shared/routers/GameRouter";
import { Spinner } from "../../components/core/Spinner";

export function GameSubPageLineups(props: {
  filtered: boolean;
  home?: { teamid: number; team: string; teamName: string };
  away?: { teamid: number; team: string; teamName: string };
  lineupBreakdowns?: GameLineupBreakdown[];
}) {
  const { filtered, home, away, lineupBreakdowns } = props;

  const teams = [home, away];

  // If we have multiple games worth of data it means we are in the series page
  // and should hide the lineups by time panel.
  const hideLineupsByTime =
    new Set((lineupBreakdowns || []).map((l) => l.gameId)).size > 1;

  return (
    <div>
      <Row>
        <Col>
          <Panel
            header="Lineups Summary"
            panelID="lineupsSummary"
            filtered={filtered}
          >
            {lineupBreakdowns ? (
              <div>
                {teams.map((t, i) => {
                  if (!t) return null;
                  return (
                    <div key={i}>
                      <b>{t.teamName}</b>
                      <GameLineupsSummary
                        data={lineupBreakdowns.filter(
                          (l) => l.teamid === t.teamid
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Spinner />
            )}
          </Panel>
        </Col>
      </Row>
      {/* 
        This panel doesn't make sense for series page when multiple games are 
        selected so hide it if we have multiple game ids present. 
      */}
      <Row>
        <Col>
          <Panel
            header="Lineups By Time"
            panelID="lineupsByTime"
            filtered={filtered}
          >
            {hideLineupsByTime ? (
              <div>
                To see lineups by time select a single game from the series.
              </div>
            ) : lineupBreakdowns ? (
              <div>
                {teams.map((t, i) => {
                  if (!t) return null;
                  return (
                    <div key={i}>
                      <b>{t.teamName}</b>
                      <GameLineupsByTime
                        data={lineupBreakdowns.filter(
                          (l) => l.teamid === t.teamid
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Spinner />
            )}
          </Panel>
        </Col>
      </Row>
    </div>
  );
}
