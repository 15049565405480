import React from "react";
import { Col, Row } from "react-bootstrap";

import { GameInjuryReport } from "../../components/games/GameInjuryReport";
import { GameDetails, GameInjury } from "../../../shared/routers/GameRouter";
import { Panel } from "../../components/core/Panel";

export function GameSubPageInjuryReport(props: {
  game?: GameDetails[];
  injuries?: GameInjury[];
}) {
  const { game, injuries } = props;

  const gameDetails = game && game[0];

  return (
    <div>
      <Row>
        <Col>
          <Panel header="Injury Report" panelID={"injuryReport"}>
            {gameDetails && injuries && (
              <GameInjuryReport game={gameDetails} injuries={injuries} />
            )}
          </Panel>
        </Col>
      </Row>
    </div>
  );
}
