import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../components/core/Spinner";
import { trpc } from "../util/tRPC";

export function VideoProxyPage(props: { type: "chance" | "shot" }) {
  const { type } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    // If no id is provided, navigate to the home page.
    navigate("/");
    return null;
  } else if (type === "shot") {
    return <ShotProxyPage id={id} />;
  } else {
    return <ChanceProxyPage id={id} />;
  }
}

function ShotProxyPage(props: { id: string }) {
  const navigate = useNavigate();
  const { id } = props;

  const { data } = trpc.shot.getShotDetails.useQuery({ shotEagleId: id });

  if (data) {
    const shot = data[0];
    const shotUrl = shot && shot.SynergyURL;
    if (!shotUrl) {
      // No shot data... navigate to the home page.
      navigate("/");
    } else {
      window.location.href = shotUrl;
    }
  }

  return <BlankScreenWithLoader />;
}

function ChanceProxyPage(props: { id: string }) {
  const navigate = useNavigate();
  const { id } = props;

  const { data } = trpc.synergy.getUrlForChanceId.useQuery({
    chanceId: id,
  });

  if (data) {
    const chance = data[0];
    const chanceUrl = chance && chance.SynergyURL;
    if (!chanceUrl) {
      // No shot data... navigate to the home page.
      navigate("/");
    } else {
      window.location.href = chanceUrl;
    }
  }

  return <BlankScreenWithLoader />;
}

function BlankScreenWithLoader() {
  // Return an entirely blank page with loading spinner.
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 10000,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
      }}
    >
      <Spinner white={true} />
    </div>
  );
}
