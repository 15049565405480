import React from "react";

import { colorFromPosition } from "../../util/Colors";

export function PositionIndicator(props: { position: number | null }) {
  const { position } = props;

  return (
    <div style={{ display: "flex", gap: "2px" }}>
      {Array.from({ length: 5 }).map((_, i) => (
        <svg key={i} width="10" height="10">
          <circle
            cx="5"
            cy="5"
            r="4"
            fill={i + 1 === position ? colorFromPosition(i + 1) : "lightgray"}
          />
        </svg>
      ))}
    </div>
  );
}
