import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import {
  GameLineup,
  GamePlayer,
  GamePossession,
  GameWinProbability,
} from "../../../shared/routers/GameRouter";
import { TeamSubstitutionPattern } from "../../../shared/routers/TeamRouter";
import { GameFlowPanel } from "../../components/games/GameFlowPanel";
import { TeamSubstitutionPatternChart } from "../../components/team/TeamSubstitutionPatternChart";
import { GameWinProbabilityChart } from "../../components/games/GameWinProbabilityChart";
import { Restrict } from "../../components/core/Restrict";

export function GameSubPageGameFlow(props: {
  filtered: boolean;
  lineups?: GameLineup[];
  players?: GamePlayer[];
  possessions?: GamePossession[];
  home?: {
    teamid: number;
    team: string;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
  away?: {
    teamid: number;
    team: string;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
  homeSubs?: TeamSubstitutionPattern[];
  awaySubs?: TeamSubstitutionPattern[];
  gameWinProb?: GameWinProbability[];
}) {
  const {
    filtered,
    home,
    away,
    lineups,
    players,
    possessions,
    homeSubs,
    awaySubs,
    gameWinProb,
  } = props;

  if (!home || !away) return null;

  return (
    <div>
      <Row>
        <Col>
          <Panel header="Game Flow" panelID="gameFlow" filtered={filtered}>
            {lineups && players && possessions && (
              <GameFlowPanel
                home={home}
                away={away}
                lineups={lineups}
                players={players}
                possessions={possessions}
              />
            )}
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel header="Sub Pattern" panelID="subPattern" filtered={filtered}>
            {awaySubs && homeSubs && (
              <SubPatternPanel
                home={home}
                away={away}
                homeSubs={homeSubs}
                awaySubs={awaySubs}
              />
            )}
          </Panel>
        </Col>
      </Row>
      <Restrict roles={["bia"]}>
        <Row>
          <Col>
            <Panel
              header="[BIA ONLY] - Win Probability"
              panelID="winProbability"
              filtered={filtered}
            >
              {gameWinProb && (
                <GameWinProbabilityChart
                  home={home}
                  away={away}
                  gameWinProb={gameWinProb}
                />
              )}
            </Panel>
          </Col>
        </Row>
      </Restrict>
    </div>
  );
}

function SubPatternPanel(props: {
  home: { teamid: number; teamCity: string };
  away: { teamid: number; teamCity: string };
  homeSubs: TeamSubstitutionPattern[];
  awaySubs: TeamSubstitutionPattern[];
}) {
  const { home, away, homeSubs, awaySubs } = props;

  const numGames = new Set(homeSubs.map((d) => d.gameId)).size;

  return (
    <div>
      {[homeSubs, awaySubs].map((d, i) => {
        const teamCity = i === 0 ? home.teamCity : away.teamCity;
        return (
          <div key={i}>
            <b>{teamCity}</b>
            <TeamSubstitutionPatternChart numGames={numGames} data={d} />
          </div>
        );
      })}
    </div>
  );
}
