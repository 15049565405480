import moment from "moment";

import { GameDetails } from "../../shared/routers/GameRouter";

// Type of object our video player is designed to take in.
export class Video {
  constructor(
    public urls?: string[],
    public names?: string[],
    public fallbackUrls?: string[],
    public sourceName?: string,
    public auxData?: Record<string, unknown>,
    public onFallback = false
  ) {}
}
export const getHoop1Urls = (game: GameDetails) => {
  let homeTeam = game.homeHoopOne;
  let awayTeam = game.awayHoopOne;

  if (homeTeam === null || awayTeam === null)
    return { urls: [], fallbackUrls: [] };

  const season = game.season;
  const gameDate = moment(game.gameDate, "YYYY-MM-DDTHH:mm:ss.SSSS").format(
    "MMDDYY"
  );
  const league = game.leagueName;

  // Looks like they are changing this every year so probably need to add a
  // reminder to update it each season after we know the new server name.
  let server = "https://cdnep2425v1-hjcxg9hcfthxabc2.z03.azurefd.net/";
  if (season === 2024) {
    server = "https://cdn2324.azureedge.net/";
  } else if (season < 2024) {
    server = "https://az811666.vo.msecnd.net/";
  }

  let seasonString = `${season - 1}-${season}/`;
  let leagueStr = "BASKETBALL";
  let periods = 2;
  if (league === "NCAA") {
    leagueStr = "NCAA%20MEN";
  } else if (
    league.includes("NBA") ||
    league.includes("Summer") ||
    league.includes("California Classic")
  ) {
    leagueStr = "NBA";
    periods = 4;
    if (league.includes("Summer") || league.includes("California Classic")) {
      seasonString = `${season - 2}-${season - 1}/`;
      periods = 2;
    }
  }

  let lastPart = "?USERID=CELTICSK";
  let at = "at";
  homeTeam = homeTeam.toUpperCase();
  awayTeam = awayTeam.toUpperCase();
  lastPart = `?${gameDate}`;
  at = "AT";
  seasonString += leagueStr;
  seasonString += "/";

  // https://az811666.vo.msecnd.net/2019-2020/NBA/FRANCA%20AT%20BROOKLYN%20NETS_100419_2.MP4?20191005a
  const urls = [],
    fallbackUrls = [];
  for (const period of Array.from(
    new Array(periods),
    (val, index) => index + 1
  )) {
    const url = `${server}${seasonString}${awayTeam} ${at} ${homeTeam}_${gameDate}_${period}.MP4${lastPart}`;
    const altUrl = `${server}${seasonString}${homeTeam} ${at} ${awayTeam}_${gameDate}_${period}.MP4${lastPart}`;

    urls.push(url);
    fallbackUrls.push(altUrl);
    // Hack time -- check for overtime.
    if (period == periods) {
      const otUrl = `${server}${seasonString}${awayTeam} ${at} ${homeTeam}_${gameDate}_OT_1.MP4${lastPart}`;
      fetch(otUrl).then(function (response) {
        if (response.status == 200) {
          urls.push(otUrl);
          const altUrl = `${server}${seasonString}${homeTeam} ${at} ${awayTeam}_${gameDate}_OT_1.MP4${lastPart}`;
          fallbackUrls.push(altUrl.replace(period.toString(), "OT_1"));
        }
      });
    }
  }

  return { urls, fallbackUrls };
};
