import moment from "moment";

import { Shot } from "../../../shared/routers/ShotRouter";
import { Pnr } from "../../../shared/routers/PnrRouter";
import {
  gameClockFormat,
  intlGameClockFormat,
  collegeGameClockFormat,
  period,
} from "../../util/Format";
import { SynergyGameClip } from "../../../shared/routers/SynergyRouter";
import { ListPlayCall } from "../../../shared/routers/PlayCallRouter";

export interface SynergyEditorClip {
  label: string;
  url: string;
  auxData: {
    makeMiss: string;
    gameClockStr: string;
    game: string;
    dateStr: string;
    period: string;
  };
}

// Transform a shot object into something synergy editor will be able to make
// a csv from.
export function shotToSynergyEditorClip(shot: Shot): SynergyEditorClip {
  return {
    label: `${shot.shooter} ${shot.gameDate}`,
    url: shot.synergyUrl || "",
    auxData: {
      makeMiss: shot.made ? "Make" : "Miss",
      gameClockStr: gameClockFormat(shot.gameClock),
      game: `${shot.oteam} vs. ${shot.dteam}`,
      dateStr: moment(shot.gameDate, "YYYYMMDD").format("L"),
      period: period(shot.period),
    },
  };
}

// Transform a play call into something synergy editor will be able to make
// a csv from.
export function playCallToSynergyEditorClip(
  p: ListPlayCall
): SynergyEditorClip {
  return {
    label: `${p.playcall}-${p.outcome}`,
    url: p.synergyURL || "",
    auxData: {
      makeMiss: p.made ? "Make" : "Miss",
      gameClockStr: p.endGameClock ? gameClockFormat(p.endGameClock) : "",
      game: `${p.oTeam || "Unknown"} vs. ${p.dTeam || "Unknown"}`,
      dateStr: moment(p.gameDate, "YYYYMMDD").format("L"),
      period: period(p.period),
    },
  };
}

export function pnrToSynergyEditorClip(pnr: Pnr): SynergyEditorClip {
  let makeMiss = "";
  if (
    pnr.outcomesBhr.includes("FGM") ||
    (pnr.outcomesScr && pnr.outcomesScr.includes("FGM"))
  ) {
    makeMiss = "Make";
  } else if (
    pnr.outcomesBhr.includes("FGX") ||
    (pnr.outcomesScr && pnr.outcomesScr.includes("FGX"))
  ) {
    makeMiss = "Miss";
  }

  return {
    label: `${pnr.ballhandler}/${pnr.screener} ${pnr.gameDate}`,
    url: pnr.synergyUrl,
    auxData: {
      makeMiss,
      gameClockStr: "",
      game: `${pnr.oteam} vs. ${pnr.dteam}`,
      dateStr: moment(pnr.gameDate, "YYYYMMDD").format("L"),
      period: period(pnr.period),
    },
  };
}

export function gameClipToSynergyEditorClip(clip: SynergyGameClip) {
  let gameClockStr;
  if (clip.leagueID === 2) {
    gameClockStr = collegeGameClockFormat(clip.clock);
  } else if (
    clip.leagueID !== null &&
    [1, 10, 91, 92, 93, 97, 99, 100].includes(clip.leagueID)
  ) {
    gameClockStr = gameClockFormat(clip.clock / 10);
  } else {
    gameClockStr = intlGameClockFormat(clip.clock);
  }
  return {
    label: `${clip.visteam} @ ${clip.hometeam}`,
    url: clip.videoUrl,
    auxData: {
      makeMiss: makeOrMissFromEvent(clip),
      gameClockStr: gameClockStr || "",
      game: `${clip.visteam} @ ${clip.hometeam}`,
      dateStr: moment(clip.gameDate, "YYYYMMDD").format("L"),
      period: period(clip.gameQuarter),
      prevClipId: clip.prevClipId,
      clipId: clip.clipId,
      skipTime: clip.skipTime,
    },
  };
}

function makeOrMissFromEvent(clip: SynergyGameClip) {
  if (clip.string_event === null) return "";

  const event = clip.string_event.toLocaleLowerCase();
  if (event.includes("miss")) {
    return "Miss";
  } else if (event.includes("make")) {
    return "Make";
  }
  return "";
}
