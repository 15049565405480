import React from "react";
import Immutable, { List } from "immutable";

import MultiSelectDeprecated from "../../deprecated/components/core/MultiSelect";

export interface MultiSelectOption {
  value: string;
  label: string;
  aux1?: string | null;
  aux2?: string | null;
}

export function MultiSelect(props: {
  values: MultiSelectOption[];
  selected: string[];
  onChange: (
    values: string[],
    value: string,
    option: MultiSelectOption | undefined
  ) => void;
  limit?: number;
  autocompleteValues?: MultiSelectOption[];
  onTextChange?: (text: string) => void;
  disabled?: boolean;
  shouldNotRemoveInvalidItems?: boolean;
}) {
  const {
    values,
    selected,
    onChange,
    limit,
    autocompleteValues,
    onTextChange,
    disabled,
    shouldNotRemoveInvalidItems,
  } = props;
  return (
    <MultiSelectDeprecated
      values={Immutable.List(values)}
      selected={Immutable.List(selected)}
      onChange={(
        vals: List<string>,
        val: string,
        option: MultiSelectOption | undefined
      ) => onChange(vals.toJS() as string[], val, option)}
      limit={limit}
      autocompleteValues={
        autocompleteValues ? Immutable.List(autocompleteValues) : undefined
      }
      onTextChange={onTextChange}
      disabled={disabled}
      shouldNotRemoveInvalidItems={!!shouldNotRemoveInvalidItems}
    />
  );
}
