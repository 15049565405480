import React from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { VideoPlayer } from "../components/video/VideoPlayer";
import { PlayerMinutesGameBoxScores } from "../components/player/PlayerMinutesGameBoxScores";
import { trpc } from "../util/tRPC";

export function PlayerMinutesPage() {
  const { gameId, playerId } = useParams();

  const { data: playerMinutes } = trpc.synergy.getSynergyPlayerMinutes.useQuery(
    { playerId, gameId },
    { enabled: playerId !== undefined && gameId !== undefined }
  );

  const { data: playerMinutesGame } =
    trpc.synergy.getSynergyPlayerMinutesGame.useQuery(
      { gameId },
      { enabled: gameId !== undefined }
    );

  const info =
    playerMinutesGame &&
    playerMinutesGame.find((i) => i.celticsID.toString() === playerId);

  if (!info) return null;

  let gameLink;
  if (info.leagueKey === "nba" || info.leagueKey === "nba_preseason") {
    gameLink = (
      <Link
        title={"Go to game page"}
        target="_blank"
        to={`/game/${info.gameID}`}
      >
        Game Page
      </Link>
    );
  } else {
    gameLink = (
      <Link
        target="_blank"
        title={"Go to game page"}
        to={`/game/ml/${info.leagueKey}/${info.gameID}`}
      >
        Game Page
      </Link>
    );
  }
  const playerLink = (
    <Link
      target="_blank"
      title={"Go to player page"}
      to={`/player/${info.celticsID}`}
    >
      Player Page
    </Link>
  );

  const header = (
    <div>
      <h1>{`${info.Player}`}</h1>
      <div>
        {`${info.team} vs. ${info.opponent} 
      - ${moment(info.gameDate, "YYYY-MM-DDTHH:mm:ss.SSSS").format(
        "MMMM Do, YYYY"
      )} `}
      </div>
      <div>
        {gameLink} - {playerLink}
      </div>
    </div>
  );

  return (
    <Page header={{ component: header }} title={"Player Minutes"}>
      <Panel header={"Player Minutes"}>
        <div>
          {info && <PlayerMinutesGameBoxScores data={[info]} />}
          {!playerMinutes && <Spinner />}
          {playerMinutes && playerMinutes.length > 0 && (
            <VideoPlayer
              clips={playerMinutes.map((mp) => {
                return {
                  url: mp.videoUrl,
                  label: `Q${mp.gameQuarter} (${mp.starttime}-${mp.endtime})`,
                  auxData: {
                    clipId: mp.clipId,
                    prevClipId: mp.prevClipId || "",
                    skipTime: mp.skipTime.toString(),
                  },
                };
              })}
              upDownClipSkip={true}
              showSynergyEditor={false}
            />
          )}
        </div>
      </Panel>
    </Page>
  );
}
