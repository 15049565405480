import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";
import { PlayerModeledMeasurements } from "../../../shared/routers/PlayerRouter";
import { BoxPlotByPosition } from "./BoxPlotByPosition";
import { measurementRanges } from "../../constants/AppConstants";
import { lineChartColorArray } from "../../constants/ColorConstants";
import { strToContrastingColors } from "../../util/Colors";

export function PlayerMeasurementsBoxPlots(props: {
  names?: { id: number; name: string }[];
  measurements: PlayerModeledMeasurements[];
}) {
  const { names, measurements } = props;
  const [highlightedIndex, setHighlightedIndex] = useState<null | number>(null);
  const handlePlayerEnter = (idx: number) => setHighlightedIndex(idx);
  const handlePlayerLeave = () => setHighlightedIndex(null);

  const style = { padding: ".8em", maxWidth: "215px" };

  return (
    <Row>
      {names &&
        measurements.map((m, i) => (
          <div
            onMouseEnter={() => handlePlayerEnter(i)}
            onMouseLeave={() => handlePlayerLeave()}
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              opacity: highlightedIndex === i ? 1 : 0.7,
            }}
          >
            <div
              style={{
                background:
                  i < lineChartColorArray.length
                    ? lineChartColorArray[i]
                    : strToContrastingColors(m.playerId.toString()).primary,
                width: 10,
                height: 10,
              }}
            ></div>
            <div>{names.find((n) => n.id === m.playerId)?.name}</div>
          </div>
        ))}
      {Object.keys(measurementRanges)
        .filter((key) => measurements.some((m) => !!(m as any)[key]) as any)
        .map((key) => {
          const measurementRangeForPos = measurementRanges[key];
          if (!measurementRangeForPos) return null;

          return (
            <Col xs={6} sm={3} md={2} style={style} key={key}>
              <BoxPlotByPosition
                highlightedIndex={
                  measurements.length === 1 ? 0 : highlightedIndex
                }
                mapKey={key}
                measurementRangesByPos={measurementRangeForPos}
                values={measurements.map((m) => (m as any)[key])}
              />
            </Col>
          );
        })}
    </Row>
  );
}
