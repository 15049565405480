import React, { useState, useMemo, useContext, useCallback } from "react";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";

import { Table, createColumnHelper } from "../core/Table";
import { RugPlot } from "../chart/RugPlot";
import { decFormat2, decFormat } from "../../util/Format";
import { extent } from "../../util/Util";
import { TeamActionBreakdown } from "../../../shared/routers/TeamRouter";
import { reduceArrayToObject } from "../../../shared/util/Collections";
import { TeamContext } from "../../TeamContext";

const ACTION_TYPES = [
  "PNR Total",
  "Off Ball",
  "Isolation",
  "Catch-and-Go Drive",
  "Cut",
  "Post",
  "Transition",
  // PNR Subtypes
  "Side/Wing",
  "Middle",
  "Angle/Step-up",
  "Double",
  "Low Angle",
  "PNR Other",
  "DHO",
];

interface ActionWithValuesAndRanks {
  actionType: string;
  ppp: number | null;
  pppRank: number | null;
  xPpp: number | null;
  xPppRank: number | null;
  aoi: number | null;
  aoiRank: number | null;
  xAoi: number | null;
  xAoiRank: number | null;
  per100: number;
  per100Rank: number | null;
  isPnrSubRow?: boolean;
}

const columnHelper = createColumnHelper<{
  actionType: string;
  value: number | null;
  valueRank: number | null;
  per100: number;
  per100Rank: number | null;
}>();

export function TeamActionsBreakdownTable(props: {
  teamId: number;
  teamActions?: TeamActionBreakdown[];
  leagueActions?: TeamActionBreakdown[];
  statType: "OFF" | "DEF";
}) {
  const { teamId, statType, teamActions, leagueActions } = props;
  const [hoveredTeamId, setHoveredTeamId] = useState<number>();
  const [expectedToggle, setExpectedToggle] = useState("xppp");
  const [actionLevelToggle, setActionLevelToggle] = useState("poss");

  const teams = useContext(TeamContext).teams;

  const sortDir = statType === "OFF" ? -1 : 1;

  const teamObj = reduceArrayToObject(teams, (t) => t.teamid.toString());

  const selectedTeamId = hoveredTeamId || teamId;

  // Replace the entries in leagueActions for the current team with the data
  // from teamActions entry. Then create a new entry for each team with total
  // PNR data.
  const teamDataDict = useMemo(() => {
    if (!teamActions || !leagueActions) {
      return undefined;
    }

    // Swap in team data for the current team.
    const combined = leagueActions
      .filter((la) => la.teamId !== teamId)
      .concat(teamActions);

    return reduceArrayToObject(combined, (d) => d.teamId.toString());
  }, [teamActions, leagueActions, teamId]);

  const dataValuesByTeam = useMemo(() => {
    const ret: Record<string, ActionWithValuesAndRanks[]> = {};
    if (teamDataDict === undefined) return ret;
    for (const [teamId, teamData] of Object.entries(teamDataDict)) {
      ret[teamId] = getDataRowsForTeam(teamData);
    }
    return ret;
  }, [teamDataDict]);

  // Now we can go back in and fill in the ranks.
  for (const actionType of ACTION_TYPES) {
    const getTeamDatas = Object.values(dataValuesByTeam)
      .flatMap((d) => d)
      .filter((d) => d.actionType === actionType);
    // xPPP
    const sortedByXPpp = [...getTeamDatas].sort((a, b) => {
      const aVal = a.xPpp;
      const bVal = b.xPpp;
      if (aVal === null && bVal === null) {
        return 0;
      } else if (aVal === null) {
        return sortDir;
      } else if (bVal === null) {
        return -sortDir;
      }
      return sortDir * (aVal - bVal);
    });
    // PPP
    const sortedByPpp = [...getTeamDatas].sort((a, b) => {
      const aVal = a.ppp;
      const bVal = b.ppp;
      if (aVal === null && bVal === null) {
        return 0;
      } else if (aVal === null) {
        return sortDir;
      } else if (bVal === null) {
        return -sortDir;
      }
      return sortDir * (aVal - bVal);
    });
    // xAOI
    const sortedByXAoi = [...getTeamDatas].sort((a, b) => {
      const aVal = a.xAoi;
      const bVal = b.xAoi;
      if (aVal === null && bVal === null) {
        return 0;
      } else if (aVal === null) {
        return sortDir;
      } else if (bVal === null) {
        return -sortDir;
      }
      return sortDir * (aVal - bVal);
    });
    // AOI
    const sortedByAoi = [...getTeamDatas].sort((a, b) => {
      const aVal = a.aoi;
      const bVal = b.aoi;
      if (aVal === null && bVal === null) {
        return 0;
      } else if (aVal === null) {
        return sortDir;
      } else if (bVal === null) {
        return -sortDir;
      }
      return sortDir * (aVal - bVal);
    });
    // per100
    const sortedByPer100 = [...getTeamDatas].sort((a, b) => {
      return sortDir * (a.per100 - b.per100);
    });
    for (let i = 0; i < teams.length; i++) {
      const xPPP = sortedByXPpp[i];
      if (xPPP) {
        xPPP.xPppRank = i + 1;
      }
      const ppp = sortedByPpp[i];
      if (ppp) {
        ppp.pppRank = i + 1;
      }
      const xAOI = sortedByXAoi[i];
      if (xAOI) {
        xAOI.xAoiRank = i + 1;
      }
      const aoi = sortedByAoi[i];
      if (aoi) {
        aoi.aoiRank = i + 1;
      }
      const per100 = sortedByPer100[i];
      if (per100) {
        per100.per100Rank = i + 1;
      }
    }
  }

  const dataForTeam = dataValuesByTeam[selectedTeamId.toString()];

  const getValueAndRank = useCallback(
    (d: ActionWithValuesAndRanks) => {
      if (expectedToggle === "xppp") {
        return actionLevelToggle === "poss"
          ? { rank: d.xPppRank, value: d.xPpp }
          : { rank: d.xAoiRank, value: d.xAoi };
      } else {
        return actionLevelToggle === "poss"
          ? { rank: d.pppRank, value: d.ppp }
          : { rank: d.aoiRank, value: d.aoi };
      }
    },
    [actionLevelToggle, expectedToggle]
  );

  const data = dataForTeam
    ? dataForTeam
        .filter((d) => !d.isPnrSubRow)
        .map((d) => {
          const { value, rank } = getValueAndRank(d);
          return {
            actionType: d.actionType,
            value: value,
            valueRank: rank,
            per100: d.per100,
            per100Rank: d.per100Rank,
            subRows:
              d.actionType === "PNR Total"
                ? dataForTeam
                    .filter((d) => d.isPnrSubRow)
                    .map((d) => {
                      const { value, rank } = getValueAndRank(d);
                      return {
                        actionType: d.actionType,
                        value: value,
                        valueRank: rank,
                        per100: d.per100,
                        per100Rank: d.per100Rank,
                      };
                    })
                : undefined,
          };
        })
    : undefined;

  const columns = useMemo(() => {
    const valueHeader = expectedToggle === "xppp" ? "xPPP" : "PPP";

    if (data === undefined) return [];

    const hoveredTeam = hoveredTeamId && teamObj[hoveredTeamId.toString()];

    const hoveredTeamMsg = hoveredTeam ? (
      <div>
        <span style={{ fontWeight: "normal" }}>Showing stats for </span>
        <span>{hoveredTeam.teamcity}</span>
      </div>
    ) : null;

    const allValues = Object.values(dataValuesByTeam)
      .flatMap((d) => d)
      .map((d) => {
        return {
          value: getValueAndRank(d).value,
          rate: d.per100,
        };
      })
      .filter((d) => d.value !== null && d.rate !== null) as {
      value: number;
      rate: number;
    }[];

    const { min: minValue, max: maxValue } = extent(allValues, (a) => a.value);
    const { min: minRate, max: maxRate } = extent(allValues, (a) => a.rate);

    let g = 0;
    return [
      columnHelper.accessor("actionType", {
        header: "Type",
        cell: (info) => {
          const val = info.getValue();
          return info.row.depth === 0 ? <b>{val}</b> : val;
        },
        meta: { textAlign: "left", group: g++ },
      }),
      columnHelper.accessor("valueRank", {
        header: "",
        cell: (info) => <div style={{ width: 30 }}>{info.getValue()}</div>,
        meta: { group: g },
      }),
      columnHelper.accessor("value", {
        header: valueHeader,
        cell: (info) => (
          <div style={{ opacity: 0.7, width: 30 }}>
            {decFormat2(info.getValue())}
          </div>
        ),
        meta: { group: g },
      }),
      columnHelper.display({
        id: "valueChart",
        header: () => hoveredTeamMsg,
        cell: (info) => (
          <RugPlot
            teamId={teamId}
            domain={[minValue - 0.1, maxValue + 0.1]}
            colorDomain={[minValue, maxValue]}
            hoveredTeamId={hoveredTeamId}
            setHoveredTeamId={setHoveredTeamId}
            data={
              dataValuesByTeam
                ? (Object.entries(dataValuesByTeam)
                    .map(([teamId, teamArr]) => {
                      const d = teamArr.find(
                        (d) => d.actionType === info.row.original.actionType
                      );
                      if (!d) return undefined;
                      return {
                        value: getValueAndRank(d).value,
                        teamId: parseInt(teamId),
                      };
                    })
                    .filter((d) => d !== undefined) as {
                    value: number;
                    teamId: number;
                  }[])
                : []
            }
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("per100Rank", {
        header: "",
        cell: (info) => <div style={{ width: 30 }}>{info.getValue()}</div>,
        meta: { group: g },
      }),
      columnHelper.accessor("per100", {
        header: "# Per 100",
        cell: (info) => (
          <div style={{ opacity: 0.7, width: 30 }}>
            {decFormat(info.getValue())}
          </div>
        ),
        meta: { group: g },
      }),
      columnHelper.display({
        id: "rateChart",
        header: () => hoveredTeamMsg,
        cell: (info) => (
          <RugPlot
            teamId={teamId}
            domain={[minRate - 0.01, maxRate + 0.01]}
            colorDomain={[minRate, maxRate]}
            hoveredTeamId={hoveredTeamId}
            setHoveredTeamId={setHoveredTeamId}
            data={
              dataValuesByTeam
                ? (Object.entries(dataValuesByTeam)
                    .map(([teamId, teamArr]) => {
                      const d = teamArr.find(
                        (d) => d.actionType === info.row.original.actionType
                      );
                      if (!d) return undefined;
                      return {
                        value: d.per100,
                        teamId: parseInt(teamId),
                      };
                    })
                    .filter((d) => d !== undefined) as {
                    value: number;
                    teamId: number;
                  }[])
                : []
            }
          />
        ),
        meta: { group: g },
      }),
    ];
  }, [
    data,
    dataValuesByTeam,
    expectedToggle,
    getValueAndRank,
    hoveredTeamId,
    teamId,
    teamObj,
  ]);

  return data ? (
    <>
      <Table
        data={data}
        columns={columns}
        showRowIndex={false}
        autoWidth={true}
        expandColumnId="actionType"
      />
      <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
        <ToggleButtonGroup
          name="expected-points-toggle"
          type="radio"
          value={expectedToggle}
          onChange={setExpectedToggle}
        >
          <ToggleButton id="expected-points-toggle-xppp" value={"xppp"}>
            Expected Points
          </ToggleButton>
          <ToggleButton id="expected-points-toggle-ppp" value={"ppp"}>
            Actual Points
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          name="action-level-toggle"
          type="radio"
          value={actionLevelToggle}
          onChange={setActionLevelToggle}
        >
          <ToggleButton id="action-level-toggle-poss" value={"poss"}>
            Possession Level
          </ToggleButton>
          <ToggleButton id="action-level-toggle-action" value={"action"}>
            Action Level
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </>
  ) : null;
}

function getDataRowsForTeam(
  d: TeamActionBreakdown
): ActionWithValuesAndRanks[] {
  return [
    {
      actionType: "PNR Total",
      ppp: d.pnr_total_PPP,
      xPpp: d.pnr_total_xPPP,
      aoi: d.pnr_total_AOI,
      xAoi: d.pnr_total_xAOI,
      per100: (d.pnr_total_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Off Ball",
      ppp: d.off_ball_PPP,
      xPpp: d.off_ball_xPPP,
      aoi: d.off_ball_AOI,
      xAoi: d.off_ball_xAOI,
      per100: (d.off_ball_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Isolation",
      ppp: d.iso_PPP,
      xPpp: d.iso_xPPP,
      aoi: d.iso_AOI,
      xAoi: d.iso_xAOI,
      per100: (d.iso_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Catch-and-Go Drive",
      ppp: d.catch_and_go_drive_PPP,
      xPpp: d.catch_and_go_drive_xPPP,
      aoi: d.catch_and_go_drive_AOI,
      xAoi: d.catch_and_go_drive_xAOI,
      per100: (d.catch_and_go_drive_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Cut",
      ppp: d.cut_PPP,
      xPpp: d.cut_xPPP,
      aoi: d.cut_AOI,
      xAoi: d.cut_xAOI,
      per100: (d.cut_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Post",
      ppp: d.post_PPP,
      xPpp: d.post_xPPP,
      aoi: d.post_AOI,
      xAoi: d.post_xAOI,
      per100: (d.post_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Transition",
      ppp: d.transition_PPP,
      xPpp: d.transition_xPPP,
      aoi: d.transition_AOI,
      xAoi: d.transition_xAOI,
      per100: (d.transition_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
    },
    {
      actionType: "Side/Wing",
      ppp: d.pnr_side_PPP,
      xPpp: d.pnr_side_xPPP,
      aoi: d.pnr_side_AOI,
      xAoi: d.pnr_side_xAOI,
      per100: (d.pnr_side_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "Middle",
      ppp: d.pnr_middle_PPP,
      xPpp: d.pnr_middle_xPPP,
      aoi: d.pnr_middle_AOI,
      xAoi: d.pnr_middle_xAOI,
      per100: (d.pnr_middle_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "Angle/Step-up",
      ppp: d.pnr_angle_PPP,
      xPpp: d.pnr_angle_xPPP,
      aoi: d.pnr_angle_AOI,
      xAoi: d.pnr_angle_xAOI,
      per100: (d.pnr_angle_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "Double",
      ppp: d.pnr_double_PPP,
      xPpp: d.pnr_double_xPPP,
      aoi: d.pnr_double_AOI,
      xAoi: d.pnr_double_xAOI,
      per100: (d.pnr_double_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "Low Angle",
      ppp: d.pnr_low_angle_PPP,
      xPpp: d.pnr_low_angle_xPPP,
      aoi: d.pnr_low_angle_AOI,
      xAoi: d.pnr_low_angle_xAOI,
      per100: (d.pnr_low_angle_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "PNR Other",
      ppp: d.pnr_other_PPP,
      xPpp: d.pnr_other_xPPP,
      aoi: d.pnr_other_AOI,
      xAoi: d.pnr_other_xAOI,
      per100: (d.pnr_other_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
    {
      actionType: "DHO",
      ppp: d.pnr_dho_PPP,
      xPpp: d.pnr_dho_xPPP,
      aoi: d.pnr_dho_AOI,
      xAoi: d.pnr_dho_xAOI,
      per100: (d.pnr_dho_n * 100) / d.poss_n,
      pppRank: null,
      xPppRank: null,
      aoiRank: null,
      xAoiRank: null,
      per100Rank: null,
      isPnrSubRow: true,
    },
  ];
}
