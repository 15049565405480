import React, { useMemo, useContext, useState } from "react";
import {
  useQueryParams,
  JsonParam,
  QueryParamConfig,
  withDefault,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
} from "use-query-params";
import {
  Form,
  Col,
  Row,
  Alert,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";

import {
  Positions,
  Periods,
  BHR_DEFENDER_COVERAGES,
  SCR_DEFENDER_COVERAGES,
  SCR_DEFENDER_DEPTH,
  PNR_TYPES,
  SCREEN_TYPES,
} from "../constants/AppConstants";
import AppContext from "../../shared/AppContext";
import { TeamContext } from "../TeamContext";
import { SSPlayerContext } from "../PlayerContext";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { SortingState } from "../components/core/Table";
import { Spinner } from "../components/core/Spinner";
import { MultiSelect } from "../components/core/MultiSelect";
import { ExpandCollapseButton } from "../components/core/ExpandCollapseButton";
import { VideoModal } from "../components/video/VideoModal";
import { PnrQueryResultsTable } from "../components/pnr/PnrQueryResultsTable_2";
import { trpc } from "../util/tRPC";
import { PnrAggregate, PnrFilters } from "../../shared/routers/PnrRouter_2";
import { NbaEaglePlayer } from "../../shared/routers/RosterRouter";
import { FilterForm } from "../components/query/FilterForm";
import { groupBy as arrGroupBy } from "../../shared/util/Collections";
import { pnrToSynergyEditorClip } from "../components/video/utilities";
import { ordinalFormat } from "../util/Format";
import { FilterChips } from "../components/query/FilterChips";

const RESULT_LIMIT = 10_000;

const RESULT_LIMIT_MSG = `Your query produced a large return set. To improve
performance we've limited the return data to ${RESULT_LIMIT.toLocaleString()}
rows.`;

const pnrGroupBys: { value: keyof PnrAggregate; label: string }[] = [
  { value: "season", label: "Season" },
  { value: "gameId", label: "Game" },
  { value: "offTeamId", label: "Offense Team" },
  { value: "defTeamId", label: "Defense Team" },
  { value: "ballhandlerId", label: "Ballhandler" },
  { value: "ballhandlerPos", label: "Ballhandler Position" },
  { value: "screenerId", label: "Screener" },
  { value: "screenerPos", label: "Screener Position" },
  { value: "ballhandlerDefenderId", label: "Ballhandler Defender" },
  { value: "ballhandlerDefenderPos", label: "Ballhandler Defender Position" },
  { value: "screenerDefenderId", label: "Screener Defender" },
  { value: "screenerDefenderPos", label: "Screener Defender Position" },
  {
    value: "period",
    label: "Period",
  },
  {
    value: "playoffs",
    label: "Playoffs",
  },
  { value: "bhrDefenderCoverage", label: "Ballhandler Defender Coverage" },
  { value: "scrDefenderCoverage", label: "Coverage" },
  { value: "scrDefenderDepth", label: "Screener Defender Depth" },
  { value: "type", label: "Type" },
  { value: "screenType", label: "Screen Type" },
  { value: "direction", label: "Direction" },
  { value: "floorSide", label: "Floor Side" },
  { value: "rollPop", label: "Roll/Pop" },
  { value: "pickSlip", label: "Pick/Slip" },
  { value: "numPerimSpacers", label: "# Perimeter Spacers" },
  { value: "numElitePerimSpacers", label: "# Elite Perimeter Spacers" },
  { value: "numAboveAvgPerimSpacers", label: "# Above Avg Perimeter Spacers" },
  { value: "numBelowAvgPerimSpacers", label: "# Below Avg Perimeter Spacers" },
  { value: "numRimSpacers", label: "# Rim Spacers" },
  // TODO(chrisbu): Add back when we include double screens again.
  // { value: "double", label: "Double" },
  { value: "horns", label: "Horns" },
  { value: "drag", label: "Drag" },
  { value: "emptySide", label: "Empty Side" },
  { value: "dunkerFilled", label: "Dunker Filled" },
  { value: "fiveOut", label: "5-Out" },
];

const SELECTABLE_COLUMNS: {
  value: keyof PnrAggregate;
  label: string;
  category: string;
}[] = [
  { value: "xppp", label: "xPPP", category: "Team Outcome" },
  { value: "stderr", label: "SE", category: "Team Outcome" },
  { value: "xpps", label: "xPPS", category: "Team Outcome" },
  { value: "xOrbPct", label: "xOR%", category: "Team Outcome" },
  { value: "xTurnoverPct", label: "xTOV%", category: "Team Outcome" },

  { value: "layupPct", label: "Layup %", category: "Team Shot Selection" },
  { value: "threePct", label: "3PA %", category: "Team Shot Selection" },
  { value: "nonLayupTwoPct", label: "NL2 %", category: "Team Shot Selection" },

  {
    value: "bhrShootPct",
    label: "Shoot %",
    category: "Ballhandler Results",
  },
  { value: "bhrxPPS", label: "xPPS", category: "Ballhandler Results" },
  { value: "bhr3paPct", label: "3PA %", category: "Ballhandler Results" },
  { value: "bhr3pPct", label: "3P %", category: "Ballhandler Results" },
  {
    value: "bhr2pJumperRate",
    label: "2P Jumper Rate",
    category: "Ballhandler Results",
  },
  {
    value: "bhr2pJumperxPPS",
    label: "2P Jumper xPPS",
    category: "Ballhandler Results",
  },
  {
    value: "bhrAstOppPct",
    label: "Ast Opp %",
    category: "Ballhandler Results",
  },
  {
    value: "bhrToPct",
    label: "TOV %",
    category: "Ballhandler Results",
  },
  {
    value: "bhrAstScrPct",
    label: "Ast Scr %",
    category: "Ballhandler Results",
  },

  // Screener Results go here.
  {
    value: "scrCatchPct",
    label: "Catch %",
    category: "Screener Results",
  },
  {
    value: "scrxPPS",
    label: "xPPS",
    category: "Screener Results",
  },
  {
    value: "scrCatchShootPct",
    label: "Catch Shoot %",
    category: "Screener Results",
  },
  {
    value: "scrAstOppPct",
    label: "Ast Opp %",
    category: "Screener Results",
  },
  {
    value: "scrToPct",
    label: "TOV %",
    category: "Screener Results",
  },
  {
    value: "scrPopPct",
    label: "Pop %",
    category: "Screener Results",
  },
  {
    value: "scrPop3paPct",
    label: "Pop 3PA %",
    category: "Screener Results",
  },
  {
    value: "scrPopxPPS",
    label: "Pop xPPS",
    category: "Screener Results",
  },
  {
    value: "scrPop3pPct",
    label: "Pop 3P %",
    category: "Screener Results",
  },

  { value: "ppp", label: "PPP", category: "Actual Results" },
  { value: "pps", label: "PPS", category: "Actual Results" },
  { value: "turnoverPct", label: "TOV%", category: "Actual Results" },
  { value: "orbPct", label: "OR%", category: "Actual Results" },
  { value: "layupFgPct", label: "Layup FG%", category: "Actual Results" },
  { value: "threeFgPct", label: "3P FG%", category: "Actual Results" },
  { value: "nonLayupTwoFgPct", label: "NL2 FG%", category: "Actual Results" },
];

export function PnrExplorerPage() {
  const [showColumnChooser, setShowColumnChooser] = useState(true);
  const [video, setVideo] = useState<PnrAggregate>();

  const [queryParams, setQueryParams] = useQueryParams({
    sorting: JsonParam as QueryParamConfig<SortingState>,
    groupBy: withDefault(DelimitedArrayParam, [
      "offTeamId",
    ]) as QueryParamConfig<(keyof PnrAggregate)[]>,
    filters: withDefault(JsonParam, {
      season: [AppContext.currentSeason],
      type: ["pnr"],
      scrDefenderCoverage: SCR_DEFENDER_COVERAGES.map((c) => c.value).filter(
        (c) => c !== "non-threatening"
      ),
    }) as QueryParamConfig<PnrFilters>,
    pnrThreshold: withDefault(NumberParam, 0),
    columns: withDefault(DelimitedArrayParam, [
      "numPicks",
      "xppp",
      "stderr",
    ]) as QueryParamConfig<(keyof PnrAggregate)[]>,
    level: withDefault(StringParam, "poss"),
  });

  const { groupBy, filters, sorting, pnrThreshold, columns, level } =
    queryParams;

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (
    p: NbaEaglePlayer,
    offense = true,
    positions: number[] = []
  ) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    // If positions are provided see if the player matches any of them. If they
    // don't we can bail out here.
    if (positions.length) {
      const playerPos = Math.round(p.pos_estimate);
      if (!positions.some((pos) => pos === playerPos)) {
        return false;
      }
    }

    const seasons = filters.season;
    const teams = offense ? filters.offTeamId : filters.defTeamId;

    if (seasons?.length && teams?.length) {
      for (const season of seasons) {
        for (const team of teams) {
          const seasonEntry = entry[season];
          if (seasonEntry && seasonEntry.has(team)) {
            return true;
          }
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) => Object.keys(entry).includes(season));
    } else if (teams?.length) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return teams.some((team) => allTeams.has(team));
    }

    return true;
  };

  const { data: pnrs } = trpc.pnr2.getPnrAggregate.useQuery(
    {
      groupBy: groupBy.join(","),
      filters,
      limit: RESULT_LIMIT,
    },
    { enabled: groupBy.length > 0 }
  );

  function pnrToFilters(pnr: PnrAggregate) {
    const retObj: Record<string, unknown> = {};

    (Object.keys(pnr) as (keyof PnrAggregate)[]).forEach((key) => {
      switch (key) {
        // These are the "do nothing cases".
        case "gameDate":
        case "gameString":
        case "offTeam":
        case "defTeam":
        case "ballhandler":
        case "ballhandlerDefender":
        case "screener":
        case "screenerDefender":
          // These are the "do nothing cases" but they are group-by able.
          break;
        case "numPicks":
        case "xppp":
        case "xppp_action":
        case "stderr":
        case "stderr_action":
        case "xpps":
        case "xpps_action":
        case "layupPct":
        case "layupPct_action":
        case "threePct":
        case "threePct_action":
        case "nonLayupTwoPct":
        case "nonLayupTwoPct_action":
        case "xTurnoverPct":
        case "turnoverPct":
        case "turnoverPct_action":
        case "orbPct":
        case "orbPct_action":
        case "xOrbPct":
        case "xOrbPct_action":
        case "bhrShootPct":
        case "bhrxPPS":
        case "bhr3paPct":
        case "bhr3pPct":
        case "bhr2pJumperRate":
        case "bhr2pJumperxPPS":
        case "bhrAstOppPct":
        case "bhrToPct":
        case "bhrAstScrPct":
        case "scrCatchPct":
        case "scrxPPS":
        case "scrCatchShootPct":
        case "scrAstOppPct":
        case "scrToPct":
        case "scrPopPct":
        case "scrPop3paPct":
        case "scrPopxPPS":
        case "scrPop3pPct":
        case "ppp":
        case "ppp_action":
        case "pps":
        case "pps_action":
        case "layupFgPct":
        case "layupFgPct_action":
        case "threeFgPct":
        case "threeFgPct_action":
        case "nonLayupTwoFgPct":
        case "nonLayupTwoFgPct_action":
        case "hasVideo":
          // TODO(chrisbu): Can we figure out the TS magic to narrow the type
          // downto only the PnrAggregate keys used in pnrGroupBys so that we
          // don't have all the skips here?
          break;
        case "season":
        case "gameId":
        case "period":
        case "offTeamId":
        case "defTeamId":
        case "ballhandlerId":
        case "ballhandlerDefenderId":
        case "screenerId":
        case "screenerDefenderId":
        case "type":
        case "screenType":
        case "direction":
        case "floorSide":
        case "rollPop":
        case "pickSlip":
        case "bhrDefenderCoverage":
        case "scrDefenderCoverage":
        case "scrDefenderDepth":
        case "locationType":
        case "ballhandlerPos":
        case "screenerPos":
        case "ballhandlerDefenderPos":
        case "screenerDefenderPos":
          // These are the multiselect cases.
          retObj[key] = [pnr[key] + ""];
          break;
        case "numPerimSpacers":
        case "numElitePerimSpacers":
        case "numAboveAvgPerimSpacers":
        case "numBelowAvgPerimSpacers":
        case "numRimSpacers":
        case "playoffs":
        case "horns":
        case "drag":
        case "emptySide":
        case "dunkerFilled":
        case "fiveOut":
          // These are the booleans/single values;
          retObj[key] = pnr[key];
          break;
        default: {
          // Exhaustive check that makes sure we are handling all possible
          // group bys and won't compile if we are missing any.
          const exhaustiveCheck: never = key;
          throw new Error(`Unhandled key: ${exhaustiveCheck}`);
        }
      }
    });
    return retObj;
  }

  const videoFilters = { ...filters, ...(video ? pnrToFilters(video) : {}) };

  const { data: videoData } = trpc.pnr2.getPnrVideo.useQuery(
    {
      limit: RESULT_LIMIT,
      filters: { ...videoFilters },
    },
    { enabled: !!video }
  );

  const clips = videoData
    ? videoData
        .filter((vd) => vd.synergyURL)
        // TODO(chrisbu): Remove this shim when PNR explorer 2 is done.
        .map((vd) => {
          return {
            ...vd,
            synergyUrl: vd.synergyURL,
            oteam: vd.offTeam,
            dteam: vd.defTeam,
          };
        })
        .map(pnrToSynergyEditorClip)
    : [];

  const filteredPnrs = useMemo<PnrAggregate[]>(() => {
    return pnrs ? pnrs.filter((s) => s.numPicks >= pnrThreshold) : [];
  }, [pnrs, pnrThreshold]);

  const allData = pnrs ? pnrs : [];

  const msg = `Minimum PNRs: ${pnrThreshold.toLocaleString()}
  (showing ${filteredPnrs.length.toLocaleString()} of
  ${allData.length.toLocaleString()} total rows)`;

  return (
    <Page title="[WIP]: PNR Explorer" header={{ text: "[WIP]: PNR Explorer" }}>
      <>
        <Panel header={"Filters"}>
          <FilterForm
            filters={filters}
            setFilters={(f) => setQueryParams({ filters: f })}
            controls={[
              // Group 1 - Season/Period
              [
                // Row 1
                [
                  {
                    label: "Season",
                    key: "season",
                    type: "multiselect",
                    options: AppContext.seasons.map((s) => {
                      return { value: s.value.toString(), label: s.label };
                    }),
                  },
                  {
                    label: "Period",
                    key: "period",
                    type: "multiselect",
                    options: Object.keys(Periods).map((p) => {
                      return { value: p, label: Periods[parseInt(p)] || "" };
                    }),
                  },
                ],
              ],
              // Group 2 - Date/Playoffs
              [
                // Row 1
                [
                  {
                    label: "Date",
                    key: "dateFrom",
                    key2: "dateTo",
                    type: "daterange",
                  },
                  {
                    label: "Is Playoffs",
                    key: "playoffs",
                    type: "boolean",
                  },
                ],
                // Row 2
                [
                  {
                    label: "Shot Clock",
                    key: "shotClockFrom",
                    key2: "shotClockTo",
                    type: "range",
                    maxValue: 24,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) => `${v1}s - ${v2}s`,
                  },
                  {
                    label: "Leverage",
                    key: "leverageFrom",
                    key2: "leverageTo",
                    type: "leverage",
                  },
                ],
              ],
              // Group 3 - Offense
              [
                // Row 1
                [
                  {
                    label: "Offense Team",
                    key: "offTeamId",
                    type: "multiselect",
                    options: teams.map((t) => {
                      return {
                        label: `${t.teamcity} ${t.teamname}`,
                        value: t.teamid.toString(),
                      };
                    }),
                  },
                ],
                // Row 2
                [
                  {
                    label: "Ballhandler",
                    key: "ballhandlerId",
                    type: "multiselect",
                    options: players
                      .filter((p) =>
                        filterPlayers(p, true, filters.ballhandlerPos)
                      )
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Ballhandler Position",
                    key: "ballhandlerPos",
                    type: "numericmultiselect",
                    options: Object.keys(Positions).map((p) => {
                      return {
                        value: parseInt(p),
                        label: Positions[parseInt(p)] || "",
                      };
                    }),
                  },
                ],

                // Row 3
                [
                  {
                    label: "Screener",
                    key: "screenerId",
                    type: "multiselect",
                    options: players
                      .filter((p) =>
                        filterPlayers(p, true, filters.screenerPos)
                      )
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Screener Position",
                    key: "screenerPos",
                    type: "numericmultiselect",
                    options: Object.keys(Positions).map((p) => {
                      return {
                        value: parseInt(p),
                        label: Positions[parseInt(p)] || "",
                      };
                    }),
                  },
                ],
                [
                  {
                    label: "Ballhandler Ability",
                    key: "ballhandlerPctlFrom",
                    key2: "ballhandlerPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                  {
                    label: "Ballhandler Pocket 3 Ability",
                    key: "ballhandlerPocket3PctlFrom",
                    key2: "ballhandlerPocket3PctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
                [
                  {
                    label: "Screener Ability",
                    key: "screenerPctlFrom",
                    key2: "screenerPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                  {
                    type: "blank",
                  },
                ],
                [
                  {
                    label: "Popper Catch & Shoot 3 Ability",
                    key: "popperCatchAndShoot3PctlFrom",
                    key2: "popperCatchAndShoot3PctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                  {
                    label: "Roller OR Ability",
                    key: "rollerOrPctlFrom",
                    key2: "rollerOrPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
              ],
              // Group 4 - Defense
              [
                // Row 1
                [
                  {
                    label: "Defense Team",
                    key: "defTeamId",
                    type: "multiselect",
                    options: teams.map((t) => {
                      return {
                        label: `${t.teamcity} ${t.teamname}`,
                        value: t.teamid.toString(),
                      };
                    }),
                  },
                ],
                // Row 2
                [
                  {
                    label: "Ballhandler Defender",
                    key: "ballhandlerDefenderId",
                    type: "multiselect",
                    options: players
                      .filter((p) =>
                        filterPlayers(p, false, filters.ballhandlerDefenderPos)
                      )
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Ballhandler Defender Position",
                    key: "ballhandlerDefenderPos",
                    type: "numericmultiselect",
                    options: Object.keys(Positions).map((p) => {
                      return {
                        value: parseInt(p),
                        label: Positions[parseInt(p)] || "",
                      };
                    }),
                  },
                ],
                // Row 3
                [
                  {
                    label: "Screener Defender",
                    key: "screenerDefenderId",
                    type: "multiselect",
                    options: players
                      .filter((p) =>
                        filterPlayers(p, false, filters.screenerDefenderPos)
                      )
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Screener Defender Position",
                    key: "screenerDefenderPos",
                    type: "numericmultiselect",
                    options: Object.keys(Positions).map((p) => {
                      return {
                        value: parseInt(p),
                        label: Positions[parseInt(p)] || "",
                      };
                    }),
                  },
                ],
                [
                  {
                    label: "Ballhandler Defender Ability",
                    key: "ballhandlerDefenderPctlFrom",
                    key2: "ballhandlerDefenderPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                  {
                    label: "Screener Defender Ability",
                    key: "screenerDefenderPctlFrom",
                    key2: "screenerDefenderPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
              ],
              [
                // Group 5 - Perimeter Spacers
                [
                  {
                    label: "Perimeter Spacer",
                    key: "perimeterSpacerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "# Perimeter Spacers",
                    key: "fromNumPerimSpacers",
                    key2: "toNumPerimSpacers",
                    type: "range",
                    maxValue: 3,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) =>
                      v1 === v2 ? `${v1} Spacers` : `${v1} - ${v2} Spacers`,
                  },
                  {
                    label: "With Shooting Ability",
                    key: "fromPerimSpacersPctl",
                    key2: "toPerimSpacersPctl",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
              ],
              // Group 6 - Rim Spacers
              [
                [
                  {
                    label: "Rim Spacer",
                    key: "rimSpacerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "# Rim Spacers",
                    key: "fromNumRimSpacers",
                    key2: "toNumRimSpacers",
                    type: "range",
                    maxValue: 3,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) =>
                      v1 === v2 ? `${v1} Spacers` : `${v1} - ${v2} Spacers`,
                  },
                ],
              ],
              [
                // Group 7 - PNR Context
                [
                  {
                    label: "PNR/DHO",
                    key: "type",
                    type: "multiselect",
                    options: PNR_TYPES,
                  },
                  {
                    label: "Screen Type",
                    key: "screenType",
                    type: "multiselect",
                    options: SCREEN_TYPES,
                  },
                  {
                    label: "Direction",
                    key: "direction",
                    type: "multiselect",
                    options: [
                      { value: "right", label: "Right" },
                      { value: "left", label: "Left" },
                      { value: "none", label: "None" },
                    ],
                  },
                ],
                [
                  {
                    label: "Floor Side",
                    key: "floorSide",
                    type: "multiselect",
                    options: [
                      { value: "right", label: "Right" },
                      { value: "left", label: "Left" },
                      { value: "middle", label: "Middle" },
                    ],
                  },
                  {
                    label: "Roll/Pop",
                    key: "rollPop",
                    type: "multiselect",
                    options: [
                      {
                        value: "pop",
                        label: "Pop",
                      },
                      {
                        value: "roll",
                        label: "Roll",
                      },
                      {
                        value: "other",
                        label: "Other",
                      },
                    ],
                  },
                  {
                    label: "Pick/Slip",
                    key: "pickSlip",
                    type: "multiselect",
                    options: [
                      {
                        value: "pick",
                        label: "Pick",
                      },
                      {
                        value: "slip",
                        label: "Slip",
                      },
                    ],
                  },
                ],
              ],
              [
                // Group 8 - PNR Defense Context
                [
                  {
                    label: "Ballhandler Defender Coverage",
                    key: "bhrDefenderCoverage",
                    type: "multiselect",
                    options: BHR_DEFENDER_COVERAGES,
                  },
                  {
                    type: "blank",
                  },
                ],
                [
                  {
                    label: "Coverage",
                    key: "scrDefenderCoverage",
                    type: "multiselect",
                    options: SCR_DEFENDER_COVERAGES,
                  },
                  {
                    label: "Screener Defender Depth",
                    key: "scrDefenderDepth",
                    type: "multiselect",
                    options: SCR_DEFENDER_DEPTH,
                  },
                ],
              ],
              // Group 9 - PNR Booleans
              [
                // Row 1
                [
                  // TODO(chrisbu): Add back when we include double screens again.
                  // {
                  //   label: "Double",
                  //   key: "double",
                  //   type: "boolean",
                  // },
                  {
                    label: "Horns",
                    key: "horns",
                    type: "boolean",
                  },
                  {
                    label: "Drag",
                    key: "drag",
                    type: "boolean",
                  },
                  {
                    type: "blank",
                  },
                  {
                    type: "blank",
                  },
                ],
              ],
              // Group 10 - Spacing Booleans
              [
                // Row 1
                [
                  {
                    label: "Empty Side",
                    key: "emptySide",
                    type: "boolean",
                  },
                  {
                    label: "Dunker Filled",
                    key: "dunkerFilled",
                    type: "boolean",
                  },
                  {
                    label: "5-Out",
                    key: "fiveOut",
                    type: "boolean",
                  },
                  {
                    type: "blank",
                  },
                ],
              ],
            ]}
          />
          <Form.Group>
            <Form.Label>Group By</Form.Label>
            <MultiSelect
              values={pnrGroupBys}
              selected={groupBy}
              onChange={(g) =>
                setQueryParams({ groupBy: g as (keyof PnrAggregate)[] })
              }
            />
          </Form.Group>
          <Form style={{ marginTop: 12 }}>
            <div>
              <Form.Label>Select Columns</Form.Label>{" "}
              <ExpandCollapseButton
                onClick={() => setShowColumnChooser(!showColumnChooser)}
                expanded={showColumnChooser}
              />
            </div>
            <Collapse in={showColumnChooser}>
              <Row>
                {Object.entries(
                  arrGroupBy(SELECTABLE_COLUMNS, (x) => x.category)
                ).map(([cat, cols]) => {
                  const areAllColsInCatChecked = cols.every((sc) =>
                    columns.includes(sc.value)
                  );
                  return (
                    <Col key={cat} md={3}>
                      <div>
                        <Form.Label style={{ marginRight: 4 }}>
                          {cat}
                        </Form.Label>
                        <Form.Check
                          checked={areAllColsInCatChecked}
                          type="checkbox"
                          key={cat}
                          inline
                          style={{ verticalAlign: "top" }}
                          onChange={() => {
                            // If areAllColsInCatChecked is true, uncheck all
                            // columns in the category. Else check all columns
                            // in the category.
                            const newColumns = areAllColsInCatChecked
                              ? columns.filter(
                                  (c) => !cols.some((sc) => sc.value === c)
                                )
                              : [...columns, ...cols.map((sc) => sc.value)];
                            setQueryParams({ columns: newColumns });
                          }}
                        />
                      </div>
                      {cols.map((sc) => {
                        return (
                          <Form.Check
                            type="checkbox"
                            key={sc.value}
                            inline
                            onChange={() => {
                              const idx = columns.findIndex(
                                (c) => c === sc.value
                              );
                              const newColumns = [...columns];
                              if (idx === -1) {
                                newColumns.push(sc.value);
                              } else {
                                // Else remove the item from the array.
                                newColumns.splice(idx, 1);
                              }
                              setQueryParams({ columns: newColumns });
                            }}
                            checked={columns.includes(sc.value)}
                            label={sc.label}
                          />
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Collapse>
          </Form>
        </Panel>
        <Panel header={"Results"}>
          <Row style={{ marginBottom: 8 }}>
            <Col>
              <FilterChips
                filters={filters}
                deletableFilters={Object.keys(filters)}
                onRemove={(k) => {
                  const key = k as keyof PnrFilters;
                  // Remove this key from filters object and update.
                  const newFilters = { ...filters };
                  delete newFilters[key];
                  setQueryParams({ filters: newFilters });
                }}
              />
            </Col>
          </Row>
          {pnrs ? (
            <>
              <Row>
                {pnrs.length === RESULT_LIMIT && showWarning(RESULT_LIMIT_MSG)}
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{msg}</Form.Label>
                    <Form.Range
                      min={0}
                      max={1000}
                      value={pnrThreshold}
                      onChange={(evt) => {
                        setQueryParams({
                          pnrThreshold: parseInt(evt.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <ToggleButtonGroup
                    style={{ marginTop: 12 }}
                    name="action-level-toggle"
                    type="radio"
                    value={level}
                    onChange={(v) => setQueryParams({ level: v })}
                  >
                    <ToggleButton id="action-level-toggle-poss" value={"poss"}>
                      Possession Level
                    </ToggleButton>
                    <ToggleButton
                      id="action-level-toggle-action"
                      value={"action"}
                    >
                      Action Level
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              </Row>
              <Row>
                <PnrQueryResultsTable
                  data={filteredPnrs}
                  groupBy={groupBy}
                  sorting={sorting}
                  setSorting={(s) => setQueryParams({ sorting: s })}
                  columns={columns}
                  actionChanceLevel={level}
                  onVideo={(data: PnrAggregate) => setVideo(data)}
                />
              </Row>
              <VideoModal
                title={"PNR Explorer"}
                show={!!video && !!clips.length}
                clips={clips}
                handleClose={() => setVideo(undefined)}
                upDownClipSkip={true}
                showSynergyEditor={true}
              />
            </>
          ) : (
            <>
              {groupBy.length === 0 ? (
                <Alert variant="danger">
                  At least one group by must be selected.
                </Alert>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </Panel>
      </>
    </Page>
  );
}

function showWarning(msg: string) {
  return <Alert variant="warning">{msg}</Alert>;
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const yearObj = map[year];
    if (!yearObj) {
      map[year] = new Set([team]);
    } else {
      yearObj.add(team);
    }
  }
  return map;
}

function pctlRangeFmt(v1: number, v2: number) {
  return v1 === v2
    ? `${ordinalFormat(Math.round(v1 * 100))} Percentile`
    : `${ordinalFormat(Math.round(v1 * 100))}  - ${ordinalFormat(
        Math.round(v2 * 100)
      )}  Percentile`;
}
