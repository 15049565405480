import React, { useContext } from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  QueryParamConfig,
  DelimitedArrayParam,
} from "use-query-params";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TeamWowFilters } from "../components/team/TeamWowFilters";
import { TeamWowSummary } from "../components/team/TeamWowSummary";
import { TeamWowBoxes } from "../components/team/TeamWowBoxes";
import { CELTICS_TEAM_ID } from "../constants/AppConstants";
import AppContext from "../../shared/AppContext";
import { Spinner } from "../components/core/Spinner";
import { SSPlayerContext } from "../PlayerContext";
import { trpc } from "../util/tRPC";
import { PossessionFilters } from "../../shared/routers/PossessionRouter";

export function WowPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    team: withDefault(StringParam, CELTICS_TEAM_ID.toString()),
    season: withDefault(StringParam, AppContext.currentSeason),
    withPlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    withoutPlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    withOnePlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    gameDate: withDefault(DelimitedArrayParam, ["", ""]) as QueryParamConfig<
      string[]
    >,
    leverage: withDefault(StringParam, undefined) as QueryParamConfig<string>,
  });
  const {
    team,
    season,
    withPlayer,
    withoutPlayer,
    withOnePlayer,
    gameDate,
    leverage,
  } = queryParams;

  const allPlayers = useContext(SSPlayerContext);

  const totalPlayers =
    withPlayer.length + withoutPlayer.length + withOnePlayer.length;

  const filters: PossessionFilters = {
    season: [season],
    teamId: [team],
    fromDate: gameDate[0] || undefined,
    toDate: gameDate[1] || undefined,
    leverage: leverage || undefined,
    wowyLineup: {
      withPlayers: withPlayer,
      withoutPlayers: withoutPlayer,
      oneOfPlayers: withOnePlayer,
    },
  };

  const { data: possessions } = trpc.possesion.getAggPossesions.useQuery({
    filters,
    limit: 5_000,
    groupBy: ["lineup"],
  });

  const { data: playerStats } =
    trpc.possesion.getAggPossesionPlayerStats.useQuery({
      filters,
      limit: 5_000,
      groupBy: ["lineup"],
    });

  const toLastName = (playerId: string) => {
    const foundPlayer = allPlayers.find(
      (p) => playerId === p.playerId.toString()
    );
    if (foundPlayer) {
      return foundPlayer.lastName;
    }
    return "";
  };

  const withPlayerLastNames = withPlayer.map(toLastName);
  const withoutPlayerLastNames = withoutPlayer.map(toLastName);
  const withOnePlayerLastNames = withOnePlayer.map(toLastName);

  const header = (
    <div>
      <h1>With or Without</h1>
      <div>
        See player box score stats when playing with or without specified
        teammates.
      </div>
    </div>
  );

  if (allPlayers.length === 0) return null;

  const lineupPoss = (possessions || []).find((p) => p.lineup === true);
  const lineupPlayerStats = (playerStats || []).filter((p) => p.lineup);
  const complementPoss = (possessions || []).find((p) => p.lineup === false);
  const complementPlayerStats = (playerStats || []).filter(
    (p) => p.lineup === false
  );

  return (
    <Page title={"With or Without"} header={{ component: header }}>
      <div>
        <Panel header="Filters">
          {allPlayers && (
            <TeamWowFilters
              allPlayers={allPlayers}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              showSeasonAndDateControls={true}
              showLeverageControls={true}
            />
          )}
        </Panel>
        {allPlayers && totalPlayers > 0 && (
          <>
            <Panel header="Summary">
              {possessions ? (
                lineupPoss && complementPoss ? (
                  <TeamWowSummary
                    filters={filters}
                    lineup={lineupPoss}
                    complement={complementPoss}
                  />
                ) : (
                  <div>No data found for this player combination.</div>
                )
              ) : (
                <Spinner />
              )}
            </Panel>
            <Panel header="Filtered Results">
              <>
                {wowString(
                  withPlayerLastNames,
                  withoutPlayerLastNames,
                  withOnePlayerLastNames
                )}
                {possessions && playerStats ? (
                  lineupPlayerStats ? (
                    <TeamWowBoxes data={lineupPlayerStats} />
                  ) : (
                    <div>No data found for this player combination.</div>
                  )
                ) : (
                  <Spinner />
                )}
              </>
            </Panel>
            <Panel header="Everything Else">
              <>
                {wowString(
                  withPlayerLastNames,
                  withoutPlayerLastNames,
                  withOnePlayerLastNames,
                  true
                )}
                {possessions && playerStats ? (
                  complementPlayerStats ? (
                    <TeamWowBoxes data={complementPlayerStats} />
                  ) : (
                    <div>No data found for this player combination.</div>
                  )
                ) : (
                  <Spinner />
                )}
              </>
            </Panel>
          </>
        )}
      </div>
    </Page>
  );
}

export function wowString(
  withPlayers: string[],
  withoutPlayers: string[],
  withOnePlayer: string[],
  complement?: boolean
) {
  const withStr = withPlayers.length
    ? [
        <span key={0}>{complement ? "did not have " : "included "}</span>,
        <b key={1}>{withPlayers.join(" and ")}</b>,
        withoutPlayers.length > 0 || withOnePlayer.length > 0 ? (
          <span>{complement ? " or " : " and "}</span>
        ) : (
          ""
        ),
      ]
    : "";

  const withoutStr = withoutPlayers.length
    ? [
        <span key={0}>{complement ? "did include " : "did not include "}</span>,
        <b key={1}>{withoutPlayers.join(" or ")}</b>,
        withOnePlayer.length > 0 ? (
          <span>{complement ? " or " : " and "}</span>
        ) : (
          ""
        ),
      ]
    : "";

  const withOneStr = withOnePlayer.length
    ? [
        <span key={0}>
          {complement
            ? "did not include exactly one of "
            : "included exactly one of "}
        </span>,
        <b key={1}>{withOnePlayer.join(" or ")}</b>,
      ]
    : "";

  return (
    <p style={{ fontSize: 22, fontWeight: 200 }}>
      <span>{"Showing box score stats when lineups "}</span>
      {withStr}
      {withoutStr}
      {withOneStr}
      <span>.</span>
    </p>
  );
}
