import React from "react";

import { trpc } from "../../util/tRPC";

export function DebugInfo(props: {
  playerId?: number;
  gameId?: number;
  teamId?: number;
  isGleagueGame?: boolean;
}) {
  const { playerId, gameId, teamId, isGleagueGame } = props;

  const { data: gameInfoData } = trpc.game.getGameDebugInfo.useQuery({
    gameId,
    isGLeague: isGleagueGame,
  });

  const gameInfo = gameInfoData && gameInfoData[0];

  const { data: teamInfoData } = trpc.team.getTeamDebugInfo.useQuery({
    teamId,
  });

  const teamInfo = teamInfoData && teamInfoData[0];

  const { data: playerInfoData } = trpc.player.getPlayerBios.useQuery({
    playerIds: playerId ? [playerId.toString()] : [],
  });

  const playerInfo = playerInfoData && playerInfoData[0];

  const debugInfo: Record<string, string | number | null> = {};

  if (gameInfo) {
    debugInfo["ALAG"] = gameInfo.alag;
    debugInfo["IDS"] = gameInfo.ids;
    debugInfo["Eagle"] = gameInfo.Eagle;
    debugInfo["EagleInt"] = gameInfo.eagleInt;
    debugInfo["Draft Express"] = gameInfo.de;
  } else if (teamInfo) {
    debugInfo["Celtics ID"] = teamInfo.Id;
    debugInfo["IDS"] = teamInfo.IDS;
    debugInfo["Eagle"] = teamInfo.Eagle;
    debugInfo["Draft Express"] = teamInfo.DE;
    debugInfo["International"] = teamInfo.Intl;
    debugInfo["Synergy20"] = teamInfo.Synergy20;
  } else if (playerInfo) {
    debugInfo["Celtics ID"] = playerInfo.playerId;
    debugInfo["IDS"] = playerInfo.idIds;
    debugInfo["Eagle"] = playerInfo.idEagle;
    debugInfo["Draft Express"] = playerInfo.idDe;
    debugInfo["NCAA"] = playerInfo.idNcaa;
    debugInfo["International"] = playerInfo.idIntl;
  }

  if (Object.keys(debugInfo).length === 0) return null;

  return (
    <ul
      style={{
        opacity: 0.5,
        fontSize: ".8em",
        marginBottom: 0,
        paddingLeft: 0,
        listStyle: "none",
        marginLeft: -5,
      }}
    >
      {Object.entries(debugInfo).map(([key, value]) => (
        <li
          key={key}
          style={{ display: "inline-block", paddingLeft: 5, paddingRight: 5 }}
        >
          <span>{key}</span>
          <div>{value == null ? "--" : value}</div>
        </li>
      ))}
    </ul>
  );
}
