import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { TeamLogo } from "../team/TeamLogo";
import { dateFormatLong, timeFormat } from "../../util/Format";
import { useWindowSize } from "../../util/Hooks";
import { UserPreferenceContext } from "../../UserContext";

export function GameMultiLeaguePageHeader(props: {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamName: string;
  homeTeamIdsId: number | null;
  awayTeamId: number;
  awayTeamIdsId: number | null;
  homeTeamDxId: number | null;
  homeTeamEspnId?: number;
  awayTeamEspnId?: number;
  awayTeamDxId: number | null;
  homePts: number;
  awayPts: number;
  gameDateTimeStr: string | null;
  league: string;
  season: number;
}) {
  const {
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    homeTeamIdsId,
    awayTeamIdsId,
    homeTeamEspnId,
    awayTeamEspnId,
    homeTeamDxId,
    awayTeamDxId,
    homePts,
    awayPts,
    gameDateTimeStr,
    season,
    league,
  } = props;

  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: isMobile ? 0 : 24,
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <PageHeaderTeam
            isMobile={isMobile}
            teamName={awayTeamName}
            isHome={false}
            teamId={awayTeamId}
            teamIdsId={awayTeamIdsId}
            espnId={awayTeamEspnId}
            dxId={awayTeamDxId}
            pts={awayPts}
            season={season}
            league={league}
          />
          <PageHeaderTeam
            isMobile={isMobile}
            teamName={homeTeamName}
            isHome={true}
            teamId={homeTeamId}
            teamIdsId={homeTeamIdsId}
            espnId={homeTeamEspnId}
            dxId={homeTeamDxId}
            pts={homePts}
            season={season}
            league={league}
          />
        </div>
      </div>
      {gameDateTimeStr && (
        <div
          style={{
            display: "flex",
            gap: 8,
            fontSize: 13,
            margin: "0 -30px 8px",
            padding: "3px 15px",
            color: "rgba(255,255,255,.5)",
          }}
        >
          {`${dateFormatLong(new Date(gameDateTimeStr))} at ${timeFormat(
            new Date(gameDateTimeStr)
          )}`}
        </div>
      )}
    </>
  );
}

function PageHeaderTeam(props: {
  isMobile: boolean;
  teamName: string;
  isHome: boolean;
  teamId: number;
  teamIdsId: number | null;
  espnId: number | undefined;
  dxId: number | null;
  pts: number;
  season: number;
  league: string;
}) {
  const {
    isMobile,
    teamName,
    isHome,
    pts,
    teamId,
    teamIdsId,
    espnId,
    dxId,
    season,
    league,
  } = props;
  const logoBlur = !!useContext(UserPreferenceContext)["Logo Blur"];

  const isGLeague = league === "g-league";
  const isNCAA = league === "ncaa";
  const logo = (
    <Link to={`/team/ml/${teamId}?season=${season}`}>
      <TeamLogo
        idsId={teamIdsId || undefined}
        espnId={espnId}
        dxId={dxId || undefined}
        blur={logoBlur}
        isGLeague={isGLeague}
        isNcaa={isNCAA}
        size={isNCAA ? 64 : undefined}
      />
    </Link>
  );

  const ptsDiv = (
    <div
      style={{
        marginRight: 16,
        marginLeft: 16,
      }}
    >
      <div
        style={{
          fontSize: 40,
          width: 60,
          textAlign: isHome ? "right" : "left",
        }}
      >
        {pts}
      </div>
      <div
        style={{
          textAlign: isHome ? "right" : "left",
          color: "rgba(255,255,255,.5)",
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {isHome ? "Home" : "Away"}
      </div>
    </div>
  );

  if (!isHome) {
    return (
      <div
        style={{
          display: "inline-block",
          verticalAlign: "top",
          marginRight: 16,
        }}
      >
        <h3>{teamName}</h3>
        <div style={{ display: "flex" }}>
          {logo}
          {ptsDiv}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "inline-block",
          verticalAlign: "top",
          marginLeft: 16,
          textAlign: "right",
          float: isHome && isMobile ? "right" : undefined,
        }}
      >
        <h3>{teamName}</h3>
        <div style={{ display: "flex" }}>
          {ptsDiv}
          {logo}
        </div>
      </div>
    );
  }
}
