import React, { useMemo, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import { dec100Format, decFormat, decFormat2 } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";
import { TeamThreePointShooting } from "../../../shared/routers/TeamRouter";

interface TeamThreePointShootingRow {
  player: string;
  playerId: number;
  onTeam: boolean;
  fga: number;
  fgm: number;
  fgPct: number;
  pps: number | null;
  xpps: number | null;
  pct: number;
  per48: number;
  perGame: number;
}

const columnHelper = createColumnHelper<TeamThreePointShootingRow>();

export function TeamThreePointShootingPanel(props: {
  threePointShooting?: TeamThreePointShooting[];
}) {
  const [showFullRoster, setShowFullRoster] = useState(false);

  const { threePointShooting } = props;

  if (!threePointShooting) return <Spinner />;

  const corner = threePointShooting
    .map((t) => {
      return {
        player: t.shooter,
        playerId: t.shooterId,
        onTeam: t.onTeam,
        fga: t.fgaCorner,
        fgm: t.fgmCorner,
        fgPct: t.fgmCorner / (t.fgaCorner || 0),
        pps: t.ppsCorner,
        xpps: t.xppsCorner,
        pct: t.pctCorner,
        per48: t.fgaPer48Corner,
        perGame: t.fgaPerGameCorner,
      };
    })
    .sort((a, b) => b.fga - a.fga)
    .filter((a) => a.fga > 0)
    .filter((c) => showFullRoster || (!showFullRoster && c.onTeam));

  const atb = threePointShooting
    .map((t) => {
      return {
        player: t.shooter,
        playerId: t.shooterId,
        onTeam: t.onTeam,
        fga: t.fgaATB,
        fgm: t.fgmATB,
        fgPct: t.fgmATB / (t.fgaATB || 0),
        pps: t.ppsATB,
        xpps: t.xppsATB,
        pct: t.pctATB,
        per48: t.fgaPer48ATB,
        perGame: t.fgaPerGameATB,
      };
    })
    .sort((a, b) => b.fga - a.fga)
    .filter((a) => a.fga > 0)
    .filter((a) => showFullRoster || (!showFullRoster && a.onTeam));

  return (
    <div>
      <Form.Check
        label="Show Full Roster"
        checked={showFullRoster}
        onChange={() => setShowFullRoster(!showFullRoster)}
      />
      <Row>
        <Col md={6}>
          <ThreePointTable type="Corner" data={corner} />
        </Col>
        <Col md={6}>
          <ThreePointTable type="Above the break" data={atb} />
        </Col>
      </Row>
    </div>
  );
}

export function ThreePointTable(props: {
  type: string;
  data: TeamThreePointShootingRow[];
}) {
  const { type, data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={`${info.getValue()}${!info.row.original.onTeam ? "*" : ""}`}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: 0, highlights: Highlights.Max },
      }),
      columnHelper.accessor("fgm", {
        header: () => "FGM",
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [1, 200],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("fga", {
        header: () => "FGA",
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [2, 350],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("fgPct", {
        header: () => "FG%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [0.25, 0.45],
        },
      }),
      columnHelper.accessor("pct", {
        header: () => "% of shots",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: [0.1, 0.54],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("per48", {
        header: () => "Per 48",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: type === "Corner" ? [0, 3.4] : [0, 9],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("perGame", {
        header: () => "Per Game",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: type === "Corner" ? [0, 1.92] : [0, 5.95],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("pps", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 3, highlights: Highlights.Max, colorDomain: [0.8, 1.2] },
      }),
      columnHelper.accessor("xpps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 3, highlights: Highlights.Max, colorDomain: [0.8, 1.2] },
      }),
    ],
    [type]
  );

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <b>{type} 3pt</b>
      </div>
      <Table
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
      />
    </div>
  );
}
