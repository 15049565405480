import React, { useMemo, useState, useCallback } from "react";
import { extent } from "d3";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Highlights } from "../../constants/AppConstants";
import {
  decFormat2,
  dec100Format,
  TABLE_EMPTY_VALUE_STR,
} from "../../util/Format";
import { PlayerTableCell } from "../core/TableCell";
import { GamePnr } from "../../../shared/routers/GameRouter";

const columnHelper = createColumnHelper<GamePnr>();

export function GamePnrTable(props: {
  data: GamePnr[];
  offDef: string;
  type: string;
  useColorDomain?: boolean;
  heatmapAlways?: boolean;
  usePercentage?: boolean;
  threshold?: number;
}) {
  const {
    data,
    offDef,
    type,
    useColorDomain,
    heatmapAlways,
    usePercentage,
    threshold,
  } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const highlightByOffDef =
    offDef === "offense" ? Highlights.Max : Highlights.Min;

  const formatHeader = useCallback(
    (label: string) => {
      return offDef === "offense" ? `vs. ${label}` : label;
    },
    [offDef]
  );

  const columns = useMemo(() => {
    const pppColorDomain = useColorDomain
      ? offDef === "offense"
        ? [0.84, 1.2]
        : [1.2, 0.84]
      : undefined;

    const countColorDomains: Record<string, number[]> = {
      overall: extent(data.map((d) => d.totalPicks)) as number[],
      blitz: extent(
        data.map((d) =>
          usePercentage ? d.blitzPicks / d.totalPicks : d.blitzPicks
        )
      ) as number[],
      contain: extent(
        data.map((d) =>
          usePercentage ? d.containPicks / d.totalPicks : d.containPicks
        )
      ) as number[],
      ice: extent(
        data.map((d) =>
          usePercentage ? d.icePicks / d.totalPicks : d.icePicks
        )
      ) as number[],
      reject: extent(
        data.map((d) =>
          usePercentage ? d.rejectPicks / d.totalPicks : d.rejectPicks
        )
      ) as number[],
      show: extent(
        data.map((d) =>
          usePercentage ? d.showPicks / d.totalPicks : d.showPicks
        )
      ) as number[],
      soft: extent(
        data.map((d) =>
          usePercentage ? d.softPicks / d.totalPicks : d.softPicks
        )
      ) as number[],
      veer: extent(
        data.map((d) =>
          usePercentage ? d.veerPicks / d.totalPicks : d.veerPicks
        )
      ) as number[],
      switch: extent(
        data.map((d) =>
          usePercentage ? d.switchPicks / d.totalPicks : d.switchPicks
        )
      ) as number[],
      under: extent(
        data.map((d) =>
          usePercentage ? d.underPicks / d.totalPicks : d.underPicks
        )
      ) as number[],
      upToTouch: extent(
        data.map((d) =>
          usePercentage ? d.upToTouchPicks / d.totalPicks : d.upToTouchPicks
        )
      ) as number[],
      weak: extent(
        data.map((d) =>
          usePercentage ? d.weakPicks / d.totalPicks : d.weakPicks
        )
      ) as number[],
      nonThreat: extent(
        data.map((d) => (usePercentage ? d.NTPicks / d.totalPicks : d.NTPicks))
      ) as number[],
    };
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.group({
        id: "overallGroup",
        meta: { group: 1 },
        header: "Overall",
        columns: [
          columnHelper.accessor("totalPicks", {
            header: () => "#",
            meta: {
              group: 1,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["overall"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.totalPicks < threshold
                ? null
                : row.overallPpp,
            {
              id: "overalPPP",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 1,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "blitzGroup",
        meta: { group: 2 },
        header: formatHeader("Blitz"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.blitzPicks / row.totalPicks : row.blitzPicks,
            {
              id: "blitzPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 2,
                highlights: Highlights.Max,
                colorDomain: countColorDomains["blitz"],
                neutralColorScheme: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.blitzPicks < threshold
                ? null
                : row.blitzPpp,
            {
              id: "blitzPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 2,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "containGroup",
        meta: { group: 3 },
        header: formatHeader("Contain"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage
                ? row.containPicks / row.totalPicks
                : row.containPicks,
            {
              id: "containPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 3,
                highlights: Highlights.Max,
                colorDomain: countColorDomains["contain"],
                neutralColorScheme: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.containPicks < threshold
                ? null
                : row.containPpp,
            {
              id: "containPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 3,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "iceGroup",
        meta: { group: 4 },
        header: formatHeader("Ice"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.icePicks / row.totalPicks : row.icePicks,
            {
              id: "icePicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 4,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["ice"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.icePicks < threshold
                ? null
                : row.icePpp,
            {
              id: "icePpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 4,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "rejectGroup",
        meta: { group: 5 },
        header: formatHeader("Reject"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage
                ? row.rejectPicks / row.totalPicks
                : row.rejectPicks,
            {
              id: "rejectPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 5,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["reject"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.rejectPicks < threshold
                ? null
                : row.rejectPpp,
            {
              id: "rejectPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 5,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "showGroup",
        meta: { group: 6 },
        header: formatHeader("Show"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.showPicks / row.totalPicks : row.showPicks,
            {
              id: "showPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 6,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["show"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.showPicks < threshold
                ? null
                : row.showPpp,
            {
              id: "showPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 6,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "softGroup",
        meta: { group: 7 },
        header: formatHeader("Soft"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.softPicks / row.totalPicks : row.softPicks,
            {
              id: "softPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 7,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["soft"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.softPicks < threshold
                ? null
                : row.softPpp,
            {
              id: "softPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 7,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "veerGroup",
        meta: { group: 8 },
        header: formatHeader("Veer"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.veerPicks / row.totalPicks : row.veerPicks,
            {
              id: "veeerPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 8,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["veer"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.veerPicks < threshold
                ? null
                : row.veerPpp,
            {
              id: "veerPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 8,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "switchGroup",
        meta: { group: 9 },
        header: formatHeader("Switch"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage
                ? row.switchPicks / row.totalPicks
                : row.switchPicks,
            {
              id: "switchPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 9,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["switch"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.switchPicks < threshold
                ? null
                : row.switchPpp,
            {
              id: "switchPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 9,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "underGroup",
        meta: { group: 10 },
        header: formatHeader("Under"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.underPicks / row.totalPicks : row.underPicks,
            {
              id: "underPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 10,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["under"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.underPicks < threshold
                ? null
                : row.underPpp,
            {
              id: "underPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 10,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "upToTouchGroup",
        meta: { group: 11 },
        header: formatHeader("Up To Touch"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage
                ? row.upToTouchPicks / row.totalPicks
                : row.upToTouchPicks,
            {
              id: "upToTouchPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 11,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["upToTouch"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.upToTouchPicks < threshold
                ? null
                : row.upToTouchPpp,
            {
              id: "upToTouchPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 11,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "weakGroup",
        meta: { group: 12 },
        header: formatHeader("Weak"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.weakPicks / row.totalPicks : row.weakPicks,
            {
              id: "weakPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 12,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["weak"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.weakPicks < threshold
                ? null
                : row.weakPpp,
            {
              id: "weakPpp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 12,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "nonThreatGroup",
        meta: { group: 13 },
        header: formatHeader("Non-Threat"),
        columns: [
          columnHelper.accessor(
            (row) =>
              usePercentage ? row.NTPicks / row.totalPicks : row.NTPicks,
            {
              id: "NTPicks",
              header: () => (usePercentage ? "%" : "#"),
              cell: (info) => {
                if (!info.getValue()) return TABLE_EMPTY_VALUE_STR;
                return usePercentage
                  ? dec100Format(info.getValue())
                  : info.getValue();
              },
              meta: {
                group: 13,
                highlights: Highlights.Max,
                neutralColorScheme: true,
                colorDomain: countColorDomains["nonThreat"],
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              threshold !== undefined && row.NTPicks < threshold
                ? null
                : row.NTPpp,
            {
              id: "NTPppp",
              header: () => "PPP",
              cell: (info) => decFormat2(info.getValue()),
              meta: {
                group: 13,
                highlights: highlightByOffDef,
                colorDomain: pppColorDomain,
                heatmap: heatmapAlways,
              },
            }
          ),
        ],
      }),
    ];
  }, [
    useColorDomain,
    offDef,
    data,
    highlightByOffDef,
    heatmapAlways,
    formatHeader,
    usePercentage,
    threshold,
  ]);

  let title = "";
  if (offDef === "offense" && type === "ballhandler") {
    title = "As Ballhandler";
  } else if (offDef === "offense" && type === "screener") {
    title = "As Screener";
  } else if (offDef === "defense" && type === "ballhandler") {
    title = "Defending Ballhandlers";
  } else if (offDef === "defense" && type === "screener") {
    title = "Defending Screeners";
  }

  return (
    <div>
      <b>{title}</b>
      <Table
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
      />
    </div>
  );
}
