import React, { useState, useMemo, useContext } from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  QueryParamConfig,
  DelimitedArrayParam,
} from "use-query-params";

import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TeamWowFilters } from "../components/team/TeamWowFilters";
import { TeamLineup } from "../../shared/routers/TeamRouter";
import { CELTICS_TEAM_ID, Highlights } from "../constants/AppConstants";
import AppContext from "../../shared/AppContext";
import { decFormat2, plusMinusFormat2 } from "../util/Format";
import { SSPlayerContext } from "../PlayerContext";
import { PlayerTableCell } from "../components/core/TableCell";
import { trpc } from "../util/tRPC";

export function LineupsPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    team: withDefault(StringParam, CELTICS_TEAM_ID.toString()),
    season: withDefault(StringParam, AppContext.currentSeason.toString()),
    withPlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    withoutPlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    withOnePlayer: withDefault(DelimitedArrayParam, []) as QueryParamConfig<
      string[]
    >,
    gameDate: withDefault(DelimitedArrayParam, ["", ""]) as QueryParamConfig<
      string[]
    >,
    leverage: withDefault(StringParam, undefined) as QueryParamConfig<string>,
  });
  const { team, withPlayer, withoutPlayer, withOnePlayer } = queryParams;

  const allPlayers = useContext(SSPlayerContext);

  const params = {
    teamId: team,
    mustHave: withPlayer.join(","),
    mustNotHave: withoutPlayer.join(","),
    exactlyOne: withOnePlayer.join(","),
  };

  const { data: lineups } = trpc.team.getTeamLineups.useQuery(params);

  const toLastName = (playerId: string) => {
    const foundPlayer = allPlayers.find(
      (p) => playerId === p.playerId.toString()
    );
    if (foundPlayer) {
      return foundPlayer.lastName;
    }
    return "";
  };

  const withPlayerLastNames = withPlayer.map(toLastName);
  const withoutPlayerLastNames = withoutPlayer.map(toLastName);
  const withOnePlayerLastNames = withOnePlayer.map(toLastName);
  const header = (
    <div>
      <h1>Lineups</h1>
      <div>
        See predicted lineup efficiencies for a given team and set of players.
      </div>
    </div>
  );

  if (allPlayers.length === 0) return null;

  const mostUsed =
    lineups &&
    lineups.filter((l) => l.type === "most").sort((a, b) => b.poss - a.poss);
  const bestPred =
    lineups &&
    lineups.filter((l) => l.type === "best").sort((a, b) => b.net - a.net);
  const worstPred =
    lineups &&
    lineups.filter((l) => l.type === "worst").sort((a, b) => a.net - b.net);

  return (
    <Page header={{ component: header }} title="Lineups">
      <div>
        <Panel header="Filters">
          {allPlayers && (
            <TeamWowFilters
              allPlayers={allPlayers}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              showSeasonAndDateControls={false}
              showLeverageControls={false}
            />
          )}
        </Panel>
        <Panel>
          {wowString(
            withPlayerLastNames,
            withoutPlayerLastNames,
            withOnePlayerLastNames
          )}
        </Panel>
        {mostUsed && (
          <LineupPanel title={"Most Used Lineups"} data={mostUsed} />
        )}
        {bestPred && (
          <LineupPanel title={"Best Predicted Lineups"} data={bestPred} />
        )}
        {worstPred && (
          <LineupPanel title={"Worst Predicted Lineups"} data={worstPred} />
        )}
      </div>
    </Page>
  );
}

const columnHelper = createColumnHelper<TeamLineup>();

function LineupPanel(props: { title: string; data: TeamLineup[] }) {
  const { data, title } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name1", {
        header: () => "Player 1",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.p1}
            name={info.getValue()}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("name2", {
        header: () => "Player 2",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.p2}
            name={info.getValue()}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("name3", {
        header: () => "Player 3",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.p3}
            name={info.getValue()}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("name4", {
        header: () => "Player 4",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.p4}
            name={info.getValue()}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("name5", {
        header: () => "Player 5",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.p5}
            name={info.getValue()}
            showPlayerStatusIcons={false}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("poss", {
        header: () => "Poss",
        meta: { group: 1, highlights: Highlights.Max },
      }),
      columnHelper.group({
        id: "prediction",
        header: () => "Predicted",
        meta: { group: 2 },
        columns: [
          columnHelper.accessor("oppp", {
            header: () => "Off PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: 2,
              colorDomain: [1.1, 1.14],
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("dppp", {
            header: () => "Def PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: 2,
              colorDomain: [1.14, 1.1],
              highlights: Highlights.Min,
            },
          }),
          columnHelper.accessor("net", {
            id: "net",
            header: () => "Net",
            cell: (info) => plusMinusFormat2(info.getValue()),
            meta: {
              group: 2,
              colorDomain: [-0.08, 0.08],
              highlights: Highlights.Max,
            },
          }),
        ],
      }),
    ],
    []
  );

  const showNoLineupsMsg = title === "Most Used Lineups" && data.length === 0;

  return (
    <Panel header={title}>
      {showNoLineupsMsg ? (
        <div>No lineups played that match the filters set above.</div>
      ) : (
        <Table
          data={data}
          columns={columns}
          sorting={sorting}
          setSorting={setSorting}
          autoWidth={true}
          showColorOnHover={true}
          disableStickyColumn={true}
        />
      )}
    </Panel>
  );
}

export function wowString(
  withPlayers: string[],
  withoutPlayers: string[],
  withOnePlayer: string[],
  complement?: boolean
) {
  const withStr = withPlayers.length
    ? [
        <span key={0}>{complement ? "did not have " : "included "}</span>,
        <b key={1}>{withPlayers.join(" and ")}</b>,
        withoutPlayers.length > 0 || withOnePlayer.length > 0 ? (
          <span>{complement ? " or " : " and "}</span>
        ) : (
          ""
        ),
      ]
    : "";

  const withoutStr = withoutPlayers.length
    ? [
        <span key={0}>{complement ? "did include " : "did not include "}</span>,
        <b key={1}>{withoutPlayers.join(" or ")}</b>,
        withOnePlayer.length > 0 ? (
          <span>{complement ? " or " : " and "}</span>
        ) : (
          ""
        ),
      ]
    : "";

  const withOneStr = withOnePlayer.length
    ? [
        <span key={0}>
          {complement
            ? "did not include exactly one of "
            : "included exactly one of "}
        </span>,
        <b key={1}>{withOnePlayer.join(" or ")}</b>,
      ]
    : "";

  return (
    <div style={{ fontSize: 22, fontWeight: 200 }}>
      {withStr || withoutStr || withOneStr ? (
        <div>
          <span>{"Showing top lineup stats when lineups "}</span>
          {withStr}
          {withoutStr}
          {withOneStr}
          <span>.</span>
        </div>
      ) : (
        <span>{"Showing top lineup stats for all possible lineups."}</span>
      )}
    </div>
  );
}
