import React, { useState, useMemo, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

import { PlayerMultiLeagueSeasonBoxes } from "../../../shared/routers/PlayerRouter";
import { groupBy } from "../../../shared/util/Collections";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { TableNote } from "../core/TableNote";
import {
  seasonString,
  decFormat,
  decFormat2,
  fracDecFormat,
  dec100Format,
  TABLE_EMPTY_VALUE_STR,
  intFormat,
  makePlusMinus,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { sum } from "../../util/Util";
import { PlayerStatColorDomains } from "../../constants/ColorDomains";
import { LEAGUE_COLORS } from "../../constants/ColorConstants";
import { TeamTableCell, TruncatedTableCell } from "../core/TableCell";
import { UserContext } from "../../UserContext";

type BoxScoreType = "perGame" | "per100Poss" | "totals";

function calcStatForType(
  data: PlayerMultiLeagueSeasonBoxes | undefined,
  stat: keyof PlayerMultiLeagueSeasonBoxes,
  type: BoxScoreType
): number | null {
  if (data === undefined) return null;

  if (data[stat] === null) return null;
  else if (type === "totals") {
    return data[stat] as number;
  } else if (type === "perGame") {
    return data.gp === null ? null : (data[stat] as number) / data.gp;
  } else {
    return (100 * (data[stat] as number)) / data.EstPossPlayed;
  }
}

function formatStat(value: number | null, type: BoxScoreType) {
  if (value === null) return TABLE_EMPTY_VALUE_STR;
  if (type === "totals") {
    return value.toFixed(0);
  } else {
    return decFormat(value);
  }
}

function formatFooter(
  value: number | null,
  type: "perGame" | "per100Poss" | "totals"
) {
  if (value === null) return "";
  else if (type === "totals") {
    return value.toLocaleString();
  } else {
    return decFormat(value);
  }
}

const columnHelper = createColumnHelper<PlayerMultiLeagueSeasonBoxes>();

export function PlayerMultiLeagueSeasonBox(props: {
  data: {
    career: PlayerMultiLeagueSeasonBoxes[];
    season: PlayerMultiLeagueSeasonBoxes[][];
  };
  type: "perGame" | "per100Poss" | "totals";
}) {
  const user = useContext(UserContext);
  const [sorting, setSorting] = useState<SortingState>();
  const [careerIndex, setCareerIndex] = useState(0);
  const [showAllLeagues, setShowAllLeagues] = useState(false);
  const { data, type } = props;

  const careerOptions = data.career;
  const footerRow = careerOptions[careerIndex];

  const preparedData: (PlayerMultiLeagueSeasonBoxes & {
    subRows?: PlayerMultiLeagueSeasonBoxes[];
  })[] = data.season
    .map((row) => {
      if (row.length === 1) return row[0];
      const sortedByMin = row.sort((a, b) => {
        if (a.min === b.min) return 0;
        else if (a.min === null) return 1;
        else if (b.min === null) return -1;
        return a.min > b.min ? -1 : 1;
      });
      const sortedByMinFirst = sortedByMin[0];
      if (sortedByMinFirst === undefined) return undefined;
      return { ...sortedByMinFirst, subRows: sortedByMin.slice(1) };
    })
    .filter((d) => d !== undefined) as (PlayerMultiLeagueSeasonBoxes & {
    subRows?: PlayerMultiLeagueSeasonBoxes[];
  })[];

  const flatSeasonData = data.season.flat();
  const groupedBySeason = groupBy(flatSeasonData, (d) =>
    d.season ? d.season.toString() : ""
  );
  // If showAllLeagues is false only show one row per season. The row we choose
  // should be NBA > GLeague > NCAA > [league with most min].
  const filteredData = showAllLeagues
    ? preparedData
    : (Object.values(groupedBySeason)
        .map((seasons) => {
          const sorted = seasons.sort((a, b) => {
            if (a.league === b.league) {
              if (a.min === b.min) return 0;
              else if (a.min === null) return 1;
              else if (b.min === null) return -1;
              return a.min > b.min ? -1 : 1;
            } else if (a.league === "NBA" || b.league === "NBA") {
              return a.league === "NBA" ? -1 : 1;
            } else if (a.league === "G-League" || b.league === "G-League") {
              return a.league === "G-League" ? -1 : 1;
            } else if (a.league === "NCAA" || b.league === "NCAA") {
              return a.league === "NCAA" ? -1 : 1;
            }
            if (a.min === b.min) return 0;
            else if (a.min === null) return 1;
            else if (b.min === null) return -1;
            return a.min > b.min ? -1 : 1;
          });

          const first = sorted[0];

          if (first === undefined) return undefined;

          const subRows =
            first.team === "Total"
              ? flatSeasonData.filter(
                  (d) =>
                    d.season === first.season &&
                    d.league === first.league &&
                    d.team !== "Total"
                )
              : [];

          return {
            ...first,
            subRows: subRows.length > 0 ? subRows : undefined,
          };
        })
        .filter((d) => d !== undefined) as (PlayerMultiLeagueSeasonBoxes & {
        subRows?: PlayerMultiLeagueSeasonBoxes[];
      })[]);

  const hiddenSeasons = useMemo(
    () =>
      new Set(
        Object.keys(groupedBySeason).filter((gbs) => {
          const season = groupedBySeason[gbs];
          return season && season.length > 1;
        })
      ),
    [groupedBySeason]
  );

  // Total NBA GP after 2020-21 season. Use to calculate career dunk numbers
  // while ignoring games from pre-cameras tracking this.
  const nbaSeasonsWithDunkData = data.season
    .flatMap((s) => s)
    .filter(
      (d) =>
        d.league === "NBA" &&
        // We can't use the helper at the bottom of this file b/c this version
        // needs to filter out the total rows.
        d.team !== "Total" &&
        d.season !== null &&
        d.season >= 2021
    );
  const totalNbaGpWithDunks = sum("gp", nbaSeasonsWithDunkData);
  const totalNbaPossWithDunks = sum("EstPossPlayed", nbaSeasonsWithDunkData);

  const columns = useMemo(() => {
    if (footerRow === undefined) return [];
    const toggleButton = showAllLeagues ? (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowAllLeagues(false);
        }}
        style={{
          lineHeight: 0,
          padding: 3,
          marginRight: 5,
          fontSize: ".7em",
          verticalAlign: "top",
        }}
      >
        <IoMdRemove />
      </Button>
    ) : (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowAllLeagues(true);
        }}
        style={{
          lineHeight: 0,
          padding: 3,
          marginRight: 5,
          fontSize: ".7em",
          verticalAlign: "top",
        }}
      >
        <IoMdAdd />
      </Button>
    );

    let g = 0;
    return [
      columnHelper.accessor("league", {
        header: () => <div>{toggleButton}League</div>,
        footer: () => (
          <div style={{ verticalAlign: "top", display: "inline-block" }}>
            <Form.Select
              value={careerIndex}
              style={{
                lineHeight: 1,
                fontSize: ".8rem",
                position: "absolute",
                padding: 2,
                paddingRight: 30,
                width: "auto",
                maxWidth: 200,
              }}
              onChange={(e) => setCareerIndex(parseInt(e.target.value))}
            >
              {careerOptions.map((c, i) => (
                <option key={i} value={i}>
                  {c.league} ({c.gp} games)
                </option>
              ))}
            </Form.Select>
          </div>
        ),
        cell: (info) => (
          <TruncatedTableCell>
            {info.row.depth === 0 ? info.getValue() || "" : ""}
          </TruncatedTableCell>
        ),
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => {
          const val = info.getValue();
          return info.row.depth > 0 || !val ? (
            ""
          ) : (
            <span>
              {seasonString(val.toString())}
              <sup>{hiddenSeasons.has(val.toString()) ? "†" : ""}</sup>
            </span>
          );
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.teamIdsId || undefined}
            id={info.row.original.teamId || undefined}
            name={info.getValue()}
            league={info.row.original.league
              .toLocaleLowerCase()
              .replaceAll(" ", "_")}
            season={
              info.row.original.season
                ? info.row.original.season.toString()
                : undefined
            }
            useSpan={info.getValue() === "Total"}
          />
        ),
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("eligibility", {
        header: () => "Class",
        cell: (info) => {
          const classMap: Record<string, string> = {
            Freshman: "Fr.",
            Sophomore: "So.",
            Junior: "Jr.",
            Senior: "Sr.",
          };
          const val = info.getValue();
          return val ? classMap[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("gp", {
        header: () => "G",
        cell: (info) => intFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["gp"],
        },
        footer: footerRow.gp?.toString() || "",
      }),
      columnHelper.accessor("gs", {
        header: () => "GS",
        cell: (info) => intFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["gs"],
        },
        footer: footerRow.gs?.toString() || "",
      }),
      columnHelper.accessor(
        (row) =>
          type === "totals"
            ? row.min
            : row.min === null || row.gp === null
            ? null
            : row.min / row.gp,
        {
          id: "mpg",
          header: () => (type === "totals" ? "MIN" : "MPG"),
          cell: (info) =>
            type === "totals"
              ? intFormat(info.getValue())
              : decFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["min"],
          },
          footer:
            type === "totals"
              ? intFormat(footerRow.min)
              : decFormat(
                  footerRow.min === null || footerRow.gp === null
                    ? null
                    : footerRow.min / footerRow.gp
                ),
        }
      ),
      columnHelper.accessor((row) => calcStatForType(row, "pts", type), {
        id: "pts",
        header: () => "PTS",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["pts"],
        },
        footer: formatFooter(calcStatForType(footerRow, "pts", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "roff", type), {
        id: "orb",
        header: () => "ORB",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["orb"],
        },
        footer: formatFooter(calcStatForType(footerRow, "roff", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "rdef", type), {
        id: "drb",
        header: () => "DRB",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["drb"],
        },
        footer: formatFooter(calcStatForType(footerRow, "rdef", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "rtot", type), {
        id: "trb",
        header: () => "TRB",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["trb"],
        },
        footer: formatFooter(calcStatForType(footerRow, "rtot", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "ast", type), {
        id: "ast",
        header: () => "AST",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["ast"],
        },
        footer: formatFooter(calcStatForType(footerRow, "ast", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "blk", type), {
        id: "blk",
        header: () => "BLK",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["blk"],
        },
        footer: formatFooter(calcStatForType(footerRow, "blk", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "stl", type), {
        id: "stl",
        header: () => "STL",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["stl"],
        },
        footer: formatFooter(calcStatForType(footerRow, "stl", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "turn", type), {
        id: "turn",
        header: () => "TOV",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: PlayerStatColorDomains[type]["to"],
        },
        footer: formatFooter(calcStatForType(footerRow, "turn", type), type),
      }),
      columnHelper.accessor(
        (row) =>
          type === "perGame"
            ? row.min === null || row.pf === null
              ? null
              : (48 * row.pf) / row.min
            : calcStatForType(row, "pf", type),
        {
          id: "pf",
          header: () => (type === "perGame" ? "PF/48" : "PF"),
          cell: (info) => formatStat(info.getValue(), type),
          meta: {
            group: g++,
            highlights: Highlights.Min,
            colorDomain: PlayerStatColorDomains[type]["pf"],
          },
          footer:
            type === "perGame"
              ? footerRow.pf === null
                ? ""
                : decFormat(
                    footerRow.min === null
                      ? null
                      : (48 * footerRow.pf) / footerRow.min
                  )
              : formatFooter(calcStatForType(footerRow, "pf", type), type),
        }
      ),
      columnHelper.accessor("crashRate", {
        header: () => "Crash %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["crashRate"],
        },
        footer: footerRow.crashRate ? decFormat(100 * footerRow.crashRate) : "",
      }),
      columnHelper.accessor(
        (row) =>
          row.crashRate === null || row.predCrashRate === null
            ? null
            : row.crashRate - row.predCrashRate,
        {
          id: "crashDiff",
          header: () => "vs Exp",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["crashRateDiff"],
          },
          footer:
            footerRow.crashRate === null || footerRow.predCrashRate === null
              ? ""
              : makePlusMinus(dec100Format)(
                  footerRow.crashRate - footerRow.predCrashRate
                ),
        }
      ),
      columnHelper.accessor("roffpct", {
        header: () => "OR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["orbpct"],
        },
        footer: decFormat(footerRow.roffpct),
      }),
      columnHelper.accessor("rdefpct", {
        header: () => "DR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["drbpct"],
        },
        footer: decFormat(footerRow.rdefpct),
      }),
      columnHelper.accessor(
        (row) =>
          row.PlayerPoss === 0 || row.turn === null
            ? null
            : row.turn / row.PlayerPoss,
        {
          id: "topct",
          header: () => "TOV%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Min,
            colorDomain: PlayerStatColorDomains[type]["topct"],
          },
          footer: decFormat(
            footerRow.turn === null
              ? null
              : (100 * footerRow.turn) / footerRow.PlayerPoss
          ),
        }
      ),
      columnHelper.accessor(
        (row) => (row.fg2a && row.fg2m !== null ? row.fg2m / row.fg2a : null),
        {
          id: "2pct",
          header: () => "2P%",
          cell: (info) =>
            fracDecFormat(45, {
              denominator: info.row.original.fg2a,
              numerator: info.row.original.fg2m,
            }),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["2pct"],
          },
          footer:
            footerRow.fg2a && footerRow.fg2m !== null
              ? decFormat((100 * footerRow.fg2m) / footerRow.fg2a)
              : "",
        }
      ),
      columnHelper.accessor(
        (row) => (row.fg3a && row.fg3m !== null ? row.fg3m / row.fg3a : null),
        {
          id: "3pct",
          header: () => "3P%",
          cell: (info) =>
            fracDecFormat(45, {
              denominator: info.row.original.fg3a,
              numerator: info.row.original.fg3m,
            }),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["3pct"],
          },
          footer:
            footerRow.fg3a && footerRow.fg3m !== null
              ? decFormat((100 * footerRow.fg3m) / footerRow.fg3a)
              : "",
        }
      ),
      columnHelper.accessor(
        (row) => (row.fta && row.ftm !== null ? row.ftm / row.fta : null),
        {
          id: "ftpct",
          header: () => "FT%",
          cell: (info) =>
            fracDecFormat(20, {
              denominator: info.row.original.fta,
              numerator: info.row.original.ftm,
            }),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["ftpct"],
          },
          footer:
            footerRow.fta && footerRow.ftm !== null
              ? decFormat((100 * footerRow.ftm) / footerRow.fta)
              : "",
        }
      ),
      columnHelper.accessor(
        (row) => (row.fga && row.fg3a !== null ? row.fg3a / row.fga : null),
        {
          id: "3papct",
          header: () => "3PA%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["3papct"],
          },
          footer:
            footerRow.fga && footerRow.fg3a !== null
              ? decFormat((100 * footerRow.fg3a) / footerRow.fga)
              : "",
        }
      ),
      columnHelper.accessor((row) => row.fg2m, {
        id: "fg2m",
        header: () => "2PM",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["2pm"],
        },
        footer: formatFooter(calcStatForType(footerRow, "fg2m", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "fg2a", type), {
        id: "fg2a",
        header: () => "2PA",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["2pa"],
        },
        footer: formatFooter(calcStatForType(footerRow, "fg2a", type), type),
      }),
      columnHelper.accessor((row) => row.fg3m, {
        id: "fg3m",
        header: () => "3PM",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["3pm"],
        },
        footer: formatFooter(calcStatForType(footerRow, "fg3m", type), type),
      }),
      columnHelper.accessor((row) => calcStatForType(row, "fg3a", type), {
        id: "fg3a",
        header: () => "3PA",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["3pa"],
        },
        footer: formatFooter(calcStatForType(footerRow, "fg3a", type), type),
      }),
      columnHelper.accessor((row) => row.ftm, {
        id: "ftm",
        header: () => "FTM",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["ftm"],
        },
        footer: formatFooter(calcStatForType(footerRow, "ftm", type), type),
      }),
      columnHelper.accessor(
        (row) =>
          type === "perGame"
            ? row.fta === null || row.min === null
              ? null
              : (48 * row.fta) / row.min
            : calcStatForType(row, "fta", type),
        {
          id: "ftarate",
          header: () => (type === "perGame" ? "FTA/48" : "FTA"),
          cell: (info) => formatStat(info.getValue(), type),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["fta"],
          },
          footer: formatFooter(
            type === "perGame"
              ? footerRow.fta === null || footerRow.min === null
                ? null
                : (48 * footerRow.fta) / footerRow.min
              : calcStatForType(footerRow, "fta", type),
            type
          ),
        }
      ),
      columnHelper.accessor((row) => calcStatForType(row, "numDunks", type), {
        id: "numDunks",
        header: () => "Dunks",
        cell: (info) => formatStat(info.getValue(), type),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["numDunks"],
        },
        footer: () => {
          let val = footerRow.numDunks;
          if (val !== null && type === "per100Poss") {
            val = (100 * val) / totalNbaPossWithDunks;
          } else if (val !== null && type === "perGame") {
            val = val / totalNbaGpWithDunks;
          }
          return formatFooter(val, type);
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.numShots ? (row.xPtsFromShots || 0) / row.numShots : null,
        {
          id: "xpps",
          header: () => "xPPS",
          cell: (info) => decFormat2(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["xpps"],
          },
          footer: decFormat2(
            footerRow.numShots
              ? (footerRow.xPtsFromShots || 0) / footerRow.numShots
              : null
          ),
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.numShots ? (row.xPtsLgFromShots || 0) / row.numShots : null,
        {
          id: "xppsLg",
          header: () => "xPPS Lg",
          cell: (info) => decFormat2(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["xppsLg"],
          },
          footer: decFormat2(
            footerRow.numShots
              ? (footerRow.xPtsLgFromShots || 0) / footerRow.numShots
              : null
          ),
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.pts === null ||
          row.fga === null ||
          row.fta === null ||
          row.fga + row.fta === 0
            ? null
            : row.pts / (2 * (row.fga + 0.44 * row.fta)),
        {
          id: "ts",
          header: () => "TS%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: PlayerStatColorDomains[type]["ts"],
          },
          footer:
            footerRow.pts === null ||
            footerRow.fga === null ||
            footerRow.fta === null
              ? ""
              : decFormat(
                  100 *
                    (footerRow.pts /
                      (2 * (footerRow.fga + 0.44 * footerRow.fta)))
                ),
        }
      ),
      columnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["ppp"],
        },
        footer: decFormat2(footerRow.ppp || 0),
      }),
      columnHelper.accessor("usg", {
        header: () => "Usg",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: PlayerStatColorDomains[type]["usg"],
        },
        footer: decFormat(100 * (footerRow.usg || 0)),
      }),
    ];
  }, [
    footerRow,
    showAllLeagues,
    type,
    careerIndex,
    careerOptions,
    hiddenSeasons,
    totalNbaPossWithDunks,
    totalNbaGpWithDunks,
  ]);

  const rowColorMap: Record<number, { backgroundColor: string }> = {};

  let i = 0;
  for (const row of filteredData) {
    const league =
      row.isYouthLeague && row.league !== "NCAA" ? "youth" : row.league;
    const leagueColor = league ? LEAGUE_COLORS[league] : LEAGUE_COLORS["other"];
    if (leagueColor) {
      rowColorMap[i] = {
        backgroundColor: leagueColor,
      };
    } else {
      const otherColor = LEAGUE_COLORS["other"];
      if (otherColor) {
        rowColorMap[i] = {
          backgroundColor: otherColor,
        };
      }
    }
    i++;
  }

  // These stats don't exist for most leagues so for guys that have never played
  // in the NBA just hide all these columns so we don't just see a bunch of
  // empty columns.
  const hasGameStartData = data.career.some((d) => d.gs !== null);
  const hasCrashRateData = data.career.some((d) => d.crashRate !== null);
  const hasShotModelData = data.career.some((d) => d.xPtsFromShots !== null);
  const hasDunkData = data.career.some((d) => d.numDunks !== null);

  const hiddenColumns = {
    gs: hasGameStartData,
    crashRate: hasCrashRateData && type !== "totals",
    crashDiff: hasCrashRateData && type !== "totals",
    roffpct: type !== "totals",
    rdefpct: type !== "totals",
    topct: type !== "totals",
    "2pct": type !== "totals",
    "3pct": type !== "totals",
    ftpct: type !== "totals",
    "3papct": type !== "totals",
    fg2m: type === "totals",
    fg3m: type === "totals",
    ftm: type === "totals",
    ts: type !== "totals",
    xpps: hasShotModelData && type !== "totals",
    xppsLg: hasShotModelData && type !== "totals",
    ppp: type !== "totals",
    usg: type !== "totals",
    // TODO(chrisbu): Make available to everyone or delete this feature.
    numDunks: !!(hasDunkData && user && user.email === "chrisbu@celtics.com"),
  };

  const processSelected = (data: PlayerMultiLeagueSeasonBoxes[]) => {
    if (type === "perGame") {
      return [
        "Selected",
        "",
        "",
        decFormat(sum("age", data) / data.length),
        "",
        sum("gp", data),
        sum("gs", data),
        decFormat(sum("min", data) / sum("gp", data)),
        decFormat(sum("pts", data) / sum("gp", data)),
        decFormat(sum("roff", data) / sum("gp", data)),
        decFormat(sum("rdef", data) / sum("gp", data)),
        decFormat(sum("rtot", data) / sum("gp", data)),
        decFormat(sum("ast", data) / sum("gp", data)),
        decFormat(sum("blk", data) / sum("gp", data)),
        decFormat(sum("stl", data) / sum("gp", data)),
        decFormat(sum("turn", data) / sum("gp", data)),
        decFormat((48 * sum("pf", data)) / sum("min", data)),
        decFormat(
          (100 *
            sum(
              (d: PlayerMultiLeagueSeasonBoxes) =>
                d.crashRate && d.n_crash_opp ? d.crashRate * d.n_crash_opp : 0,
              data
            )) /
            (sum("n_crash_opp", data) || 1)
        ),
        decFormat(
          (100 *
            sum(
              (d: PlayerMultiLeagueSeasonBoxes) =>
                d.predCrashRate && d.crashRate && d.n_crash_opp
                  ? (d.crashRate - d.predCrashRate) * d.n_crash_opp
                  : 0,
              data
            )) /
            (sum("n_crash_opp", data) || 1)
        ),
        decFormat((100 * sum("roff", data)) / sum("roffavail", data)),
        decFormat((100 * sum("rdef", data)) / sum("rdefavail", data)),
        decFormat((100 * sum("turn", data)) / sum("PlayerPoss", data)),
        decFormat((100 * sum("fg2m", data)) / sum("fg2a", data)),
        decFormat((100 * sum("fg3m", data)) / sum("fg3a", data)),
        decFormat((100 * sum("ftm", data)) / sum("fta", data)),
        decFormat((100 * sum("fg3a", data)) / sum("fga", data)),
        "",
        decFormat(sum("fg2a", data) / sum("gp", data)),
        "",
        decFormat(sum("fg3a", data) / sum("gp", data)),
        "",
        decFormat((48 * sum("fta", data)) / sum("min", data)),
        decFormat(
          sum("numDunks", data) / (sum("gp", data.filter(rowHasDunkData)) || 1)
        ),
        decFormat2(sum("xPtsFromShots", data) / sum("numShots", data)),
        decFormat2(sum("xPtsLgFromShots", data) / sum("numShots", data)),
        decFormat(
          100 *
            (sum("pts", data) /
              (2 * (0.44 * sum("fta", data) + sum("fga", data))))
        ),
        decFormat2(sum("pts", data) / sum("PlayerPoss", data)),
        decFormat((100 * sum("PlayerPoss", data)) / sum("UsgAvail", data)),
      ];
    } else if (type === "per100Poss") {
      return [
        "Selected",
        "",
        "",
        decFormat(sum("age", data) / data.length),
        "",
        sum("gp", data),
        sum("gs", data),
        decFormat(sum("min", data) / sum("gp", data)),
        decFormat((100 * sum("pts", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("roff", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("rdef", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("rtot", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("ast", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("blk", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("stl", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("turn", data)) / sum("EstPossPlayed", data)),
        decFormat((100 * sum("pf", data)) / sum("EstPossPlayed", data)),
        decFormat(
          (100 *
            sum(
              (d: PlayerMultiLeagueSeasonBoxes) =>
                d.crashRate && d.n_crash_opp ? d.crashRate * d.n_crash_opp : 0,
              data
            )) /
            (sum("n_crash_opp", data) || 1)
        ),
        decFormat(
          (100 *
            sum(
              (d: PlayerMultiLeagueSeasonBoxes) =>
                d.predCrashRate && d.crashRate && d.n_crash_opp
                  ? (d.crashRate - d.predCrashRate) * d.n_crash_opp
                  : 0,
              data
            )) /
            (sum("n_crash_opp", data) || 1)
        ),
        decFormat((100 * sum("roff", data)) / sum("roffavail", data)),
        decFormat((100 * sum("rdef", data)) / sum("rdefavail", data)),
        decFormat((100 * sum("turn", data)) / sum("PlayerPoss", data)),
        decFormat((100 * sum("fg2m", data)) / sum("fg2a", data)),
        decFormat((100 * sum("fg3m", data)) / sum("fg3a", data)),
        decFormat((100 * sum("ftm", data)) / sum("fta", data)),
        decFormat((100 * sum("fg3a", data)) / sum("fga", data)),
        "",
        decFormat((100 * sum("fg2a", data)) / sum("EstPossPlayed", data)),
        "",
        decFormat((100 * sum("fg3a", data)) / sum("EstPossPlayed", data)),
        "",
        decFormat((100 * sum("fta", data)) / sum("EstPossPlayed", data)),
        decFormat(
          (100 * sum("numDunks", data)) /
            (sum("EstPossPlayed", data.filter(rowHasDunkData)) || 1)
        ),
        decFormat2(sum("xPtsFromShots", data) / sum("numShots", data)),
        decFormat2(sum("xPtsLgFromShots", data) / sum("numShots", data)),
        decFormat(
          100 *
            (sum("pts", data) /
              (2 * (0.44 * sum("fta", data) + sum("fga", data))))
        ),
        decFormat2(sum("pts", data) / sum("PlayerPoss", data)),
        decFormat((100 * sum("PlayerPoss", data)) / sum("UsgAvail", data)),
      ];
    } else if (type === "totals") {
      return [
        "Selected",
        "",
        "",
        decFormat(sum("age", data) / data.length),
        "",
        sum("gp", data),
        sum("gs", data),
        Math.floor(sum("min", data)),
        sum("pts", data),
        sum("roff", data),
        sum("rdef", data),
        sum("rtot", data),
        sum("ast", data),
        sum("blk", data),
        sum("stl", data),
        sum("turn", data),
        sum("pf", data),
        "", // Crash rate.
        "", // Crash diff.
        "", // OR%
        "", // DR%
        "", // TOV%
        "", // 2P%
        "", // 3P%
        "", // FT%
        "", // 3PA%
        sum("fg2m", data),
        sum("fg2a", data),
        sum("fg3m", data),
        sum("fg3a", data),
        sum("ftm", data),
        sum("fta", data),
        sum("numDunks", data),
        "",
        "",
        "",
        "",
        "",
      ];
    }
    // Should never reach this.
    return [];
  };

  return (
    <div>
      <Table
        key={`${type}-${showAllLeagues}`}
        autoWidth={true}
        data={filteredData}
        columns={columns}
        rowColorMap={rowColorMap}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
        hiddenColumns={hiddenColumns}
        processSelected={processSelected}
        expandColumnId={"team"}
      />
      <TableNote
        note={
          <>
            <sup>†</sup> indicates there are multiple leagues for this season,
            which are not currently visible. Clicking
            <IoMdAdd /> beside the League column header reveals them.
          </>
        }
      />
    </div>
  );
}

function rowHasDunkData(d: PlayerMultiLeagueSeasonBoxes) {
  return d.league === "NBA" && d.season !== null && d.season >= 2021;
}
