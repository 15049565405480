import { scaleLinear } from "d3";
import _ from "lodash";

import { NbaDates } from "../../shared/NbaDates";

export const identity = (x: unknown) => x;

/**
 * Creates a string to be used in the <title> of the page. Appends the site name
 * to the provided title.
 */
export function docTitle(title: string) {
  if (title) {
    return title + " · BIA";
  }
  return "BIA";
}

export function yearDiff(date1: number, date2: number) {
  return (
    (new Date(date1).getTime() - new Date(date2).getTime()) /
    (1000 * 60 * 60 * 24 * 365.25)
  );
}

/**
 * Takes a start and end of a domain and interpolates between them to have domainSize points
 * Useful for matching colour scales to simple input ranges.
 *
 * NOTE: As of July 28, 2015 I've realized this might be redundant with d3.range, although
 * the scaleFunc is different.
 *
 * @param start - the start of the domain
 * @param end - the end of the domain
 * @param domainSize - the number of entries to produce in the domain (typically range.length)
 * @param scaleFunc - the function to be used to interpolate between start and end to produce
 *                    the domainSize points (e.g. d3.scale.linear by default)
 */
export function generateDomain(
  start: number,
  end: number,
  domainSize: number,
  scaleFunc = scaleLinear
) {
  const scale = scaleFunc()
    .domain([0, domainSize - 1])
    .range([start, end]);
  const domain = [];

  for (let i = 0; i < domainSize; i++) {
    domain.push(scale(i));
  }

  return domain;
}

export function average<T>(
  key: keyof T | ((item: T) => number | null),
  items: T[]
): number {
  const filteredValues = items.filter((item) => {
    if (typeof key === "function") {
      return key(item) != null;
    } else {
      return item[key] != null;
    }
  });

  const sum = filteredValues.reduce((acc, item) => {
    if (typeof key === "function") {
      return acc + Number(key(item));
    } else {
      return acc + Number(item[key]);
    }
  }, 0);

  return sum / filteredValues.length;
}

export function sum(accessor: any, data: any) {
  if (data === undefined) return 0;
  if (!_.isFunction(accessor)) {
    const prop = accessor;
    accessor = (d: any) => {
      return d.get ? d.get(prop) : d[prop];
    };
  }

  const sum = data.reduce(
    (sum: any, d: any) => (accessor(d) == null ? sum : accessor(d) + sum),
    0
  );

  return sum;
}

export function sumFromField<T>(field: keyof T, data: T[]): number | null {
  // If every element is null or undefined return null.
  if (data.every((d) => d[field] === undefined || d[field] === null)) {
    return null;
  }
  return data.reduce(
    (prev, cur) => ((cur[field] as unknown as number) || 0) + prev,
    0
  );
}

// Function that grabs a field value from the first el. Used when we are
// aggregating a bunch of items and reducing to a single row, some fields we
// just want the value from the first row (like a name).
export function getFirstForField<T, K extends keyof T>(
  field: K,
  data: T[],
  fallback: T[K]
): T[K] {
  const first = data[0];
  if (first) {
    return first[field];
  }
  return fallback;
}

export function weightedAverage(
  weightByName: string,
  statName: string,
  weightByData: any,
  statData?: any
) {
  if (weightByData.length === 0) {
    return null;
  }
  statData = statData || weightByData;
  let result = 0;
  let totalWeight = 0;
  for (let i = 0; i < weightByData.length; i++) {
    result += weightByData[i][weightByName] * statData[i][statName];
    totalWeight += weightByData[i][weightByName];
  }
  if (totalWeight === 0) return null;
  return result / totalWeight;
}

export function extent<T>(data: T[], accessor: (d: T) => number) {
  // return d3Extent(data, accessor) as [number, number];
  const sorted = [...data].sort((a, b) => accessor(a) - accessor(b));
  const first = sorted[0];
  const firstVal = first && accessor(first);
  const last = sorted[sorted.length - 1];
  const lastVal = last && accessor(last);
  // Returning 0,0 probably not useful but if we get an empty array its better
  // than nothing and this prevents us from having to check for undefined on
  // return.
  if (firstVal === undefined && lastVal === undefined)
    return { min: 0, max: 0 };
  else if (firstVal === undefined)
    return { min: lastVal || 0, max: lastVal || 0 };
  else if (lastVal === undefined)
    return { min: firstVal || 0, max: firstVal || 0 };
  return { min: firstVal, max: lastVal };
}

// To be used with ImmutableJS data structures to get the [min,max] values.
export function extentBy(collection: any, accessor: any, accessResult = true) {
  const min = collection.minBy((d: any) =>
    accessor(d) == null ? Infinity : accessor(d)
  );
  const max = collection.maxBy((d: any) =>
    accessor(d) == null ? -Infinity : accessor(d)
  );

  if (accessResult) {
    return [accessor(min), accessor(max)];
  }

  return [min, max];
}

// To be used with ImmutableJS to get [min,max] across a collection of lists.
export function multiExtentBy(
  collections: any,
  accessor: any,
  accessResult = true
) {
  const finalExtent = collections
    .map((collection: any) => extentBy(collection, accessor, false))
    .reduce((result: any, extent: any) => {
      if (result == null) {
        return extent;
      }

      let min, max;

      if (accessor(extent[0]) == null) {
        min = result[0];
      } else {
        min = accessor(result[0]) < accessor(extent[0]) ? result[0] : extent[0];
      }

      if (accessor(extent[1]) == null) {
        max = result[1];
      } else {
        max = accessor(result[1]) > accessor(extent[1]) ? result[1] : extent[1];
      }

      return [min, max];
    }, null);

  if (accessResult) {
    return [accessor(finalExtent[0]), accessor(finalExtent[1])];
  }

  return finalExtent;
}

/**
 * Set this value as the href attribute in an <a> tag and it will download a csv
 * on click.
 */
export function createCsvHref(csv: string) {
  // Convert to byte array to maintain UTF-8 encoding.
  const uint8 = new Uint8Array(csv.length);
  for (let i = 0; i < uint8.length; i++) {
    uint8[i] = csv.charCodeAt(i);
  }

  const blob = new Blob([uint8], { type: "application/csv" });
  return URL.createObjectURL(blob);
}

// get the first non null value for this attribute in the list
export function firstNonNull(list: any, accessor: any) {
  if (!list || !list.size) {
    return undefined;
  }

  if (typeof accessor === "string") {
    const key = accessor;
    accessor = (d: any) => d.get(key);
  }

  for (let i = 0; i < list.size; i++) {
    const value = accessor(list.get(i));
    if (value != null) {
      return value;
    }
  }
}

// Maps column name to estimated value.
export function estimatedMeasurements(whichAreEstimated: string | null) {
  const estimated: Record<string, boolean> = {};
  const columnMap: Record<string, string> = { WingSpan: "wingspan" };

  if (whichAreEstimated) {
    const tokens = whichAreEstimated.split(",");
    tokens.forEach((token) => {
      if (columnMap[token]) {
        const cmt = columnMap[token];
        if (cmt !== undefined) {
          estimated[cmt] = true;
        }
      }
    });
  }

  return estimated;
}

export function strokeDasharrayBottom(width: number, height: number) {
  return `0,${width + height},${width},${height}`;
}

export function pythagoreanWinExpectation(
  pppOff: number | null,
  pppDef: number | null
) {
  if (pppOff == null || pppDef == null) {
    return 0;
  }

  return (
    (82 * Math.pow(pppOff, 14.24)) /
    (Math.pow(pppDef, 14.24) + Math.pow(pppOff, 14.24))
  );
}

// Adds an s if number = 1 otherwise does not.
export function pluralCheck(word: string, number: number, pluralChars = "s") {
  if (number === 1) {
    return word;
  }

  return `${word}${pluralChars}`;
}

export function deepEqual(a: any, b: any) {
  return _.isEqual(a, b);
}

/**
 * Given a date returns an object containing the season it is from (e.g 2022).
 */
export function getSeasonFromDate(dateStr: string) {
  // Parse the date with the time removed.
  const datePart1 = dateStr.split(" ")[0];
  if (!datePart1) return null;

  const target = Date.parse(datePart1);
  for (const season of Object.keys(NbaDates)) {
    const seasonDates = NbaDates[season];
    // Something went wrong!
    if (seasonDates === undefined) return null;

    const start = seasonDates.preseason.start;
    const end = seasonDates.postseason.end;
    if (target >= start && target <= end) {
      return season;
    }
  }
  return null;
}

export function percentile(sortedArray: number[], percentile: number) {
  const pos = (sortedArray.length - 1) * percentile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sortedArray[base + 1] !== undefined) {
    const sab = sortedArray[base] || 0;
    const sab1 = sortedArray[base + 1] || 0;
    return sab + rest * (sab1 - sab);
  } else {
    return sortedArray[base] || 0;
  }
}

// Copied from the SQL version at
// https://www.sqlservercentral.com/Forums/Topic965963-338-1.aspx
export function normdist(
  value: number,
  mean: number,
  sigma: number,
  cumulative: boolean
) {
  let z, t, ans, returnvalue;

  const x = (value - mean) / sigma;
  if (cumulative) {
    z = Math.abs(x) / Math.sqrt(2);
    t = 1 / (1 + z / 2);
    ans =
      (t *
        Math.exp(
          -z * z -
            1.26551223 +
            t *
              (1.00002368 +
                t *
                  (0.37409196 +
                    t *
                      (0.09678418 +
                        t *
                          (-0.18628806 +
                            t *
                              (0.27886807 +
                                t *
                                  (-1.13520398 +
                                    t *
                                      (1.48851587 +
                                        t *
                                          (-0.82215223 + t * 0.17087277))))))))
        )) /
      2;

    if (x <= 0) {
      returnvalue = ans;
    } else {
      returnvalue = 1 - ans;
    }
  } else {
    returnvalue =
      (1 / (sigma * Math.sqrt(2 * Math.PI))) *
      Math.exp(-((value - mean) * (value - mean)) / (2 * (sigma * sigma)));
  }

  return returnvalue;
}

export function toDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}
