import React from "react";
import { Link } from "react-router-dom";
import { extent } from "d3";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PnrAggregate } from "../../../shared/routers/PnrRouter_2";
import {
  Positions,
  Highlights,
  pnrLabels,
  BHR_DEFENDER_COVERAGES,
  SCR_DEFENDER_COVERAGES,
  SCR_DEFENDER_DEPTH,
  SCREEN_TYPES,
} from "../../constants/AppConstants";
import {
  dateFormat,
  decFormat2,
  pctFormat,
  seFormat2,
} from "../../util/Format";
import { VideoControl } from "../query/VideoControl";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";

const BHR_DEFENDER_COVERAGES_MAP = valueLabelArrToMap(BHR_DEFENDER_COVERAGES);

const SCR_DEFENDER_COVERAGES_MAP = valueLabelArrToMap(SCR_DEFENDER_COVERAGES);

const SCR_DEFENDER_MAP = valueLabelArrToMap(SCR_DEFENDER_DEPTH);

const SCREEN_TYPE_MAP = valueLabelArrToMap(SCREEN_TYPES);

const columnHelper = createColumnHelper<PnrAggregate>();

export function PnrQueryResultsTable(props: {
  data: PnrAggregate[];
  groupBy: string[];
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
  onVideo: (data: PnrAggregate, groupBy: string[]) => void;
  columns: (keyof PnrAggregate)[];
  actionChanceLevel: string;
}) {
  const {
    data,
    groupBy,
    sorting,
    setSorting,
    onVideo,
    columns: selectedColumns,
    actionChanceLevel,
  } = props;

  const columns = React.useMemo(() => {
    const minPicks = Math.min(...data.map((d) => d.numPicks));
    const maxPicks = Math.max(...data.map((d) => d.numPicks));

    const isActionLevel = actionChanceLevel === "action";

    let g = 0;
    return [
      columnHelper.accessor("season", {
        header: () => "Season",
        meta: { group: g++ },
      }),
      columnHelper.accessor("playoffs", {
        header: () => "Playoffs",
        cell: (info) => (info.getValue() ? "Playoffs" : "Regular Season"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("offTeam", {
        header: () => "Offense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.offTeamId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("defTeam", {
        header: () => "Defense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.defTeamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandler", {
        header: () => "Ballhandler",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerId}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screener", {
        header: () => "Screener",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue() || undefined}
            id={info.row.original.screenerId || undefined}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screenerDefender", {
        header: () => "Screener Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue() || undefined}
            id={info.row.original.screenerId || undefined}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandlerDefender", {
        header: () => "Ballhandler Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerDefenderId}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screenerDefender", {
        header: () => "Screener Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.screenerDefenderId}
            showPlayerStatusIcons={true}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandlerPos", {
        header: () => "Ballhandler Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("screenerPos", {
        header: () => "Screener Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandlerDefenderPos", {
        header: () => "Ballhandler Defender Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("screenerDefenderPos", {
        header: () => "Screener Defender Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("type", {
        header: () => "Type",
        cell: (info) => {
          const val = info.getValue();
          return val === "pnr"
            ? "PNR"
            : val === "dho"
            ? "DHO"
            : val === "fake_dho"
            ? "Fake DHO"
            : "Unknown";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("screenType", {
        header: () => "Screen Type",
        cell: (info) => {
          const val = info.getValue();
          return val ? SCREEN_TYPE_MAP[val as string] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("bhrDefenderCoverage", {
        header: () => "Bhr Def Coverage",
        cell: (info) => {
          const val = info.getValue();
          return val ? BHR_DEFENDER_COVERAGES_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefenderCoverage", {
        header: () => "Coverage",
        cell: (info) => {
          const val = info.getValue();
          return val ? SCR_DEFENDER_COVERAGES_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefenderDepth", {
        header: () => "Scr Def Depth",
        cell: (info) => {
          const val = info.getValue();
          return val ? SCR_DEFENDER_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          const val = info.getValue();
          return val ? dateFormat(new Date(val.substring(0, 10))) : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("gameString", {
        header: () => "Game",
        cell: (info) => (
          <Link to={`/game/${info.row.original.gameId}`}>
            {info.getValue()}
          </Link>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("period", {
        header: () => "Period",
        meta: { group: g++ },
      }),
      columnHelper.accessor("direction", {
        header: () => "Direction",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.direction[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("rollPop", {
        header: () => "Roll / Pop",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.roll_pop[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("pickSlip", {
        header: () => "Pick / Slip",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.scr_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("locationType", {
        header: () => "Location",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.location_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("floorSide", {
        header: () => "Floor Side",
        cell: (info) => {
          const val = info.getValue();
          if (!val) return "";
          return val[0]!.toUpperCase() + val.slice(1);
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("numPerimSpacers", {
        header: () => "# Perim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("numElitePerimSpacers", {
        header: () => "# Elite Perim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("numAboveAvgPerimSpacers", {
        header: () => "# Above Avg Perim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("numBelowAvgPerimSpacers", {
        header: () => "# Below Avg Perim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("numRimSpacers", {
        header: () => "# Rim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("emptySide", {
        header: () => "Empty Side",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fiveOut", {
        header: () => "5-Out",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("horns", {
        header: () => "Horns",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("dunkerFilled", {
        header: () => "Dunker Filled",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("drag", {
        header: () => "Drag",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      // columnHelper.accessor("double", {
      //   header: () => "Double",
      //   cell: (info) => (info.getValue() ? "Yes" : "No"),
      //   meta: { group: g++ },
      // }),
      columnHelper.accessor("numPicks", {
        header: () => "#",
        cell: (info) => info.getValue().toLocaleString(),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: [minPicks, maxPicks],
        },
      }),
      columnHelper.accessor(isActionLevel ? "xppp_action" : "xppp", {
        header: () => "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
          heatmap: true,
        },
      }),
      columnHelper.accessor(isActionLevel ? "stderr_action" : "stderr", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: {
          group: g,
          neutralColorScheme: true,
          colorDomain: extent(
            data.map((d) => (isActionLevel ? d.stderr_action : d.stderr) || 0)
          ).reverse() as [number, number],
        },
      }),
      columnHelper.accessor(isActionLevel ? "xpps_action" : "xpps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [1, 1.2],
        },
      }),
      columnHelper.accessor(isActionLevel ? "xOrbPct_action" : "xOrbPct", {
        header: () => "xOR%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [0.22, 0.35],
          group: g,
        },
      }),
      columnHelper.accessor("xTurnoverPct", {
        header: () => "xTOV%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Min,
          colorDomain: [0.15, 0.1],
          group: g++,
        },
      }),
      columnHelper.accessor(isActionLevel ? "layupPct_action" : "layupPct", {
        header: () => "Layup %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.26, 0.42],
        },
      }),
      columnHelper.accessor(isActionLevel ? "threePct_action" : "threePct", {
        header: () => "3PA %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.35, 0.46],
        },
      }),
      columnHelper.accessor(
        isActionLevel ? "nonLayupTwoPct_action" : "nonLayupTwoPct",
        {
          header: () => "NL2 %",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Min,
            colorDomain: [0.38, 0.21],
          },
        }
      ),
      columnHelper.group({
        id: "bhr",
        meta: { group: g },
        header: "Ballhandler",
        columns: [
          columnHelper.accessor("bhrShootPct", {
            header: () => "Shoot %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.2, 0.4],
              group: g,
            },
          }),
          columnHelper.accessor("bhrxPPS", {
            header: () => "xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.95, 1.18],
              group: g,
            },
          }),
          columnHelper.accessor("bhr3paPct", {
            header: () => "3PA %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.1, 0.4],
              group: g,
            },
          }),
          columnHelper.accessor("bhr3pPct", {
            header: () => "BHR 3P %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.25, 0.42],
              group: g,
            },
          }),
          columnHelper.accessor("bhr2pJumperRate", {
            header: () => "2P Jumper Rate",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              colorDomain: [0.3, 0.1],
              group: g,
            },
          }),
          columnHelper.accessor("bhr2pJumperxPPS", {
            header: () => "2P Jumper xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.8, 1.1],
              group: g,
            },
          }),
          columnHelper.accessor("bhrAstOppPct", {
            header: () => "Ast Opp %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.2, 0.3],
              group: g,
            },
          }),
          columnHelper.accessor("bhrToPct", {
            header: () => "TOV %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              colorDomain: [0.07, 0.03],
              group: g,
            },
          }),
          columnHelper.accessor("bhrAstScrPct", {
            header: () => "Ast Scr %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.08, 0.13],
              group: g++,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "scr",
        meta: { group: g },
        header: "Screener",
        columns: [
          columnHelper.accessor("scrCatchPct", {
            header: () => "Catch %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.2, 0.3],
              group: g,
            },
          }),
          columnHelper.accessor("scrxPPS", {
            header: () => "xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [1.1, 1.3],
              group: g,
            },
          }),
          columnHelper.accessor("scrCatchShootPct", {
            header: () => "Catch Shoot %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.4, 0.6],
              group: g,
            },
          }),
          columnHelper.accessor("scrAstOppPct", {
            header: () => "Ast Opp %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.12, 0.24],
              group: g,
            },
          }),
          columnHelper.accessor("scrToPct", {
            header: () => "TOV %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Min,
              colorDomain: [0.1, 0.03],
              group: g,
            },
          }),
          columnHelper.accessor("scrPopPct", {
            header: () => "Pop %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.2, 0.5],
              group: g,
            },
          }),
          columnHelper.accessor("scrPop3paPct", {
            header: () => "Pop 3PA %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.4, 0.8],
              group: g,
            },
          }),
          columnHelper.accessor("scrPopxPPS", {
            header: () => "Pop xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [1, 1.2],
              group: g,
            },
          }),
          columnHelper.accessor("scrPop3pPct", {
            header: () => "Pop 3P %",
            cell: (info) => pctFormat(info.getValue()),
            meta: {
              highlights: Highlights.Max,
              colorDomain: [0.22, 0.45],
              group: g++,
            },
          }),
        ],
      }),
      columnHelper.accessor(isActionLevel ? "ppp_action" : "ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
        },
      }),
      columnHelper.accessor(isActionLevel ? "pps_action" : "pps", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [1, 1.2],
        },
      }),
      columnHelper.accessor(
        isActionLevel ? "turnoverPct_action" : "turnoverPct",
        {
          header: () => "TOV%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            highlights: Highlights.Min,
            colorDomain: [0.17, 0.1],
            group: g,
          },
        }
      ),
      columnHelper.accessor(isActionLevel ? "orbPct_action" : "orbPct", {
        header: () => "OR%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [0.24, 0.42],
          group: g,
        },
      }),
      columnHelper.accessor(
        isActionLevel ? "layupFgPct_action" : "layupFgPct",
        {
          header: () => "Layup FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: [0.54, 0.63],
          },
        }
      ),
      columnHelper.accessor(
        isActionLevel ? "threeFgPct_action" : "threeFgPct",
        {
          header: () => "Three FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: [0.3, 0.4],
          },
        }
      ),
      columnHelper.accessor(
        isActionLevel ? "nonLayupTwoFgPct_action" : "nonLayupTwoFgPct",
        {
          header: () => "NL2 FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [0.4, 0.47],
          },
        }
      ),
      columnHelper.accessor("hasVideo", {
        header: () => "Video",
        cell: (info) => {
          return info.getValue() ? (
            <VideoControl
              data={info.row.original}
              onVideo={(d) => onVideo(d, groupBy)}
            />
          ) : (
            ""
          );
        },
        meta: { group: g++ },
      }),
    ];
  }, [data, actionChanceLevel, onVideo, groupBy]);

  const hiddenColumns = {
    season: groupBy.includes("season"),
    playoffs: groupBy.includes("playoffs"),
    period: groupBy.includes("period"),
    gameDate: groupBy.includes("gameId"),
    gameString: groupBy.includes("gameId"),
    offTeam: groupBy.includes("offTeamId"),
    defTeam: groupBy.includes("defTeamId"),
    screener: groupBy.includes("screenerId"),
    ballhandler: groupBy.includes("ballhandlerId"),
    screenerDefender: groupBy.includes("screenerDefenderId"),
    ballhandlerDefender: groupBy.includes("ballhandlerDefenderId"),
    ballhandlerPos: groupBy.includes("ballhandlerPos"),
    screenerPos: groupBy.includes("screenerPos"),
    ballhandlerDefenderPos: groupBy.includes("ballhandlerDefenderPos"),
    screenerDefenderPos: groupBy.includes("screenerDefenderPos"),
    type: groupBy.includes("type"),
    screenType: groupBy.includes("screenType"),
    direction: groupBy.includes("direction"),
    bhrDefenderCoverage: groupBy.includes("bhrDefenderCoverage"),
    scrDefenderCoverage: groupBy.includes("scrDefenderCoverage"),
    scrDefenderDepth: groupBy.includes("scrDefenderDepth"),
    floorSide: groupBy.includes("floorSide"),
    numPerimSpacers: groupBy.includes("numPerimSpacers"),
    numElitePerimSpacers: groupBy.includes("numElitePerimSpacers"),
    numAboveAvgPerimSpacers: groupBy.includes("numAboveAvgPerimSpacers"),
    numBelowAvgPerimSpacers: groupBy.includes("numBelowAvgPerimSpacers"),
    numRimSpacers: groupBy.includes("numRimSpacers"),
    // double: groupBy.includes("double"),
    horns: groupBy.includes("horns"),
    drag: groupBy.includes("drag"),
    emptySide: groupBy.includes("emptySide"),
    fiveOut: groupBy.includes("fiveOut"),
    dunkerFilled: groupBy.includes("dunkerFilled"),
    rollPop: groupBy.includes("rollPop"),
    pickSlip: groupBy.includes("pickSlip"),
    locationType: groupBy.includes("locationType"),
    // Selectable columns:
    xppp: selectedColumns.includes("xppp"),
    xppp_action: selectedColumns.includes("xppp"),
    xpps: selectedColumns.includes("xpps"),
    xpps_action: selectedColumns.includes("xpps"),
    stderr: selectedColumns.includes("stderr"),
    stderr_action: selectedColumns.includes("stderr"),
    ppp: selectedColumns.includes("ppp"),
    ppp_action: selectedColumns.includes("ppp"),
    pps: selectedColumns.includes("pps"),
    pps_action: selectedColumns.includes("pps"),
    layupPct: selectedColumns.includes("layupPct"),
    layupPct_action: selectedColumns.includes("layupPct"),
    threePct: selectedColumns.includes("threePct"),
    threePct_action: selectedColumns.includes("threePct"),
    nonLayupTwoPct: selectedColumns.includes("nonLayupTwoPct"),
    nonLayupTwoPct_action: selectedColumns.includes("nonLayupTwoPct"),
    xTurnoverPct:
      selectedColumns.includes("xTurnoverPct") && actionChanceLevel === "poss",
    turnoverPct: selectedColumns.includes("turnoverPct"),
    turnoverPct_action: selectedColumns.includes("turnoverPct"),
    orbPct: selectedColumns.includes("orbPct"),
    orbPct_action: selectedColumns.includes("orbPct"),
    xOrbPct: selectedColumns.includes("xOrbPct"),
    xOrbPct_action: selectedColumns.includes("xOrbPct"),

    bhrShootPct: selectedColumns.includes("bhrShootPct"),
    bhrxPPS: selectedColumns.includes("bhrxPPS"),
    bhr3paPct: selectedColumns.includes("bhr3paPct"),
    bhr3pPct: selectedColumns.includes("bhr3pPct"),
    bhr2pJumperRate: selectedColumns.includes("bhr2pJumperRate"),
    bhr2pJumperxPPS: selectedColumns.includes("bhr2pJumperxPPS"),
    bhrAstOppPct: selectedColumns.includes("bhrAstOppPct"),
    bhrToPct: selectedColumns.includes("bhrToPct"),
    bhrAstScrPct: selectedColumns.includes("bhrAstScrPct"),

    scrCatchPct: selectedColumns.includes("scrCatchPct"),
    scrxPPS: selectedColumns.includes("scrxPPS"),
    scrCatchShootPct: selectedColumns.includes("scrCatchShootPct"),
    scrAstOppPct: selectedColumns.includes("scrAstOppPct"),
    scrToPct: selectedColumns.includes("scrToPct"),
    scrPopPct: selectedColumns.includes("scrPopPct"),
    scrPop3paPct: selectedColumns.includes("scrPop3paPct"),
    scrPopxPPS: selectedColumns.includes("scrPopxPPS"),
    scrPop3pPct: selectedColumns.includes("scrPop3pPct"),

    layupFgPct: selectedColumns.includes("layupFgPct"),
    layupFgPct_action: selectedColumns.includes("layupFgPct"),
    threeFgPct: selectedColumns.includes("threeFgPct"),
    threeFgPct_action: selectedColumns.includes("threeFgPct"),
    nonLayupTwoFgPct: selectedColumns.includes("nonLayupTwoFgPct"),
    nonLayupTwoFgPct_action: selectedColumns.includes("nonLayupTwoFgPct"),
  };

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      hiddenColumns={hiddenColumns}
      setSorting={setSorting}
      virtualScroll={true}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}

function valueLabelArrToMap(arr: { value: string; label: string }[]) {
  return arr.reduce((acc, { value, label }) => {
    acc[value] = label;
    return acc;
  }, {} as Record<string, string>);
}
