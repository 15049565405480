import React, { ReactNode } from "react";
import {
  Form,
  Row,
  Col,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import { MultiSelect } from "../core/MultiSelect";
import { BooleanInput } from "../core/BooleanInput";
import { Slider } from "../core/Slider";
import { NumberInput } from "../core/NumberInput";
import { pctFormat } from "../../util/Format";
import { Restrict } from "../core/Restrict";

type FilterFormRange<T> = {
  type: "range";
  key2: keyof T;
  maxValue: number;
  stepSize: number;
  fmt: (v1: number, v2: number) => string;
};

type FilterFormDateRange<T> = {
  type: "daterange";
  key2: keyof T;
};

type FilterFormBoolean = {
  type: "boolean";
};

type FilterFormMultiSelect = {
  type: "multiselect";
  options: { value: string; label: string }[];
};

type FilterFormNumericMultiSelect = {
  type: "numericmultiselect";
  options: { value: number; label: string }[];
};

type FilterFormSelect = {
  type: "select";
  options: { value: string; label: string }[];
};

type FilterFormNumber = {
  type: "number";
};

// Special case for leverage.
type FilterFormLeverage<T> = {
  type: "leverage";
  key2: keyof T;
};

type FilterFormCustom = {
  type: "custom";
  render: () => ReactNode;
};

// Just a placeholder for alignment.
type FilterFormBlank = { type: "blank" };

type FilterFormInput<T> =
  | FilterFormBlank
  | ({
      label: string;
      key: keyof T;
      biaonly?: boolean;
    } & (
      | FilterFormBoolean
      | FilterFormSelect
      | FilterFormMultiSelect
      | FilterFormNumericMultiSelect
      | FilterFormDateRange<T>
      | FilterFormRange<T>
      | FilterFormLeverage<T>
      | FilterFormNumber
      | FilterFormCustom
    ));

export function FilterForm<T>(props: {
  filters: T;
  setFilters: (f: T) => void;
  controls: FilterFormInput<T>[][][];
}) {
  const { filters, setFilters, controls } = props;

  // Iterate through list of controls and every two will be put in a row.
  const rows = Array.from(
    { length: Math.ceil(controls.length / 2) },
    (_, i) => i
  );

  let iterator = 0;

  return (
    <Form>
      <Form.Group
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: 15,
          marginBottom: 15,
        }}
      >
        {rows.map((r) => {
          const leftCol = controls[iterator] || [];
          const rightCol = controls[iterator + 1] || [];
          iterator += 2;
          return (
            <Row key={r}>
              {[leftCol, rightCol].map((col, i) => {
                const onlyBooleanControls =
                  col.flatMap((c) =>
                    c.filter((c) => c.type !== "boolean" && c.type !== "blank")
                  ).length === 0;
                return (
                  <Col
                    key={i}
                    style={{
                      background: onlyBooleanControls ? undefined : "#f4f4f4",
                      borderRadius: 4,
                      margin: 4,
                      padding: 12,
                    }}
                  >
                    {col.map((c, j) => (
                      <Row key={j} style={{ marginBottom: 12 }}>
                        {c.map((c, k) => {
                          let inputElement: ReactNode = `${c.type} not implemented`;
                          if (
                            c.type === "multiselect" ||
                            c.type === "numericmultiselect"
                          ) {
                            inputElement = (
                              <MultiSelect
                                values={(c.options || []).map((o) => {
                                  return {
                                    value: o.value.toString(),
                                    label: o.label,
                                  };
                                })}
                                selected={(
                                  (filters[c.key] || []) as string[]
                                ).map((v) => v.toString())}
                                onChange={(vals) => {
                                  const newFilters = {
                                    ...filters,
                                    [c.key]:
                                      c.type === "numericmultiselect"
                                        ? vals.map((v) => parseInt(v))
                                        : vals,
                                  };
                                  if (vals.length === 0) {
                                    delete newFilters[c.key];
                                  }
                                  setFilters(newFilters);
                                }}
                              />
                            );
                          } else if (c.type === "select") {
                            inputElement = (
                              <MultiSelect
                                limit={1}
                                values={(c.options || []).map((o) => {
                                  return { value: o.value, label: o.label };
                                })}
                                selected={filters[c.key] as string[]}
                                onChange={(vals) =>
                                  setFilters({ ...filters, [c.key]: vals })
                                }
                              />
                            );
                          } else if (c.type === "boolean") {
                            inputElement = (
                              <BooleanInput
                                name={c.key as string}
                                value={filters[c.key] as boolean}
                                onChange={(val) =>
                                  setFilters({
                                    ...filters,
                                    [c.key]: val,
                                  })
                                }
                              />
                            );
                          } else if (c.type === "daterange") {
                            inputElement = (
                              <div>
                                <Form.Control
                                  type="date"
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                  }}
                                  value={filters[c.key] as string}
                                  onChange={(evt) => {
                                    setFilters({
                                      ...filters,
                                      [c.key]: evt.target.value,
                                    });
                                  }}
                                />
                                {" to "}
                                <Form.Control
                                  type="date"
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                  }}
                                  value={filters[c.key2] as string}
                                  onChange={(evt) => {
                                    setFilters({
                                      ...filters,
                                      [c.key2]: evt.target.value,
                                    });
                                  }}
                                />
                              </div>
                            );
                          } else if (c.type === "range") {
                            const multiple = 1 / c.stepSize;

                            inputElement = (
                              <div>
                                <div style={{ height: 8 }}>
                                  <Slider
                                    numSteps={c.maxValue * multiple}
                                    value={{
                                      min:
                                        ((filters[c.key] as number) || 0) *
                                        multiple,
                                      max:
                                        ((filters[c.key2] as number) ||
                                          c.maxValue) * multiple,
                                    }}
                                    onChange={(v: {
                                      min: number;
                                      max: number;
                                    }) => {
                                      const newFilters = {
                                        ...filters,
                                        [c.key]: v.min / multiple,
                                        [c.key2]: v.max / multiple,
                                      };
                                      if (v.min === 0) {
                                        delete newFilters[c.key];
                                      }
                                      if (v.max === c.maxValue * multiple) {
                                        delete newFilters[c.key2];
                                      }
                                      setFilters(newFilters);
                                    }}
                                  />
                                </div>
                                <div>
                                  {c.fmt(
                                    (filters[c.key] as number) || 0,
                                    (filters[c.key2] as number) || c.maxValue
                                  )}
                                </div>
                              </div>
                            );
                          } else if (c.type === "number") {
                            inputElement = (
                              <NumberInput
                                value={filters[c.key] || {}}
                                onChange={(v) => {
                                  setFilters({
                                    ...filters,
                                    [c.key]: v || undefined,
                                  });
                                }}
                              />
                            );
                          } else if (c.type === "leverage") {
                            inputElement = (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "top",
                                  gap: 8,
                                }}
                              >
                                <div>
                                  <div style={{ height: 8 }}>
                                    <Slider
                                      numSteps={200}
                                      value={{
                                        min: (filters[c.key] as number) || 0,
                                        max: (filters[c.key2] as number) || 200,
                                      }}
                                      onChange={(v: {
                                        min: number;
                                        max: number;
                                      }) =>
                                        setFilters({
                                          ...filters,
                                          [c.key]: v.min,
                                          [c.key2]: v.max,
                                        })
                                      }
                                    />
                                  </div>
                                  <div>
                                    {leverageString(
                                      (filters[c.key] as number) || 0,
                                      (filters[c.key2] as number) || 200
                                    )}
                                  </div>
                                </div>
                                <DropdownButton
                                  as={ButtonGroup}
                                  title="Jump To"
                                >
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setFilters({
                                          ...filters,
                                          [c.key]: 0,
                                          [c.key2]: 200,
                                        });
                                      }}
                                    >
                                      All Possessions
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setFilters({
                                          ...filters,
                                          [c.key]: 2,
                                          [c.key2]: 200,
                                        });
                                      }}
                                    >
                                      No Garbage Time
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setFilters({
                                          ...filters,
                                          [c.key]: 15,
                                          [c.key2]: 200,
                                        });
                                      }}
                                    >
                                      Close Game
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setFilters({
                                          ...filters,
                                          [c.key]: 50,
                                          [c.key2]: 200,
                                        });
                                      }}
                                    >
                                      High Leverage
                                    </Dropdown.Item>
                                  </>
                                </DropdownButton>
                              </div>
                            );
                          } else if (c.type === "custom") {
                            inputElement = c.render();
                          } else if (c.type === "blank") {
                            inputElement = null;
                          }
                          if (c.type !== "blank" && c.biaonly) {
                            return (
                              <Col key={k}>
                                <Restrict roles={["bia"]}>
                                  <Form.Label>{c.label} [BIA ONLY]</Form.Label>
                                  {inputElement}
                                </Restrict>
                              </Col>
                            );
                          }
                          return (
                            <Col key={k}>
                              <Form.Label>
                                {c.type !== "blank" ? c.label : ""}
                              </Form.Label>
                              {inputElement}
                            </Col>
                          );
                        })}
                      </Row>
                    ))}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Form.Group>
    </Form>
  );
}

function leverageString(fromLeverage: number, toLeverage: number) {
  return `${pctFormat(fromLeverage / 1_000)} - ${pctFormat(
    toLeverage / 1_000
  )}`;
}
