import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";

import { RosterPlayer } from "../../../shared/routers/RosterRouter";
import { ContractLabel } from "./ContractLabel";
import { PlayerTableCell } from "../core/TableCell";

const useStyles = makeStyles(() =>
  createStyles({
    contractLabel: {
      marginRight: 10,
      padding: "0.2em 0.6em",
    },
    inactive: {
      color: "#888",
    },
  })
);

/**
 * Renders a link to a player page, possibly including contract information.
 */
export function PlayerLink(props: {
  player: RosterPlayer;
  contractSeason: string;
  showInactive?: boolean;
}) {
  const classes = useStyles();
  const { player, contractSeason } = props;

  if (!player || player.playerId == null) {
    return null;
  }

  let contractLabel;
  if (contractSeason != null && player.contractStatus) {
    contractLabel = (
      <ContractLabel
        className={classes.contractLabel}
        status={player.playerStatusLk}
        type={player.contractStatus}
      />
    );
  }

  let playerName;
  if (player.firstName || player.lastName) {
    playerName = `${player.firstName || ""} ${player.lastName}`.trim();
  } else {
    playerName = "Unknown";
  }
  return (
    <PlayerTableCell
      useSpans={true}
      id={player.playerId}
      name={
        <span
          className={cx(
            { [classes.inactive]: props.showInactive },
            {
              [`contract-${player.contractStatus}`]:
                contractSeason != null && player.contractStatus,
            }
          )}
        >
          {contractLabel}
          {playerName}
        </span>
      }
      showPlayerStatusIcons={true}
    />
  );
}
